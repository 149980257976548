import { useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";

import { QualificationCriteriaTableWithToggles } from "shared/ui/qualification/table/with-toggles";
import {
  QC_TABLE_TOGGLES_INITIAL_STATE,
  QC_TABLE_TOGGLES_INITIAL_STATE_WITH_BONUS,
} from "shared/lib/constants/qualification-criteria";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { Button } from "shared/ui/shadcn/button";
import { QualificationCriteriaTableTogglesValueI } from "shared/lib/interfaces/qualification-criteria";
import {
  checkIfSelectedQCsQualify,
  checkIfSelectedQCsQualifyWithBonus,
  mapSelectedQCsToRequest,
} from "shared/lib/helpers/qualification-criteria";
import { clsxMerge } from "shared/lib/helpers";

import { UseFetchQualificationCriteriaAndPricingReturnI } from "@/api/routes/qualification-criteria/queries";

export const ACCOUNT_QUALIFICATION_MODAL_ID = "account-qualification-modal";

interface QualificationModalPropsI {
  modalId?: string;
  accountName: string;
  onClose?: () => void;
  onSuccess?: (selectedQCs: QualificationCriteriaTableTogglesValueI) => void;
  qcApi: UseFetchQualificationCriteriaAndPricingReturnI;
}

const QualificationModalContent = ({
  onSuccess = () => {},
  qcApi,
  handleClose,
  setModalTitle,
  setModalDescription,
}: QualificationModalPropsI & ModalChildrenFnPropsI) => {
  const [selectedQCMap, setSelectedQCMap] = useState(
    QC_TABLE_TOGGLES_INITIAL_STATE
  );

  useEffect(() => {
    if (qcApi.isSuccess && qcApi.data.bonusChargeAmount > 0) {
      setSelectedQCMap((prevState) => ({
        ...QC_TABLE_TOGGLES_INITIAL_STATE_WITH_BONUS,
        ...prevState,
      }));
    }
  }, [qcApi.isSuccess, qcApi.data]);

  const { areAllRequiredQCsMet, areAllRequiredQCsWithBonusMet } =
    useMemo(() => {
      const mappedQCs = mapSelectedQCsToRequest(selectedQCMap);

      return {
        areAllRequiredQCsMet: checkIfSelectedQCsQualify(mappedQCs),
        areAllRequiredQCsWithBonusMet:
          checkIfSelectedQCsQualifyWithBonus(mappedQCs),
      };
    }, [selectedQCMap]);
  const areNotAllRequiredQCsMet = !areAllRequiredQCsMet;

  const [skipCounter, setSkipCounter] = useState(0);

  // const { onChangeStatusCancelRef } = useAccountsContext();
  // const onClose = () => {
  //   // TODO - refactor move it to higher level modals and use onClose only
  //   if (onChangeStatusCancelRef.current) {
  //     onChangeStatusCancelRef.current();
  //     onChangeStatusCancelRef.current = null;
  //   }
  //
  //   handleClose();
  //   onCloseProp();
  // };

  const handleSkip = () => {
    setSkipCounter(skipCounter + 1);
  };

  const submitHandler = () => {
    setSkipCounter(0);
    onSuccess(selectedQCMap);
    handleClose();
  };

  useEffect(() => {
    setModalTitle(
      areAllRequiredQCsMet ? "Nice job qualifying the prospect!" : undefined
    );

    setModalDescription(
      areAllRequiredQCsMet
        ? "Review and confirm all qualifications have been met. Note that you will not be paid if the criteria are not met."
        : undefined
    );
  }, [areAllRequiredQCsMet]);

  return (
    <>
      <DataStatesWrapper
        viewName="qualification critera"
        api={qcApi}
        loadingClassName="h-20"
        errorClassName="h-20"
      >
        <QualificationCriteriaTableWithToggles
          className="animate-fadein"
          hasBonus={!!qcApi.data.bonusChargeAmount}
          texts={qcApi.data}
          value={selectedQCMap}
          onChange={setSelectedQCMap}
        />
      </DataStatesWrapper>

      <div className="mt-6 flex items-center justify-between">
        {areAllRequiredQCsMet ? (
          <div className="flex flex-col">
            <div className="flex gap-1 typography-body-4-semibold">
              <span>Potential payout</span>

              {areAllRequiredQCsWithBonusMet && (
                <span className="animate-fadein">+ bonus</span>
              )}
            </div>

            <div className="flex items-center gap-2 typography-header-5-bold">
              <CountUp
                className="animate-fadein"
                prefix="$"
                end={qcApi.data.baseChargeAmount / 100}
              />

              {areAllRequiredQCsWithBonusMet && (
                <>
                  <span className="animate-fadein">+</span>{" "}
                  <CountUp
                    prefix="$"
                    className="animate-fadein"
                    end={qcApi.data.bonusChargeAmount / 100}
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <div
            className={clsxMerge(
              "ae-typography-detail1 pr-6 text-error-content",
              "transition-opacity duration-300",
              {
                "opacity-0": skipCounter === 0 || areAllRequiredQCsMet,
                "opacity-100": skipCounter > 0 && areNotAllRequiredQCsMet,
              }
            )}
          >
            Are you sure? You may not be paid if all qualification criteria are
            not met
          </div>
        )}

        <div className="flex justify-end">
          <Button
            variant="ghost"
            className={clsxMerge("mr-2", "transition-opacity duration-300", {
              "opacity-0": areAllRequiredQCsMet,
              "opacity-100": areNotAllRequiredQCsMet,
            })}
            onClick={skipCounter > 0 ? submitHandler : handleSkip}
          >
            Skip {skipCounter > 0 && "anyways"}
          </Button>

          <Button
            disabled={areNotAllRequiredQCsMet}
            className="w-[190px]"
            onClick={submitHandler}
          >
            Proceed to scheduling
          </Button>
        </div>
      </div>
    </>
  );
};

const QualificationModal = (props: QualificationModalPropsI) => (
  <Modal
    id={props.modalId || ACCOUNT_QUALIFICATION_MODAL_ID}
    shouldCloseOnOverlayClick={false}
    title={`Is ${props.accountName} qualified?`}
    titleClassName="typography-header-6"
    description="Toggle on qualification criteria that are met. Note that you may not be paid if the criteria are not met."
    modalBoxClassName="rounded-lg w-[700px]"
  >
    {(modalProps) => <QualificationModalContent {...props} {...modalProps} />}
  </Modal>
);

export default QualificationModal;
