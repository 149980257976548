import {
  Dispatch,
  EventHandler,
  SetStateAction,
  useEffect,
  useState,
  createContext,
  useMemo,
} from "react";
import { Portal } from "react-portal";

import unionBy from "lodash/unionBy";

import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

import { WIDGETS } from "@/constants/widgets";
import { dispatchCustomEvent } from "@/helpers/events";
import { asyncGet } from "@/helpers/context";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { GLOBAL_STATE_VAR_ACTIVE_WIDGETS } from "@/constants/window-globals";

import {
  WidgetI,
  DialerWidgetI,
  MaxedDialerWidgetI,
  OmitedWidgetBaseProps,
} from "./interface";
import { WidgetDialer, dialerWidgetConditionalCloseAction } from "./dialer";
import { WidgetMaxedDialer } from "./maxed-dialer";
import { useWidgetsSearchQueryParams } from "./use-widgets-search-query-params";
import { dd } from "@/helpers/datadog";
import { LOG_CATEGORIES } from "@/constants/logs";

export * from "@/constants/widgets";

/**
 * NOTE "widgets" object is used to call from none context related parts of the code
 *      (helpers, utils & etc...) which basically deataches function from the
 *      context dependency which is quite useful in many senarios
 */
export const widgets = {
  open: (data: WidgetI) => {
    dd.rum.log(`${LOG_CATEGORIES.WIDGET} - open`, { data });
    dispatchCustomEvent<WidgetI>({
      eventType: CUSTOM_EVENTS.WIDGETS[data?.id].OPEN,
      data,
    });
  },
  /**
   *
   * @param data using partial interface because
   *             we basically need only an id from the data to
   *             close the widget
   */
  close: (data: Omit<WidgetI, OmitedWidgetBaseProps>) => {
    dd.rum.log(`${LOG_CATEGORIES.WIDGET} - close`, { data });
    dispatchCustomEvent({
      eventType:
        CUSTOM_EVENTS.WIDGETS[data?.id as ValueOfObjectFields<typeof WIDGETS>]
          .CLOSE,
      data,
    });
  },
  toggle: (data: WidgetI) => {
    dd.rum.log(`${LOG_CATEGORIES.WIDGET} - toggle`, { data });
    dispatchCustomEvent<WidgetI>({
      eventType: CUSTOM_EVENTS.WIDGETS[data?.id].TOGGLE,
      data,
    });
  },
  trigger: (data: WidgetI) => {
    dd.rum.log(`${LOG_CATEGORIES.WIDGET} - trigger`, { data });
    dispatchCustomEvent<WidgetI>({
      eventType: CUSTOM_EVENTS.WIDGETS[data?.id].TRIGGER,
      data,
    });
  },
};

/**
 * NOTE
 * @param toggle - if opened -> close AND if closed -> open;
 * @param trigger - if opened -> close and open AND if closed -> open
 */
export interface WidgetsContextI {
  activeWidgets?: Array<WidgetI>;
  setActiveWidgets?: Dispatch<SetStateAction<Array<WidgetI>>>;
  open?: (widget?: WidgetI) => void;
  close?: (widget?: Partial<WidgetI>) => void;
  toggle?: (widget?: WidgetI) => void;
  trigger?: (widget?: WidgetI) => void;
}

export const WidgetsContext = createContext<WidgetsContextI>({});

const selectorWidget = (
  id: ValueOfObjectFields<typeof WIDGETS>,
  widgets: Array<WidgetI>
) => widgets.find((w) => w?.id === id);

export const WidgetsProvider = ({ children }: { children?: any }) => {
  useWidgetsSearchQueryParams();

  const [activeWidgets, setActiveWidgets] = useState<Array<WidgetI>>([]);

  const openWidget = (widget?: WidgetI) => {
    setActiveWidgets((widgets) => unionBy([widget as WidgetI], widgets, "id"));
  };

  /**
   * NOTE ability to conditional opening the widgets
   *
   * By default you can open multiple instances of the same widget
   * Primary example would be a chat
   *
   * If you don't want have multiple instances of the same widget
   * you need to manually restrict it
   */
  const handleOpenWidget = async (widget?: WidgetI) => {
    const widgets = await asyncGet(setActiveWidgets);

    switch (widget?.id) {
      /**
       * NOTE shouldn't update active dialer configurations as
       *      it introduces undesirable side effects
       */
      case WIDGETS.DIALER:
        if (!selectorWidget(WIDGETS.DIALER, widgets)) openWidget(widget);
        return;
      /**
       * NOTE shouldn't be able to open multiple insances of the MAXED_DIALER
       */
      case WIDGETS.MAXED_DIALER:
        if (!selectorWidget(WIDGETS.MAXED_DIALER, widgets)) openWidget(widget);
        return;
      default:
        openWidget(widget);
    }
  };

  const closeWidget = (widget?: Partial<WidgetI>) => {
    setActiveWidgets((widgets) => widgets?.filter((w) => w?.id !== widget?.id));
  };

  /**
   * NOTE for each widget to handle state condition that might
   *      need to block closing the widget like for example
   *      dialer should be open while call is active
   *
   *      This function can be called as though hooks but also
   *      using eventBus so we always need to get the latest
   *      activeWidgets
   */
  const handleCloseWidget = async (widget?: Partial<WidgetI>) => {
    const widgets = await asyncGet(setActiveWidgets);

    switch (widget?.id) {
      case WIDGETS.DIALER:
        (() => {
          const activeWidget = selectorWidget(WIDGETS.DIALER, widgets);

          const closeWidgetCallback = () => closeWidget(activeWidget);

          dialerWidgetConditionalCloseAction(
            activeWidget?.state,
            closeWidgetCallback
          );
        })();

        return;
      default:
        closeWidget(widget);
    }
  };

  const handleToggleWidget = async (widget?: WidgetI) => {
    const widgets = await asyncGet(setActiveWidgets);

    if (widgets.find((w) => w.id === widget?.id)) {
      handleCloseWidget(widget);
    } else {
      handleOpenWidget(widget);
    }
  };

  const handleTriggerWidget = async (widget?: WidgetI) => {
    const widgets = await asyncGet(setActiveWidgets);

    if (widgets.find((w) => w.id === widget?.id)) {
      await handleCloseWidget(widget);
      handleOpenWidget(widget);
    } else {
      handleOpenWidget(widget);
    }
  };

  useEffect(() => {
    const widgetTypes = Object.values(WIDGETS);

    const handleEventOpenWidget = (data: CustomEvent<WidgetI>) => {
      const widget = data?.detail;

      handleOpenWidget(widget);
    };

    const handleEventCloseWidget = (data: CustomEvent<WidgetI>) => {
      const widget = data?.detail;

      handleCloseWidget(widget);
    };

    const hanbleEventToggleWidget = (data: CustomEvent<WidgetI>) => {
      const widget = data?.detail;

      handleToggleWidget(widget);
    };

    const hanbleEventTriggerWidget = (data: CustomEvent<WidgetI>) => {
      const widget = data?.detail;
      console.log(widget);
      handleTriggerWidget(widget);
    };

    const removeEventListeners = widgetTypes.map((widgetType) => {
      const openWidgetEventArgs = [
        CUSTOM_EVENTS.WIDGETS[widgetType].OPEN,
        handleEventOpenWidget,
      ] as [type: string, listener: EventListenerOrEventListenerObject];

      const closeWidgetEventArgs = [
        CUSTOM_EVENTS.WIDGETS[widgetType].CLOSE,
        handleEventCloseWidget as EventHandler<any>,
      ] as [type: string, listener: EventListenerOrEventListenerObject];

      const toggleWidgetEventArgs = [
        CUSTOM_EVENTS.WIDGETS[widgetType].TOGGLE,
        hanbleEventToggleWidget as EventHandler<any>,
      ] as [type: string, listener: EventListenerOrEventListenerObject];

      const triggerWidgetEventArgs = [
        CUSTOM_EVENTS.WIDGETS[widgetType].TRIGGER,
        hanbleEventTriggerWidget as EventHandler<any>,
      ] as [type: string, listener: EventListenerOrEventListenerObject];

      window.document.addEventListener(...openWidgetEventArgs);
      window.document.addEventListener(...closeWidgetEventArgs);
      window.document.addEventListener(...toggleWidgetEventArgs);
      window.document.addEventListener(...triggerWidgetEventArgs);

      return () => {
        window.document.removeEventListener(...openWidgetEventArgs);
        window.document.removeEventListener(...closeWidgetEventArgs);
        window.document.removeEventListener(...toggleWidgetEventArgs);
        window.document.removeEventListener(...triggerWidgetEventArgs);
      };
    });

    return () => {
      removeEventListeners.forEach((removeEventListenersByWidgetType) =>
        removeEventListenersByWidgetType()
      );
    };
  }, []);

  /** handle state updates inside the widget */
  const handleWidgetStateChange =
    (widgetId: ValueOfObjectFields<typeof WIDGETS>) => (state: any) => {
      setActiveWidgets((widgets) =>
        widgets?.map((w) => {
          return w.id === widgetId ? { ...w, state } : w;
        })
      );
    };

  /**
   * Saves the current widgets state into a global variable so it can be accessed
   * from higher-order modules.
   *
   * The only use case is DialerGlobalContext.
   */

  (window as any)[GLOBAL_STATE_VAR_ACTIVE_WIDGETS] = activeWidgets;

  const widgetData_Dialer = useMemo(
    () => activeWidgets.find((w) => w?.id === WIDGETS.DIALER) as DialerWidgetI,
    [activeWidgets]
  );

  const widgetData_MaxedDialer = useMemo(
    () =>
      activeWidgets.find(
        (w) => w?.id === WIDGETS.MAXED_DIALER
      ) as MaxedDialerWidgetI,
    [activeWidgets]
  );

  return (
    <WidgetsContext.Provider
      value={{
        activeWidgets,
        setActiveWidgets,
        open: handleOpenWidget,
        close: handleCloseWidget,
        toggle: handleToggleWidget,
        trigger: handleTriggerWidget,
      }}
    >
      {children}

      {/*@ts-ignore*/}
      <Portal>
        {widgetData_Dialer && (
          <WidgetDialer
            {...widgetData_Dialer?.config}
            onChange={handleWidgetStateChange(WIDGETS.DIALER)}
          />
        )}

        {widgetData_MaxedDialer && (
          <WidgetMaxedDialer {...widgetData_MaxedDialer?.config} />
        )}
      </Portal>
    </WidgetsContext.Provider>
  );
};
