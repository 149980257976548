export const NOTIFICATION_TYPES = {
  CUSTOMER_FORUM_POST: "CustomerForumPost",
  EMAIL_OPENED: "EmailOpen",
  EMAIL_RECEIVED: "EmailReceived",
  MISSED_CALL: "MissedCalls",
  AUDITION: "AuditionAssessment",
  MEETING: "MeetingFeedback",
  LIST_RECEIVED: "ListReceived",
  COLD_OUTREACH_EMAIL_RESPONDED: "ColdOutreachAnswered",
  QC_PRE_CHECK_SUCCEEDED: "QCPreCheckSucceeded",
} as const;
