import { FindLeadsButton } from "@/modules/pipeline/account-details/nav/find-leads-btn";

export const DispositionSectionSearchRow = () => {
  return (
    <>
      <hr className="divider-horizontal mx-0 w-full" />
      <div className="mb-2 flex w-full flex-wrap  items-center justify-between gap-x-10 pb-3 pt-5">
        <span className="min-w-[316px] typography-body-4-bold">
          Search for the right person
        </span>

        <div className="flex-grow">
          <FindLeadsButton />
        </div>
      </div>
    </>
  );
};
