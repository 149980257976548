import { FC, useEffect, useState } from "react";
import _noop from "lodash/noop";

import { clsxMerge, safeGetDate } from "shared/lib/helpers";
import { DATE_FORMAT } from "shared/lib/constants/time";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { QualificationCriteriaTableWithToggles } from "shared/ui/qualification/table/with-toggles";

import { useBeforeTabClose } from "@/hooks/use-before-tab-close";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { SubmitBookedMeetingModalCommonPropsI } from "@/modals/account-book-meeting-modal/submit-booked-meeting-modal/index";

interface MeetingBookedCardContentI
  extends SubmitBookedMeetingModalCommonPropsI,
    PropsWithClassNameI {
  isWrapperNoStyles?: boolean;
  isVisible: boolean;
  calendlyEvent: CalendlyEventI;
  notes: string;
  loading?: boolean;
  onNotesUpdate: (notes: string) => void;
  onSubmit: () => void;
}

export const MeetingBookedCardContent: FC<MeetingBookedCardContentI> = ({
  className,
  isWrapperNoStyles,
  isVisible,
  calendlyEvent,
  qcApi,
  selectedQCs,
  notes,
  loading,
  onNotesUpdate,
  onSubmit,
}) => {
  useBeforeTabClose(
    "You are required to submit AE notes after the call ends. Are you sure you want to close the window and lose the progress with the prospect?"
  );

  const { modalAccount } = useAccountsContext();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitClickedOnce, setIsSubmitClickedOnce] = useState(false);

  useEffect(() => {
    if (isSubmitClickedOnce) {
      const condition = notes?.length < 1;

      setIsError(condition);
      setIsDisabled(condition);
    }
  }, [notes, isSubmitClickedOnce]);

  const handleSubmit = () => {
    setIsSubmitClickedOnce(true);

    if (notes?.length < 1) {
      setIsError(true);
    } else {
      onSubmit();
    }
  };

  return (
    <div
      className={
        isWrapperNoStyles
          ? ""
          : clsxMerge(
              "w-[729px] rounded-[10px] bg-white px-8 py-6",
              "transition-[opacity,transform] duration-500",
              isVisible ? "scale-100 opacity-100" : "scale-50 opacity-0",
              className
            )
      }
    >
      <div>
        <section className="mb-6 flex gap-8">
          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1">Lead Name</div>
            <div className="ae-typography-detail1">Lead Title</div>
            <div className="ae-typography-detail1">Company Name</div>
            <div className="ae-typography-detail1">Meeting Time</div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1 text-black/60">
              {modalAccount?.contact?.name || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {modalAccount?.contact?.title || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {modalAccount?.accountName || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {safeGetDate(calendlyEvent?.start_time, DATE_FORMAT)}
            </div>
          </div>
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">Disposition</span>
          </label>
          <div className="ae-typography-detail1 w-full rounded-lg border-[1px] border-black/20 bg-[#DFF4B4] p-3 px-4 text-black">
            Meeting booked
          </div>
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">
              Qualification criteria you have checked off
            </span>
          </label>

          <QualificationCriteriaTableWithToggles
            isEditable={false}
            hasBonus={!!qcApi.data.bonusChargeAmount}
            texts={qcApi.data}
            value={selectedQCs}
            onChange={_noop}
          />
        </section>

        <section className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">
              Notes for Account Executive
            </span>
          </label>

          <textarea
            name="notes"
            value={notes}
            onChange={(ev) => onNotesUpdate(ev.target.value)}
            rows={5}
            className={clsxMerge(
              "ae-typography-body1 textarea w-full resize-none rounded-lg bg-black/5 p-4 focus:outline-none",
              {
                "textarea-bordered border-error-content": isError,
              }
            )}
          />
        </section>

        <section className="flex w-full justify-end">
          <button
            onClick={handleSubmit}
            disabled={isDisabled || loading}
            className={clsxMerge("btn-ae-default w-[140px]", {
              loading,
            })}
          >
            Send
          </button>
        </section>
      </div>
    </div>
  );
};
