import { DISPOSITIONS_V3 } from "shared/lib/constants/dispositions";
import { clsxMerge } from "shared/lib/helpers";
import { FormValidationSchemaI } from "../interface";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { FormEvent, SetStateAction } from "react";
import { FormikErrors } from "formik";

export const DispositionActionsRow = ({
  values,
  isValid,
  isSubmitting,
  //TODO group buttons data together
  actionControlKeys = [],
  buttonTexts = ["Save", "Save & continue"],

  className,
  setValues,
  onSubmit,
}: {
  values: FormValidationSchemaI;
  isValid?: boolean;
  isSubmitting?: boolean;
  className?: string;
  /**
   * Ability to distinguish in final form validation
   * function which button was clicked
   *
   */
  actionControlKeys?: string[];
  buttonTexts?: string[];
  setValues: (
    values: SetStateAction<FormValidationSchemaI>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValidationSchemaI>>;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
}) => {
  const { isEnabled } = useDialerGlobalContext();

  const handleSubmit = (controlKey?: string) => () => {
    setValues({ ...values, controlKey });

    setTimeout(() => {
      onSubmit();
    }, 200);
  };

  return (
    <div
      className={clsxMerge(
        "flex w-full flex-wrap items-center justify-between",
        className
      )}
    >
      <div>
        {[
          DISPOSITIONS_V3.INTERESTED,
          DISPOSITIONS_V3.NOT_QUALIFIED_NEED,
          DISPOSITIONS_V3.NOT_QUALIFIED_TIMING,
          DISPOSITIONS_V3.PITCH_REJECTED,
          DISPOSITIONS_V3.INTRO_REJECTED,
          DISPOSITIONS_V3.OTHER_FOLLOW_UP,
        ].includes(values.disposition as string) && (
          <span className="animate-fadein typography-body-4-semibold">
            This will be moved to your nurture list.
          </span>
        )}
      </div>
      <div className="flex  items-center justify-end gap-x-2">
        <button
          className={clsxMerge({
            "btn-ae-default lg:w-[200px]": !isEnabled,
            "btn-nofill border-black disabled:border-transparent": isEnabled,
            disabled: !isValid || isSubmitting,
            loading: isSubmitting,
          })}
          type="button"
          onClick={handleSubmit(actionControlKeys?.[0])}
          disabled={!isValid || isSubmitting}
        >
          {buttonTexts?.[0]}
        </button>
        {isEnabled && (
          <button
            className={clsxMerge("btn-ae-default lg:w-[200px]", {
              disabled: !isValid || isSubmitting,
              loading: isSubmitting,
            })}
            type="button"
            onClick={handleSubmit(actionControlKeys?.[1])}
            disabled={!isValid || isSubmitting}
          >
            {buttonTexts?.[1]}
          </button>
        )}
      </div>
    </div>
  );
};
