import { EmailEditorContentI } from "./index";

/**
 * Adjust the output of the email editor content to be fully compatible with
 * gmail formatting.
 */
export const processEmailEditorContent = ({
  plainText,
  htmlText,
}: EmailEditorContentI) => {
  return {
    plainText,
    htmlText: htmlText
      ? htmlText
          .replace(/<div><\/div>/g, "<div><br></div>")
          .replace(/<br class="ProseMirror-trailingBreak">/g, "")
      : "",
  };
};
