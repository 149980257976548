import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";
import {
  LabelValueV2,
  createRecord,
} from "@/components/modules/pipeline/account-details/shared/label-value";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useMemo } from "react";

export const ContactCustomFieldsSection = ({
  className,
}: {
  className?: string;
}) => {
  const { contacts } = useAccountDetailsContext();
  const { contact } = useInCallContext();

  const customFieldItems = useMemo(() => {
    const customFields = contacts?.find((c) => c.id === contact?.id)?.meta
      ?.values;

    return (
      customFields?.map(({ label, value }) => createRecord(label, value)) || []
    );
  }, [contacts, contact]);

  return customFieldItems?.length ? (
    <AccountDetailsSectionContainer
      title="Custom details"
      className={className}
    >
      <div className="mt-2 flex w-full flex-col gap-5">
        {customFieldItems.map((record, i) => (
          <LabelValueV2
            key={i}
            {...record}
            valueClassName="typography-body-4"
          />
        ))}
      </div>
    </AccountDetailsSectionContainer>
  ) : null;
};
