import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { AxiosError } from "axios";

import { getQCsAndPricingFromResponse } from "shared/lib/helpers/qualification-criteria";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

import { QualificationCriteriaQueryKeys } from "./keys";
import { useApiClient } from "@/context/api-client";

export const useFetchQualificationCriteriaAndPricing = (
  campaignId: string | undefined
) => {
  const apiClient = useApiClient();

  const api = useQuery({
    enabled: !!campaignId,
    queryKey: QualificationCriteriaQueryKeys.qcsAndPricing(campaignId),
    staleTime: TIME_INTERVALS_MS.ONE_HOUR / 30,
    queryFn: async () => {
      const response = await apiClient.qualificationCriteria.getQCsAndPricing(
        campaignId as string
      );

      if (response instanceof AxiosError) {
        throw new Error(
          "Failed to fetch qualification criteria and pricing data."
        );
      }

      return response;
    },
    select: (data) => data.data,
  });

  useEffect(() => {
    if (api.error) {
      toast.error("Failed to fetch qualification criteria and pricing data.");
    }
  }, [api.error]);

  return {
    ...api,
    data: getQCsAndPricingFromResponse(api.data),
  };
};

export type UseFetchQualificationCriteriaAndPricingReturnI = ReturnType<
  typeof useFetchQualificationCriteriaAndPricing
>;
