import { CSSProperties, PropsWithChildren, ReactNode } from "react";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

export interface AccountDetailsSectionContainerProps
  extends PropsWithClassNameI,
    PropsWithChildren {
  id?: string;
  title: ReactNode;
  actions?: ReactNode;
  style?: CSSProperties;
  headerClassName?: string;
  actionsClassName?: string;
  contentClassName?: string;
}

export const AccountDetailsSectionContainer = ({
  id,
  style,
  className,
  headerClassName,
  actionsClassName,
  contentClassName,
  title,
  actions,
  children,
}: AccountDetailsSectionContainerProps) => {
  return (
    <div
      id={id}
      className={clsxMerge("rounded-lg border", className)}
      style={style}
    >
      <div
        className={clsxMerge(
          "rounded-t-lg",
          "flex items-center justify-between bg-[#FAFAFA]",
          "typography-header-8-semibold px-3 py-2",
          headerClassName
        )}
      >
        {title}

        <div className={clsxMerge("typography-body-4", actionsClassName)}>
          {actions}
        </div>
      </div>

      <div
        className={clsxMerge(
          "bg-white px-4 py-2",
          "rounded-b-lg",
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};
