import { FC, useCallback } from "react";

import SidebarV2 from "shared/ui/sidebarV2";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { AccountSection } from "@/modules/pipeline/account-details/sections/account-section";
import { LeadsSection } from "@/modules/pipeline/account-details/sections/leads-section";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { SidebarCloseButtonI } from "shared/ui/sidebarV2/components/slide/header";

import { ChevronsRightIcon } from "lucide-react";
import { clsxMerge } from "shared/lib/helpers";
interface CalendlyBookingDetailsSidebarPropsI extends PropsWithClassNameI {
  isOpen: boolean;
  onClose?: () => void;

  contactId?: string;
}

const CloseButton: SidebarCloseButtonI = ({ id, className, onClick }) => {
  return (
    <button
      id={id}
      className={clsxMerge("btn btn-circle btn-ghost btn-sm", className)}
      onClick={onClick}
    >
      <ChevronsRightIcon className="h-4 w-4" />
    </button>
  );
};

export const CalendlyBookingDetailsSidebar: FC<
  CalendlyBookingDetailsSidebarPropsI
> = ({ isOpen, onClose = () => {}, className, contactId }) => {
  const filterContacts = useCallback(
    (contact: ContactDetailI) => contact.id === contactId,
    [contactId]
  );

  return (
    <SidebarV2
      isOpen={isOpen}
      onClose={onClose}
      allowPageInteraction
      className={className}
      id="calendly-sidebar"
    >
      <SidebarSlide
        title="Account Details"
        headerClassName="bg-white b-typography-h4 py-3"
        contentClassName="px-2 flex flex-col gap-2"
        closeButton={CloseButton}
      >
        <AccountSection />
        <LeadsSection filterFunc={filterContacts} />
      </SidebarSlide>
    </SidebarV2>
  );
};
