import { glencocoClientAPI } from "@/api/glencoco";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dd } from "@/helpers/datadog";
import { dispatchCustomEvent } from "@/helpers/events";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";

import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { FormValidationSchemaI } from "./interface";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { DayJs } from "shared/lib/helpers/date";

import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import { AddCallDispositionV2ParamsI } from "@/api/routes/dialer";
import {
  NEXT_TOUCH_TIME_OPTIONS,
  NEXT_TOUCH_TIME_OPTIONS_DAYJS_DURATION_MAP,
} from "shared/lib/constants/dispositions";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

const formatNextTouchTime = (
  selectedOption?: ValueOfObjectFields<typeof NEXT_TOUCH_TIME_OPTIONS> | ""
) => {
  if (
    !selectedOption ||
    [NEXT_TOUCH_TIME_OPTIONS.CUSTOM as string].includes(selectedOption)
  )
    return undefined;

  const nextTouchTimeDate = DayJs()
    .add(
      DayJs.duration(
        NEXT_TOUCH_TIME_OPTIONS_DAYJS_DURATION_MAP[selectedOption as string]
      )
    )
    .toISOString();

  return nextTouchTimeDate;
};

export const useDispositionSubmission = ({
  isDispoConversation,
  nextTouchTimeCustom,
  onSuccess,
}: {
  isDispoConversation?: boolean;
  nextTouchTimeCustom?: string;
  onSuccess?: (data: FormValidationSchemaI) => void;
}) => {
  const { campaign, call } = useCallingContext();

  const onSuccessDispositionSubmission = (data: FormValidationSchemaI) => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
    });

    onSuccess?.(data);
  };

  const onErrorDispositionSubmission = (resp: AxiosResponse) => {
    const errorMessage = "Failed to submit call disposition info";

    toast.error(errorMessage);

    dd.rum.error(`${ERROR_CATEGORIES.DISPOSITION} - ${errorMessage}`, {
      data: {
        call: call,
        customParameters: Object.fromEntries(call?.customParameters || []),
        response: resp,
      },
    });
  };

  const handleDispoNoConversation = async (data: FormValidationSchemaI) => {
    const API = glencocoClientAPI();
    const callId = call?.customParameters.get("call_id") as string;

    const Resp = await API.addCallDispositionV2(
      campaign?.id as string,
      callId,
      {
        had_conversation_with_prospect: false,
        notes: data.note || "",
      }
    ).catch((e) => e);

    if (Resp.status === 200) {
      onSuccessDispositionSubmission(data);
    } else onErrorDispositionSubmission(Resp);
  };

  const handleDispoWithConversation = async (data: FormValidationSchemaI) => {
    const API = glencocoClientAPI();
    const callId = call?.customParameters.get("call_id") as string;

    let params: AddCallDispositionV2ParamsI = {
      had_conversation_with_prospect: true,
      notes: data?.note || "",

      prospect_disposition_type: data?.disposition_type,
      disposition: data?.disposition,
      next_touch_time:
        nextTouchTimeCustom || formatNextTouchTime(data.next_touch_time),
    };
    params = omitBy(params, isUndefined);

    const Resp = await API.addCallDispositionV2(
      campaign?.id as string,
      callId,
      /**
       * If next touch time is undefined
       * it shouldn't be included in request
       */
      params
    ).catch((e) => e);

    if (Resp.status === 200) {
      onSuccessDispositionSubmission(data);
    } else onErrorDispositionSubmission(Resp);
  };

  const handleDipositionSubmission = async (data: FormValidationSchemaI) => {
    if (!isDispoConversation) {
      handleDispoNoConversation(data);
      return;
    }

    handleDispoWithConversation(data);
  };

  return {
    handleDipositionSubmission,
  };
};
