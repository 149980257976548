import { WebsocketInstanceI } from "@/interfaces/websockets";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { WEBSOCKET_CONNECTION_TYPES } from "lib/websockets/constants";
import { GLOBAL_WS_INSTANCES } from "@/constants/window-globals";

export const setWebsocketInstance = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>,
  ws: WebSocket
) => {
  const wsInstances: Record<
    ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>,
    WebSocket
  > = (<any>window)?.[GLOBAL_WS_INSTANCES] || {};

  wsInstances[connectionType] = ws;

  (<any>window)[GLOBAL_WS_INSTANCES] = wsInstances;
};

export const getWebsocketInstance = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>
) => {
  return (<any>window)?.[GLOBAL_WS_INSTANCES]?.[
    connectionType
  ] as WebsocketInstanceI;
};
