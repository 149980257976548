import { useEffect } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { glencocoClientAPI } from "@/api/glencoco";
import { webAIClientStreamAPI } from "@/api/webai";
import {
  CreateEmailTemplateRequestParamsI,
  GetEmailGenerationInfoRequestParamsI,
  SendEmailRequestParamsI,
  UpdateEmailTemplateRequestParamsI,
} from "@/api/routes/email";

import { GenerateEmailStreamRequestParamsI } from "shared/lib/interfaces/email/ai";

export const EMAIL_TEMPLATES_LIST_QUERY_KEY = "email_templates_list";

// Get all lists
export const useFetchEmailTemplatesList = () => {
  const listApi = useQuery({
    queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
    queryFn: glencocoClientAPI().email.getEmailTemplatesList,
  });

  useEffect(() => {
    if (listApi.isError) {
      toast.error(
        "Failed to load list of templates, please try reloading the page."
      );
    }
  }, [listApi.isError]);

  return listApi;
};

export const useCheckCampaignHasEmailAutomation = (campaignId?: string) =>
  useQuery({
    queryKey: ["campaign_email_automation_check"],
    queryFn: () =>
      campaignId
        ? glencocoClientAPI().email.checkCampaignHasEmailAutomation(campaignId)
        : null,
  });

/**
 * Returns the information needed to generate an email
 * (returns an id that is needed for submitting the email later)
 **/
export const useEmailGenerationInfo = () => {
  return useMutation({
    mutationFn: ({
      templateId,
      parameters,
    }: {
      templateId?: string;
      parameters?: GetEmailGenerationInfoRequestParamsI;
    }) =>
      glencocoClientAPI().email.getEmailGenerationInfo(templateId, parameters),
    onError: () => toast.error("Failed to get email configurations."),
  });
};

export const useGenerateAIEmailStreamBody = () => {
  return useMutation({
    mutationFn: ({
      parameters,
      signal,
    }: {
      parameters: GenerateEmailStreamRequestParamsI;
      signal?: AbortSignal;
    }) => webAIClientStreamAPI().streamEmailBody(parameters, signal),
    onError: () => toast.error("Failed to generate AI email."),
  });
};

export const useGenerateAIEmailStreamSubject = () => {
  return useMutation({
    mutationFn: ({
      parameters,
      signal,
    }: {
      parameters: GenerateEmailStreamRequestParamsI;
      signal?: AbortSignal;
    }) => webAIClientStreamAPI().streamEmailSubject(parameters, signal),
    onError: () => toast.error("Failed to generate AI email."),
  });
};

export const useValidateEmails = () => {
  return useMutation({
    mutationFn: (emails: string[]) =>
      glencocoClientAPI().email.validateEmails({ emails }),
  });
};

export const useSendEmail = () => {
  return useMutation({
    mutationFn: ({ parameters }: { parameters: SendEmailRequestParamsI }) =>
      glencocoClientAPI().email.sendEmail(parameters),
    onError: () => {
      toast.error("Failed to send email");
    },
  });
};

// List CRUD
export const useCreateEmailTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (parameters: CreateEmailTemplateRequestParamsI) =>
      glencocoClientAPI().email.createEmailTemplate(parameters),
    onSuccess: (_, { name }) => {
      toast.success(`Template '${name}' has been created!`);

      queryClient.invalidateQueries({
        queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to create new template."),
  });
};

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      templateId,
      params,
    }: {
      templateId: string;
      params: UpdateEmailTemplateRequestParamsI;
    }) => glencocoClientAPI().email.updateEmailTemplate(templateId, params),
    onSuccess: () => {
      toast.success("List details have been updated!");

      queryClient.invalidateQueries({
        queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to update template."),
  });
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (templateId: string) =>
      glencocoClientAPI().email.deleteEmailTemplate(templateId),
    onSuccess: () => {
      toast.success("Template removed.");
      queryClient.invalidateQueries({
        queryKey: [EMAIL_TEMPLATES_LIST_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to remove template."),
  });
};
