export const processLink = (element: Element) => {
  if (element.tagName === "A") {
    element.setAttribute("target", "_blank");
    element.setAttribute("rel", "noopener noreferrer");
  }
};

export const processVideoEmbed = (element: Element) => {
  if (element.tagName === "FIGURE") {
    const oEmbedElement = element.children.item(0);
    const contentURL = oEmbedElement?.getAttribute("url");

    if (oEmbedElement?.tagName === "OEMBED" && contentURL) {
      const videoContainer = document.createElement("div");
      videoContainer.className = "video-container";
      videoContainer.setAttribute("data-video-url", contentURL);

      element.parentNode?.insertBefore(videoContainer, element);
      element.remove();
    }
  }
};

/**
 * Sometimes emails come as a plane text, this function converts plain text to
 * HTML with links to then display by the RenderHTML
 *
 * @param text
 */
export const convertPlainTextToHTMLWithLinks = (text: string): string => {
  // For detecting email addresses
  const emailPattern = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/gi;
  // For cases when URL includes protocol
  const urlPattern = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/gi;
  // For cases when URL doesn't include protocol
  const plainUrlPattern =
    /(\b(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,6}\b(?:\/[^\s]*)?)/gi;

  // Replace email addresses first
  let processedText = text.replace(emailPattern, '<a href="mailto:$1">$1</a>');

  // Replace URLs that are not already enclosed in a mailto link
  processedText = processedText.replace(urlPattern, (match) => {
    if (processedText.includes(`mailto:${match}`)) {
      return match;
    }
    return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });

  // Replace plain URLs that are not already enclosed in a mailto link
  processedText = processedText.replace(plainUrlPattern, (match) => {
    if (processedText.includes(`mailto:`) && processedText.includes(match)) {
      return match;
    }
    return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });

  return processedText;
};
