import { useWindowWidth } from "@react-hook/window-size";
import { clsxMerge } from "shared/lib/helpers";

import { AccountDetailsLayout as Layout } from "@/modules/pipeline/account-details/layout";

import { AccountSection } from "@/components/modules/pipeline/account-details/sections/account-section";
import { StatusSection } from "@/components/modules/pipeline/account-details/sections/status-section";
import { LeadsSection } from "@/components/modules/pipeline/account-details/sections/leads-section";
import { NotepadSection } from "@/components/modules/pipeline/account-details/sections/notepad-section";
import { AccountAboutSection } from "@/components/modules/pipeline/account-details/sections/account-about-section";
import { AccountTalkingPointsSection } from "@/components/modules/pipeline/account-details/sections/account-talking-points-section";
import { AccountSummarySection } from "@/components/modules/pipeline/account-details/sections/account-summary-section";

export const AccountDetailsTabDetails = () => {
  const windowWidth = useWindowWidth();

  const isSmallScreen = windowWidth < 1024;
  return (
    <Layout.GridColumns columns={isSmallScreen ? 2 : 3}>
      <Layout.GridColumn
        className={clsxMerge("pl-2 pr-1 pt-3", "flex flex-col gap-2")}
      >
        <AccountSection />
        {isSmallScreen && <StatusSection />}
        <AccountSummarySection />
        <AccountAboutSection />
        <AccountTalkingPointsSection />

        {isSmallScreen && (
          <>
            <LeadsSection />
          </>
        )}
      </Layout.GridColumn>

      {!isSmallScreen && (
        <Layout.GridColumn
          className={clsxMerge("px-1 pt-3", "flex flex-col gap-2")}
        >
          <StatusSection />
          <LeadsSection />
        </Layout.GridColumn>
      )}

      <Layout.GridColumn
        className={clsxMerge("pl-1 pr-2 pt-3", "flex flex-col gap-2")}
      >
        <NotepadSection inputClassName="max-h-none" />
      </Layout.GridColumn>
    </Layout.GridColumns>
  );
};
