import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";

export const GenerateWithAIButton = ({
  className,
  disabled = false,
  onClick = () => {},
}: PropsWithClassNameI & {
  disabled?: boolean;
  onClick?: () => any;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger className="cursor-default">
        {
          <button
            type="button"
            className={clsxMerge("ae-link", {
              "pointer-events-none text-[#666666]": disabled,
              className,
            })}
            onClick={onClick}
            disabled={disabled}
          >
            Regenerate
          </button>
        }
      </TooltipTrigger>

      <TooltipContent className="z-[99] text-white">
        Only regenerates once every 7 days.
      </TooltipContent>
    </Tooltip>
  );
};
