import { FC } from "react";

import { formatMoneyCents } from "shared/lib/helpers/numbers";
import {
  BONUS_QUALIFICATION_CRITERIA_TYPE,
  QUALIFICATION_CRITERIA_LABELS,
} from "shared/lib/constants/qualification-criteria";

import {
  QualificationCriteriaTable,
  QualificationCriteriaTablePropsI,
} from "./shared/base";

interface QualificationCriteriaTableWithPayoutExtensionPropsI
  extends QualificationCriteriaTablePropsI {
  payout: number;
  bonusPayout?: number;
}

const PAYOUT_AMOUNT_FORMAT_CONFIG = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

/**
 *
 * @param payout - Base payout in cents
 * @param bonusPayout - Bonus payout in cents
 * @param texts - Map of QC values (bonus is optional) entered by the customer
 * during the onboarding
 * @param restTableProps - Basic props for the QualificationCriteriaTable component
 */
export const QualificationCriteriaTableWithPayout: FC<
  QualificationCriteriaTableWithPayoutExtensionPropsI
> = ({ payout, bonusPayout, texts, ...restTableProps }) => {
  return (
    <QualificationCriteriaTable
      {...restTableProps}
      texts={texts}
      rightExtension={{
        head: {
          content: "Payout",
          className: "text-right",
        },
        cells: [
          {
            className: "text-right",
            content: formatMoneyCents(payout, PAYOUT_AMOUNT_FORMAT_CONFIG),
            colSpan: 4,
          },
        ],
      }}
      bottomExtension={
        bonusPayout
          ? {
              cells: [
                {
                  content:
                    QUALIFICATION_CRITERIA_LABELS[
                      BONUS_QUALIFICATION_CRITERIA_TYPE
                    ],
                },
                {
                  content: texts[BONUS_QUALIFICATION_CRITERIA_TYPE],
                },
                {
                  className: "text-right",
                  content: `${formatMoneyCents(
                    bonusPayout,
                    PAYOUT_AMOUNT_FORMAT_CONFIG
                  )} Caller Bonus`,
                },
              ],
            }
          : undefined
      }
    />
  );
};
