import { Sparkle } from "lucide-react";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

export const SparkleCircleIcon = ({ className }: PropsWithClassNameI) => {
  return (
    <div
      className={clsxMerge(
        "h-fit rounded-full border border-[#D0D0D0] bg-[#E6E6E6]",
        className
      )}
    >
      <Sparkle className="size-6 p-1 text-white" fill="currentColor" />
    </div>
  );
};
