import { FC, useMemo } from "react";

import { ControllableComponentPropsI } from "shared/lib/interfaces/ui";

import {
  QualificationCriteriaTable,
  QualificationCriteriaTablePropsI,
} from "./shared/base";
import {
  BONUS_QUALIFICATION_CRITERIA_TYPE,
  QUALIFICATION_CRITERIA_LABELS,
  QUALIFICATION_CRITERIA_TYPES,
} from "shared/lib/constants/qualification-criteria";
import { QualificationCriteriaTableTogglesValueI } from "shared/lib/interfaces/qualification-criteria";
import {
  checkIfSelectedQCsQualify,
  mapSelectedQCsToRequest,
} from "shared/lib/helpers/qualification-criteria";
import {
  QC_TABLE_TOGGLE_STYLES,
  QCCheckbox,
} from "shared/ui/qualification/table/shared/qc-checkbox";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

export interface QualificationCriteriaTableWithTogglesPropsI
  extends QualificationCriteriaTablePropsI,
    ControllableComponentPropsI<QualificationCriteriaTableTogglesValueI> {
  isEditable?: boolean;
  hasBonus?: boolean;
  toggleStyle?: ValueOfObjectFields<typeof QC_TABLE_TOGGLE_STYLES>;
}

export const QualificationCriteriaTableWithToggles: FC<
  QualificationCriteriaTableWithTogglesPropsI
> = ({
  isEditable = true,
  hasBonus = false,
  value: _value,
  onChange,
  texts,
  toggleStyle = QC_TABLE_TOGGLE_STYLES.REGULAR,
  ...restTableProps
}) => {
  const value = _value as QualificationCriteriaTableTogglesValueI;

  // Need that to only allow toggling the bonus criteria if all basic criteria
  // are met
  const areBasicQCsMet = useMemo(
    () =>
      _value
        ? checkIfSelectedQCsQualify(mapSelectedQCsToRequest(_value))
        : false,
    [_value]
  );

  const handleChange = (newValue: QualificationCriteriaTableTogglesValueI) => {
    if (
      newValue &&
      !checkIfSelectedQCsQualify(mapSelectedQCsToRequest(newValue)) &&
      newValue[BONUS_QUALIFICATION_CRITERIA_TYPE]
    ) {
      onChange({ ...newValue, [BONUS_QUALIFICATION_CRITERIA_TYPE]: false });
      return;
    }

    onChange(newValue);
  };

  const rightExtension = useMemo(() => {
    const head = {
      className: "text-center",
      content: "Met",
    };

    const cells = QUALIFICATION_CRITERIA_TYPES.map((type) => ({
      className: "text-center p-0 h-max",
      content: (
        <QCCheckbox
          toggleStyle={toggleStyle}
          type={type}
          isEditable={isEditable}
          value={value}
          onChange={handleChange}
        />
      ),
    }));

    return { head, cells };
  }, [isEditable, hasBonus, _value]);

  return (
    <QualificationCriteriaTable
      {...restTableProps}
      texts={texts}
      rightExtension={rightExtension}
      bottomExtension={
        hasBonus
          ? {
              cells: [
                {
                  content:
                    QUALIFICATION_CRITERIA_LABELS[
                      BONUS_QUALIFICATION_CRITERIA_TYPE
                    ],
                },
                {
                  content: texts[BONUS_QUALIFICATION_CRITERIA_TYPE],
                },
                {
                  className: "text-center p-0",
                  content: (
                    <QCCheckbox
                      toggleStyle={toggleStyle}
                      type={BONUS_QUALIFICATION_CRITERIA_TYPE}
                      isEditable={areBasicQCsMet && isEditable}
                      value={value}
                      onChange={handleChange}
                    />
                  ),
                },
              ],
            }
          : undefined
      }
    />
  );
};
