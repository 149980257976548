import {
  LabelValue,
  createRecord,
} from "@/components/modules/pipeline/account-details/shared/label-value";
import { Fragment, useMemo } from "react";

import { clsxMerge, fixUrl } from "shared/lib/helpers";
import { formatMoneyDollars, formatNumbers } from "shared/lib/helpers/numbers";
import { AccountDetailsStatusI, AccountI } from "shared/lib/interfaces/account";
import { AccountDispositionStatusSmallPill } from "@/components/modules/pipeline/account-details/shared/status-pill";
import { getAccountDispositionStatusParams } from "shared/lib/helpers/disposition";
import { HorizontalDivider } from "./horizontal-diviider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { CampaignI } from "@/interfaces/campaign";

export const AccountTopSectionContent = ({
  campaign,
  account,
  accountStatus,
}: {
  campaign?: CampaignI;
  account?: AccountI;
  accountStatus?: AccountDetailsStatusI;
}) => {
  const accountInfoItems = [
    createRecord("Campaign name", campaign?.name || null),
    createRecord(
      "Revenue",
      account?.revenue
        ? `${formatMoneyDollars(account.revenue, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`
        : null
    ),
    createRecord("HQ State", account?.hq_state || null),
    createRecord(
      "Employees",
      account?.employee_count
        ? `${formatNumbers(account?.employee_count)}`
        : null
    ),
    createRecord("Industry", account?.industry || null),
  ];

  const accountStatusBadge = useMemo(
    () => (
      <AccountDispositionStatusSmallPill
        {...getAccountDispositionStatusParams(accountStatus, false)}
      />
    ),
    [accountStatus?.status]
  );

  const linkProps = useMemo(() => {
    return account?.website
      ? {
          target: "_blank",
          href: fixUrl(account?.website),
          rel: "noreferrer",
        }
      : {};
  }, [account?.website]);

  return (
    <div className="px-6 pt-6">
      <a
        className={clsxMerge(
          "mb-1 w-fit typography-header-5-semibold",
          "flex items-center",
          {
            "hover:underline": account?.website,
          }
        )}
        {...linkProps}
      >
        {account?.name}

        {account?.website && (
          <span>
            <ArrowTopRightOnSquareIcon className="ml-2 w-5 text-[#4474E3]" />
          </span>
        )}
      </a>

      <div className="flex w-full items-end gap-x-3">
        <LabelValue
          label="Status"
          value={accountStatusBadge}
          valueClassName="mb-0"
        />

        <HorizontalDivider />

        {accountInfoItems?.map((record, i) => (
          <Fragment key={i}>
            <LabelValue {...record} className="max-w-[25%]" />
            {record?.value && <HorizontalDivider />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
