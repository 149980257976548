import { AxiosInstance } from "axios";

import { CoachingMessageI } from "@/interfaces/coaching";

export interface GetCoachingMessagesResponseI {
  coaching_messages: CoachingMessageI[];
  next_token?: string;
}

export const CoachingAPIEndpoints = (client: AxiosInstance) => ({
  getCoachingMessages: (params: { next_token?: string }) =>
    client.post<GetCoachingMessagesResponseI>("/v1/coaching", params),

  getCoachingMessagesByCampaign: (
    campaignId: string,
    nextToken: string | null | undefined
  ) =>
    client.post<GetCoachingMessagesResponseI>(
      `/v1/campaign/${campaignId}/coaching`,
      {
        next_token: nextToken,
      }
    ),

  likeCoachingFeedbackFetcher: (
    callRecordingId: string,
    params: {
      is_liked: boolean;
    }
  ) => client.post(`v1/coaching/${callRecordingId}/like`, params),
});

export type CoachingApiEndpointsI = ReturnType<typeof CoachingAPIEndpoints>;
