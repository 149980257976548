import { FC } from "react";
import ReactConfetti from "react-confetti";
import { Portal } from "react-portal";

export const Confetti: FC = () => {
  return (
    // @ts-ignore
    <Portal>
      <ReactConfetti
        style={{ zIndex: 1000 }} //.modal has z-index of 999, so confetti needs to be 1000 so it can be ontop of modals
        numberOfPieces={1000}
        tweenDuration={25000}
        recycle={false}
      />
    </Portal>
  );
};
