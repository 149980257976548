import { QualificationCriteriaTableWithPayout } from "shared/ui/qualification/table/with-pricing";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useFetchQualificationCriteriaAndPricing } from "@/api/routes/qualification-criteria/queries";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";

export const AccountDetailsTabQualificationCriteria = () => {
  const { campaign } = useAccountDetailsContext();
  const qcApi = useFetchQualificationCriteriaAndPricing(campaign?.id);

  return (
    <section className="h-full w-full bg-white px-3">
      <div className="mx-auto max-w-[850px] pt-6">
        <div className="mb-2 typography-body-4-medium">
          Qualification criteria
        </div>

        <DataStatesWrapper
          viewName="qualification criteria table"
          loadingClassName="h-[200px]"
          api={qcApi}
        >
          <QualificationCriteriaTableWithPayout
            payout={qcApi.data.baseChargeAmount}
            bonusPayout={qcApi.data.bonusChargeAmount}
            texts={qcApi.data}
          />
        </DataStatesWrapper>
      </div>
    </section>
  );
};
