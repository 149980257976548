import { FC, useMemo } from "react";

// Shared lib imports
import { clsxMerge } from "shared/lib/helpers";
import { ActionMenu } from "shared/ui/action-menu";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ContactDetailI } from "shared/lib/interfaces/account";

// Internal app imports
// Components
import { ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME } from "@/modules/pipeline/account-details/constants";

// Context
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

// Modal IDs
import { DISQUALIFY_MODAL_ID } from "@/modals/disqualify-modal";
import { EDIT_CONTACT_MODAL_ID } from "@/modals/edit-contact-modal";
import { DNC_NUMBERS_TOGGLE_MODAL_ID } from "@/components/modals/dnc-numbers-toggle-modal";
import { SET_CONTACT_TYPE_MODAL_ID } from "@/modals/set-contact-type-modal";
import { DELETE_ACCOUNT_LEAD_CONFIRMATION_MODAL_ID } from "@/modals/delete-account-lead-confirmation-modal";
import { SET_UNSET_PRIMARY_LEAD_CONFIRMATION_MODAL_ID } from "@/modals/set-primary-lead-confirmation-modal";

// Icons
import {
  TagIcon,
  UserIcon,
  UserXIcon,
  CircleOffIcon,
  PhoneMissedIcon,
  PersonStandingIcon,
} from "lucide-react";

interface AccountDetailsV2LeadItemEditLeadMenuPropsI {
  contact?: ContactDetailI;
  isDeleteContactAllowed?: boolean;
  isDisqualified: boolean;
  isCurrentlyCalling?: boolean;
  isMarkedDNC?: boolean;
}

const getSetLabel = (currentValue: boolean) => (currentValue ? "Unset" : "Set");

const MenuItem = ({
  icon: Icon,
  title,
  handler,
}: {
  icon: any;
  title: string;
  handler: () => void;
}) => (
  <span
    onClick={handler}
    className={clsxMerge(
      "flex w-[182px] items-center gap-2",
      "cursor-pointer",
      "rounded-md p-2 hover:bg-black/5",
      "typography-body-4"
    )}
  >
    <Icon className="h-4 w-4 min-w-4" /> <span>{title}</span>
  </span>
);

export const AccountDetailsV2LeadItemEditLeadMenu: FC<
  AccountDetailsV2LeadItemEditLeadMenuPropsI
> = ({
  contact,
  isDeleteContactAllowed,
  isDisqualified,
  isCurrentlyCalling,
  isMarkedDNC,
}) => {
  const { setFocusedContact } = useAccountDetailsContext();

  const isPrimaryContact = !!contact?.is_primary_lead;

  const actions = useMemo(() => {
    const basicOptions = [
      {
        content: (
          <MenuItem
            icon={UserIcon}
            title="Edit lead"
            handler={() => {
              setFocusedContact(contact);
              modalHelpers.lazyOpen(EDIT_CONTACT_MODAL_ID);
            }}
          />
        ),
      },
      {
        content: (
          <MenuItem
            icon={PersonStandingIcon}
            title={`${getSetLabel(isPrimaryContact)} as primary lead`}
            handler={() => {
              setFocusedContact(contact);
              modalHelpers.lazyOpen(
                SET_UNSET_PRIMARY_LEAD_CONFIRMATION_MODAL_ID
              );
            }}
          />
        ),
      },
      {
        content: (
          <MenuItem
            icon={TagIcon}
            title="Set contact type"
            handler={() => {
              setFocusedContact(contact);
              modalHelpers.lazyOpen(SET_CONTACT_TYPE_MODAL_ID);
            }}
          />
        ),
      },
    ];

    if (!isMarkedDNC) {
      basicOptions.push({
        content: (
          <MenuItem
            icon={PhoneMissedIcon}
            title="Mark Phone DNCs"
            handler={() => {
              setFocusedContact(contact);
              modalHelpers.lazyOpen(DNC_NUMBERS_TOGGLE_MODAL_ID);
            }}
          />
        ),
      });
    }

    if (!isDisqualified && !isCurrentlyCalling && !isMarkedDNC) {
      basicOptions.push({
        content: (
          <MenuItem
            icon={CircleOffIcon}
            title="Disqualify"
            handler={() => {
              setFocusedContact(contact);
              modalHelpers.lazyOpen(DISQUALIFY_MODAL_ID);
            }}
          />
        ),
      });
    }

    if (isDeleteContactAllowed && !isCurrentlyCalling) {
      basicOptions.push({
        content: (
          <MenuItem
            icon={UserXIcon}
            title="Delete"
            handler={() => {
              setFocusedContact(contact);
              modalHelpers.lazyOpen(DELETE_ACCOUNT_LEAD_CONFIRMATION_MODAL_ID);
            }}
          />
        ),
      });
    }

    return basicOptions;
  }, [
    contact,
    isDeleteContactAllowed,
    isPrimaryContact,
    isDisqualified,
    isCurrentlyCalling,
    isMarkedDNC,
    setFocusedContact,
  ]);

  return (
    <ActionMenu
      actions={actions}
      trigger={
        <button
          className={clsxMerge(
            ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME,
            "-translate-y-1"
          )}
        >
          Edit
        </button>
      }
    />
  );
};
