import { ContactDetailI } from "@/interfaces/accounts";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { clsxMerge, getFullEntityName } from "shared/lib/helpers";
import { PhoneLink } from "@/components/modules/pipeline/account-details/shared/phone-link";
import { PhoneIcon } from "shared/ui/icons";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/solid";

export const CallButtonMenuContactBlock = ({
  contact,
}: {
  contact?: ContactDetailI;
}) => {
  return (
    <div
      className={clsxMerge(
        "flex w-full cursor-default flex-col items-start gap-y-2 p-0 pt-3 ",
        "bg-white"
      )}
    >
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center px-4">
          <UserCircleIcon className="w-6 min-w-[24px] text-black/60" />

          <span className="ml-2 typography-body-4-semibold">
            {getFullEntityName(contact)}
          </span>
        </div>

        <div className="flex w-full flex-col gap-y-1 px-2">
          {contact?.mobile_v2?.phone && !contact?.mobile_v2?.do_not_call && (
            <div
              className={clsxMerge(
                "flex w-full items-center rounded-md px-2",
                "hover:bg-black/10",
                "transition-colors duration-300",
                "cursor-pointer"
              )}
            >
              <div className="rounded-md bg-black/10 px-[6px] py-[2px]">
                <DevicePhoneMobileIcon className="w-[10px]" />
              </div>
              <PhoneLink
                contactId={contact?.id}
                phoneNumber={contact?.mobile}
                className="ml-1 text-black typography-body-4"
              />
            </div>
          )}

          {contact?.phone_v2?.phone && !contact?.phone_v2?.do_not_call && (
            <div
              className={clsxMerge(
                "flex w-full items-center rounded-md px-2",
                "hover:bg-black/10",
                "transition-colors duration-300",
                "cursor-pointer"
              )}
            >
              <div className="rounded-md bg-black/10 px-[6px] py-[2px]">
                <PhoneIcon className="w-2" />
              </div>
              <PhoneLink
                contactId={contact?.id}
                phoneNumber={contact?.phone}
                className="ml-1 text-black typography-body-4"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
