import { FC, ReactNode } from "react";

import { clsxMerge } from "shared/lib/helpers";
import Avatar from "shared/ui/avatar";

import { CampaignCardDefaultI } from "./campaign-card-default";
import { CampaignCardBonusPrice } from "./shared/campaign-card-bonus-price";

export interface CampaignCardHighlightsI extends CampaignCardDefaultI {
  upcomingDates?: ReactNode;
  performance?: ReactNode;
  actions?: ReactNode;
}

export const CampaignCardHighlights: FC<CampaignCardHighlightsI> = ({
  imgSrc,
  title,
  price,
  priceDescription,
  bonusPrice,
  status,
  labels,
  stats,

  onClick,
  className,
}) => {
  return (
    <div
      className={clsxMerge(
        "card flex cursor-pointer flex-col rounded-lg bg-[white] p-6",
        className
      )}
      onClick={onClick}
    >
      <div className="mb-2 flex w-full justify-between">
        <div>
          <Avatar
            src={imgSrc}
            placeholderText={title}
            className="h-[88px] w-[88px] rounded-lg border-[1px]"
          />
        </div>

        <div className="flex flex-col items-end gap-y-2">
          {status}
          {labels}
        </div>
      </div>

      <article className="flex w-full grow flex-col justify-between">
        <div className="flex grow flex-col">
          <div className="mb-4 flex items-center justify-between">
            <div className="b-typography-h2 line-clamp-1 font-medium">
              {title}
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="ae-typography-h1">{price}</div>

            <CampaignCardBonusPrice className="mt-1" price={bonusPrice} />
          </div>
        </div>

        <section className="mt-3">{stats}</section>

        {priceDescription && <div className="mt-3">{priceDescription}</div>}
      </article>
    </div>
  );
};
