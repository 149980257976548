import { useEffect } from "react";

export const useOnTabFocus = (callback = () => {}) =>
  useEffect(() => {
    const windowFocusHandler = () => {
      if (typeof callback === "function") {
        callback();
      }
    };

    window.addEventListener("focus", windowFocusHandler);

    return () => {
      window.removeEventListener("focus", windowFocusHandler);
    };
  }, [callback]);
