import { FC, useMemo } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { Modal } from "shared/ui";
import {
  StartCallingSectionsList as SectionsList,
  useStartCallingSectionsList,
} from "./sections-list";
import {
  StartCallingModalListDialerSection as ListDialerSection,
  useStartCallingModalListDialerSection,
} from "./sections/list-dialer";
import { StartCallingModalGeneralDialerSection as GeneralDialerSection } from "./sections/general-dialer";
import { MODAL_DIALING_SECTIONS } from "./constants";
import { useFetchDataStartCallingModal as useFetchData } from "./queries";
import { useRouter } from "next/router";
import Router from "@/helpers/router";

export const START_CALLING_MODAL_ID = "start-calling-modal";

const StartCallingModalContent = ({ onClose }: { onClose?: () => void }) => {
  const router = useRouter();
  const { isLoading } = useFetchData();
  const {
    data: listsData,
    selected: selectedListId,
    setSelected: setSelectedListId,
  } = useStartCallingModalListDialerSection();

  const {
    sections,
    selected: selectedSection,
    setSelected: setSelectedSection,
  } = useStartCallingSectionsList();

  const handleOnChangeSection = (key: string) => {
    setSelectedSection(key);
  };

  const handleOnChangeListId = (listId: string) => {
    setSelectedListId(listId);
  };

  const onSubmit = () => {
    switch (selectedSection) {
      case MODAL_DIALING_SECTIONS.LIST_DIALER:
        router.push(Router.lists(selectedListId));
        break;
      case MODAL_DIALING_SECTIONS.GENERAL_DIALER:
        router.push(Router.lists());
        break;
    }

    onClose?.();
  };

  const submitButtonText = useMemo(() => {
    if (selectedSection === MODAL_DIALING_SECTIONS.GENERAL_DIALER) {
      return "Go to your lists";
    }

    return "Start Calling";
  }, [selectedSection]);

  return (
    <div className="flex h-full">
      <div
        className={clsxMerge("min-w-[140px] bg-[#F5F5F5]", "px-1 pb-3 pt-2")}
      >
        <h3 className="mb-5 pl-3 typography-body-4-bold">Start Calling</h3>

        <SectionsList
          sections={sections}
          selected={selectedSection}
          onChange={handleOnChangeSection}
        />
      </div>
      <div className={clsxMerge("flex w-full flex-col", "pb-3 pl-3 pr-2 pt-4")}>
        {selectedSection === MODAL_DIALING_SECTIONS.LIST_DIALER && (
          <ListDialerSection
            isLoading={isLoading}
            data={listsData}
            selected={selectedListId}
            onChange={handleOnChangeListId}
          />
        )}

        {selectedSection === MODAL_DIALING_SECTIONS.GENERAL_DIALER && (
          <GeneralDialerSection isLoading={isLoading} />
        )}

        <div className="flex items-center justify-end">
          <button className="btn-ae-text" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn-ae-default"
            onClick={onSubmit}
            disabled={
              selectedSection === MODAL_DIALING_SECTIONS.LIST_DIALER &&
              !selectedListId
            }
          >
            {submitButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export const StartCallingModal: FC = () => {
  return (
    <Modal
      id={START_CALLING_MODAL_ID}
      showCloseButton
      unmountOnClose
      modalContainerClassName="w-[520px] h-[390px]"
      modalBoxClassName="rounded-lg p-0 h-full"
      closeButtonClassName="btn-xs top-2 right-2"
      closeIconClassName="w-4 h-4"
    >
      {({ handleClose }) => {
        return <StartCallingModalContent onClose={handleClose} />;
      }}
    </Modal>
  );
};
