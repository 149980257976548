import { AxiosInstance } from "axios";

import { MEETING_DISPOSITIONS } from "shared/lib/constants/dispositions";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { DefaultResponseI } from "shared/lib/interfaces/api";
import {
  ActivityLogQCsV2,
  QCsAndPricingResponseI,
  QualificationCriteriaDisputeReasonsRequestI,
  QualificationCriteriaSurveyValuesI,
} from "shared/lib/interfaces/qualification-criteria";
import { PAYMENT_TYPE } from "shared/lib/constants/payment";

import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";

export interface AccountExecutiveSurveyUser {
  first_name?: string;
  last_name?: string;
  profile_pic_url?: string;
  user_id?: string;
}

export interface GetAccountExecutiveSurveyV2ResponseI
  extends QCsAndPricingResponseI,
    DefaultResponseI {
  user: AccountExecutiveSurveyUser;
  contact: CallContactI;
  account: CallAccountI;
  event: CalendlyEventI;
  already_submitted: boolean;
  already_submitted_review: boolean;
  qc_pre_check_audit_result: ActivityLogQCsV2 | null;
}

export interface SubmitAccountExecutiveSurveyV2ParamsI
  extends Partial<QualificationCriteriaSurveyValuesI>,
    Partial<QualificationCriteriaDisputeReasonsRequestI> {
  meeting_disposition: ValueOfObjectFields<typeof MEETING_DISPOSITIONS>;
  notes?: string;
  reschedule_time?: string;
}

export interface GetMeetingChargeInformationResponseI extends DefaultResponseI {
  error_code?: number;

  campaign_id: string;
  customer_charge_amount: number;

  has_bonus?: boolean;

  is_finished_verification: boolean;
  id: string;
  last4: string;
  bank_name: string;
  payment_type: ValueOfObjectFields<typeof PAYMENT_TYPE>;

  base_charge_amount: number;
  bonus_charge_amount: number;
}

export const AccountExecutiveAPIEndpoints = (api: AxiosInstance) => ({
  getAccountExecutiveSurvey: (surveyId: string) =>
    api.get<GetAccountExecutiveSurveyV2ResponseI>(
      `/public/customer/v2/ae_survey/${surveyId}`
    ),
  submitAccountExecutiveSurvey: (
    surveyId: string,
    params: SubmitAccountExecutiveSurveyV2ParamsI
  ) => api.post(`/public/customer/v2/ae_survey/${surveyId}`, params),
  submitAESurveyCallerRating: (
    surveyId: string,
    params: {
      caller_rating?: number | null;
      caller_rating_notes?: string;
    }
  ) =>
    api.post(`/public/customer/v1/ae_survey/${surveyId}/caller_rating`, {
      ...params,
    }),
  getMeetingChargeInformation: (surveyId: string) =>
    api.get<GetMeetingChargeInformationResponseI>(
      `/public/customer/v1/ae_survey/${surveyId}/charge_info`
    ),
});
