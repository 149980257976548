import { FileTypeIcon, XIcon } from "lucide-react";
import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { useTruncateMiddle } from "shared/lib/hooks/use-truncate-middle";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { Button } from "shared/ui/shadcn/button";

export const AttachmentPill: FC<
  PropsWithClassNameI & { title: string; onClose: () => void }
> = ({ className, title, onClose }) => {
  const { truncatedText, elementRef } = useTruncateMiddle(title);

  return (
    <Button
      className={clsxMerge(
        "hover:bg-transparent hover:text-inherit hover:shadow-none",
        "cursor-default truncate",
        className
      )}
      type="button"
      variant="outline"
      title={title}
    >
      <FileTypeIcon className="w-3" />
      <span className="max-w-[150px]" ref={elementRef}>
        {truncatedText}
      </span>
      <span onClick={onClose} className="btn btn-circle btn-ghost btn-sm -mr-3">
        <XIcon className=" w-3 text-black" />
      </span>
    </Button>
  );
};
