import { useEffect } from "react";
import {
  Building2Icon,
  Shovel,
  History,
  MessageCircleMore,
  ScrollText,
} from "lucide-react";

import { clsxMerge } from "shared/lib/helpers";
import { AccountDetailsButton } from "shared/ui/account-details/base/button";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { ACCOUNTS_DETAILS_TABS } from "@/modules/pipeline/account-details/constants";
import { AccountDetailsViewOptions as ViewOptions } from "@/modules/pipeline/account-details/nav/view-options";
import { FindLeadsButton } from "@/modules/pipeline/account-details/nav/find-leads-btn";

const ACCOUNT_DETAILS_NAV_ITEMS = [
  {
    id: ACCOUNTS_DETAILS_TABS.DIALER_TOOLKIT,
    text: "Dialer toolkit",
    icon: <Shovel className="w-4" />,
  },
  {
    id: ACCOUNTS_DETAILS_TABS.DETAILS,
    text: "Details",
    icon: <Building2Icon className="w-4" />,
  },
  {
    id: ACCOUNTS_DETAILS_TABS.HISTORY,
    text: "History",
    icon: <History className="w-4" />,
  },
  {
    id: ACCOUNTS_DETAILS_TABS.AE_MESSAGES,
    text: "AE messages",
    icon: <MessageCircleMore className="w-4" />,
  },
  {
    id: ACCOUNTS_DETAILS_TABS.SCRIPTS,
    text: "Scripts",
    icon: <ScrollText className="w-4" />,
  },
  {
    id: ACCOUNTS_DETAILS_TABS.QUALIFICATION_CRITERIA,
    text: "Qualification criteria",
    icon: <ScrollText className="w-4" />,
  },
];

export const AccountDetailsNav = () => {
  const { activeTab, setActiveTab } = useAccountDetailsContext();

  useEffect(() => {
    return () => {
      setActiveTab(null);
    };
  }, []);

  return (
    <div
      className={clsxMerge(
        "border-b border-[#D5D5D5] bg-white p-2",
        "flex items-center justify-between"
      )}
    >
      {/* Left side nav items */}
      <div className="flex items-center">
        {ACCOUNT_DETAILS_NAV_ITEMS.map((item, i) => (
          <AccountDetailsButton
            key={i}
            className="mr-2"
            isActive={activeTab === item.id}
            onClick={() => setActiveTab(item.id)}
          >
            {item.icon}
            {item.text}
          </AccountDetailsButton>
        ))}
      </div>

      {/* Right side nav items */}
      <div className="flex items-center">
        {activeTab === ACCOUNTS_DETAILS_TABS.DIALER_TOOLKIT && (
          <div className="flex items-center">
            <ViewOptions />
          </div>
        )}

        <FindLeadsButton />
      </div>
    </div>
  );
};
