import { Fragment } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { clsxMerge } from "shared/lib/helpers";
import { PrimaryNavigationItemI } from "@/components/navigation/primary/interfaces";
import { NotificationLabel } from "@/components/navigation/shared/notification-label";

export const NavDropdownMenuItem = ({
  subItem,
  disabled,
}: {
  subItem: PrimaryNavigationItemI;
  disabled?: boolean;
}) => {
  const router = useRouter();

  return (
    <Fragment>
      <Link
        href={subItem.href as string}
        className={clsxMerge(
          "flex items-center justify-between gap-3",
          "brand-typography-body6 text-nowrap text-black",
          "my-0.5 w-full rounded-md p-2",
          "hover:bg-black/10",
          {
            "bg-black text-white hover:bg-black": router.route === subItem.href,
            "pointer-events-none": subItem.disabled || disabled,
          }
        )}
      >
        <span className="flex items-center gap-3">
          <span className="w-4">{subItem.icon}</span>
          <span>{subItem.text}</span>
        </span>

        <NotificationLabel id={subItem.id} />
      </Link>
    </Fragment>
  );
};
