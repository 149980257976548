import { FC } from "react";
import { BadgeCheck, Info, LucideProps } from "lucide-react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui/tooltip";
import { Alert, AlertDescription, AlertTitle } from "shared/ui/shadcn/alert";

interface PreQualifiedVerificationBadgePropsI
  extends LucideProps,
    PropsWithClassNameI {
  hasTooltip?: boolean;
}

export const PreQualifiedVerificationBadge: FC<
  PreQualifiedVerificationBadgePropsI
> = ({
  className,
  size = 20,
  fill = "#1da1f2",
  hasTooltip = true,
  ...props
}) => {
  const badge = (
    <BadgeCheck
      className={clsxMerge("text-white", className)}
      size={size}
      fill={fill}
      {...props}
    />
  );

  if (hasTooltip) {
    return (
      <Tooltip arrowFillColor="#fff" arrowStroke="#E4E4E7">
        <TooltipTrigger>{badge}</TooltipTrigger>
        <TooltipContent className="z-[100] w-[480px] bg-transparent p-0">
          <Alert className="bg-white">
            <Info size={16} />
            <AlertTitle className="typography-body-3">
              This meeting has been pre-qualified
            </AlertTitle>
            <AlertDescription>
              Meetings are pre-qualified if we detect that all the qualification
              criteria have been met. Meetings that are pre-qualified{" "}
              <b>will automatically be paid out</b> if the prospect attends the
              meeting.
            </AlertDescription>
          </Alert>
        </TooltipContent>
      </Tooltip>
    );
  }

  return badge;
};
