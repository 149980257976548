import React, { FC, PropsWithChildren, useCallback } from "react";
import throttle from "lodash/throttle";

import {
  useInfiniteScrollWithObserver,
  INFINITE_SCROLL_WITH_OBSERVER_INDICATOR,
} from "shared/lib/hooks/infinite-scrolling/use-infinite-scroll-with-observer";
import { clsxMerge } from "shared/lib/helpers";
import { InfiniteScrollWithObserverCommonPropsI } from "./interfaces";

interface InfiniteScrollWithObserverPropsI
  extends PropsWithChildren,
    InfiniteScrollWithObserverCommonPropsI {
  hasError?: boolean;
  isMore: boolean;
  isEmpty?: boolean;
  emptyStateContent?: React.ReactNode;
  isLoading: boolean;
  loadingComponent?: React.ReactNode;
  onMore?: () => void;
  onReload?: () => void;
}

const DEFAULT_END_OF_LIST_MESSAGE =
  "You've reached the bottom, it's only up from here!";

const InfiniteScrollStates: FC<InfiniteScrollWithObserverPropsI> = ({
  isLoading,
  loading,
  loadingClassName,
  loadingComponent,
  hasError,
  isMore,
  isEmpty,
  emptyStateContent,
  isDefaultMessageHidden,
  endOfListMessageClassName,
  endOfListMessage,
  onReload,
}) => {
  if (hasError) {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-2">
        <span className="text-black/80">
          An error occurred, please try again
        </span>

        <button className="btn-ae-default" onClick={onReload}>
          Reload the table
        </button>
      </div>
    );
  }

  if (isEmpty) {
    return (
      emptyStateContent || (
        <p className="py-6 text-black/40">No results found</p>
      )
    );
  }

  if (!isMore && !isDefaultMessageHidden) {
    return (
      <p className={clsxMerge("py-6 text-black/40", endOfListMessageClassName)}>
        {endOfListMessage}
      </p>
    );
  }

  if (isLoading) {
    return (
      loading ||
      loadingComponent || (
        <button
          className={clsxMerge(
            "btn loading btn-link cursor-default bg-transparent text-black",
            loadingClassName
          )}
        />
      )
    );
  }

  return null;
};

const InfiniteScrollWithObserver: FC<InfiniteScrollWithObserverPropsI> = ({
  children,
  loading,
  isLoading,
  loadingClassName,
  loadingComponent,
  isMore,
  hasError = false,
  isEmpty = false,
  emptyStateContent,
  endOfListMessageClassName,
  endOfListMessage = DEFAULT_END_OF_LIST_MESSAGE,
  isDefaultMessageHidden,
  onMore = () => {},
  onReload = () => {},
  loadMoreThrottleInterval = 400,
  scrollIndicatorId = INFINITE_SCROLL_WITH_OBSERVER_INDICATOR,
  scrollIndicatorClassName,
}) => {
  const fetchMore = useCallback(throttle(onMore, loadMoreThrottleInterval), [
    onMore,
    loadMoreThrottleInterval,
  ]);

  useInfiniteScrollWithObserver({
    isLoading,
    hasMore: isMore,
    fetchMore,
    scrollIndicatorId,
  });

  return (
    <>
      {children}

      <div
        id={scrollIndicatorId}
        className={clsxMerge("h-1", scrollIndicatorClassName)}
      />

      <div className="brand-typography-body3 flex w-full justify-center">
        <InfiniteScrollStates
          isLoading={isLoading}
          loading={loading}
          loadingClassName={loadingClassName}
          loadingComponent={loadingComponent}
          isMore={isMore}
          isEmpty={isEmpty}
          emptyStateContent={emptyStateContent}
          hasError={hasError}
          isDefaultMessageHidden={isDefaultMessageHidden}
          endOfListMessageClassName={endOfListMessageClassName}
          endOfListMessage={endOfListMessage}
          onReload={onReload}
        />
      </div>
    </>
  );
};

export default InfiniteScrollWithObserver;
