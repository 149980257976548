import { DispositionSectionUI } from "@/components/modules/pipeline/account-details/sections/disposition-section";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { combineActivityNotes } from "@/helpers/activity-log";
import { useMemo } from "react";
import Calling from "@/helpers/calling";

import { glencocoClientAPI } from "@/api/glencoco";
import { handleErrorMessage } from "@/helpers/error";

export const SetDispositionCardV2 = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();

  const note = useMemo(
    () => combineActivityNotes(inCallContext).join("\r\n"),
    [inCallContext?.activity_log, inCallContext?.contact]
  );

  const handleModuleExit = () => {
    Calling.exit({
      context: callingContext,
      inCallContext,
    });
  };

  const moveToNextLead = async (campaignId: string) => {
    const API = glencocoClientAPI();

    const MoveToNextLeadResponse = await API.dialerMoveToNextLead(
      campaignId
    ).catch((e) => e);

    if (MoveToNextLeadResponse.status === 200) {
      return true;
    } else {
      handleErrorMessage(
        MoveToNextLeadResponse?.data?.error_code as number,
        "Failed to find next lead in queue"
      );

      Calling.cleanupAndExit({ context: callingContext, inCallContext });
      return false;
    }
  };

  // eslint-disable-next-line
  const handlerOnSubmit = async () => {
    let isContinue = true;

    //We we are in legacy targeted dialer mode
    if (!callingContext.callerProfile.autoDial) {
      isContinue = await moveToNextLead(callingContext?.campaign?.id as string);
    }

    if (isContinue) {
      onSuccess?.();
      handleModuleExit();
    }
  };

  return (
    <DispositionSectionUI
      buttonTexts={["Save & continue", ""]}
      note={note}
      onSubmit={handlerOnSubmit}
      className="mt-0 rounded-[10px]"
    />
  );
};
