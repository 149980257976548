import _fromPairs from "lodash/fromPairs";

import {
  QualificationCriteriaTableDisputeReasonsValueI,
  QualificationCriteriaTableTogglesValueI,
} from "shared/lib/interfaces/qualification-criteria";

export const QUALIFICATION_CRITERIA_TYPE_MAP = {
  COMPANY_SIZE: "companySizeCriteria",
  PROSPECT_TITLE: "titleCriteria",
  COMPANY_INDUSTRY: "industryCriteria",
  PROBLEM: "problemPainCriteria",
} as const;

export const QUALIFICATION_CRITERIA_REASONS_NOT_MET_FIELDS = {
  SIZE: "reason_size_not_met",
  TITLE: "reason_title_not_met",
  INDUSTRY: "reason_industry_not_met",
  PROBLEM: "reason_problem_not_met",
  TIMING: "reason_timing_not_met",
};

// Calculating that incase we add more base criteria in the future
export const BONUS_CRITERIA_INDEX = Object.values(
  QUALIFICATION_CRITERIA_TYPE_MAP
).length;

export const BONUS_QUALIFICATION_CRITERIA_TYPE = "timingBudgetCriteria";

export const QUALIFICATION_CRITERIA_TYPES = Object.values(
  QUALIFICATION_CRITERIA_TYPE_MAP
);
export const QUALIFICATION_CRITERIA_LABELS = {
  [QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_SIZE]: "Company Size",
  [QUALIFICATION_CRITERIA_TYPE_MAP.PROSPECT_TITLE]: "Prospect Title",
  [QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_INDUSTRY]: "Company Industry",
  [QUALIFICATION_CRITERIA_TYPE_MAP.PROBLEM]: "Problem / Pain",
  [BONUS_QUALIFICATION_CRITERIA_TYPE]: "Timing or Budget",
} as const;

export const QC_TABLE_TOGGLES_INITIAL_STATE = _fromPairs(
  Object.values(QUALIFICATION_CRITERIA_TYPE_MAP).map((qc) => [qc, false])
) as QualificationCriteriaTableTogglesValueI;

export const QC_TABLE_TOGGLES_INITIAL_STATE_PRESELECTED = _fromPairs(
  Object.values(QUALIFICATION_CRITERIA_TYPE_MAP).map((qc) => [qc, true])
) as QualificationCriteriaTableTogglesValueI;

export const QC_TABLE_TOGGLES_INITIAL_STATE_WITH_BONUS = {
  ...QC_TABLE_TOGGLES_INITIAL_STATE,
  [BONUS_QUALIFICATION_CRITERIA_TYPE]: false,
};

export const DEFAULT_QUALIFICATION_CRITERIA_SURVEY_VALUES = _fromPairs(
  Object.values(QUALIFICATION_CRITERIA_TYPE_MAP).map((qc) => [qc, false])
) as QualificationCriteriaTableTogglesValueI;

export const DEFAULT_QUALIFICATION_CRITERIA_DISPUTE_REASONS = _fromPairs(
  Object.values(QUALIFICATION_CRITERIA_TYPE_MAP).map((qc) => [qc, null])
) as QualificationCriteriaTableDisputeReasonsValueI;
