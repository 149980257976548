import { FC, ReactNode, useMemo } from "react";
import { Bot } from "lucide-react";

import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui/tooltip";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface AgentEmailIconPropsI extends PropsWithClassNameI {
  iconClassName?: string;
  tooltipContent?: ReactNode;
  tooltipContentClassName?: string;
}

export const AgentEmailIcon: FC<AgentEmailIconPropsI> = ({
  className,
  iconClassName,
  tooltipContent,
  tooltipContentClassName,
}) => {
  const wrappedIcon = useMemo(
    () => (
      <div
        className={clsxMerge(
          "flex-center app-gradient h-5 w-5 rounded-full",
          className
        )}
      >
        <Bot color="white" className={clsxMerge("h-3 w-3", iconClassName)} />
      </div>
    ),
    [className, iconClassName]
  );

  if (tooltipContent) {
    return (
      <Tooltip>
        <TooltipTrigger>{wrappedIcon}</TooltipTrigger>
        <TooltipContent className={tooltipContentClassName}>
          {tooltipContent}
        </TooltipContent>
      </Tooltip>
    );
  }

  return wrappedIcon;
};
