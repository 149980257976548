// React & Third Party
import { FC } from "react";
import { PlusIcon } from "lucide-react";

// Local Module
import { ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME } from "@/modules/pipeline/account-details/constants";
import { AccountDetailsListItemAsBadge as ListItem } from "@/components/modules/pipeline/account-details/sections/lists-section/list-item";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useAddUserToListSidebarContext } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";

// Shared
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";

const Header = () => {
  const { onOpen: openAddUserToListSidebar } = useAddUserToListSidebarContext();

  return (
    <div className="flex w-full items-center justify-between">
      <h6 className="typography-body-4-medium">Lists</h6>

      <button
        className={clsxMerge(ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME, "gap-1")}
        onClick={openAddUserToListSidebar}
      >
        <PlusIcon className="size-4" /> <span>Add</span>
      </button>
    </div>
  );
};

export const AccountStatusLists: FC<PropsWithClassNameI> = ({ className }) => {
  const { accountDetailsApi, accountUserLists, account, campaign } =
    useAccountDetailsContext();

  return (
    <div className={clsxMerge("w-full", className)}>
      <Header />

      <DataStatesWrapper
        api={accountDetailsApi}
        loading={<CenteredSpinner className="h-[40px]" />}
      >
        <div className="mt-1 flex flex-wrap gap-2">
          {accountUserLists?.map((listItem) => (
            <ListItem
              key={listItem?.list_id}
              accountId={account?.id}
              campaignId={campaign?.id}
              listData={listItem}
            />
          ))}
        </div>
      </DataStatesWrapper>
    </div>
  );
};
