import { SparklesIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import CircularLoading from "@/components/base/circular-loading";
import { BorderWithGradient } from "shared/ui/border-with-gradient";

export const ComposeWithAiButton = ({
  className,
  loading = false,
  onClick = () => {},
}: {
  className?: string;
  loading?: boolean;
  onClick?: () => void;
}) => {
  return (
    <BorderWithGradient
      className={clsxMerge("rounded-md p-0")}
      containerClassName="rounded-lg"
    >
      <button
        type="button"
        className={clsxMerge(
          "btn-ae border-none text-transparent typography-header-8-semibold",
          "gradient-text flex-nowrap whitespace-nowrap",
          className
        )}
        disabled={loading}
        onClick={onClick}
      >
        {loading ? (
          <CircularLoading className="mr-2 w-4 text-[#714CC9]" />
        ) : (
          <SparklesIcon className="mb-1 mr-2 w-4 text-[#714CC9]" />
        )}
        Compose with AI
      </button>
    </BorderWithGradient>
  );
};
