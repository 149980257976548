import { Button } from "shared/ui/shadcn/button";
import { clsxMerge } from "shared/lib/helpers";

export interface AccountDetailsButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
}

export const AccountDetailsButton = ({
  className,
  isActive,
  ...props
}: AccountDetailsButtonProps) => {
  return (
    <Button
      variant="outline"
      size="sm"
      className={clsxMerge(
        "h-[26px] rounded-lg",
        "typography-body-5 text-[#71717A]",
        "border-[#CCCCCC]",
        {
          "border-[#333333] bg-[#F4F5FF] text-[#333333]": isActive,
        },
        className
      )}
      {...props}
    />
  );
};
