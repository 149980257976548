import { LocalStorage } from "@/helpers/local-storage";
import { AccountDetailsViewOptionI } from "./interface";

export const syncOptions = (options: AccountDetailsViewOptionI[]) => {
  if (typeof window !== "undefined") {
    const LS = new LocalStorage();

    return options.map((option) => {
      return (
        LS.accountDetailsViewOptions?.find((o) => o.label === option.label) ||
        option
      );
    });
  }

  return options;
};

export const createOption = (
  label: string,
  value: boolean
): AccountDetailsViewOptionI => ({
  label,
  value,
});
