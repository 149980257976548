import { GetEmailGenerationStatusResponseI } from "@/api/routes/email";
import { SessionStorage } from "@/helpers/session-storage";
import { useEffect, useState } from "react";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

export const EMAIL_TEMPLATE_LOCAL_DRAFTS_KEY_TYPES = {
  CALL_ID: "call_id",
} as const;

const getSessionStorageLocalDrafts = (
  type: ValueOfObjectFields<typeof EMAIL_TEMPLATE_LOCAL_DRAFTS_KEY_TYPES>
) => {
  if (typeof window !== "undefined") {
    const SS = new SessionStorage();

    switch (type) {
      case EMAIL_TEMPLATE_LOCAL_DRAFTS_KEY_TYPES.CALL_ID:
        return SS.emailLocalDraftsByCallId || {};
      default:
        return {};
    }
  }

  return {};
};

/**
 * Local drafts for email templates
 * This is used to save email data locally when user is composing email
 * so that it can be used later when user comes back to the email template
 */
export const useEmailTemplateLocalDrafts = (
  type: ValueOfObjectFields<typeof EMAIL_TEMPLATE_LOCAL_DRAFTS_KEY_TYPES>
) => {
  const [localDrafts, setLocalDrafts] = useState<
    Record<string, GetEmailGenerationStatusResponseI>
  >(getSessionStorageLocalDrafts(type));

  const setLocalDraft = (
    draftId: string,
    emailData?: GetEmailGenerationStatusResponseI
  ) => {
    setLocalDrafts((prev) => ({
      ...prev,
      [draftId]: { ...(emailData || {}) },
    }));
  };

  const getLocalDraft = (draftId: string) => localDrafts[draftId];

  useEffect(() => {
    const SS = new SessionStorage();

    switch (type) {
      case EMAIL_TEMPLATE_LOCAL_DRAFTS_KEY_TYPES.CALL_ID:
        SS.emailLocalDraftsByCallId = localDrafts;
        break;
      default:
        break;
    }
  }, [localDrafts]);

  return {
    localDrafts,
    setLocalDrafts,
    /**
     * Set local draft for a given draft id
     * @param draftId - id of the draft
     * @param emailData - email data to be saved
     */
    setLocalDraft,
    /**
     * Get local draft for a given draft id
     * @param draftId - id of the draft
     * @returns email data
     */
    getLocalDraft,
  };
};
