import { useMemo, useState } from "react";
import { ListBulletIcon, PlusIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

import { ControlledDropdown } from "shared/ui";
import { clsxMerge } from "shared/lib/helpers";
import { Checkbox } from "shared/ui/ae-user-input";
import {
  useBulkAddContactsToList,
  useCreateList,
  useFetchLists,
} from "@/modules/calling/queries";
import { CreateListSidebar } from "@/components/base/sidebars/create-list-sidebar";
import { useDisclosure } from "shared/lib/hooks";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { ListPillI } from "shared/lib/interfaces/lists";

const EnhancedCheckbox = Checkbox();

export const AddToListsButton = ({
  className,
  disabled,
}: {
  className?: string;
  disabled?: boolean;
}) => {
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  const inCallContext = useInCallContext();
  const callingContext = useCallingContext();
  const { contact } = inCallContext;
  const { campaign } = callingContext;

  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);
  const { mutateAsync: createList } = useCreateList();
  const { mutateAsync: bulkAddContactsToList } = useBulkAddContactsToList();

  const { data: listsData } = useFetchLists();

  const targetListsOptions = useMemo(
    () =>
      listsData?.data?.user_lists.filter(
        ({ is_default_list }) => !is_default_list
      ) || [],
    [listsData?.data?.user_lists]
  );

  const handleListChange = (list: ListPillI) => {
    if (selectedListIds.includes(list.id)) {
      const newListIds = selectedListIds.filter((id) => id !== list.id);
      setSelectedListIds(newListIds);
    } else {
      setSelectedListIds([...selectedListIds, list.id]);
    }
  };

  const handleCreateList = async (name: string, description?: string) => {
    await createList(
      { name: name, description: description },
      { onSuccess: () => toast.success(`List "${name}" created`) }
    );
    onSidebarClose();
  };

  const handleApply = async () => {
    if (contact.id && campaign?.id && !!selectedListIds.length) {
      await bulkAddContactsToList({
        contacts: [{ campaign_id: campaign.id, contact_id: contact.id }],
        list_ids: selectedListIds,
      });
    }
  };

  return (
    <>
      <ControlledDropdown
        className={clsxMerge("dropdown-top w-full", {
          "disabled cursor-not-allowed opacity-60": disabled,
        })}
      >
        {() => (
          <>
            <label
              tabIndex={0}
              className={clsxMerge("block w-full text-start", className)}
            >
              <div
                className={clsxMerge("btn-nofill btn-ae-l w-full text-black")}
              >
                <ListBulletIcon className="mr-2 w-5" /> Add to list
              </div>
            </label>

            <div
              tabIndex={0}
              className={clsxMerge(
                "dropdown-content w-[250px] bg-base-100 p-2",
                "rounded-lg shadow-lg"
              )}
            >
              <div className={clsxMerge("max-h-[400px] overflow-y-auto")}>
                {targetListsOptions?.map((list, i) => (
                  <EnhancedCheckbox
                    key={i}
                    name="list"
                    label={list.name}
                    className="b-typography-body1"
                    labelTextClassName="truncate"
                    value={selectedListIds.includes(list.id)}
                    inputProps={{ onChange: () => handleListChange(list) }}
                  />
                ))}

                <label
                  className={clsxMerge("btn-transparent ae-link label")}
                  onClick={onSidebarOpen}
                >
                  <PlusIcon className="w-4" /> Create a custom list
                </label>

                <button
                  className="btn-nofill btn-sm w-full"
                  onClick={handleApply}
                  disabled={selectedListIds.length === 0}
                >
                  Apply
                </button>
              </div>
            </div>
          </>
        )}
      </ControlledDropdown>

      <CreateListSidebar
        isOpen={isSidebarOpen}
        onClose={onSidebarClose}
        onSubmit={handleCreateList}
      />
    </>
  );
};
