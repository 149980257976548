import _omit from "lodash/omit";

import {
  ActivityLogQCsV2,
  MappedQCsAndPricingResponseI,
  QCsAndPricingResponseI,
  QCsBackendTextsI,
  QualificationCriteriaDisputeReasonsRequestI,
  QualificationCriteriaSurveyValuesI,
  QualificationCriteriaTableDisputeReasonsValueI,
  QualificationCriteriaTableTogglesValueI,
} from "shared/lib/interfaces/qualification-criteria";
import {
  BONUS_QUALIFICATION_CRITERIA_TYPE,
  QUALIFICATION_CRITERIA_TYPE_MAP,
} from "shared/lib/constants/qualification-criteria";

export const getQCTextsFromResponse = (qcs: QCsBackendTextsI | undefined) => {
  if (!qcs) {
    return {
      companySizeCriteria: "",
      titleCriteria: "",
      industryCriteria: "",
      problemPainCriteria: "",
      timingBudgetCriteria: "",
    };
  }

  return {
    companySizeCriteria: qcs.company_size_criteria || "",
    titleCriteria: qcs.title_criteria || "",
    industryCriteria: qcs.industry_criteria || "",
    problemPainCriteria: qcs.problem_pain_criteria || "",
    timingBudgetCriteria: qcs.timing_budget_criteria || "",
  };
};

export const getQCsAndPricingFromResponse = (
  qcsAndPricingResponse: QCsAndPricingResponseI | undefined
): MappedQCsAndPricingResponseI => {
  if (!qcsAndPricingResponse) {
    return {
      baseChargeAmount: 0,
      bonusChargeAmount: 0,
      companySizeCriteria: "",
      titleCriteria: "",
      industryCriteria: "",
      problemPainCriteria: "",
      timingBudgetCriteria: "",
    };
  }

  return {
    baseChargeAmount:
      qcsAndPricingResponse.qualification_criteria?.base_charge_amount,
    bonusChargeAmount:
      qcsAndPricingResponse.qualification_criteria?.bonus_charge_amount,
    ...getQCTextsFromResponse(qcsAndPricingResponse.qualification_criteria),
  };
};

export const mapSelectedQCsToRequest = (
  selectedQCs: QualificationCriteriaTableTogglesValueI | undefined
): QualificationCriteriaSurveyValuesI => ({
  size_met:
    selectedQCs?.[QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_SIZE] ?? false,
  title_met:
    selectedQCs?.[QUALIFICATION_CRITERIA_TYPE_MAP.PROSPECT_TITLE] ?? false,
  industry_met:
    selectedQCs?.[QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_INDUSTRY] ?? false,
  problem_met: selectedQCs?.[QUALIFICATION_CRITERIA_TYPE_MAP.PROBLEM] ?? false,
  timing_met: selectedQCs?.[BONUS_QUALIFICATION_CRITERIA_TYPE] ?? false,
});

export const mapActivityLogQCToSelectedQCs = (
  backendCheckedQCs: ActivityLogQCsV2 | undefined
): QualificationCriteriaTableTogglesValueI => ({
  [QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_SIZE]:
    backendCheckedQCs?.is_size_met || false,
  [QUALIFICATION_CRITERIA_TYPE_MAP.PROSPECT_TITLE]:
    backendCheckedQCs?.is_title_met || false,
  [QUALIFICATION_CRITERIA_TYPE_MAP.COMPANY_INDUSTRY]:
    backendCheckedQCs?.is_industry_met || false,
  [QUALIFICATION_CRITERIA_TYPE_MAP.PROBLEM]:
    backendCheckedQCs?.is_problem_pain_met || false,
  [BONUS_QUALIFICATION_CRITERIA_TYPE]:
    backendCheckedQCs?.is_timing_budget_met || false,
});

export const checkIfSelectedQCsQualify = (
  selectedQCs: QualificationCriteriaSurveyValuesI
) => Object.values(_omit(selectedQCs, "timing_met")).every(Boolean);

export const checkIfSelectedQCsQualifyWithBonus = (
  selectedQCs: QualificationCriteriaSurveyValuesI
) => Object.values(selectedQCs).every(Boolean);

/**
 * This function used to convert disputes entered by the user to the request of
 * submit AE survey v2.
 *
 * @param disputeReasons
 */
export const mapDisputeReasonsToRequest = (
  disputeReasons: QualificationCriteriaTableDisputeReasonsValueI | undefined
): QualificationCriteriaDisputeReasonsRequestI => ({
  reason_size_not_met: disputeReasons?.companySizeCriteria || null,
  reason_title_not_met: disputeReasons?.titleCriteria || null,
  reason_industry_not_met: disputeReasons?.industryCriteria || null,
  reason_problem_not_met: disputeReasons?.problemPainCriteria || null,
  reason_timing_not_met: disputeReasons?.timingBudgetCriteria || null,
});

export const checkIfQCAuditQualified = (
  audit: ActivityLogQCsV2 | null | undefined
) => {
  if (!audit) {
    return false;
  }

  return (
    audit?.is_size_met &&
    audit?.is_title_met &&
    audit?.is_industry_met &&
    audit?.is_problem_pain_met
  );
};
