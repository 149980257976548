export const ACCOUNTS_DETAILS_TABS = {
  DIALER_TOOLKIT: "dialer_toolkit",
  DETAILS: "details",
  HISTORY: "history",
  AE_MESSAGES: "ae_messages",
  SCRIPTS: "scripts",
  QUALIFICATION_CRITERIA: "qualification_criteria",
} as const;

export const ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME =
  "ae-link brand-typography-body6 flex items-center gap-2";
