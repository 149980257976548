import { FC, Fragment, ReactNode } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "shared/ui/shadcn/table";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import {
  BONUS_QUALIFICATION_CRITERIA_TYPE,
  QUALIFICATION_CRITERIA_LABELS,
  QUALIFICATION_CRITERIA_TYPES,
} from "shared/lib/constants/qualification-criteria";
import {
  QualificationCriteriaTextsI,
  QualificationCriteriaTypeWithBonusI,
} from "shared/lib/interfaces/qualification-criteria";

interface CellI extends PropsWithClassNameI {
  colSpan?: number;
  rowSpan?: number;
  content: ReactNode;
}

export interface QualificationCriteriaTablePropsI extends PropsWithClassNameI {
  tableClassName?: string;
  tableHeaderClassName?: string;
  tableBodyClassName?: string;
  tableRowClassName?: string;
  tableFooterClassName?: string;

  // Map of QC values (bonus is optional) entered by the customer during the
  // onboarding
  texts: QualificationCriteriaTextsI;

  renderQCRowExtra?: (type: QualificationCriteriaTypeWithBonusI) => ReactNode;

  rightExtension?: {
    head: CellI;
    cells: CellI[];
  };
  bottomExtension?: {
    cells: CellI[];
  };
}

export const QualificationCriteriaTable: FC<
  QualificationCriteriaTablePropsI
> = ({
  className,
  tableClassName,
  tableHeaderClassName,
  tableBodyClassName,
  tableRowClassName,
  tableFooterClassName,
  rightExtension,
  bottomExtension,
  texts,
  renderQCRowExtra,
}) => (
  <div className={clsxMerge("overflow-none rounded-lg border", className)}>
    <Table className={clsxMerge("overflow-none", tableClassName)}>
      <TableHeader className={clsxMerge("border-b", tableHeaderClassName)}>
        <TableRow>
          <TableHead className="min-w-[160px]">Criteria type</TableHead>
          <TableHead>Input</TableHead>

          {rightExtension && (
            <TableHead
              className={clsxMerge("border-l", rightExtension.head.className)}
            >
              {rightExtension.head.content}
            </TableHead>
          )}
        </TableRow>
      </TableHeader>

      <TableBody className={tableBodyClassName}>
        {QUALIFICATION_CRITERIA_TYPES.map((type, index) => (
          <Fragment key={type}>
            <TableRow
              className={clsxMerge(
                "min-w-[160px] hover:bg-transparent",
                tableRowClassName
              )}
            >
              <TableCell className="font-medium">
                {QUALIFICATION_CRITERIA_LABELS[type]}
              </TableCell>

              <TableCell>{texts[type] || "Not Specified"}</TableCell>

              {rightExtension?.cells?.[index] && (
                <TableCell
                  rowSpan={rightExtension.cells?.[index]?.colSpan || 1}
                  className={clsxMerge(
                    "border-l",
                    rightExtension.cells?.[index]?.className
                  )}
                >
                  {rightExtension.cells?.[index]?.content || "Not Specified"}
                </TableCell>
              )}
            </TableRow>

            {renderQCRowExtra && renderQCRowExtra(type)}
          </Fragment>
        ))}
      </TableBody>

      {bottomExtension && (
        <TableFooter className={tableFooterClassName}>
          <TableRow className="font-bold">
            {bottomExtension.cells.map((bottomExtensionCell, index) => (
              <TableCell
                key={index}
                colSpan={bottomExtensionCell.rowSpan || 1}
                className={bottomExtensionCell.className}
              >
                {bottomExtensionCell.content || "Not Specified"}
              </TableCell>
            ))}
          </TableRow>
          {renderQCRowExtra &&
            renderQCRowExtra(BONUS_QUALIFICATION_CRITERIA_TYPE)}
        </TableFooter>
      )}
    </Table>
  </div>
);
