import { InfoIcon, MailIcon } from "lucide-react";
import { Badge } from "shared/ui/shadcn/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";

export const DispositionSectionHeader = ({
  isEmailReady = false,
}: {
  isEmailReady?: boolean;
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      <h6>What happened on the call?</h6>

      {isEmailReady && (
        <div className="animate-fadein">
          <Tooltip placement="bottom">
            <TooltipTrigger className="cursor-default">
              <Badge
                variant="outline"
                className="flex items-center gap-1 border-[#CCCCCC]"
              >
                <MailIcon className="relative top-[-1px] w-3 text-[#714CC9]" />
                <span className="gradient-text typography-body-5">
                  Follow-up email ready
                </span>
              </Badge>
            </TooltipTrigger>
            <TooltipContent className="z-[999]">
              <div className="max-w-[450px] p-2">
                <div className="flex gap-2">
                  <div>
                    <InfoIcon className="w-6 min-w-6" />
                  </div>
                  <div>
                    <h6 className="mb-1 typography-body-3-medium">
                      Send follow-up emails after conversation
                    </h6>
                    <p className="typography-body-4">
                      Based on your call, a follow-up email has been generated
                      for you. After submitting the disposition, you will be
                      taken to the email for review.
                    </p>
                  </div>
                </div>
              </div>
            </TooltipContent>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
