import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import Script from "next/script";

import { useGlobalContext } from "@/hooks/use-global-context";
import { UserI } from "@/interfaces/user";
import { checkIfClient } from "shared/lib/helpers";
import Router from "@/helpers/router";

export const IntercomInitLibrary = () => {
  return ["staging", "production"].includes(
    process.env.NEXT_PUBLIC_ENV as string
  ) ? (
    <Script id="intercome-script" strategy="afterInteractive" async={true}>
      {`
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  `}
    </Script>
  ) : null;
};

const BLOCKED_LIST = ["/assessment", Router.lists(), Router.publicProfile()];

const isAllowed = (user?: UserI) => {
  const { pathname } = new URL(window.location.href);

  return (
    !!user?.registered &&
    BLOCKED_LIST.every((blocked) => !pathname?.includes(blocked))
  );
};

export const IntercomLaunchMessenger = (
  intercomData?: any,
  isBoot?: boolean
) => {
  if (checkIfClient() && process.env.NEXT_PUBLIC_INTERCOM_APP_ID) {
    const attrs = intercomData || {};

    (window as any).intercomSettings = {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      api_base: "https://api-iam.intercom.io",
      ...attrs,
    };

    if (isBoot) {
      (window as any).Intercom?.("boot", {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        api_base: "https://api-iam.intercom.io",
        ...attrs,
      });
    }
  }
};

export const IntercomComponent = () => {
  const { pathname } = useRouter();
  const { intercom, isUserLoaded, glencocoUser: user } = useGlobalContext();
  const [isConfigSet, setIsConfigSet] = useState(false);

  const isIntercomBubbleVisible = useMemo(() => {
    return isAllowed(user as UserI);
  }, [user, pathname]);

  useEffect(() => {
    if (isUserLoaded && intercom && isIntercomBubbleVisible) {
      IntercomLaunchMessenger(intercom);
      setIsConfigSet(true);
    }
  }, [intercom, isUserLoaded, isIntercomBubbleVisible]);

  useEffect(() => {
    if (isConfigSet) {
      setTimeout(() => {
        IntercomLaunchMessenger(intercom, true);
      }, 1000);
    }
  }, [isConfigSet]);

  useEffect(() => {
    if (!checkIfClient() || !isConfigSet) {
      return;
    }

    if (isIntercomBubbleVisible) {
      (window as any).Intercom?.("update", { hide_default_launcher: false });
    } else {
      (window as any).Intercom?.("update", { hide_default_launcher: true });
    }
  }, [isConfigSet, isIntercomBubbleVisible]);

  return <>{isConfigSet && IntercomInitLibrary()}</>;
};
