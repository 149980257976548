import CreateNewLeadModal, {
  CREATE_NEW_LEAD_MODAL_ID,
} from "@/components/modals/create-new-lead-modal";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";

import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { PlusIcon } from "@heroicons/react/24/outline";

import { Portal } from "react-portal";
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

const DIALER_WIDGET_CREATE_NEW_LEAD_MODAL_ID = `dialer_widget_${CREATE_NEW_LEAD_MODAL_ID}`;

export const AddNewLeadButton = ({ className }: { className?: string }) => {
  const { campaign } = useCallingContext();
  const { account } = useInCallContext();

  const handleOpenModal = () =>
    modalHelpers.open(DIALER_WIDGET_CREATE_NEW_LEAD_MODAL_ID);

  const handleOnSuccess = () => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD,
    });
  };

  return (
    <>
      <button
        className={clsxMerge("btn-transparent", className)}
        onClick={handleOpenModal}
      >
        <PlusIcon className="mr-3 w-4" />
        Add lead
      </button>

      {/*@ts-ignore*/}
      <Portal>
        <CreateNewLeadModal
          modalId={DIALER_WIDGET_CREATE_NEW_LEAD_MODAL_ID}
          account={account}
          campaign={campaign}
          onSuccess={handleOnSuccess}
        />
      </Portal>
    </>
  );
};
