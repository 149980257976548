// React hooks
import { useEffect, useMemo } from "react";

// Contexts & hooks
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useCallCopilot } from "@/modules/pipeline/account-details/hooks/use-call-copilot";

// Constants
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";
import { DIALER_TOOLKIT_VIEW_OPTIONS } from "@/modules/pipeline/account-details/nav/view-options/constants";

// Layout components
import { AccountDetailsLayout as Layout } from "@/modules/pipeline/account-details/layout";

// Section components
import { ScriptsSection } from "@/modules/pipeline/account-details/sections/scripts-section";
import { LeadsSection } from "@/modules/pipeline/account-details/sections/leads-section";
import { DispositionSection } from "@/modules/pipeline/account-details/sections/disposition-section";
import { NotepadSection } from "@/modules/pipeline/account-details/sections/notepad-section";
import { AccountSummarySection } from "@/modules/pipeline/account-details/sections/account-summary-section";
import { AccountTalkingPointsSection } from "@/modules/pipeline/account-details/sections/account-talking-points-section";
import { AccountAboutSection } from "@/modules/pipeline/account-details/sections/account-about-section";
import { ContactCustomFieldsSection } from "@/modules/pipeline/account-details/sections/contact-custom-fields-section";
import { EmailSection } from "@/modules/pipeline/account-details/sections/email-section";
import { CallCopilotSection } from "@/modules/pipeline/account-details/sections/call-copilot-section";

// Utilities
import { clsxMerge } from "shared/lib/helpers";

export const AccountDetailsTabDialerToolkit = () => {
  const {
    accountStatus,
    dialerToolkitViewOptions,
    isEmailSection,
    setIsEmailSection,
    emailFollowUpData,
    setEmailFollowUpData,
    emailFollowUpRequestParams,
    setEmailFollowUpRequestParams,
  } = useAccountDetailsContext();
  const { clear: clearCallContext } = useInCallContext();
  const callStatus = useDialerCallStatus();
  const { isCallCopilotSection } = useCallCopilot();

  const isScripts = useMemo(() => {
    return dialerToolkitViewOptions?.find(
      (option) => option.label === DIALER_TOOLKIT_VIEW_OPTIONS.SCRIPTS
    )?.value;
  }, [dialerToolkitViewOptions]);

  const isNotepad = useMemo(() => {
    return dialerToolkitViewOptions?.find(
      (option) => option.label === DIALER_TOOLKIT_VIEW_OPTIONS.NOTEPAD
    )?.value;
  }, [dialerToolkitViewOptions]);

  const isAccountSummary = useMemo(() => {
    return dialerToolkitViewOptions?.find(
      (option) => option.label === DIALER_TOOLKIT_VIEW_OPTIONS.ACCOUNT_SUMMARY
    )?.value;
  }, [dialerToolkitViewOptions]);

  const isPitch = useMemo(() => {
    return dialerToolkitViewOptions?.find(
      (option) => option.label === DIALER_TOOLKIT_VIEW_OPTIONS.PITCH
    )?.value;
  }, [dialerToolkitViewOptions]);

  const isCompanyPurpose = useMemo(() => {
    return dialerToolkitViewOptions?.find(
      (option) => option.label === DIALER_TOOLKIT_VIEW_OPTIONS.COMPANY_PURPOSE
    )?.value;
  }, [dialerToolkitViewOptions]);

  const isLeads = useMemo(() => {
    return dialerToolkitViewOptions?.find(
      (option) => option.label === DIALER_TOOLKIT_VIEW_OPTIONS.LEADS
    )?.value;
  }, [dialerToolkitViewOptions]);

  /**
   * Note
   * We get this values earlier than isEmailSection is set
   * So we need block triggering split second disposition section if meeting is booked
   */
  const isEmailSectionByBookedMeeting = useMemo(() => {
    return (
      emailFollowUpData?.should_send_email &&
      emailFollowUpRequestParams?.has_meeting
    );
  }, [
    emailFollowUpData?.should_send_email,
    emailFollowUpRequestParams?.has_meeting,
  ]);

  /**
   * Note
   * Do not require disposition if meeting is already booked
   */
  useEffect(() => {
    /**
     * Useecase
     * When call is after and meeting is scheduled, and no email section active,
     * we want to clear the call context
     */
    if (
      callStatus === DIALER_CALL_STATUS.AFTER &&
      accountStatus?.status ===
        ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED &&
      !isEmailSectionByBookedMeeting
    ) {
      clearCallContext();
    }
  }, [callStatus, accountStatus]);

  useEffect(() => {
    /**
     * Useecase
     * Clean up on default call status
     */
    if (callStatus === DIALER_CALL_STATUS.BEFORE) {
      setEmailFollowUpData?.(undefined);
      setEmailFollowUpRequestParams?.(undefined);
    }
    /**
     * Useecase
     * When call is ongoing, we don't want to show email section
     */
    if (callStatus === DIALER_CALL_STATUS.DURING) {
      setIsEmailSection?.(false);
    }
  }, [callStatus]);

  /**
   * Useecase
   * When call is ends and meeting is scheduled, and no email section is active,
   * we want to show email section if emailFollowUpData object is present
   * before cleaning the call context
   */
  useEffect(() => {
    if (
      callStatus === DIALER_CALL_STATUS.AFTER &&
      emailFollowUpData?.should_send_email &&
      emailFollowUpRequestParams?.has_meeting
    ) {
      setIsEmailSection?.(true);
    }
  }, [callStatus, emailFollowUpData, emailFollowUpRequestParams]);

  return (
    <Layout.GridColumns columns={2}>
      <Layout.GridColumn className="pb-0 pl-2 pr-1 pt-3">
        <div
          className={clsxMerge("flex flex-col gap-y-3 divide-y", {
            // When call copilot is not active, we want to add padding to the bottom of the container
            // so that disposition is not hidden behind the actions control area
            // bascially for smaller screens when users have to disposition or send an email
            "pb-36":
              !isCallCopilotSection && DIALER_CALL_STATUS.BEFORE !== callStatus,
          })}
        >
          {isCallCopilotSection && <CallCopilotSection />}

          {isEmailSection && <EmailSection />}

          {DIALER_CALL_STATUS.AFTER === callStatus &&
            !isEmailSectionByBookedMeeting &&
            !isEmailSection && <DispositionSection />}

          {isScripts && DIALER_CALL_STATUS.AFTER !== callStatus && (
            <ScriptsSection />
          )}
        </div>
      </Layout.GridColumn>

      <Layout.GridColumn className="pl-1 pr-2 pt-3">
        <div className="mx-auto max-w-[1100px]">
          {isNotepad && <NotepadSection />}

          {isAccountSummary && <AccountSummarySection className="mt-3" />}

          <ContactCustomFieldsSection className="mt-3" />

          {isPitch && <AccountTalkingPointsSection className="mt-3" />}

          {isCompanyPurpose && <AccountAboutSection className="mt-3" />}

          {isLeads && <LeadsSection className="mb-24 mt-5" />}
        </div>
      </Layout.GridColumn>
    </Layout.GridColumns>
  );
};
