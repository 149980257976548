import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { DIALER_CALL_STATUS, DIALER_STATES } from "@/constants/dialer";
import { WIDGETS } from "@/constants/widgets";
import Router from "@/helpers/router";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { useRouter } from "next/router";

import { EventHandler, useEffect } from "react";
import { DIALER_LIST_DIALING_STATUS } from "shared/lib/constants/dialer";

export const DialerWidgetController = () => {
  const { setCallingState, setCall, setCampaignID } = useCallingContext();
  const { connectedLead, setConnectedLead, setCalls } =
    useDialerGlobalContext();
  const callStatus = useDialerCallStatus();

  const router = useRouter();

  const setConnectedLeadToWasConnected = () => {
    setConnectedLead?.((lead) => {
      if (!lead) return lead;
      setCalls?.((prevCallsMap) => {
        const newCallsMap = new Map(prevCallsMap);
        const connectedLead = newCallsMap.get(lead.list_membership_id);

        if (!connectedLead) return prevCallsMap;

        newCallsMap.set(lead.list_membership_id, {
          ...connectedLead,
          status: DIALER_LIST_DIALING_STATUS.HAD_CONVERSATION,
        });

        return newCallsMap;
      });

      return undefined;
    });
  };

  /**
   * As soon as connected lead is established in the global dialer context
   * it is required to set the dialing state to Call in the comoponent
   * where callingContext is available
   */
  useEffect(() => {
    if (connectedLead) {
      if (connectedLead?.campaign_id) setCampaignID(connectedLead?.campaign_id);

      setCallingState(DIALER_STATES.CALL);
    }
  }, [connectedLead]);

  useEffect(() => {
    if (callStatus === DIALER_CALL_STATUS.AFTER) {
      setConnectedLeadToWasConnected();
    }
  }, [callStatus]);

  useEffect(() => {
    const handleEventOpenWidget = () => {};

    const handleEventCloseWidget = () => {
      /**
       * The referrence to acceptCall function in CallingHelper
       */
      setCallingState(DIALER_STATES.DIALING);

      /**
       * Make sure call is cleared to avoid disconnects
       */
      setCall(undefined);

      /**
       * Due to delay on the BE side to ensure stable dialing to the next lead
       * and accurate statuses
       */
      setConnectedLeadToWasConnected();

      /**
       * Redirect back to dialing list
       */
      router.push(Router.lists());
    };

    const closeWidgetEventArgs = [
      CUSTOM_EVENTS.WIDGETS[WIDGETS.MAXED_DIALER].CLOSE,
      handleEventCloseWidget as EventHandler<any>,
    ] as [type: string, listener: EventListenerOrEventListenerObject];

    const openWidgetEventArgs = [
      CUSTOM_EVENTS.WIDGETS[WIDGETS.MAXED_DIALER].OPEN,
      handleEventOpenWidget as EventHandler<any>,
    ] as [type: string, listener: EventListenerOrEventListenerObject];

    window.document.addEventListener(...openWidgetEventArgs);
    window.document.addEventListener(...closeWidgetEventArgs);

    return () => {
      window.document.removeEventListener(...openWidgetEventArgs);
      window.document.removeEventListener(...closeWidgetEventArgs);
    };
  }, []);

  return null;
};
