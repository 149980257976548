import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

import { ACCOUNTS_DETAILS_TABS } from "@/modules/pipeline/account-details/constants";
import { AccountDetailsTabDialerToolkit } from "@/components/modules/pipeline/account-details/tabs/tab-dialer-toolkit";
import { AccountDetailsTabDetails } from "@/components/modules/pipeline/account-details/tabs/tab-details.tsx";
import { AccountDetailsTabHistory } from "@/components/modules/pipeline/account-details/tabs/tab-history";
import { AccountDetailsTabAeMessages } from "@/components/modules/pipeline/account-details/tabs/tab-ae-messages";
import { AccountDetailsTabScripts } from "@/components/modules/pipeline/account-details/tabs/tab-scripts";
import { AccountDetailsTabQualificationCriteria } from "@/modules/pipeline/account-details/tabs/tab-qualification-criteria";

import {
  useCallCopilot,
  useCallCopilotWSConnection,
} from "@/modules/pipeline/account-details/hooks/use-call-copilot";

const CallCopilotWSConnection = () => {
  useCallCopilotWSConnection();

  return null;
};

export const PrimaryContent = () => {
  const { isCallCopilotSection } = useCallCopilot();

  const { activeTab } = useAccountDetailsContext();

  return (
    <>
      {isCallCopilotSection && <CallCopilotWSConnection />}

      {ACCOUNTS_DETAILS_TABS.DIALER_TOOLKIT === activeTab && (
        <AccountDetailsTabDialerToolkit />
      )}

      {ACCOUNTS_DETAILS_TABS.DETAILS === activeTab && (
        <AccountDetailsTabDetails />
      )}

      {ACCOUNTS_DETAILS_TABS.HISTORY === activeTab && (
        <AccountDetailsTabHistory />
      )}

      {ACCOUNTS_DETAILS_TABS.AE_MESSAGES === activeTab && (
        <AccountDetailsTabAeMessages />
      )}

      {ACCOUNTS_DETAILS_TABS.SCRIPTS === activeTab && (
        <AccountDetailsTabScripts />
      )}

      {ACCOUNTS_DETAILS_TABS.QUALIFICATION_CRITERIA === activeTab && (
        <AccountDetailsTabQualificationCriteria />
      )}
    </>
  );
};
