import { checkIfClient } from "./utils";

const getModalCheckboxControl = (modalId: string) => {
  if (!checkIfClient()) {
    return null;
  }

  const modalVisibilityControl = document.getElementById(modalId);

  if (!modalVisibilityControl) {
    return null;
  }

  return modalVisibilityControl as HTMLInputElement;
};

export const modalHelpers = {
  trigger: (modalId: string) => {
    getModalCheckboxControl(modalId)?.click();
  },
  open: (modalId: string) => {
    const modalCheckboxControl = getModalCheckboxControl(modalId);

    if (modalCheckboxControl && !modalCheckboxControl.checked) {
      modalCheckboxControl.click();
    }
  },
  lazyOpen: (modalId: string, delayMilliseconds = 100) => {
    // That's a common use case for modals within our app to open with a delay
    setTimeout(() => modalHelpers.open(modalId), delayMilliseconds);
  },
  close: (modalId: string) => {
    const modalCheckboxControl = getModalCheckboxControl(modalId);

    if (modalCheckboxControl && modalCheckboxControl.checked) {
      modalCheckboxControl.click();
    }
  },
};
