import { useState } from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import { useInterval } from "shared/lib/hooks";
import { TIME_INTERVALS_MS } from "shared/lib/constants/time";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { clsxMerge } from "shared/lib/helpers/styles";
import { Button } from "shared/ui/shadcn/button";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { WIDGETS } from "@/constants/widgets";
import { widgets } from "@/components/shared/widgets";

const TEXT_STRINGS = [
  "Analyzing call transcript...",
  "Analyzing call notes...",
  "Analyzing account summary...",
  "Analyzing external news...",
];

const ANIMATION_SPEED_MS = TIME_INTERVALS_MS.ONE_SECOND * 3.5;

export const EmailSectionSkeleton = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const { isEnabled } = useDialerGlobalContext();

  const { setCall } = useCallingContext();
  const { clear: clearInCallContext } = useInCallContext();
  const { setIsEmailSection } = useAccountDetailsContext();

  const handlerOnSkip = () => {
    setCall(undefined);
    clearInCallContext();
    setIsEmailSection?.(false);

    if (isEnabled) {
      widgets.close({ id: WIDGETS.MAXED_DIALER });
    }
  };

  useInterval(
    () => {
      setCurrentTextIndex((prevIndex) =>
        prevIndex === TEXT_STRINGS.length - 1 ? 0 : prevIndex + 1
      );
    },
    ANIMATION_SPEED_MS,
    []
  );

  return (
    <div className="flex w-full animate-fadein flex-col p-6">
      <div className="h-full w-full">
        <div className="flex gap-3">
          <div className="w-[40%]">
            <Skeleton count={1} containerClassName="flex-1" height={15} />
          </div>
          <div className="w-[40%]">
            <Skeleton count={1} containerClassName="flex-1" height={15} />
          </div>
        </div>

        <div className="w-[82%]">
          <Skeleton count={1} containerClassName="flex-1" height={15} />
        </div>

        <div className="relative mb-4">
          <Skeleton count={1} containerClassName="flex-1" height={200} />

          <p className="absolute left-4 top-4 typography-body-4">
            <span
              key={currentTextIndex}
              className={clsxMerge(
                "gradient-text duration-1000 animate-in fade-in-0 slide-in-from-left-1/4",
                "flex items-center gap-x-2"
              )}
            >
              <SparklesIcon className={clsxMerge("w-6 text-[#714CC9]")} />
              {TEXT_STRINGS[currentTextIndex]}
            </span>
            {/* Analyzing call notes, account summary and external news... */}
          </p>
        </div>

        <div className="mb-4 w-[70%]">
          <Skeleton count={1} containerClassName="flex-1" height={15} />
        </div>

        <div className="w-[20%]">
          <Skeleton count={1} height={15} />
        </div>
        <div className="w-[15%]">
          <Skeleton count={1} height={15} />
        </div>
      </div>

      <div className="flex  w-full justify-end">
        <Button type="button" variant="outline" onClick={handlerOnSkip}>
          {isEnabled ? "Skip and continue dialing" : "Skip emailing"}
        </Button>
      </div>
    </div>
  );
};
