import { useEffect, useState } from "react";
import { Portal } from "react-portal";

import { modalHelpers } from "shared/lib/helpers/modalHelpers";

import ControlMenu from "@/components/modules/calling/control-menu/v1";
import MainContainer from "@/modules/calling/main-container";
import Sidebar from "@/modules/calling/sidebar";
import WelcomeCard from "@/modules/calling/cards/welcome-card";
import DialingState from "@/modules/calling/dialing-state";
import LeadCard from "@/components/modules/calling/cards/lead-card/v1";
import UnauthorisedCard from "@/modules/calling/cards/unathorized-card";
import ActivityLog from "@/modules/calling/activity-log";
import { DialerLeadsCarousel as LeadsCarouselV1 } from "@/components/modules/calling/leads-carousel/v1";
import { DIALER_CALL_STATUS, DIALER_STATES } from "@/constants/dialer";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import AddNextLeadModal from "@/modules/calling/modals/add-next-lead";
import FollowUpModal from "@/modules/calling/modals/follow-up-modal";
import { CallingModuleBookMeetingModal } from "@/modules/calling/modals/booking-meeting-modal";
import CallingDispositionModal from "@/modules/calling/modals/disposition-modal";
import CallingTour from "@/modules/tours/calling";
import StripeConnectModal from "@/components/modals/stripe-connect-modal";
import { LiveTransferModal } from "@/modules/calling/modals/live-transfer-modal";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import {
  LEAD_QUALITY_FEEDBACK_MODAL_ID,
  LeadQualityFeedbackModal,
} from "@/components/modals/lead-quality-feedback-modal";

export const DialerDefaultMode = () => {
  const callingContext = useCallingContext();
  const { mode } = callingContext || {};

  const [cState, setCState] = useState(callingContext.callingState);
  const [isDialingTransition, setIsDialingTransition] = useState(false);

  const callStatus = useDialerCallStatus();

  useEffect(() => {
    if (
      cState === DIALER_STATES.DIALING &&
      cState !== callingContext.callingState
    ) {
      setCState(callingContext.callingState);
      setIsDialingTransition(false);
    } else {
      setCState(callingContext.callingState);
    }
  }, [callingContext.callingState]);

  // ONLY TO TEST DIALING
  // useEffect(() => {
  //   setTimeout(() => {
  //     setCState(DIALER_STATES.DIALING);
  //   }, 1000);
  // }, []);

  return (
    <section className="flex">
      <MainContainer className="grow">
        <div className="flex h-full w-full justify-between">
          <div className="flex h-full grow flex-col items-center justify-center">
            <div className="mb-auto" />

            {DIALER_STATES.UNAUTHORIZED === cState && <UnauthorisedCard />}

            {DIALER_STATES.PRE === cState && (
              <button
                className="btn-ae-default absolute right-[334px] top-0 m-2"
                onClick={() =>
                  modalHelpers.trigger(LEAD_QUALITY_FEEDBACK_MODAL_ID)
                }
              >
                Help us improve leads
              </button>
            )}

            {DIALER_STATES.PRE === cState && <WelcomeCard />}

            {DIALER_STATES.LEAD_SELECTION === cState && <LeadsCarouselV1 />}

            {DIALER_STATES.DIALING === cState && (
              <DialingState isAboutToUnmount={isDialingTransition} />
            )}

            {DIALER_STATES.CALL === cState && <LeadCard />}

            {DIALER_STATES.DISPOSITION === cState && <LeadCard isCallEnded />}

            <ControlMenu className="mb-18 caller control-bar mt-auto" />
          </div>

          <div className="caller activity-log px-3 py-2">
            <ActivityLog />
          </div>
        </div>
      </MainContainer>

      <Sidebar className="caller sidebar flex-none" />

      {DIALER_STATES.PRE === cState && (
        // @ts-ignore
        <Portal>
          <StripeConnectModal />
          <LeadQualityFeedbackModal campaignId={callingContext.campaignId} />
        </Portal>
      )}

      {[DIALER_STATES.CALL, DIALER_STATES.DISPOSITION].includes(
        cState as keyof typeof DIALER_STATES
      ) && (
        // @ts-ignore
        <Portal>
          <LiveTransferModal />
          <AddNextLeadModal />
          <FollowUpModal isNotes={callStatus === DIALER_CALL_STATUS.AFTER} />
          <CallingDispositionModal />
          <CallingModuleBookMeetingModal />
        </Portal>
      )}

      <CallingTour mode={mode} />
    </section>
  );
};
