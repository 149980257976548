import { FC } from "react";
import { toast } from "react-hot-toast";

import { ConfirmActionModal } from "shared/ui/modal/confirm-action";
import { useUndisqualifyAccount } from "@/api/routes/account/mutations";

interface AccountUndisqualifyConfirmationModalPropsI {
  campaignId?: string;
  accountId?: string;
  onSuccess?: () => void;
}

export const CONFIRM_UNDISQUALIFY_MODAL_ID = "confirm-undisqualify-modal";

export const AccountUndisqualifyConfirmationModal: FC<
  AccountUndisqualifyConfirmationModalPropsI
> = ({ campaignId, accountId, onSuccess }) => {
  const { mutateAsync: undisqualifyAccount } = useUndisqualifyAccount();
  const handleUndisqualify = () => {
    if (!campaignId || !accountId) {
      return toast.error(
        "Either campaign or account IDs are not defined, can't undisqualify the account."
      );
    }

    undisqualifyAccount(
      {
        campaignId: campaignId as string,
        accountId: accountId as string,
      },
      {
        onSuccess: () => {
          toast.success("Account has been undisqualified successfully!");
          onSuccess?.();
        },
        onError: () => {
          toast.error(
            "Failed to undisqualify the account. Please message us in the chat window on the bottom right so we can assist."
          );
        },
      }
    );
  };

  return (
    <ConfirmActionModal
      id={CONFIRM_UNDISQUALIFY_MODAL_ID}
      onConfirm={handleUndisqualify}
      title="Undisqualify account"
      description="Are you sure you want to undisqualify this account?"
    />
  );
};
