import { useMemo } from "react";
import clsx from "clsx";

import { glencocoClientAPI, APII } from "@/api/glencoco";

import { CatchupActions as ActionsSection } from "@/components/modules/calling/leads-carousel/v2/actions/catchup-mode";
import LeadCardV2 from "@/components/modules/calling/cards/lead-card/v2";

import { LeadsCarouselSendToBackButton as SendToBackButton } from "@/modules/calling/leads-carousel/v2/send-to-back";
import { LoadingComponent } from "@/modules/loading";
import { LeadsCarouselTitle as Title } from "./title";
import { useApi } from "shared/lib/hooks/use-api";
import { useCatchupsContext } from "@/context/dialer-v2/catchups";
import Calling from "@/helpers/calling";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { DIALER_STATES } from "@/constants/dialer";
import { CATCHUPS_WALKTHROUGH_ELEMENT_IDS } from "@/modules/calling/modes/catchup/walkthrough";
import { useIsCatchupsWalkthroughMode } from "@/modules/calling/hooks/use-calling-mode";

const sendTodoToBackFetcher = (api: APII, todoId: string) =>
  api.sendTodoToBack(todoId);

export const DialerLeadsCarousel = () => {
  const {
    fetchCatchupTodos,
    activeTodoId,
    catchupType,
    setIsShowLoading,
    isCatchupCardSliding,
    todosCount,
    isLoadingTodos,
    triggerCatchupCardSlideAnimation,
  } = useCatchupsContext();
  const context = useCallingContext();
  const inCallContext = useInCallContext();

  const [{ isLoading: isSendingTodoToBack }, sendToBack] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: sendTodoToBackFetcher,
    shouldCallAutomatically: false,
    onSuccess: fetchCatchupTodos,
    errorBuilder: () =>
      "Failed sending this to-do to back of a list. Please message us in the chat window on the bottom right so we can assist.",
  });

  const isCatchupsWalkthroughMode = useIsCatchupsWalkthroughMode();

  const isCalling = useMemo(
    () => context.callingState === DIALER_STATES.CALL,
    [context.callingState]
  );

  const onSendToBack = async () => {
    if (!activeTodoId || isCalling) {
      return;
    }

    setIsShowLoading(true);

    triggerCatchupCardSlideAnimation();
    await sendToBack(activeTodoId);

    Calling.clearCallData({ context, inCallContext });

    setIsShowLoading(false);
  };

  return (
    <section className="relative transition-opacity duration-300">
      <div className="z-1 relative h-full w-full rounded-lg">
        {isLoadingTodos && (
          <div className="absolute left-[calc(50%-25px)] top-[calc(50%-25px)]">
            <LoadingComponent spinnerClassName="text-white" />
          </div>
        )}

        {todosCount !== 0 && (
          <div
            className={clsx(
              "relative z-10 min-w-[430px] transition-all",
              isCatchupCardSliding
                ? "animate-swipeleft-scale opacity-0"
                : "left-0 opacity-100"
            )}
          >
            <div>
              <div id={CATCHUPS_WALKTHROUGH_ELEMENT_IDS.TITLE}>
                <Title type={catchupType} className="mb-6" />
              </div>

              <div className="relative">
                <LeadCardV2
                  actionsSection={
                    <ActionsSection type={catchupType} className="mt-6" />
                  }
                />

                {todosCount > 1 && !isCalling && (
                  <div
                    id={CATCHUPS_WALKTHROUGH_ELEMENT_IDS.SEND_TO_BACK}
                    className={clsx(
                      {
                        "pointer-events-none": isCatchupsWalkthroughMode,
                      },
                      "absolute -right-[22px] top-1/2",
                      "z-10 -translate-y-1/2 animate-fadein"
                    )}
                  >
                    <SendToBackButton
                      onSendToBack={onSendToBack}
                      isLoading={isSendingTodoToBack || isLoadingTodos}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
