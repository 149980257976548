import {
  CalendarIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  PaperAirplaneIcon,
  PhoneArrowDownLeftIcon,
  PhoneXMarkIcon,
  StarIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { CalendarSync, Check, Clock8, MessageCircleOff, X } from "lucide-react";

import { AccountUserListTypeI } from "shared/lib/interfaces/account";
import { LIST_STATUS_MEETING_FEEDBACK } from "shared/lib/constants/dispositions";

/**
 * System lists are the lists that are created by the system and are not editable
 * by a user
 */
export const SYSTEM_LIST_TYPES = {
  CALLBACKS: "callbacks",
  MISSED_CALLS: "missed_calls",
  BOOKED_MEETINGS: "booked_meetings",
  EMAIL_ACTIONS: "email_actions",
  FEEDBACK_RECEIVED: "feedback_received",
  NO_SOWS_AND_CANCELS: "no_show_and_cancels",
  // This list will be displayed as "Hot Email Responses" in the UI
  COLD_OUTREACH_RESPONSE: "cold_outreach_response",
} as const;

export const SYSTEM_LIST_TITLES = {
  [SYSTEM_LIST_TYPES.BOOKED_MEETINGS]: "Booked Meetings",
  [SYSTEM_LIST_TYPES.CALLBACKS]: "Callbacks",
  [SYSTEM_LIST_TYPES.EMAIL_ACTIONS]: "Email Actions",
  [SYSTEM_LIST_TYPES.FEEDBACK_RECEIVED]: "Feedback Received",
  [SYSTEM_LIST_TYPES.MISSED_CALLS]: "Missed Calls",
  [SYSTEM_LIST_TYPES.COLD_OUTREACH_RESPONSE]: "Hot Email Responses",
};

/**
 * This map covers all supported list types, including custom (created by user)
 * exclusive lists (they are emitted for top performing callers) and system lists
 */
export const LIST_TYPES = {
  CUSTOM: "custom",
  EXCLUSIVE: "top_caller_dedicated",
  TODO_LIST: "todo_list",
  ...SYSTEM_LIST_TYPES,
} as const;

/**
 * User is disallowed to "remove from" these lists
 */
export const LISTS_WITH_PROTECTED_LEADS: AccountUserListTypeI[] = [
  SYSTEM_LIST_TYPES.BOOKED_MEETINGS,
];

export const LISTS_ICON_MAP = {
  [SYSTEM_LIST_TYPES.BOOKED_MEETINGS]: <CalendarIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.CALLBACKS]: <PhoneArrowDownLeftIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.EMAIL_ACTIONS]: <EnvelopeOpenIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.FEEDBACK_RECEIVED]: <StarIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.MISSED_CALLS]: <PhoneXMarkIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.NO_SOWS_AND_CANCELS]: <XCircleIcon className="w-4" />,
  [SYSTEM_LIST_TYPES.COLD_OUTREACH_RESPONSE]: (
    <PaperAirplaneIcon className="w-4 -rotate-45" />
  ),
};

export const LIST_STATUS_PILL_VARIANTS = {
  [SYSTEM_LIST_TYPES.CALLBACKS]: {
    icon: PhoneArrowDownLeftIcon,
    color: "text-white",
    bg: "bg-[#F8A467]",
  },
  [SYSTEM_LIST_TYPES.MISSED_CALLS]: {
    icon: XMarkIcon,
    color: "text-[#B90000]",
    bg: "bg-[#FEDFDF]",
  },
  [SYSTEM_LIST_TYPES.BOOKED_MEETINGS]: {
    icon: CalendarIcon,
    color: "text-white",
    bg: "bg-[#F86767]",
  },
  [SYSTEM_LIST_TYPES.EMAIL_ACTIONS]: {
    icon: EnvelopeIcon,
    color: "text-[#3B6E29]",
    bg: "bg-[#EDFFDF]",
  },
  [SYSTEM_LIST_TYPES.COLD_OUTREACH_RESPONSE]: {
    icon: EnvelopeIcon,
    color: "text-white",
    bg: "app-gradient py-1.5 w-[110px] flex-center",
  },
} as const;

// Dark-green checkmark and text on light-green background
const POSITIVE_OUTCOME_CONFIG = {
  icon: Check,
  color: "text-[#124011]",
  bg: "bg-[#C3FBB5]",
};

export const LIST_FEEDBACK_STATUS_PILL_VARIANTS = {
  [LIST_STATUS_MEETING_FEEDBACK.QUALIFIED]: POSITIVE_OUTCOME_CONFIG,
  [LIST_STATUS_MEETING_FEEDBACK.UNQUALIFIED]: {
    icon: X,
    color: "text-[#B90000]",
    bg: "bg-[#FEDFDF]",
  },
  [LIST_STATUS_MEETING_FEEDBACK.SHOW_UP]: POSITIVE_OUTCOME_CONFIG,
  [LIST_STATUS_MEETING_FEEDBACK.PENDING]: {
    icon: Clock8,
    color: "text-black",
    bg: "bg-[#ECECEC]",
  },
  [LIST_STATUS_MEETING_FEEDBACK.NO_SHOW]: {
    icon: MessageCircleOff,
    color: "text-black",
    bg: "bg-[#ECECEC]",
  },
  [LIST_STATUS_MEETING_FEEDBACK.CANCELED]: {
    icon: X,
    color: "text-black",
    bg: "bg-[#ECECEC]",
  },
  [LIST_STATUS_MEETING_FEEDBACK.RESCHEDULED]: {
    icon: CalendarSync,
    color: "text-white",
    bg: "bg-[#F86767]",
  },
} as const;

export const LIST_STATUS_PILL_LABEL_OVERRIDES = {
  [SYSTEM_LIST_TYPES.COLD_OUTREACH_RESPONSE]: "Replied",
} as const;

export const PIPELINE_LIST_TYPES = {
  DEFAULT: "default",
  CUSTOM: "custom",
  SYSTEM_LIST: "system_list",
  NURTURE_LIST: "nurture_list",
  COLD_LIST: "cold_list",
  DIALER: "dialer",
} as const;

/**
 * System lists, which are considered nurture lists (former `Callbacks` and
 * `Missed Calls`).
 */
export const SYSTEM_NURTURE_LIST_TYPES = [
  LIST_TYPES.CALLBACKS,
  LIST_TYPES.MISSED_CALLS,
];

export const NURTURE_LIST_TYPES = [
  ...SYSTEM_NURTURE_LIST_TYPES,
  LIST_TYPES.TODO_LIST,
  LIST_TYPES.CUSTOM,
  LIST_TYPES.EXCLUSIVE,
];

export const LIST_SUBSECTIONS_MAP = {
  NURTURE: "nurture_leads",
  COLD_CALL: "cold_leads",
} as const;

export const LIST_SUBSECTIONS_LABEL_MAP = {
  [LIST_SUBSECTIONS_MAP.NURTURE]: "Nurture",
  [LIST_SUBSECTIONS_MAP.COLD_CALL]: "Cold",
} as const;

export const LIST_SUBSECTION_DESCRIPTIONS_MAP = {
  [LIST_SUBSECTIONS_MAP.NURTURE]: "Being worked, touch points made",
  [LIST_SUBSECTIONS_MAP.COLD_CALL]: "No conversations yet",
} as const;
