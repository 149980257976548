import { EnvironmentI } from "shared/lib/interfaces/environment";

export const ENVIRONMENT = {
  LOCAL: "local",
  STAGING: "staging",
  PRODUCTION: "production",
} as const;

export const REMOTE_ENVIRONMENTS: EnvironmentI[] = [
  ENVIRONMENT.STAGING,
  ENVIRONMENT.PRODUCTION,
];
