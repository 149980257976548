import { GenerateEmailStreamRequestParamsI } from "shared/lib/interfaces/email/ai";
import { fetchWithTimeout } from "shared/lib/helpers/http";

import { StreamAPIConfig } from "@/api/stream";

export const EmailWebAIAPIEndpoints = (API: StreamAPIConfig) => ({
  streamEmailSubject: async (
    parameters: GenerateEmailStreamRequestParamsI,
    signal?: AbortSignal
  ) => {
    const response = await fetchWithTimeout(
      `${API.baseURL}/email/generate_content_subject`,
      {
        method: "POST",
        headers: API.headers,
        body: JSON.stringify(parameters),
      },
      API.timeout,
      signal
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    return response;
  },
  streamEmailBody: async (
    parameters: GenerateEmailStreamRequestParamsI,
    signal?: AbortSignal
  ) => {
    const response = await fetchWithTimeout(
      `${API.baseURL}/email/generate_content_body`,
      {
        method: "POST",
        headers: API.headers,
        body: JSON.stringify(parameters),
      },
      API.timeout,
      signal
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    return response;
  },
});
