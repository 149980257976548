import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useApi } from "shared/lib/hooks/use-api";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { CATCHUPS_COMPLETED_MODAL_ID } from "@/modules/calling/modals/catchups-completed";
import { DialerLeadTodoI } from "@/api/routes/dialer";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { CatchupTypesI } from "@/interfaces/dialer/catchups";
import { DIALER_MODES, DIALER_STATES } from "@/constants/dialer";

interface CatchupsContextI {
  activeTodoId: string | null;
  setActiveTodoId: (id: string | null) => void;

  fetchCatchupTodos: () => Promise<any>;
  catchupType?: CatchupTypesI;

  setContextForCatchup: (todo: DialerLeadTodoI) => void;

  setTodosCount: (count: number) => void;

  isShowLoading: boolean;
  setIsShowLoading: (value: boolean) => void;

  isCatchupCardSliding: boolean;

  todosCount: number;
  isLoadingTodos: boolean;

  triggerCatchupCardSlideAnimation: () => void;
}

interface CatchupsProviderPropsI {
  children: ReactNode;
}

const CatchupsContext = createContext<CatchupsContextI>({
  activeTodoId: null,
  setActiveTodoId: () => {},
  fetchCatchupTodos: async () => {},
  setContextForCatchup: () => {},
  setTodosCount: () => {},
  catchupType: undefined,
  isShowLoading: true,
  setIsShowLoading: () => {},
  triggerCatchupCardSlideAnimation: () => {},
  isCatchupCardSliding: false,
  todosCount: 0,
  isLoadingTodos: true,
});

export const useCatchupsContext = (): CatchupsContextI =>
  useContext(CatchupsContext);

export const CatchupsProvider: FC<CatchupsProviderPropsI> = ({ children }) => {
  const isCatchupsLoaded = useRef(false);
  const [isShowLoading, setIsShowLoading] = useState(true);
  const [todosCount, setTodosCount] = useState(0);

  const context = useCallingContext();
  const inCallContext = useInCallContext();

  const { setCampaignID } = context;
  const { setAccount, setContact, setActivityLog, setMetadata } = inCallContext;

  const [catchupType, setCatchupType] = useState<CatchupTypesI>();
  const [activeTodoId, setActiveTodoId] = useState<string | null>(null);
  const [isCatchupCardSliding, setIsCatchupCardSliding] = useState(false);

  const triggerCatchupCardSlideAnimation = () => {
    // if (isCatchupsLoaded.current) {
    setIsCatchupCardSliding(true);

    // setTimeout(() => {
    //   setIsCatchupCardSliding(false);
    // }, 400);
    // }
  };

  const setContextForCatchup = (todo: DialerLeadTodoI) => {
    const {
      id,
      campaign_id,
      call_context,
      type,
      follow_up_date,
      has_missed_call,
    } = todo || {};
    const { account, contact, activity_log } = call_context || {};

    setActiveTodoId(todo.id);

    setCatchupType(type);
    setCampaignID(campaign_id as string);

    setAccount(account || {});
    setContact(contact || {});

    setMetadata({
      todoId: id,
      contactLocalTimeZone: call_context?.contact_local_time_zone,
      lastCalledTime: call_context?.last_called_time,
      previousAttempts: call_context?.previous_attempts,
      meetingTime: follow_up_date,
      isMissedCall: has_missed_call,
    });

    if (activity_log) {
      setActivityLog(activity_log);
    }

    setIsCatchupCardSliding(false);

    isCatchupsLoaded.current = true;
  };

  const todosFetcher = useCallback(async (api: APII) => {
    setIsShowLoading(true);

    const todoResponse = await api.getDialerLeadsTodos();

    setIsShowLoading(false);

    return todoResponse;
  }, []);

  const [{ isLoading: isLoadingTodos }, fetchCatchupTodos] = useApi({
    shouldCallAutomatically: false,
    apiFactory: glencocoClientAPI,
    fetcher: todosFetcher,
    onSuccess: (response) => {
      const todos = response?.items;

      if (todos === null) {
        setTodosCount(0);
        modalHelpers.open(CATCHUPS_COMPLETED_MODAL_ID);
      } else if (todos.length > 0) {
        setTodosCount(todos.length);
        const visibleTodo = todos[0];

        setContextForCatchup(visibleTodo);
      }
    },
    errorBuilder: () =>
      "Failed loading todo list. Please message us in the chat window on the bottom right so we can assist.",
  });

  useEffect(() => {
    if (
      DIALER_STATES.POST === context?.callingState &&
      DIALER_MODES.CATCHUP_WALKTHROUGH !== context?.mode
    ) {
      triggerCatchupCardSlideAnimation();
    }
  }, [context?.callingState]);

  return (
    <CatchupsContext.Provider
      value={{
        catchupType,
        setContextForCatchup,
        activeTodoId,
        setActiveTodoId,
        fetchCatchupTodos,
        isShowLoading,
        setIsShowLoading,
        triggerCatchupCardSlideAnimation,
        isCatchupCardSliding,
        todosCount,
        isLoadingTodos,
        setTodosCount,
      }}
    >
      {children}
    </CatchupsContext.Provider>
  );
};
