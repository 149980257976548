import { useRef, useState, FC, MutableRefObject } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import "yup-phone";
import clsx from "clsx";
import { toast } from "react-hot-toast";

import { TextField } from "shared/ui/ae-user-input";
import { CloseIcon } from "shared/ui/icons";
import { ContactDetailI } from "@/interfaces/accounts";
import { glencocoClientAPI } from "@/api/glencoco";
import { CampaignI } from "@/interfaces/campaign";
import { checkIfClient, formatUSPhoneNumber } from "shared/lib/helpers";
import { yupPhone } from "shared/lib/helpers/yup";
import { handleErrorMessage } from "@/helpers/error";

const EnhancedTextField = TextField(Field);

export interface EditContactDetailsFormI {
  first_name: string;
  last_name: string;
  title: string;
  phone: string | number;
  mobile?: string | number;
  email: string;
  linkedin: string;
}

const EditContactFormValidation = Yup.object().shape(
  {
    first_name: Yup.string()
      .required("Required field")
      .max(64, "First name cannot exceed 64 characters"),
    last_name: Yup.string()
      .required("Required field")
      .max(64, "Last name cannot exceed 64 characters"),
    title: Yup.string(),
    phone: Yup.string().when("mobile", {
      is: (mobile: string) => !mobile,
      then: yupPhone("phone"),
      otherwise: Yup.string(),
    }),
    mobile: Yup.string().when("phone", {
      is: (phone: string) => !phone,
      then: yupPhone("mobile"),
      otherwise: Yup.string(),
    }),
    email: Yup.string().email().required("Required field"),
    linkedin: Yup.string(),
  },
  // @ts-ignore
  ["mobile", "phone"]
);

interface EditContactModalI {
  modalId?: string;
  campaign?: CampaignI;
  contact?: ContactDetailI;
  onSubmitSuccess?: (data: ContactDetailI) => void;
  onClose?: () => void;
}

export const EDIT_CONTACT_MODAL_ID = "edit-contact-modal";

export const EditContactModal: FC<EditContactModalI> = ({
  modalId = EDIT_CONTACT_MODAL_ID,
  campaign,
  contact,
  onSubmitSuccess = () => {},
  onClose = () => {},
}) => {
  const [disabled, disable] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const checkboxref = useRef() as MutableRefObject<HTMLInputElement>;
  const formikRef = useRef<FormikProps<EditContactDetailsFormI>>(null);

  const form: EditContactDetailsFormI = {
    first_name: contact?.first_name || "",
    last_name: contact?.last_name || "",
    title: contact?.title || "",
    phone: formatUSPhoneNumber(contact?.phone) || "",
    mobile: formatUSPhoneNumber(contact?.mobile) || "",
    email: contact?.email || "",
    linkedin: contact?.linkedin || "",
  };

  const onFormSubmitHandler = async (data: EditContactDetailsFormI) => {
    const contactId = contact?.id;

    if (campaign?.id && contactId) {
      disable(true);
      const API = glencocoClientAPI();

      const formattedData: ContactDetailI = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        title: data?.title as string,
        phone: data?.phone.toString(),
        mobile: data?.mobile?.toString(),
        email: data?.email,
        linkedin_url: data?.linkedin,
      };

      const resp = await API.editContactInfo(
        campaign.id,
        contactId,
        formattedData
      ).catch(() => ({ status: null, data: null }));

      if (resp.status === 200) {
        toast.success("Success");

        onSubmitSuccess({ ...formattedData });
        close();
      } else {
        handleErrorMessage(
          (resp as any)?.response?.data?.error_code as number,
          "Failed to update contact. Please message us in the chat window on the bottom right so we can assist."
        );
      }

      disable(false);
    }
  };

  const close = () => {
    if (checkIfClient()) {
      handleCloseButton();
      (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
    }
  };

  const handleCloseButton = () => {
    formikRef.current?.resetForm();
    onClose();
  };

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) =>
          onToogleHandler((event.target as HTMLInputElement)?.checked)
        }
      />

      <div className="modal">
        {isVisible && (
          <div className="add-note-modal modal-box relative min-w-[520px] p-6">
            <div className="mb-2 flex items-center justify-between">
              <h3 className="ae-typography-h3">Edit Contact Details</h3>
              <button
                className="btn btn-circle btn-ghost btn-sm"
                onClick={handleCloseButton}
              >
                <label
                  htmlFor={modalId}
                  className="flex h-full w-full cursor-pointer items-center justify-center"
                >
                  <CloseIcon className="h-4 w-4" />
                </label>
              </button>
            </div>

            <p className="ae-typography-body2 mb-6 text-base-content/60">
              Edit the contact details below.
            </p>

            <Formik
              initialValues={form}
              validationSchema={EditContactFormValidation}
              onSubmit={onFormSubmitHandler}
              innerRef={formikRef}
              enableReinitialize
            >
              {({ errors, touched }) => (
                <Form>
                  <EnhancedTextField
                    name="first_name"
                    label="First Name"
                    placeholder="Add first name"
                    errors={errors.first_name}
                    touched={touched.last_name}
                  />

                  <EnhancedTextField
                    name="last_name"
                    label="Last Name"
                    placeholder="Add last name"
                    errors={errors.last_name}
                    touched={touched.last_name}
                  />

                  <EnhancedTextField
                    name="title"
                    label="Title"
                    placeholder="Add title"
                    errors={errors.title}
                    touched={touched.title}
                  />

                  <EnhancedTextField
                    name="phone"
                    label="Phone Number"
                    placeholder="+1 (123) 456-7890"
                    errors={errors.phone}
                    touched={touched.phone}
                  />

                  <EnhancedTextField
                    name="mobile"
                    label="Mobile Number"
                    placeholder="+1 (123) 456-7890"
                    errors={errors.mobile}
                    touched={touched.mobile}
                  />

                  <EnhancedTextField
                    name="email"
                    label="Work Email"
                    placeholder="Add email"
                    errors={errors.email}
                    touched={touched.email}
                  />

                  <EnhancedTextField
                    name="linkedin"
                    label="Linkedin URL"
                    placeholder="Add Linkedin Profile"
                    errors={errors.linkedin}
                    touched={touched.linkedin}
                  />

                  <div className="mt-8 flex justify-end">
                    <button type="button" className="btn-ae-text mr-2">
                      <label
                        htmlFor={modalId}
                        className="flex h-full cursor-pointer items-center px-4 text-black/40"
                      >
                        Cancel
                      </label>
                    </button>

                    <button
                      type="submit"
                      disabled={disabled}
                      className={clsx("btn-ae-default w-[140px]", {
                        loading: disabled,
                      })}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};
