import { FC, ReactNode } from "react";

import { clsxMerge } from "shared/lib/helpers";
import {
  SidebarCloseButtonI,
  SidebarHeader,
} from "shared/ui/sidebarV2/components/slide/header";
import { SidebarSizeI } from "shared/ui/sidebarV2/lib/interface";
import {
  SIDEBAR_SIZE,
  SIZE_CLASSNAMES,
} from "shared/ui/sidebarV2/lib/constants";

export interface SidebarSlideI {
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  children?: any;
  onClose?: () => void;
  // for sizing, let's first try to use options from `SIDEBAR_SIZE` to maintain some level of consistency across all sidebars
  size?: SidebarSizeI;
  // for the parent-most container - useful if you really need to give a custom `w-*` width to the slide instead of using predefined `size` prop
  slideClassName?: string;
  headerClassName?: string;
  contentClassName?: string;
  closeButton?: SidebarCloseButtonI;
}

export const SidebarSlide: FC<SidebarSlideI> = ({
  title,
  subTitle,
  children,
  onClose = () => {},
  size = SIDEBAR_SIZE?.SM,
  slideClassName,
  headerClassName,
  contentClassName,
  closeButton,
}) => {
  return (
    <div
      className={clsxMerge(
        "flex h-full flex-col",
        SIZE_CLASSNAMES?.[size],
        slideClassName
      )}
    >
      {title && (
        <SidebarHeader
          className={headerClassName}
          title={title}
          subTitle={subTitle}
          onClose={onClose}
          closeButton={closeButton}
        />
      )}

      <div
        className={clsxMerge(
          "grow overflow-y-auto overflow-x-hidden",
          "px-6 py-4",
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};
