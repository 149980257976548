import { FC } from "react";
import { Portal } from "react-portal";

import SidebarV2 from "shared/ui/sidebarV2";
import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import {
  CreateOrUpdateListForm,
  CreateOrUpdateListFormI,
} from "./create-list-form";

interface CreateListSidebarPropsI {
  isOpen: boolean;
  onClose?: () => void;

  onSubmit?: (name: string, description?: string) => void;
}

export const CreateListSidebar: FC<CreateListSidebarPropsI> = ({
  isOpen,
  onClose = () => {},

  onSubmit = () => {},
}) => {
  const submitFormData = async (data: CreateOrUpdateListFormI) => {
    onSubmit(data.name, data.description);
  };

  return (
    // @ts-ignore
    <Portal>
      <SidebarV2 isOpen={isOpen} onClose={onClose}>
        <SidebarSlide
          title="Create list"
          headerClassName="bg-white b-typography-h4 py-3"
        >
          <CreateOrUpdateListForm
            onSubmit={submitFormData}
            onCancel={onClose}
          />
        </SidebarSlide>
      </SidebarV2>
    </Portal>
  );
};
