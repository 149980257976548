import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { AccountDetailsQueryKeys } from "@/modules/pipeline/account-details/queries/keys";
import { useApiClient } from "@/context/api-client";

export const useFetchCampaignScript = (campaignId: string | undefined) => {
  const API = useApiClient();

  const campaignScriptApi = useQuery({
    enabled: !!campaignId,
    queryKey: AccountDetailsQueryKeys.script,
    queryFn: () => API.getCampaignV2(campaignId as string),
    select: (data) => data?.data.campaign.script || "",
  });

  useEffect(() => {
    if (campaignScriptApi.isError) {
      toast.error("Error fetching script.");
    }
  }, [campaignScriptApi.isError]);

  return campaignScriptApi;
};

export const useFetchCampaignDefaultScript = (
  campaignId: string | undefined
) => {
  const API = useApiClient();

  const campaignDefaultScriptApi = useQuery({
    enabled: !!campaignId,
    queryKey: AccountDetailsQueryKeys.defaultScript,
    queryFn: () => API.getCampaignDefaultScript(campaignId as string),
    select: (data) => data?.data.script_text || "",
  });

  useEffect(() => {
    if (campaignDefaultScriptApi.isError) {
      toast.error("Error fetching script.");
    }
  }, [campaignDefaultScriptApi.isError]);

  return campaignDefaultScriptApi;
};

export const useFetchCampaignIcpTitles = (campaignId: string | undefined) => {
  const API = useApiClient();

  const campaignIcpTitlesApi = useQuery({
    enabled: !!campaignId,
    queryKey: AccountDetailsQueryKeys.icpTitles,
    queryFn: () => API.getCampaignIcpTitles(campaignId as string),
    select: (data) => data?.data.icp_titles || [],
  });

  useEffect(() => {
    if (campaignIcpTitlesApi.isError) {
      toast.error("Error fetching campaign ICP titles.");
    }
  }, [campaignIcpTitlesApi.isError]);

  return campaignIcpTitlesApi;
};
