import { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";

import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { CampaignI } from "@/interfaces/campaign";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { MeetingBookedCardContent } from "./meeting-booked-card";
import { UseFetchQualificationCriteriaAndPricingReturnI } from "@/api/routes/qualification-criteria/queries";
import { QualificationCriteriaTableTogglesValueI } from "shared/lib/interfaces/qualification-criteria";

export interface SubmitBookedMeetingModalCommonPropsI {
  qcApi: UseFetchQualificationCriteriaAndPricingReturnI;
  selectedQCs: QualificationCriteriaTableTogglesValueI;
}

interface SubmitBookedMeetingModalPropsI
  extends SubmitBookedMeetingModalCommonPropsI {
  calendlyUri: string;
  campaign: CampaignI;
  calendlyEvent: CalendlyEventI;
  notes?: string;
  modalId?: string;
  hideCloseAction?: boolean;
  onSubmit?: (notes: string) => Promise<void>;
}

export const ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID =
  "account-submit-booked-meeting-modal";

const SubmitBookedMeetingModalContent: FC<
  SubmitBookedMeetingModalPropsI & ModalChildrenFnPropsI
> = ({
  campaign,
  calendlyEvent,
  qcApi,
  selectedQCs,
  notes: _notes = "",
  onSubmit = () => {},
}) => {
  const { modalAccount } = useAccountsContext();

  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState(_notes);

  useEffect(() => {
    setNotes(_notes);
  }, [_notes]);

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!modalAccount?.accountId || !campaign.id) {
      toast.error("Account or contact ID is not provided.");
      setIsLoading(false);
      return;
    }

    await onSubmit(notes);

    setIsLoading(false);
  };

  return (
    <MeetingBookedCardContent
      isWrapperNoStyles
      isVisible
      calendlyEvent={calendlyEvent}
      qcApi={qcApi}
      selectedQCs={selectedQCs}
      notes={notes}
      loading={isLoading}
      onNotesUpdate={setNotes}
      onSubmit={handleSubmit}
    />
  );
};

const SubmitBookedMeetingModal: FC<SubmitBookedMeetingModalPropsI> = (
  props
) => {
  if (!props.calendlyUri) {
    return null;
  }

  return (
    <Modal
      id={props.modalId || ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID}
      shouldCloseOnOverlayClick={false}
      showCloseButton={!props.hideCloseAction}
      modalBoxClassName="rounded-lg"
      title="Meeting notes for the Account Executive"
      description="Please review your booked meeting and notes before sending to the Account Executive"
    >
      {(modalProps) => (
        <SubmitBookedMeetingModalContent {...props} {...modalProps} />
      )}
    </Modal>
  );
};

export default SubmitBookedMeetingModal;
