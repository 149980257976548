import { FC } from "react";

import { AccountDetailsLayout as Layout } from "./layout";

// import { SidebarExtentionResources as Resources } from "./shared/resources";

import { ConnectedLeadSection } from "@/components/modules/pipeline/account-details/sections/connected-lead-section";
import { AccountTopSection } from "@/components/modules/pipeline/account-details/sections/account-top-section";

import { PrimaryContent } from "@/modules/pipeline/account-details/primary-content";

import { AccountDetailsControlMenu as ControlMenu } from "@/modules/pipeline/account-details/control-menu";
import {
  AccountDetailsProvider,
  AccountDetailsProviderI,
} from "@/modules/pipeline/account-details/context";

import { AccountDetailsModals as ModalsAndSidebars } from "@/modules/pipeline/account-details/modals";
import { AccountsContextProvider } from "@/context/accounts";
import { AddUserToListSidebarProvider } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { EmailTemplatesSidebarProvider } from "@/modules/email-templates-sidebar/context";
import { AccountDetailsNav } from "@/components/modules/pipeline/account-details/nav";

import { useAccountDetailsCloseBehavior } from "./hooks/use-account-details-close";

import { WebsocketsProvider } from "@/lib/context/websockets-context";
import { WEBSOCKET_CONNECTION_TYPES } from "@/lib/websockets/constants";

const CloseButtonBehavior = () => {
  useAccountDetailsCloseBehavior();

  return null;
};

export const AccountDetailsModule: FC<AccountDetailsProviderI> = (props) => {
  return (
    <AccountsContextProvider>
      <AccountDetailsProvider {...props}>
        <AddUserToListSidebarProvider>
          <EmailTemplatesSidebarProvider>
            <WebsocketsProvider
              connectionType={WEBSOCKET_CONNECTION_TYPES.CALL_COPILOT}
            >
              <Layout>
                <Layout.SidebarExtension>
                  {/* <Resources /> */}
                </Layout.SidebarExtension>

                <Layout.GridRows>
                  <Layout.Top>
                    <CloseButtonBehavior />
                    <AccountTopSection />
                    <ConnectedLeadSection />
                  </Layout.Top>

                  <AccountDetailsNav />

                  <Layout.Content>
                    <PrimaryContent />
                  </Layout.Content>
                </Layout.GridRows>

                <ControlMenu />

                <ModalsAndSidebars />
              </Layout>
            </WebsocketsProvider>
          </EmailTemplatesSidebarProvider>
        </AddUserToListSidebarProvider>
      </AccountDetailsProvider>
    </AccountsContextProvider>
  );
};
export { DISQUALIFY_DISPOSITION_LABELS } from "shared/lib/constants/dispositions";
