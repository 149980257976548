import { FC, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { clsxMerge } from "shared/lib/helpers";
import { transformNewLines } from "shared/lib/helpers/markdown";
import { MarkdownPreview } from "shared/ui/markdown/preview";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface ScriptPreviewPropsI extends PropsWithClassNameI {
  title?: string;
  markdown?: string;
}

export const ScriptPreviewSection: FC<ScriptPreviewPropsI> = ({
  title,
  className,
  markdown,
}) => {
  const [updatedMarkdown, setMarkdown] = useState(
    transformNewLines(markdown || "")
  );

  useEffect(() => {
    setMarkdown(transformNewLines(markdown || ""));
  }, [markdown]);

  return (
    <ErrorBoundary
      fallbackRender={() => (
        <div className="b-typography-detail2 text-black/60">
          Error while displaying some content.
        </div>
      )}
    >
      <div className="typography-body-4-medium mb-1.5">{title}</div>

      <div
        className={clsxMerge(
          "h-full overflow-auto rounded-lg border border-[#E4E4E7] bg-[#F3F3F3] p-3",
          className
        )}
      >
        <MarkdownPreview className="ae-typography">
          {updatedMarkdown}
        </MarkdownPreview>
      </div>
    </ErrorBoundary>
  );
};
