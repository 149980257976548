import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";

// Generic polling condition function type
type PollCondition<T> = (data: T) => boolean;

/**
 * A hook that allows you to poll a mutation until a condition is met.
 *
 * - Takes a generic shouldContinuePolling function to determine when to keep polling
 * - Configurable pollingInterval and maxAttempts
 * - Tracks attempt count internally
 * - Reuses previous mutation variables for subsequent calls
 *
 * @param mutationFn - The mutation function to poll.
 * @param options - The options for the mutation.
 * @returns The mutation result.
 */
export const usePollableMutation = <TData, TVariables = void, TError = Error>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options: {
    queryKey: string[];
    pollingInterval?: number;
    maxAttempts?: number;
    shouldContinuePolling: PollCondition<TData>;
  }
): UseMutationResult<TData, TError, TVariables> => {
  const {
    queryKey,
    pollingInterval = 1000,
    maxAttempts = 3,
    shouldContinuePolling,
  } = options;
  const queryClient = useQueryClient();
  let resolvePolling: (value: TData) => void;
  let rejectPolling: (reason?: any) => void;

  const pollingPromise = new Promise<TData>((resolve, reject) => {
    resolvePolling = resolve;
    rejectPolling = reject;
  });

  const mutation = useMutation<TData, TError, TVariables>({
    mutationFn: async (variables) => {
      let currentData: TData;
      let currentAttempt = 0;

      do {
        currentData = await mutationFn(variables);
        queryClient.setQueryData(queryKey, currentData);

        console.log(
          `DEBUGGER usePollableMutation attempt ${currentAttempt + 1}`,
          {
            currentAttempt,
            maxAttempts,
            shouldContinuePolling: shouldContinuePolling(currentData),
            data: currentData,
          }
        );

        if (
          shouldContinuePolling(currentData) &&
          currentAttempt < maxAttempts - 1
        ) {
          await new Promise((resolve) => setTimeout(resolve, pollingInterval));
          currentAttempt++;
        } else {
          resolvePolling(currentData);
          return currentData;
        }
      } while (currentAttempt < maxAttempts);

      resolvePolling(currentData);
      return currentData;
    },
    onError: (error: TError) => {
      console.error("Polling mutation failed:", error);
      rejectPolling(error);
    },
  });

  // Override mutateAsync to return the polling promise
  const originalMutateAsync = mutation.mutateAsync;
  mutation.mutateAsync = async (variables: TVariables, opts) => {
    await originalMutateAsync(variables, opts);
    return pollingPromise;
  };

  return mutation;
};

/**
 Usage example


 interface GetShouldSendEmailFnParamsI {
  campaignId: string;
  callId: string;
  parameters: GetShouldSendEmailRequestParamsI;
}

export const getShouldSendEmailFn =
  (api: ReturnType<typeof useApiClient>) =>
  async ({ campaignId, callId, parameters }: GetShouldSendEmailFnParamsI) => {
    const response: AxiosResponse<GetShouldSendEmailResponseI> =
      await api.email.getShouldSendEmail(campaignId, callId, parameters);
    return response.data; // Extract the data from AxiosResponse
  };

export const useShouldSendEmail = () => {
  const api = useApiClient();
  const mutationFn = getShouldSendEmailFn(api);

  return useMutation({
    mutationFn,
  });
};

export const useShouldSendEmailPolling = () => {
  const api = useApiClient();
  const mutationFn = getShouldSendEmailFn(api);

  return usePollableMutation<
    GetShouldSendEmailResponseI,
    GetShouldSendEmailFnParamsI
  >(mutationFn, {
    queryKey: ["shouldSendEmail"],
    pollingInterval: 1000,
    maxAttempts: 3,
    shouldContinuePolling: (data) => !data.email_subject || !data.email_body,
  });
 */
