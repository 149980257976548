import { useState } from "react";
import { DropdownMenuCheckboxItem } from "@/components/base/shadcn/dropdown-menu";
import { AccountDetailsViewOptionI } from "./interface";

export const OptionsColumn = ({
  options: _options,
  onChange,
}: {
  options: AccountDetailsViewOptionI[];
  onChange?: (options: AccountDetailsViewOptionI[]) => void;
}) => {
  const [options, setOptions] = useState(_options);

  const handleOnSelect = (index: number, checked: boolean) => (e: Event) => {
    e.preventDefault();

    const newOptions = options.map((option, i) => {
      if (i === index) {
        return { ...option, value: !checked };
      }

      return option;
    });

    setOptions(newOptions);
    onChange?.(newOptions);
  };

  return (
    <>
      {options.map((option, index) => (
        <DropdownMenuCheckboxItem
          className="cursor-pointer"
          key={option.label}
          checked={option.value}
          onSelect={handleOnSelect(index, option.value)}
        >
          {option.label}
        </DropdownMenuCheckboxItem>
      ))}
    </>
  );
};
