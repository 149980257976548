import { useEffectOnce } from "shared/lib/hooks";

export const useBeforeTabClose = (text?: string) => {
  useEffectOnce(() => {
    const eventHandler = (e: any) => {
      const confirmationMessage =
        typeof text === "string" ? text : "Are you sure?";

      alert(confirmationMessage);

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome
    };

    window.addEventListener("beforeunload", eventHandler);

    return () => {
      window.removeEventListener("beforeunload", eventHandler);
    };
  });
};
