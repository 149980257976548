// React and hooks
import { FC, useCallback, useEffect, useMemo, useState } from "react";

// Third party
import debounce from "lodash/debounce";

// API interfaces and types
import { SendEmailRequestParamsI, EmailTemplateI } from "@/api/routes/email";
import { EmailSectionExitTypeI } from "./interface";

// Constants
import { EMAIL_SECTION_EXIT_OPTIONS } from "./constants";
import { WIDGETS } from "@/constants/widgets";

// Components
import { EmailForm } from "@/components/modules/email-templates-sidebar/content/email-template-workspace/template/email-form";
// import { EmailTemplateSelect } from "./email-template-select";
// import { ComposeWithAiButton } from "@/components/modules/email-templates-sidebar/content/email-template-workspace/template/email-form/compose-with-ai-btn";
import { emailSectionActions } from "./actions-section";
import { widgets } from "@/components/shared/widgets";

// Hooks and context
import {
  handleEmailGenerationError,
  useAccountEmailAIGeneration,
  useEmailTemplateLocalDrafts,
  EMAIL_TEMPLATE_LOCAL_DRAFTS_KEY_TYPES,
  useEmailTemplatesManager,
} from "@/hooks/use-email-templates-manager";
import { useSendEmail } from "@/components/modules/email-templates-sidebar/queries";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";

export interface EmailSectionContentPropsI {
  subject?: string;
  body?: string;
  emailId?: string;
  accountExecutiveEmail?: string;
}

export const EmailSectionContent: FC<EmailSectionContentPropsI> = ({
  subject,
  body,
  emailId,
  accountExecutiveEmail,
}) => {
  const [isEmailDataInitialized, setIsEmailDataInitialized] = useState(false);

  const {
    setFocusedContact,
    accountHistoryData: { reloadData: reloadAccountHistory },
    setIsEmailSection,
  } = useAccountDetailsContext();

  const { clear: clearInCallContext } = useInCallContext();
  const { setCall, call } = useCallingContext();

  const draftId = useMemo(
    () => call?.customParameters.get("call_id") as string,
    [call]
  );

  const {
    templates,
    activeTemplate,
    activeTemplateId,
    emailGenerationConfig,
    campaignAttachmentsData,
  } = useEmailTemplatesManager();

  const {
    isAIGenerationComplete,
    isAIGenerationStarted,
    isEmailSent,
    emailData,
    handleComposeWithAI,
    setEmailData,
    setIsEmailSent,
  } = useAccountEmailAIGeneration(activeTemplate as EmailTemplateI);

  const { setLocalDraft, getLocalDraft } = useEmailTemplateLocalDrafts(
    EMAIL_TEMPLATE_LOCAL_DRAFTS_KEY_TYPES.CALL_ID
  );

  useEffect(() => {
    if (!isEmailDataInitialized) {
      const firstTemplateId = templates?.data?.email_templates?.[0]?.id;

      const emailDraft = getLocalDraft(draftId);

      if (firstTemplateId) {
        if (emailDraft) {
          setEmailData(emailDraft);
        } else {
          setEmailData({ subject, body });
          setLocalDraft(draftId, {
            subject,
            body,
          });
        }
        setIsEmailDataInitialized(true);
      }
    }
  }, [templates]);

  const [exitType, setExitType] = useState<EmailSectionExitTypeI>();

  const { mutateAsync: sendEmailAsync, isPending: isSendEmailPending } =
    useSendEmail();

  const onEmailSuccess = () => {
    setIsEmailSent(true);
    reloadAccountHistory();
    setFocusedContact(undefined);
  };

  const handlerSendEmail = async (data: SendEmailRequestParamsI) => {
    if (emailGenerationConfig) {
      const emailOptionalParams = emailId ? { email_id: emailId } : {};

      const resp = await sendEmailAsync({
        parameters: { ...data, ...emailOptionalParams },
      });

      if (resp.status === 200) {
        dd.log(
          `${LOG_CATEGORIES.FOLLOW_UP_EMAIL}[EMAIL_SECTION] - email sent successfully`,
          {
            data: {
              responseData: resp.data,
              requestParams: { ...data, ...emailOptionalParams },
            },
          }
        );

        onEmailSuccess();
        return true;
      }

      dd.error(
        `${LOG_CATEGORIES.FOLLOW_UP_EMAIL}[EMAIL_SECTION] - email failed to send`,
        {
          data: {
            response: resp,
            requestParams: { ...data, ...emailOptionalParams },
          },
        }
      );
      handleEmailGenerationError(resp, "Failed to send email");
    }

    return false;
  };

  const debouncedHandlerSendEmail = debounce(handlerSendEmail, 1000);

  //TODO find the reason of double calls to remove this throttle
  const handleEmailSend = useCallback(debouncedHandlerSendEmail, [
    emailGenerationConfig,
    emailId,
  ]);

  useEffect(() => {
    if (isEmailSent && exitType) {
      setCall(undefined);
      clearInCallContext();

      //close section based on selected exit type
      if (exitType === EMAIL_SECTION_EXIT_OPTIONS.STAY) {
        setIsEmailSection?.(false);
      }

      if (exitType === EMAIL_SECTION_EXIT_OPTIONS.CONTINUE_DIALING) {
        widgets.close({ id: WIDGETS.MAXED_DIALER });
      }
    }
  }, [isEmailSent, exitType]);

  const handleSelectExitType = (exitType: EmailSectionExitTypeI) => {
    setExitType(exitType);
  };

  const handleOnChangeSubject = (subject?: string) => {
    if (activeTemplateId) {
      const emailData = getLocalDraft(draftId);
      setLocalDraft(draftId, {
        ...emailData,
        subject,
      });
    }
  };

  const handleOnChangeBody = (body?: string) => {
    if (activeTemplateId) {
      const emailData = getLocalDraft(draftId);
      setLocalDraft(draftId, {
        ...emailData,
        body,
      });
    }
  };

  return (
    <section className="animate-fadein">
      {activeTemplate && emailGenerationConfig && (
        <EmailForm
          //  key is used to force entire component to re-render when template changes so that all form fields can be reset
          key={activeTemplateId}
          template={activeTemplate}
          senderEmail={emailGenerationConfig?.from_email}
          ccEmails={accountExecutiveEmail ? [accountExecutiveEmail] : []}
          attachmentOptions={campaignAttachmentsData?.attachments}
          initialSubject={emailData?.subject}
          initialBody={emailData?.body}
          onComposeWithAI={handleComposeWithAI}
          onSend={handleEmailSend as any}
          isSendingEmail={isSendEmailPending}
          isSendEmailSuccess={isEmailSent}
          isAIGenerationComplete={isAIGenerationComplete}
          isAIGenerating={isAIGenerationStarted}
          hideTitle
          hideIntro
          hideAiGenerateButton
          onChangeSubject={handleOnChangeSubject}
          onChangeBody={handleOnChangeBody}
          actionsSection={emailSectionActions({
            className: "mb-3 mt-5",
            onSelect: handleSelectExitType,
            isLoading:
              (isAIGenerationStarted && !isAIGenerationComplete) ||
              isSendEmailPending,
          })}
        />
      )}
    </section>
  );
};
