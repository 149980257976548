// NOTE this is type of messages that come from the websockets
// onmessage (msg) => msg.data.type

import { MEETINGS_TOAST_TYPES } from "@/constants/custom-toasts";
import { WEBSOCKET_MESSAGING_EVENT_TYPES } from "./messaging/constants";
import { WEBSOCKET_DIALER_LIST_EVENT_TYPES } from "./dialer/constants";
import { WEBSOCKET_CALL_COPILOT_EVENT_TYPES } from "./call-copilot/constants";
export const WEBSOCKET_CONNECTION_TYPES = {
  DIALER: `${process.env.NEXT_PUBLIC_SOCKET_DOMAIN}/v1/socket`,
  GENERAL: `${process.env.NEXT_PUBLIC_SOCKET_DOMAIN}/v1/general_socket`,
  CALL_COPILOT: `${process.env.NEXT_PUBLIC_SOCKET_DOMAIN}/v1/dialer_copilot_socket`,
} as const;

export const WEBSOCKET_CONNECTION_TYPES_NAMES = {
  [WEBSOCKET_CONNECTION_TYPES.DIALER]: "DIALER",
  [WEBSOCKET_CONNECTION_TYPES.GENERAL]: "GENERAL",
  [WEBSOCKET_CONNECTION_TYPES.CALL_COPILOT]: "CALL_COPILOT",
} as const;

export const WEBSOCKET_EVENT_TYPES = {
  AUTH: "auth",
  AUTH_SUCCESS: "auth_received",

  GLOBAL_MEETINGS_BOOKED: MEETINGS_TOAST_TYPES.BOOKED_MEETING,
  GLOBAL_MEETINGS_QUALIFIED: MEETINGS_TOAST_TYPES.QUALIFIED_MEETING,

  NOTIFICATIONS_NEW: "new_user_notification",

  ERROR: "error",

  ...WEBSOCKET_MESSAGING_EVENT_TYPES,
  ...WEBSOCKET_DIALER_LIST_EVENT_TYPES,
  ...WEBSOCKET_CALL_COPILOT_EVENT_TYPES,
} as const;
