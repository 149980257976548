import { FC } from "react";

// Local module imports
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { AccountStatusLists } from "./status-lists";
import { AccountStatusDetails } from "@/components/modules/pipeline/account-details/sections/status-section/status-details";
import { AccountDispositionStatusSmallPill } from "@/components/modules/pipeline/account-details/shared/status-pill";
import { StatusSectionActions } from "@/components/modules/pipeline/account-details/sections/status-section/actions";

// Shared lib imports
import { ACCOUNT_DISPOSITION_STATUSES } from "shared/lib/constants/account";
import { getAccountDispositionStatusParams } from "shared/lib/helpers/disposition";
import { clsxMerge } from "shared/lib/helpers/styles";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

// Shared UI imports
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

interface StatusSectionPropsI {
  className?: string;
}

const Header: FC<PropsWithClassNameI> = ({ className }) => {
  const { campaign, account, accountStatus, onAccountUpdate } =
    useAccountDetailsContext();

  return (
    <div
      className={clsxMerge(
        "flex w-full items-center justify-between",
        className
      )}
    >
      <h6>Status</h6>
      <div>
        <StatusSectionActions
          campaign={campaign}
          account={account}
          accountStatus={accountStatus}
          onAccountUpdate={onAccountUpdate}
        />
      </div>
    </div>
  );
};

export const StatusSection: FC<StatusSectionPropsI> = () => {
  const { accountDetailsApi, campaign, account, accountStatus } =
    useAccountDetailsContext();

  if (ACCOUNT_DISPOSITION_STATUSES.DISQUALIFIED === accountStatus?.status) {
    return null;
  }

  return (
    <AccountDetailsSectionContainer title={<Header />}>
      <DataStatesWrapper
        viewName="Account state"
        api={accountDetailsApi}
        loading={<CenteredSpinner className="h-[120px]" />}
      >
        {accountStatus && (
          <div className="flex flex-col gap-5">
            <AccountDispositionStatusSmallPill
              {...getAccountDispositionStatusParams(accountStatus, false)}
            />

            <AccountStatusDetails
              accountStatus={accountStatus}
              campaign={campaign}
              account={account}
            />

            <AccountStatusLists className="mb-2" />
          </div>
        )}
      </DataStatesWrapper>
    </AccountDetailsSectionContainer>
  );
};
