import { FC, Fragment, useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import _groupBy from "lodash/groupBy";

import { ACCOUNT_HISTORY_CARDS_MAP } from "shared/ui/activity-log/constants/history";
import { AccountHistoryItemI } from "@/interfaces/accounts";
import AccountHistoryEmptyState from "./empty-state";
import { useLazyModal } from "shared/lib/hooks";
import {
  ACCOUNT_REPLY_EMAIL_MODAL_ID,
  AccountReplyEmailModal,
} from "@/modals/account-reply-email-modal";
import { ACCOUNT_ACTIVITY_TYPES } from "shared/lib/constants/account";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

interface HistoryListPropsI extends PropsWithClassNameI {
  isLoading?: boolean;
  historyData?: AccountHistoryItemI[];
}

export const HistoryList: FC<HistoryListPropsI> = ({
  isLoading = false,
  historyData,
  className,
}) => {
  const {
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const [focusedActivity, setFocusedActivity] = useState<AccountHistoryItemI>();

  const {
    isOpen: isReplyEmailModalOpen,
    onOpen: onReplyEmailModalOpen,
    onClose: onReplyEmailModalClose,
  } = useLazyModal(ACCOUNT_REPLY_EMAIL_MODAL_ID);

  const handleCloseEmailModal = () => {
    setFocusedActivity(undefined);
    onReplyEmailModalClose();
  };

  const groupedHistoryData = useMemo(
    () =>
      _groupBy(historyData || [], ({ created_at }) =>
        dayjs(created_at).format("ll")
      ),
    [historyData]
  );

  const actionClickMap: Record<
    string,
    (activity: AccountHistoryItemI) => void
  > = useMemo(
    () => ({
      [ACCOUNT_ACTIVITY_TYPES.EMAIL_RECEIVED]: (
        activity: AccountHistoryItemI
      ) => {
        setFocusedActivity(activity);
        onReplyEmailModalOpen();
      },
    }),
    [onReplyEmailModalOpen]
  );

  const getActionClick = useCallback(
    (activity: AccountHistoryItemI) => () => {
      if (activity.activity_type in actionClickMap) {
        return actionClickMap[activity.activity_type](activity);
      }

      return undefined;
    },
    [actionClickMap]
  );

  if (isLoading && (historyData?.length === 0 || !historyData)) {
    return null;
  }

  return historyData && historyData.length > 0 ? (
    <>
      <div className={clsxMerge("flex flex-col gap-7", className)}>
        {Object.entries(groupedHistoryData).map(
          ([dayOfActivities, activitiesOfTheDay], idx) => (
            <div key={idx} className="flex animate-fadein flex-col gap-5">
              <div className="relative flex h-8 w-full items-center justify-center">
                <div className="h-0.5 w-full bg-[#D9D9D9]" />
                <div className="brand-typography-h3 absolute left-1/2 w-[128px] -translate-x-1/2 rounded-full bg-[#555] text-center leading-8 text-white">
                  {dayOfActivities}
                </div>
              </div>

              <div className="flex flex-col gap-9 @container">
                {activitiesOfTheDay.map((activity, idx) => {
                  const ActivityCard =
                    ACCOUNT_HISTORY_CARDS_MAP[activity.activity_type];

                  if (!ActivityCard) {
                    return null;
                  }

                  return (
                    <Fragment key={idx}>
                      <ActivityCard
                        activity={activity}
                        onActionClick={getActionClick(activity)}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )
        )}
      </div>

      {isReplyEmailModalOpen && focusedActivity && (
        <AccountReplyEmailModal
          emailId={focusedActivity?.email_metadata?.id}
          replyToEmail={focusedActivity?.email_metadata?.from}
          onSuccess={() => {
            setFocusedActivity(undefined);
            onReplyEmailModalClose();
            reloadAccountHistory();
          }}
          onClose={handleCloseEmailModal}
        />
      )}
    </>
  ) : (
    <AccountHistoryEmptyState delay={300} />
  );
};
