import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface TextFieldI extends PropsWithClassNameI {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  errors?: string;
  touched?: boolean;
  inputProps?: any;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
}

const DefaultInput = (props: any) => <textarea {...props} />;

const TextArea =
  (Field = DefaultInput) =>
  // eslint-disable-next-line react/display-name
  ({
    id,
    name,
    label,
    placeholder,
    errors,
    touched,
    className,
    labelClassName,
    inputClassName,
    inputProps,
    disabled,
  }: TextFieldI) =>
    (
      <div className={clsxMerge("form-control mb-4 w-full", className)} id={id}>
        {!!label && (
          <div className="mb-2">
            <label
              className={clsxMerge(
                "ae-typography-detail1 text-base-content/60",
                labelClassName
              )}
            >
              {label}
            </label>
          </div>
        )}

        <div className={clsxMerge("flex", { "cursor-not-allowed": disabled })}>
          <Field
            name={name}
            type="text"
            as="textarea"
            rows={4}
            placeholder={placeholder}
            className={clsxMerge(
              "textarea bg-base-content/5 mb-1 grow",
              disabled && "pointer-events-none opacity-60",
              errors && touched && "border-error-content",
              inputClassName
            )}
            {...inputProps}
          />
        </div>

        {errors && touched && (
          <div className="flex items-center text-xs">
            <ExclamationCircleIcon className="text-error-content mr-1 h-5 w-5 bg-transparent" />
            <div className="text-error-content">{errors}</div>
          </div>
        )}
      </div>
    );

export default TextArea;
