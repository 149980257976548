import { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";

import { MeetingBookedCardContent } from "@/modules/calling/cards/call-disposition-card/meeting-booked-card";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { CampaignI } from "@/interfaces/campaign";
import { glencocoClientAPI } from "@/api/glencoco";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { SubmitBookedMeetingModalCommonPropsI } from "@/modals/account-book-meeting-modal/submit-booked-meeting-modal";

interface SubmitBookedMeetingModalPropsI
  extends SubmitBookedMeetingModalCommonPropsI {
  calendlyUri: string;
  campaign: CampaignI;
  contact: CallContactI;
  account: CallAccountI;
  calendlyEvent: CalendlyEventI;
  callId: string;

  notes?: string;
  modalId?: string;

  onSuccess?: () => void;
}

export const SUBMIT_BOOKED_MEETING_MODAL_ID = "submit-booked-meeting-modal";

const SubmitBookMeetingModalContent: FC<
  SubmitBookedMeetingModalPropsI & ModalChildrenFnPropsI
> = ({
  campaign,
  contact,
  account,
  calendlyEvent,
  callId,
  qcApi,
  selectedQCs,
  notes: _notes = "",
  handleClose,
  onSuccess = () => {},
}) => {
  const { setIsMeetingCanceled } = useInCallContext();

  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState(_notes);

  useEffect(() => {
    setNotes(_notes);
  }, [_notes]);

  const handleSubmit = async () => {
    setIsLoading(true);

    if (!campaign?.id || !calendlyEvent?.id) {
      toast.error("Invalid Id.");
      setIsLoading(false);
      return;
    }

    const API = glencocoClientAPI();

    const updateBookingMeetingResponse = await API.updateBookedMeeting(
      campaign.id,
      callId,
      calendlyEvent?.id,
      {
        notes,
      }
    ).catch((e) => e);

    if (updateBookingMeetingResponse.status === 200) {
      setIsMeetingCanceled(false);
      toast.success("Meeting has been successfully booked!");
      onSuccess();
      handleClose();
    } else {
      toast.error("Failed to book meeting.");
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <MeetingBookedCardContent
      isWrapperNoStyles
      isVisible
      contact={contact}
      account={account}
      calendlyEvent={calendlyEvent}
      qcApi={qcApi}
      selectedQCs={selectedQCs}
      notes={notes}
      loading={isLoading}
      onNotesUpdate={setNotes}
      onSubmit={handleSubmit}
    />
  );
};

const SubmitBookMeetingModal: FC<SubmitBookedMeetingModalPropsI> = (props) => {
  if (!props.calendlyUri) {
    return null;
  }

  return (
    <Modal
      id={props.modalId || SUBMIT_BOOKED_MEETING_MODAL_ID}
      modalBoxClassName="rounded-lg"
    >
      {(modalProps) => (
        <SubmitBookMeetingModalContent {...props} {...modalProps} />
      )}
    </Modal>
  );
};

export default SubmitBookMeetingModal;
