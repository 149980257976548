import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { LOG_CATEGORIES } from "@/constants/logs";
import { getWebsocketInstance } from "@/helpers/websockets";
import { useWebsocketConnection } from "@/hooks/use-websocket-connection";
import {
  WSAuthRequestedDataI,
  WSAuthSuccessDataI,
  WSClosedDataI,
} from "@/interfaces/events";

import {
  WEBSOCKET_CONNECTION_TYPES,
  WEBSOCKET_CONNECTION_TYPES_NAMES,
} from "lib/websockets/constants";
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
  FC,
} from "react";
import toast from "react-hot-toast";
import { checkIfClient } from "shared/lib/helpers";
import { DayJs } from "shared/lib/helpers/date";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

export interface WebsocketsContextI {
  isAuthorized?: boolean;
  authRequestedAt?: string;
}

export interface WebsocketsProviderI extends PropsWithChildren {
  connectionType?: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>;
}

const WebsocketsContext = createContext<WebsocketsContextI>({});

const safeGetWebscoket = (
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>
) => {
  if (!checkIfClient()) return;

  const ws = getWebsocketInstance(connectionType);
  return ws;
};

export const WebsocketsProvider: FC<WebsocketsProviderI> = ({
  children,
  connectionType = WEBSOCKET_CONNECTION_TYPES.GENERAL,
}) => {
  useWebsocketConnection(connectionType);

  const [isAuthorized, setIsAuthorized] = useState(
    safeGetWebscoket(connectionType)?.isAuthorized || false
  );
  const [authRequestedAt, setAuthRequestedAt] = useState<string | undefined>(
    safeGetWebscoket(connectionType)?.authRequestedAt
  );

  useEffect(() => {
    const notAuthorized = (
      event: CustomEvent<WSAuthRequestedDataI | WSClosedDataI>
    ) => {
      if (event?.detail?.connectionType !== connectionType) return;

      const ws = getWebsocketInstance(connectionType);
      ws.isAuthorized = false;

      setIsAuthorized(ws.isAuthorized);
    };

    const authorized = (event: CustomEvent<WSAuthSuccessDataI>) => {
      if (event?.detail?.connectionType !== connectionType) return;

      const ws = getWebsocketInstance(connectionType);
      if (ws) ws.isAuthorized = true;

      setIsAuthorized(true);
    };

    const requestAuth = (event: CustomEvent<WSAuthRequestedDataI>) => {
      if (event?.detail?.connectionType !== connectionType) return;

      const ws = getWebsocketInstance(connectionType);

      ws.isAuthorized = false;
      ws.authRequestedAt = DayJs().toISOString();

      setIsAuthorized(ws.isAuthorized);
      setAuthRequestedAt(ws.authRequestedAt);
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.AUTH_REQUESTED,
      requestAuth as EventListener
    );

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
      notAuthorized as EventListener
    );

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
      authorized as EventListener
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.AUTH_REQUESTED,
        notAuthorized as EventListener
      );

      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
        notAuthorized as EventListener
      );

      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
        authorized as EventListener
      );
    };
  }, []);

  const interval = useRef<number>();
  useEffect(() => {
    console.log(
      `${LOG_CATEGORIES.WS}[AUTH][${WEBSOCKET_CONNECTION_TYPES_NAMES[connectionType]}] - requested at:${authRequestedAt} | isAuthorized:${isAuthorized}`
    );

    if (!isAuthorized) {
      clearInterval(interval.current);
      interval.current = window.setInterval(() => {
        console.log(
          `${LOG_CATEGORIES.WS}[AUTH][${WEBSOCKET_CONNECTION_TYPES_NAMES[connectionType]}] - requested at:${authRequestedAt} | isAuthorized:${isAuthorized}`
        );

        const secondsPassed = DayJs().diff(authRequestedAt, "seconds");

        if (isAuthorized) {
          clearInterval(interval.current);
        }

        if (secondsPassed > 10 && !isAuthorized) {
          clearInterval(interval.current);
          toast.error(
            "Failed to authorize socket connection. Please try logout and login."
          );
        }
      }, 1000);
    } else {
      clearInterval(interval.current);
    }

    if (!authRequestedAt) {
      clearInterval(interval.current);
    }
  }, [isAuthorized, authRequestedAt]);

  return (
    <WebsocketsContext.Provider value={{ isAuthorized, authRequestedAt }}>
      {children}
    </WebsocketsContext.Provider>
  );
};

export const useWebsocketsContext = (): WebsocketsContextI =>
  useContext(WebsocketsContext);

export default WebsocketsContext;
