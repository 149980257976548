import { useEffect } from "react";
import { CalendlyBookingDetailsSidebar } from "@/components/modules/pipeline/account-details/sidebars/calendly-booking-details";
import { useDisclosure } from "shared/lib/hooks/use-disclosure";
import { Button } from "shared/ui/shadcn/button";
import { ChevronsLeftIcon } from "lucide-react";
import { clsxMerge } from "shared/lib/helpers";

export const CollapsibleDetailsSidebar = ({
  contactId,
}: {
  contactId: string;
}) => {
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure(true);

  useEffect(() => {
    onSidebarOpen();
  }, []);

  return (
    <>
      <Button
        variant="outline"
        onClick={onSidebarOpen}
        className={clsxMerge(
          "fixed right-0 top-[135px] z-[1000] w-[160px]",
          "flex items-center justify-between"
        )}
      >
        <ChevronsLeftIcon /> <span>Account Details</span>
      </Button>

      <CalendlyBookingDetailsSidebar
        contactId={contactId}
        isOpen={isSidebarOpen}
        onClose={onSidebarClose}
        className="z-[1000]"
      />
    </>
  );
};
