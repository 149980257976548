import { FC, Fragment } from "react";
import _noop from "lodash/noop";

import UICheckbox from "shared/ui/ae-user-input/checkbox";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { QualificationCriteriaTableWithToggles } from "shared/ui/qualification/table/with-toggles";
import { AccountHistoryItemI } from "shared/lib/interfaces/account";
import {
  getQCsAndPricingFromResponse,
  mapActivityLogQCToSelectedQCs,
} from "shared/lib/helpers/qualification-criteria";

interface QualificationCriteriaSummaryPropsI extends PropsWithClassNameI {
  qualificationCriteria?: Array<SelectedQualificationCriteriaQuestionI>;
  qualificationCriteriaV2?: AccountHistoryItemI["qualification_criteria_v2"];
}

const Checkbox = UICheckbox();

export const AccountHistoryCardQualificationCriteria: FC<
  QualificationCriteriaSummaryPropsI
> = ({ qualificationCriteria, qualificationCriteriaV2 }) => {
  if (qualificationCriteria) {
    return (
      <section>
        <div className="grid w-full grid-cols-[1fr_100px]">
          <div className="brand-typography-h6 mb-4 text-black">
            Qualification Criteria From Caller
          </div>

          <div className="brand-typography-h6 mb-4 text-center text-black">
            AE Agreed
          </div>

          {qualificationCriteria?.map((qc, i) => (
            <Fragment key={i}>
              <div className="flex items-center">
                <Checkbox
                  name={`checkbox-${i}`}
                  inputProps={{
                    checked: qc.is_checked,
                    disabled: true,
                  }}
                />

                <label className="ae-typography-body2 text-base-content/60">
                  {qc.question}
                </label>
              </div>

              <div className="ae-typography-body2 text-center">
                {qc.is_checked ? "Yes" : "No"}
              </div>
            </Fragment>
          ))}
        </div>
      </section>
    );
  }

  if (qualificationCriteriaV2) {
    const qcTexts = getQCsAndPricingFromResponse({
      qualification_criteria: qualificationCriteriaV2,
    });
    const qcValues = mapActivityLogQCToSelectedQCs(qualificationCriteriaV2);

    return (
      <QualificationCriteriaTableWithToggles
        isEditable={false}
        value={qcValues}
        texts={qcTexts}
        hasBonus={qualificationCriteriaV2.is_timing_budget_met}
        onChange={_noop}
      />
    );
  }

  return null;
};
