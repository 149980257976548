import { DIALER_BG_CLASSES } from "@/components/modules/calling/main-container/backgrounds";
import { clsxMerge } from "shared/lib/helpers";
import { LeadCard } from "./lead-card";
import { LeadAdditionalInfo } from "./additional-info";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

export const ConnectedLeadSection = () => {
  const callStatus = useDialerCallStatus();

  const { startAt } = useInCallContext();

  const isConnected = callStatus === DIALER_CALL_STATUS.DURING && !!startAt;
  const isDialing = callStatus === DIALER_CALL_STATUS.DURING && !startAt;
  const isEnded = callStatus === DIALER_CALL_STATUS.AFTER;

  return (
    callStatus !== DIALER_CALL_STATUS.BEFORE && (
      <div className="relative h-[135px] w-full">
        <div
          className={clsxMerge(
            "absolute left-0 top-0 z-0",
            "h-full w-full",
            "flex items-center justify-between",
            "bg-cover bg-fixed",
            "animate-maxdialerbg",
            "transition-all",
            {
              "hue-rotate-[260deg] filter": isConnected,
              "hue-rotate-[90deg] filter": isDialing,
              "brightness-[1] grayscale-[0.7] hue-rotate-[0deg] filter":
                isEnded,
            },

            DIALER_BG_CLASSES.LIGHT
          )}
        ></div>

        <div
          className={clsxMerge(
            "z-1 relative h-full w-full",
            "flex items-center justify-between"
          )}
        >
          <LeadCard />
          <LeadAdditionalInfo />
        </div>
      </div>
    )
  );
};
