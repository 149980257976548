// React & Next.js
import { FC } from "react";

// API & Data
import { AccountDetailsListItemPropsI } from "./interface";

// Constants
import { CONFIRM_REMOVE_LIST_FROM_ACCOUNT_MODAL_ID } from "./constants";

// Components
import { CloseIcon } from "shared/ui/icons";
import { ConfirmRemoveContactsFromList } from "@/modals/lists/confirm-remove-contacts-from-list";

// Utils & Helpers
import { clsxMerge } from "shared/lib/helpers";
import { getListIcon } from "@/modules/pipeline/lists/list/primary-header/selector/utils";
import { useListItem } from "./use-list-item";

export const AccountDetailsListItemAsBadge: FC<
  AccountDetailsListItemPropsI
> = ({ className, listData, campaignId, accountId }) => {
  const {
    isAllowedToOpen,
    isExclusive,
    listName,
    isOpen,
    onOpen,
    onClose,
    handleOpenList,
    handleConfirmRemoval,
  } = useListItem({
    listData,
    campaignId,
    accountId,
  });

  return (
    <div
      title={listName}
      // An external wrapper serving to create a border gradient effect
      className={clsxMerge(
        "relative w-fit rounded-md border",
        {
          "border-transparent bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]":
            isExclusive,
          // [DIALING_BADGE_CLASSES]: isDialing,
        },

        className
      )}
    >
      <div
        className={clsxMerge(
          "bg-white",
          "relative",
          "flex w-fit max-w-[260px] items-center",
          "cursor-pointer gap-2 transition-colors",
          "rounded-md px-2 py-1",
          "pr-8",
          {
            "hover:bg-[rgb(247,247,247)]": isAllowedToOpen,
            "bg-white": isExclusive,
          }
        )}
        onClick={handleOpenList}
      >
        <span className="text-[#F88167]">
          {listData.list_type &&
            getListIcon({
              list_type: listData.list_type,
              isExclusive,
            })}
        </span>

        <p
          className={clsxMerge("truncate typography-body-5", {
            "bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent":
              isExclusive,
          })}
        >
          {listName}
        </p>

        <button
          className={clsxMerge(
            "btn btn-circle btn-ghost btn-xs",
            "absolute right-1"
          )}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          <CloseIcon className="w-4 min-w-4 cursor-pointer rounded-full transition-colors" />
        </button>
      </div>

      {isOpen && (
        <ConfirmRemoveContactsFromList
          id={CONFIRM_REMOVE_LIST_FROM_ACCOUNT_MODAL_ID}
          title={`Remove these leads from the list ${listName}?`}
          onConfirm={handleConfirmRemoval}
          onCancel={onClose}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export const AccountDetailsListItemAsRow: FC<AccountDetailsListItemPropsI> = ({
  className,
  listData,
  campaignId,
  accountId,
}) => {
  const {
    isAllowedToOpen,
    isExclusive,
    listName,
    isOpen,
    onOpen,
    onClose,
    handleOpenList,
    handleConfirmRemoval,
  } = useListItem({
    listData,
    campaignId,
    accountId,
  });

  return (
    <div
      title={listName}
      // An external wrapper serving to create a border gradient effect
      className={clsxMerge(
        "relative w-full rounded-lg border",
        {
          "border-transparent bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]":
            isExclusive,
        },

        className
      )}
    >
      <div
        className={clsxMerge(
          "bg-white",
          "relative",
          "flex w-full items-center",
          "cursor-pointer gap-2 transition-colors",
          "rounded-md p-2",
          "pr-8",
          {
            "hover:bg-[rgb(247,247,247)]": isAllowedToOpen,
            "bg-white": isExclusive,
          }
        )}
        onClick={handleOpenList}
      >
        <span className="text-[#F88167]">
          {listData.list_type &&
            getListIcon({
              list_type: listData.list_type,
              isExclusive,
            })}
        </span>

        <p
          className={clsxMerge("flex flex-col", {
            "bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent":
              isExclusive,
          })}
        >
          <span className="mb-1 truncate typography-body-4-bold">
            {listName}
          </span>
        </p>

        <button
          className={clsxMerge(
            "btn btn-circle btn-ghost btn-xs",
            "absolute right-1"
          )}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        >
          <CloseIcon className="w-4 min-w-4 cursor-pointer rounded-full transition-colors" />
        </button>
      </div>

      {isOpen && (
        <ConfirmRemoveContactsFromList
          id={CONFIRM_REMOVE_LIST_FROM_ACCOUNT_MODAL_ID}
          title={`Remove these leads from the list ${listName}?`}
          onConfirm={handleConfirmRemoval}
          onCancel={onClose}
          onClose={onClose}
        />
      )}
    </div>
  );
};
