import { ReactNode, useMemo } from "react";
import { Editor } from "@tiptap/react";
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
} from "@heroicons/react/20/solid";
import { clsxMerge } from "shared/lib/helpers";
import { Link2Icon } from "lucide-react";

const ACTIVE_CLASSNAME = "opacity-100";
const INACTIVE_CLASSNAME = "opacity-100";

interface EditorMenuOptionI {
  label?: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const EditorMenu = ({
  className,
  editor,
}: {
  className?: string;
  editor: Editor | null;
}) => {
  const editorOptionsLeft: EditorMenuOptionI[] = useMemo(() => {
    return [
      {
        label: <BoldIcon className="w-4" />,
        onClick: () => editor?.chain().focus().toggleBold().run(),
        disabled: !editor?.can().chain().focus().toggleBold().run(),
        className: editor?.isActive("bold")
          ? ACTIVE_CLASSNAME
          : INACTIVE_CLASSNAME,
      },
      {
        label: <ItalicIcon className="w-4" />,
        onClick: () => editor?.chain().focus().toggleItalic().run(),
        disabled: !editor?.can().chain().focus().toggleItalic().run(),
        className: editor?.isActive("italic")
          ? ACTIVE_CLASSNAME
          : INACTIVE_CLASSNAME,
      },
      {
        label: <StrikethroughIcon className="w-4" />,
        onClick: () => editor?.chain().focus().toggleMark("strike").run(),
        disabled: !editor?.can().chain().focus().toggleMark("strike").run(),
        className: editor?.isActive("strike")
          ? ACTIVE_CLASSNAME
          : INACTIVE_CLASSNAME,
      },

      {
        label: <Link2Icon className="w-4" />,
        onClick: () => {
          if (!editor) return;

          const previousUrl = editor.getAttributes("link").href || "";
          const url = window.prompt("Enter the URL", previousUrl);

          // User canceled
          if (url === null) {
            return;
          }

          if (url === "") {
            editor.chain().focus().unsetLink().run(); // Remove link if empty
            return;
          }

          editor.chain().focus().setLink({ href: url }).run(); // Set link with https:// enforced
        },
        disabled: !editor?.can().chain().focus().setLink({ href: "" }).run(),
        className: editor?.isActive("link")
          ? ACTIVE_CLASSNAME
          : INACTIVE_CLASSNAME,
      },
    ];
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "control-group rounded-t-lg border-b bg-black/5 p-2 px-3",
        className
      )}
    >
      <div className="button-group flex items-center gap-2">
        {editorOptionsLeft.map((opt, i) => (
          <button
            key={i}
            type="button"
            className={clsxMerge("", opt.className)}
            onClick={opt.onClick}
          >
            {opt.label}
          </button>
        ))}
      </div>
    </div>
  );
};
