import { FC } from "react";

import Router from "@/helpers/router";
import { LogoPropsI } from "@/components/navigation/shared/logo/interfaces";
import { getLogoPath } from "@/components/navigation/shared/logo/utils";

export const Logo: FC<LogoPropsI> = ({ variation, width, className, href }) => (
  <a href={href || Router.home()} className={className}>
    <img
      src={getLogoPath(variation)}
      alt="Glencoco Logo"
      width={width || "89px"}
    />
  </a>
);
