import DangerousActionConfirmationModal from "@/components/modals/dangerous-action-confirmation-modal";

export const EMAIL_TEMPLATE_UNVERIFIED_CONFIRM_MODAL_ID =
  "email_template_unverified_confirm_modal";

export const EmailTemplateUnverifiedConfirmModal = ({
  email,
  onConfirm = () => {},
}: {
  email?: string;
  onConfirm?: () => void;
}) => {
  return (
    <DangerousActionConfirmationModal
      modalId={EMAIL_TEMPLATE_UNVERIFIED_CONFIRM_MODAL_ID}
      title={`Are you absolutely sure you want to send to the email address - ${email}?`}
      description={`We were unable to verify the email recipient.`}
      mainButtonText="Yes, continue"
      callback={onConfirm}
    />
  );
};
