import { EmailTemplateDeleteModal } from "@/components/modals/email-template-delete-modal";
import { EmailTemplatesList } from "@/modules/email-templates-sidebar/content/email-templates-list";
import { EmailTemplateWorkspace } from "@/modules/email-templates-sidebar/content/email-template-workspace";
import { useEmailTemplatesManager } from "@/hooks/use-email-templates-manager";

export const EmailTemplatesSidebarContent = () => {
  const {
    templates,
    activeTemplate,
    activeTemplateId,
    setActiveTemplateId,
    deleteTemplateAsync,
    isEditMode,
    setEditMode,
    emailGenerationConfig,
  } = useEmailTemplatesManager();

  return (
    <>
      <div className="flex h-full">
        <EmailTemplatesList
          className="h-full w-[420px]"
          templates={templates?.data?.email_templates}
          activeTemplateId={activeTemplateId}
          setActiveTemplateId={setActiveTemplateId}
          setEditMode={setEditMode}
        />

        <div className="flex-1 overflow-y-scroll">
          <EmailTemplateWorkspace
            emailConfig={emailGenerationConfig}
            template={activeTemplate}
            isEditMode={isEditMode}
            setActiveTemplateId={setActiveTemplateId}
            setEditMode={setEditMode}
          />
        </div>
      </div>

      <EmailTemplateDeleteModal
        name={activeTemplate?.name}
        onConfirm={() => {
          if (activeTemplateId) {
            deleteTemplateAsync(activeTemplateId);
            setActiveTemplateId(undefined);
          }
        }}
      />
    </>
  );
};
