export const ACCOUNT_DETAILS_COMMON_QUERY_KEY = "account-details";

export const AccountDetailsQueryKeys = {
  script: [ACCOUNT_DETAILS_COMMON_QUERY_KEY, "script"],
  defaultScript: [ACCOUNT_DETAILS_COMMON_QUERY_KEY, "default-script"],
  icpTitles: [ACCOUNT_DETAILS_COMMON_QUERY_KEY, "icp-titles"],
  shouldSendEmailPolling: [
    ACCOUNT_DETAILS_COMMON_QUERY_KEY,
    "should-send-email-polling",
  ],
};
