import { FC, useMemo, useState } from "react";

import { useApi } from "shared/lib/hooks";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { QC_TABLE_TOGGLES_INITIAL_STATE } from "shared/lib/constants/qualification-criteria";

import QualificationModal from "./qualification-modal";
import AccountCalendlyModal, {
  ACCOUNT_CALENDLY_MODAL_ID,
} from "./calendly-modal";
import SubmitBookedMeetingModal, {
  ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID,
} from "./submit-booked-meeting-modal";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useFetchQualificationCriteriaAndPricing } from "@/api/routes/qualification-criteria/queries";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { CampaignI } from "@/interfaces/campaign";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { QualificationCriteriaTableTogglesValueI } from "shared/lib/interfaces/qualification-criteria";
import { useBookMeetingByCallId } from "./use-book-meeting-by-call-id";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { CollapsibleDetailsSidebar } from "./collapsible-details-sidebar";

interface AccountBookMeetingModalPropsI {
  modalId?: string;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const ACCOUNT_BOOK_MEETING_MODAL_ID = "account_book_meeting_modal";

export const AccountBookMeetingModal: FC<AccountBookMeetingModalPropsI> = ({
  modalId = ACCOUNT_BOOK_MEETING_MODAL_ID,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const {
    modalAccount,
    clearModalAccount,
    accountCalendlyUri,
    updateAccountWithCampaign,
  } = useAccountsContext();

  const { call } = useCallingContext();

  const { handleBookMeetingByCallId } = useBookMeetingByCallId();

  const { campaignId, accountId } = modalAccount || {};

  const accountName = modalAccount?.accountName as string;

  const getCampaignFetcher = useMemo(
    () =>
      campaignId
        ? (api: APII) => api.getCampaign(modalAccount?.campaignId as string)
        : null,
    [modalAccount?.campaignId]
  );

  const [{ data: campaignData }] = useApi({
    fetcher: getCampaignFetcher,
    apiFactory: glencocoClientAPI,
  });

  const qualificationCriteriaAndPricingApi =
    useFetchQualificationCriteriaAndPricing(modalAccount?.campaignId);

  const campaign = campaignData?.campaign as CampaignI;

  const [selectedQCs, setSelectedQCs] = useState(
    QC_TABLE_TOGGLES_INITIAL_STATE
  );

  // NEED THIS BECAUSE CALENDLY TRIGGERS onCalendlySuccess FOR ALL RENDERED CALENDLY MODALS
  // AND WE HAVE TWO ON ACCOUNT PAGE.
  // THIS VARIABLE HELPS US RENDER IT ONLY AFTER THE QUALIFICATION CRITERIA STEP FOR THIS MODAL IS SUCCESSFUL
  const [isCalendlyModalVisible, setIsCalendlyModalVisible] = useState(false);
  const [calendlyEvent, setCalendlyEvent] = useState<CalendlyEventI>();

  const onSelectQCsSuccess = (qc: QualificationCriteriaTableTogglesValueI) => {
    setSelectedQCs(qc);
    setIsCalendlyModalVisible(true);
    modalHelpers.lazyOpen(ACCOUNT_CALENDLY_MODAL_ID);
  };

  const onCalendlySuccess = (calendlyEvent: CalendlyEventI) => {
    setCalendlyEvent(calendlyEvent);

    // Close current modal
    modalHelpers.close(ACCOUNT_CALENDLY_MODAL_ID);
    modalHelpers.lazyOpen(ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID);
  };

  /**
   * This function is used to submit the booked meeting
   * It uses 2 flows:
   * 1. If call is available, it uses the handleBookMeetingByCallId hook to book the meeting
   * 2. If call is not available, it uses the updateAccountWithCampaign hook to update the meeting
   * @param notes - The notes for the meeting
   * @returns {Promise<boolean>}
   */
  const onSubmitBookedMeetingOverview = async (notes: string) => {
    {
      if (!accountId || !campaignId) {
        return;
      }

      const callId = call?.customParameters.get("call_id");
      if (callId) {
        const isBooked = await handleBookMeetingByCallId(
          calendlyEvent as CalendlyEventI,
          notes
        );

        if (isBooked) {
          clearModalAccount();
          onSuccess();
        }

        return;
      }

      return updateAccountWithCampaign(accountId, campaignId, {
        status: ACCOUNT_STATUSES.MEETING_SCHEDULED,
        modify_meeting_request: {
          event_uri: calendlyEvent?.id || "test",
          notes,
        },
      })
        .then(() => {})
        .finally(() => {
          clearModalAccount();
          onSuccess();
        });
    }
  };

  return (
    <>
      <QualificationModal
        modalId={modalId}
        qcApi={qualificationCriteriaAndPricingApi}
        accountName={accountName}
        onSuccess={onSelectQCsSuccess}
        onClose={onClose}
      />

      {isCalendlyModalVisible && (
        <>
          <AccountCalendlyModal
            accountName={accountName}
            contact={modalAccount?.contact}
            calendlyUri={accountCalendlyUri}
            onSuccess={onCalendlySuccess}
            onClose={onClose}
          />

          <CollapsibleDetailsSidebar
            contactId={modalAccount?.contact?.contact_id as string}
          />
        </>
      )}

      <SubmitBookedMeetingModal
        hideCloseAction
        modalId={ACCOUNT_SUBMIT_BOOKED_MEETING_MODAL_ID}
        calendlyUri={accountCalendlyUri}
        calendlyEvent={calendlyEvent as CalendlyEventI}
        campaign={campaign as CampaignI}
        qcApi={qualificationCriteriaAndPricingApi}
        selectedQCs={selectedQCs}
        onSubmit={onSubmitBookedMeetingOverview}
      />
    </>
  );
};
