import { WSCallCopilotMessageReceivedDataI } from "@/interfaces/events";
import { WEBSOCKET_CALL_COPILOT_EVENT_TYPES } from "@/lib/websockets/call-copilot/constants";
import { PlaneIcon } from "lucide-react";
import { Badge } from "shared/ui/shadcn/badge";

export const CallCopilotSectionHeader = ({
  content,
}: {
  content?: WSCallCopilotMessageReceivedDataI[];
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      <h3 className="brand-typography-h5-medium flex items-center gap-1">
        <PlaneIcon className="w-4" />
        <span>Call Copilot</span>
      </h3>

      {!content?.length && (
        <span className="typography-body-4">Listening...</span>
      )}

      {content?.[0]?.type ===
        WEBSOCKET_CALL_COPILOT_EVENT_TYPES.CALL_COPILOT_MESSAGE_COMPETITOR && (
        <Badge className="animate-fadein typography-body-4" variant="secondary">
          Competitor detected
        </Badge>
      )}
    </div>
  );
};
