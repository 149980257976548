import { FC, useCallback, useEffect } from "react";

import {
  AttachmentI,
  EmailTemplateI,
  GetEmailGenerationInfoResponseI,
  SendEmailRequestParamsI,
} from "@/api/routes/email";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useSendEmail } from "@/modules/email-templates-sidebar/queries";
import { useEmailTemplatesSidebarContext } from "@/modules/email-templates-sidebar/context";
import { EmailForm } from "./email-form";

import {
  useAccountEmailAIGeneration,
  handleEmailGenerationError,
} from "@/hooks/use-email-templates-manager";

interface EmailTemplatePropsI {
  template: EmailTemplateI;
  config: GetEmailGenerationInfoResponseI;
  campaignAttachments?: AttachmentI[];
  hideTitle?: boolean;
}

export const EmailTemplate: FC<EmailTemplatePropsI> = ({
  template,
  config,
  campaignAttachments,
  hideTitle,
}) => {
  const { onClose } = useEmailTemplatesSidebarContext();
  const {
    setFocusedContact,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const {
    isAIGenerationComplete,
    isAIGenerationStarted,
    isEmailSent,
    emailData,
    handleComposeWithAI,
    setEmailData,
    setIsEmailSent,
    setIsAIGenerationComplete,
    setIsAIGenerationStarted,
  } = useAccountEmailAIGeneration(template);

  const { mutateAsync: sendEmailAsync, isPending: isSendEmailPending } =
    useSendEmail();

  const onEmailSuccess = () => {
    setIsEmailSent(true);
    reloadAccountHistory();
    setFocusedContact(undefined);
    onClose();
  };

  const handleEmailSend = useCallback(
    async (data: SendEmailRequestParamsI) => {
      if (config) {
        const resp = await sendEmailAsync({
          parameters: data,
        });

        if (resp.status === 200) {
          onEmailSuccess();
          return true;
        }

        handleEmailGenerationError(resp, "Failed to send email");
      }

      return false;
    },
    [config, onClose]
  );

  useEffect(() => {
    // reset/initialize content for selected template
    setEmailData(undefined);
    setIsEmailSent(false);
    setIsAIGenerationComplete(false);
    setIsAIGenerationStarted(false);
  }, [template.id]);

  return (
    <div className="relative flex h-full flex-col justify-center">
      <div>
        <EmailForm
          //  key is used to force entire component to re-render when template changes so that all form fields can be reset
          key={template.id}
          template={template}
          senderEmail={config?.from_email}
          attachmentOptions={campaignAttachments}
          initialSubject={emailData?.subject}
          initialBody={emailData?.body}
          onComposeWithAI={handleComposeWithAI}
          onSend={handleEmailSend}
          isSendingEmail={isSendEmailPending}
          isSendEmailSuccess={isEmailSent}
          isAIGenerationComplete={isAIGenerationComplete}
          isAIGenerating={isAIGenerationStarted}
          hideTitle={hideTitle}
        />
      </div>
    </div>
  );
};
