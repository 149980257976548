import { FC, useEffect, useState } from "react";

const AccountHistoryEmptyState: FC<{ delay?: number }> = ({ delay }) => {
  const [isVisible, setIsVisible] = useState(typeof delay !== "number");

  let interval: any = null;
  useEffect(() => {
    setIsVisible(false);
    clearInterval(interval);

    interval = setInterval(() => setIsVisible(true), delay);

    return () => {
      clearInterval(interval);
    };
  }, [delay]);

  return (
    isVisible && (
      <div className="flex h-[300px] flex-col items-center justify-center">
        <h3 className="ae-typography-h3 mb-2">No account history... yet!</h3>
        <p className="ae-typography-body1">
          Come back later to view activity that has been happening with this
          account
        </p>
      </div>
    )
  );
};

export default AccountHistoryEmptyState;
