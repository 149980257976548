import {
  Collapsible,
  CollapsibleTrigger,
} from "@/components/base/shadcn/collapsible";
import { Button } from "@/components/base/shadcn/button";
import { useEffect, useRef, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { clsxMerge } from "shared/lib/helpers/styles";
import { FC, PropsWithChildren } from "react";

interface ExpandableCustomFieldPropsI {
  lineHeight?: number;
}

export const ExpandableCustomField: FC<
  PropsWithChildren<ExpandableCustomFieldPropsI>
> = ({ children, lineHeight = 16 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkHeight = () => {
      if (textRef.current) {
        const height = textRef.current.scrollHeight;
        setIsButtonVisible(height > lineHeight * 2);
      }
    };

    requestAnimationFrame(checkHeight);
  }, [children, lineHeight]);
  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <div className="flex items-start justify-between">
        <div
          ref={textRef}
          className={clsxMerge("typography-body-4-regular", {
            "flex-1": isOpen,
            "line-clamp-2 flex-1 pr-4": !isOpen,
          })}
        >
          {children}
        </div>
        {isButtonVisible && (
          <CollapsibleTrigger asChild>
            <Button variant="link" className="h-auto flex-shrink-0 p-0">
              {isOpen ? (
                <ChevronUpIcon className="size-4" />
              ) : (
                <ChevronDownIcon className="size-4" />
              )}
            </Button>
          </CollapsibleTrigger>
        )}
      </div>
    </Collapsible>
  );
};
