import { UserRoundSearch } from "lucide-react";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { FIND_MORE_LEADS_MODAL } from "@/components/modals/account-find-more-leads-modal";

interface FindLeadsButtonProps extends PropsWithClassNameI {}

export const FindLeadsButton = ({ className }: FindLeadsButtonProps) => {
  return (
    <div
      // An external wrapper serving to create a border gradient effect
      className={clsxMerge(
        "relative w-fit rounded-lg border",
        "border-transparent bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]",
        className
      )}
    >
      <div
        className={clsxMerge(
          "bg-white",
          "relative",
          "flex items-center",
          "cursor-pointer transition-colors",
          "rounded-[7px] p-1 px-2",
          "text-[#333333] typography-body-5",
          "hover:bg-[rgb(247,247,247)]"
        )}
        onClick={() => modalHelpers.trigger(FIND_MORE_LEADS_MODAL)}
      >
        <UserRoundSearch className="mr-2 size-4" />
        Find more leads
      </div>
    </div>
  );
};
