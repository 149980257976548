import { FC, useCallback, useMemo } from "react";
import { CalendarDaysIcon, XCircleIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import {
  ACCOUNT_DISPOSITION_STATUSES,
  ACCOUNT_STATUSES,
} from "shared/lib/constants/account";
import { ActionMenu, ActionMenuActionI } from "shared/ui/action-menu";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ACCOUNT_BOOK_MEETING_MODAL_ID } from "@/modals/account-book-meeting-modal";
import {
  AccountDetailsStatusI,
  AccountI,
  AccountStatusI,
} from "shared/lib/interfaces/account";
import {
  AccountCancelMeetingConfirmationModal,
  CONFIRM_CANCEL_MEETING_MODAL_ID,
} from "@/modals/account-cancel-meeting-confirmation-modal";
import { CampaignI } from "@/interfaces/campaign";
import { ActionMoveToNurture } from "@/components/modules/pipeline/account-details/sections/status-section/move-to-nurture";

dayjs.extend(isSameOrAfter);

interface StatusSectionActionsPropsI {
  campaign?: CampaignI;
  account?: AccountI;
  accountStatus?: AccountDetailsStatusI;
  onAccountUpdate?: () => void;
}

export const StatusSectionActions: FC<StatusSectionActionsPropsI> = ({
  campaign,
  account,
  accountStatus,
  onAccountUpdate,
}) => {
  const { setModalAccount } = useAccountsContext();

  const isCold =
    accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.COLD_LEAD;
  const isMeetingScheduled =
    accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED;
  const isMeetingInPast =
    isMeetingScheduled && dayjs(accountStatus?.meeting_time).isBefore(dayjs());

  const handleModifyMeeting = useCallback(() => {
    setModalAccount({
      campaignId: campaign?.id || "",
      accountId: account?.id || "",
      accountName: account?.name || "",
      accountStatus: accountStatus?.status as AccountStatusI,
      targetAccountStatus: ACCOUNT_STATUSES.MEETING_SCHEDULED,
      contact: {
        contact_id: accountStatus?.primary_contact_id || "",
        title: accountStatus?.primary_contact_title || "",
        email: accountStatus?.primary_contact_email || "",
        name: accountStatus?.primary_contact_name || "",
      },
      followUpTime: accountStatus?.meeting_time || "",
    });

    modalHelpers.lazyOpen(ACCOUNT_BOOK_MEETING_MODAL_ID);
  }, [campaign, account, accountStatus, accountStatus]);

  const meetingScheduledActionItems: ActionMenuActionI[] = useMemo(() => {
    if (isMeetingScheduled && !isMeetingInPast) {
      return [
        {
          title: "Modify meeting",
          icon: <CalendarDaysIcon className="mr-1.5 w-4" />,
          handler: handleModifyMeeting,
        },
        {
          title: "Cancel meeting",
          icon: <XCircleIcon className="mr-1.5 w-4" />,
          handler: () => {
            modalHelpers.lazyOpen(CONFIRM_CANCEL_MEETING_MODAL_ID);
          },
        },
      ];
    }

    return [];
  }, [handleModifyMeeting, isMeetingScheduled, isMeetingInPast]);

  return (
    <>
      {isMeetingScheduled && isMeetingInPast && (
        // If a meeting is in the past, just show the "Modify meeting" button, which
        // shows reschedule meeting modal
        <button
          className="ae-link brand-typography-body6"
          onClick={handleModifyMeeting}
        >
          Modify meeting
        </button>
      )}

      {isMeetingScheduled && !isMeetingInPast && (
        <>
          {/*Otherwise, show action menu with both "Modify meeting" and "Cancel meeting" buttons*/}
          <ActionMenu
            trigger="Edit meeting"
            triggerClassName="ae-link brand-typography-body6 cursor-pointer"
            actions={meetingScheduledActionItems}
            itemClassName="w-max"
          />

          <AccountCancelMeetingConfirmationModal
            campaignId={campaign?.id}
            accountId={account?.id}
            onSuccess={onAccountUpdate}
          />
        </>
      )}

      {isCold && <ActionMoveToNurture />}
    </>
  );
};
