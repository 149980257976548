import { createOption } from "./utils";

export const DIALER_TOOLKIT_VIEW_OPTIONS = {
  SCRIPTS: "Scripts",
  NOTEPAD: "Notepad",
  ACCOUNT_SUMMARY: "Account summary",
  PITCH: "Talking points",
  COMPANY_PURPOSE: "About account",
  LEADS: "Leads",
};

export const LEFT_SIDE_OPTIONS = [
  createOption(DIALER_TOOLKIT_VIEW_OPTIONS.SCRIPTS, true),
];

export const RIGHT_SIDE_OPTIONS = [
  createOption(DIALER_TOOLKIT_VIEW_OPTIONS.NOTEPAD, true),
  createOption(DIALER_TOOLKIT_VIEW_OPTIONS.ACCOUNT_SUMMARY, true),
  createOption(DIALER_TOOLKIT_VIEW_OPTIONS.PITCH, true),
  createOption(DIALER_TOOLKIT_VIEW_OPTIONS.COMPANY_PURPOSE, true),
  createOption(DIALER_TOOLKIT_VIEW_OPTIONS.LEADS, true),
];
