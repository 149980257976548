import { FC, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { clsxMerge } from "shared/lib/helpers";
import {
  useCreateEmailTemplate,
  useUpdateTemplate,
} from "@/modules/email-templates-sidebar/queries";
import {
  CreateEmailTemplateRequestParamsI,
  EmailTemplateI,
  GetEmailGenerationInfoResponseI,
  UpdateEmailTemplateRequestParamsI,
} from "@/api/routes/email";
import { useApi } from "shared/lib/hooks";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { LoadingComponent } from "@/modules/loading";
import { EditTemplate } from "./edit-template";
import { EmailTemplate } from "./template";

interface EmailTemplateWorkspacePropsI {
  className?: string;
  template?: EmailTemplateI;
  emailConfig?: GetEmailGenerationInfoResponseI;
  isEditMode?: boolean;
  setEditMode?: (isEditing: boolean) => void;
  setActiveTemplateId?: (templateId?: string) => void;
}

export const EmailTemplateWorkspace: FC<EmailTemplateWorkspacePropsI> = ({
  className,
  template,
  emailConfig,
  isEditMode,
  setEditMode = () => {},
  setActiveTemplateId = () => {},
}) => {
  const { campaign } = useAccountDetailsContext();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: createTemplateAsync } = useCreateEmailTemplate();
  const { mutateAsync: updateTemplateAsync } = useUpdateTemplate();

  const campaignAttachmentsFetcher = useMemo(
    () =>
      campaign?.id
        ? (api: APII) => api.getCampaignAttachments(campaign.id)
        : null,
    [campaign]
  );

  const [{ data: campaignAttachmentsData }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: campaignAttachmentsFetcher,
    onError: () => toast.error("Error getting available attachments."),
  });

  const handleCreateTemplate = async (
    data: CreateEmailTemplateRequestParamsI
  ) => {
    setLoading(true);
    const resp = await createTemplateAsync(data);

    if (resp.status === 200) {
      setActiveTemplateId(resp?.data?.email_template?.id);
      setEditMode(false);
    }

    setLoading(false);
  };

  const handleUpdateTemplate = async (
    templateId: string,
    data: UpdateEmailTemplateRequestParamsI
  ) => {
    setLoading(true);

    const resp = await updateTemplateAsync({
      templateId: templateId,
      params: data,
    });

    if (resp.status === 200) {
      setEditMode(false);
    }

    setLoading(false);
  };

  const handleCreateOrUpdateTemplate = async (
    data: UpdateEmailTemplateRequestParamsI
  ) => {
    if (template?.id) {
      handleUpdateTemplate(template.id, data);
    } else {
      handleCreateTemplate(data);
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <section className={clsxMerge("px-4 py-7", className)}>
      {/* if `templateId` is undefined, <EditTemplate /> component will be used for creating a new template */}
      {isEditMode && (
        <EditTemplate
          template={template}
          onCancel={() => setEditMode(false)}
          onSave={handleCreateOrUpdateTemplate}
        />
      )}

      {!isEditMode && template && emailConfig && (
        <EmailTemplate
          template={template}
          config={emailConfig}
          campaignAttachments={campaignAttachmentsData?.attachments}
        />
      )}
    </section>
  );
};
