import { AxiosInstance } from "axios";

import { GetAccountHistoryV2ResponseI } from "@/api/glencoco";
import {
  ContactSearchResultItemI,
  DisqualifyAccountOrContactRequestParamsI,
  FindMoreContactsRequestParamsI,
  FindMoreContactsResponseI,
  GetActivityLogResponseI,
  GetContactListsResponseI,
  GetUserAccountDetailsResponseI,
  ReplyAccountContactEmailRequestParamsI,
  SendAccountContactEmailRequestParamsI,
  UpdateAccountNoteResponseI,
} from "@/api/routes/account/interfaces";
import { AccountDispositionNoteI } from "@/modules/pipeline/account-details/interfaces";
import { ProspectDispositionTypeI } from "shared/lib/interfaces/dispositions";

export const AccountAPIEndpoints = (API: AxiosInstance) => ({
  getAccountDetails: (campaignId: string, accountId: string) =>
    API.get<GetUserAccountDetailsResponseI>(
      `v2/campaign/${campaignId}/account/${accountId}`
    ),

  sendAccountEmail: (
    campaignId: string,
    contactId: string,
    params: SendAccountContactEmailRequestParamsI
  ) =>
    API.post(`/v2/campaign/${campaignId}/contact/${contactId}/followup_email`, {
      ...params,
    }),

  replyAccountContactEmail: (
    emailId: string,
    params: ReplyAccountContactEmailRequestParamsI
  ) =>
    API.post(`v2/email/${emailId}/respond`, {
      ...params,
    }),

  getAccountHistoryV2: (
    campaignId: string,
    accountId: string,
    nextToken: string | undefined
  ) =>
    API.post<GetAccountHistoryV2ResponseI>(
      `v2/campaign/${campaignId}/account/${accountId}/call_history`,
      {
        next_token: nextToken,
      }
    ),

  // This endpoint is reused across Add note and Disqualify account modals
  sendAccountDispositionNote: (
    campaignId: string,
    accountId: string,
    params: AccountDispositionNoteI
  ) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/disposition`, {
      notes: params.notes,
      contact_id: params.accountContactId,
      note_type: params.noteType,
    }),

  disqualifyAccountOrContact: (
    campaignId: string,
    accountId: string,
    params: DisqualifyAccountOrContactRequestParamsI
  ) =>
    API.post(`v2/campaign/${campaignId}/account/${accountId}/disqualify`, {
      notes: params.notes,
      contact_id: params.contactId,
      disqualification_disposition: params.disqualificationDisposition,
    }),

  undisqualifyAccount: (campaignId: string, accountId: string) =>
    API.post(`/v1/campaign/${campaignId}/account/${accountId}/undisqualify`),

  editNextTouchTime: (
    campaignId: string,
    accountId: string,
    nextTouchTime: string
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/edit_next_follow_up_time`,
      { next_follow_up_time: nextTouchTime }
    ),

  setUnsetContactAsPrimary: (
    campaignId: string,
    accountId: string,
    contactId: string,
    isPrimary: boolean
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/contact/${contactId}/set_primary`,
      { set: isPrimary }
    ),

  setContactType: (
    campaignId: string,
    accountId: string,
    contactId: string,
    contactType: ProspectDispositionTypeI
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/contact/${contactId}/set_contact_type`,
      { contact_type: contactType }
    ),

  removeListFromAccount: (
    campaignId: string,
    accountId: string,
    listId: string
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/list/${listId}/remove`
    ),

  cancelMeeting: (campaignId: string, accountId: string) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/cancel_meeting`),

  getContactLists: (campaignId: string, contactId: string) =>
    API.get<GetContactListsResponseI>(
      `v1/campaign/${campaignId}/contact/${contactId}/list_memberships`
    ),

  getActivityLog: (activityLogId: string) =>
    API.post<GetActivityLogResponseI>(`v1/activity_log/${activityLogId}`),

  moveAccountToNurture: (
    campaignId: string,
    accountId: string,
    params: {
      contact_id: string;
      next_touch_time: string;
      notes: string;
    }
  ) =>
    API.post(
      `/v2/campaign/${campaignId}/account/${accountId}/move_to_nurture`,
      params
    ),

  updatePropspectDNCnumbers: (
    campaignId: string,
    contactId: string,
    params: { phones: Array<{ phone: string; dnc: boolean }>; notes?: string }
  ) =>
    API.post(
      `/v1/campaign/${campaignId}/contact/${contactId}/phone_dnc_toggle`,
      params
    ),

  updateAccountNote: (campaignId: string, accountId: string, note?: string) =>
    API.post<UpdateAccountNoteResponseI>(
      `/v1/campaign/${campaignId}/account/${accountId}/notepad`,
      {
        note: note || "",
      }
    ),

  findMoreContacts: (
    campaignId: string,
    accountId: string,
    params: FindMoreContactsRequestParamsI
  ) =>
    API.post<FindMoreContactsResponseI>(
      `/v1/campaign/${campaignId}/account/${accountId}/more_contacts`,
      params
    ),

  enrichAndAddContacts: (
    campaignId: string,
    accountId: string,
    contacts: ContactSearchResultItemI[]
  ) =>
    API.post<FindMoreContactsResponseI>(
      `/v1/campaign/${campaignId}/account/${accountId}/enrich_and_add_contacts`,
      { contacts }
    ),

  addNoteToAccount: (
    campaignId: string,
    accountId: string,
    params: { note: string }
  ) =>
    API.post(`/v1/campaign/${campaignId}/account/${accountId}/note`, {
      note: params.note,
    }),
});
