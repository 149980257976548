import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

export function fetchWithTimeout(
  url: string,
  options: any,
  timeout = TIME_INTERVALS_MS.ONE_SECOND * 30,
  externalSignal?: AbortSignal
) {
  const controller = new AbortController();

  const { signal } = controller;
  const preparedSignal = externalSignal
    ? AbortSignal.any([signal, externalSignal])
    : signal;

  const fetchOptions = { ...options, signal: preparedSignal };
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  return fetch(url, fetchOptions).finally(() => clearTimeout(timeoutId));
}
