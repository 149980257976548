import { FC, useEffect, useRef, useState } from "react";
import { Portal } from "react-portal";
import { BellIcon, BellAlertIcon } from "@heroicons/react/24/outline";

import { clsxMerge } from "shared/lib/helpers";
import { DefaultPill } from "shared/ui/pills/default-pill";
import { NotificationsModule } from "@/components/modules/notifications";

import { useGlobalNotificationsContext } from "@/context/notifications-context";
import {
  NOTIFICATIONS_INTRO_MODAL_ID,
  NotificationsIntroModal,
} from "@/modals/notifications-intro-modal";

import { LocalStorage } from "@/helpers/local-storage";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { useOnClickOutside } from "shared/lib/hooks/use-on-click-outside";
import { useDisclosure } from "shared/lib/hooks/use-disclosure";
import { dd } from "@/helpers/datadog";
import { LOG_CATEGORIES } from "@/constants/logs";

interface NotificationsButtonPropsI {
  className?: string;
}

export const NotificationsButton: FC<NotificationsButtonPropsI> = ({
  className,
}) => {
  const { notificationCounters } = useGlobalNotificationsContext();

  const { isOpen, onClose, onToggle } = useDisclosure();
  const [unread, setUnread] = useState(notificationCounters?.unread);

  useEffect(() => {
    setUnread(notificationCounters?.unread || 0);
  }, [notificationCounters?.unread]);

  const trackingOutsideClickRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(trackingOutsideClickRef, () => {
    if (isOpen) {
      onClose();
    }
  });

  useEffect(() => {
    const LS = new LocalStorage();

    if (
      !LS.notificationIntroModalCompletionDate &&
      notificationCounters?.unread
    ) {
      modalHelpers.open(NOTIFICATIONS_INTRO_MODAL_ID);
    }
  }, [notificationCounters?.unread]);

  const handleOnClick = () => {
    dd.rum.log(`${LOG_CATEGORIES.NOTIFICATIONS} - nav menu button clicked`);

    onToggle();
  };

  return (
    <div ref={trackingOutsideClickRef}>
      <label
        tabIndex={10}
        className={clsxMerge(
          "group btn btn-ghost relative p-2 text-white opacity-80",
          "hover:border-[#F88167]/50 hover:bg-[#F88167]/20",
          {
            "btn-active": isOpen,
            "opacity-100": unread,
          },
          className
        )}
        onClick={handleOnClick}
      >
        {unread ? (
          <>
            <BellAlertIcon className="w-7 group-hover:animate-wiggle" />
            <DefaultPill
              className={clsxMerge(
                "absolute -right-1 top-6 min-w-[10px] py-[2px]",
                "animate-pulse-alt",
                "border-white",
                unread >= 10 ? "px-2" : "px-1"
              )}
            >
              {unread}
            </DefaultPill>
          </>
        ) : (
          <BellIcon className="w-7 group-hover:animate-wiggle" />
        )}
      </label>

      <div tabIndex={10} className="relative top-2">
        {isOpen && (
          <NotificationsModule
            className={clsxMerge(
              "dropdown-content absolute right-0 animate-fadein",
              "max-w-[500px] lg:max-w-[700px]"
            )}
            onClose={onClose}
          />
        )}
      </div>

      {/* For testing purposes */}
      {/* <Portal>
        <NotificationsModule className="dropdown-content fixed right-[20px] top-[90px] animate-fadein" />
      </Portal> */}

      {/*@ts-ignore*/}
      <Portal>
        <NotificationsIntroModal />
      </Portal>
    </div>
  );
};
