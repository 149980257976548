import DomPurifier from "dompurify";

// our render-html component only allows a few tags and attributes (for security reasons when dangerously inserting HTML into our DOM)
// this custom config extends it to allow necessary configs for the HTML in the pitch section
// it is added as a prop to the render-html component because the default list should be kept minimal, where possible, to avoid security issues
export const HTML_LINKS_DOM_PURIFIER_CONFIG: DomPurifier.Config = {
  FORCE_BODY: true,
  ALLOWED_TAGS: ["style", "path", "svg", "circle"],
  ALLOWED_ATTR: [
    "class",
    "width",
    "height",
    "fill-rule",
    "fill",
    "d",
    "clip-rule",
    "cx",
    "cy",
    "r",
    "viewbox",
  ],
};
