/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { css, keyframes } from "@emotion/react";
import inRange from "lodash/inRange";

import { Keyframes } from "@emotion/serialize";
import { useFrequentlyChangingCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useInterval } from "shared/lib/hooks/use-interval";

interface LeadAvatarAudioVisualizerPropsI {
  isCallInProgress?: boolean;
  isInnerCircles?: boolean;
  className?: string;
}

const pulse = keyframes`
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

const outerCircle1 = keyframes`
  0% {
    border-color: transparent;
  }
  25% {
    border-color: transparent;
  }
  75% {
    transform: scale(1.2);
    border: 1vmin solid rgba(255, 255, 255, 0.4);
  }
  100% {
    transform: scale(1.7);
    border: 1vmin solid rgba(255, 255, 255, 0.4);
    border-color: transparent;
  }
`;

const outerCircle2 = keyframes`
  0% {
    transform: scale(1.3);
    border: 1vmin solid rgba(255, 255, 255, 0.4);
  }
  50% {
    transform: scale(1.7);
    border-color: transparent;
  }
  75% {
    border-color: transparent;
  }
  100% {
    border-color: transparent;
  }
`;

const ripple1 = (scale = 1) => keyframes`
  0% {
    transform: scale(${6 * scale});
    opacity: 100%;
  }
  25% {
    transform: scale(${6 * scale});
    opacity: 100%;
  }
  50% {
    transform: scale(${6 * scale});
    opacity: 100%;
  }
  75% {
    transform: scale(${6 * scale});
    opacity: 50%;
  }
  100% {
    transform: scale(0);
    opacity: 0%;
  }
`;

const ripple2 = (scale = 1) => keyframes`
  0% {
    transform: scale(0);
    opacity: 100%;
  }
  25% {
    transform: scale(${4 * scale});
    opacity: 100%;
  }
  50% {
    transform: scale(${4 * scale});
    opacity: 100%;
  }
  75% {
    transform: scale(${4 * scale});
    opacity: 50%;
  }
  100% {
    transform: scale(0);
    opacity: 0%;
  }
`;

const ripple3 = (scale = 1) => keyframes`
  0% {
    transform: scale(0);
    opacity: 100%;
  }
  25% {
    transform: scale(0);
    opacity: 100%;
  }
  50% {
    transform: scale(${3 * scale});
    opacity: 100%;
  }
  75% {
    transform: scale(${3 * scale});
    opacity: 50%;
  }
  100% {
    transform: scale(0);
    opacity: 0%;
  }
`;

const ripple4 = (scale = 1) => keyframes`
  0% {
    transform: scale(0);
    opacity: 100%;
  }
  25% {
    transform: scale(0);
    opacity: 100%;
  }
  50% {
    transform: scale(0);
    opacity: 100%;
  }
  75% {
    transform: scale(${2 * scale});
    opacity: 50%;
  }
  100% {
    transform: scale(0);
    opacity: 0%;
  }
`;

const ripple = (rippleKeyframes: Keyframes) => css`
  animation: ${pulse} 1.5s linear infinite,
    ${rippleKeyframes} 1.3s ease-out infinite;
`;

const rippleClassName = clsx("absolute", "h-20 w-20 rounded-full bg-white/10");

const useDebouncedOutputVolume = (intervalMs = 400) => {
  const ref = useRef(0);
  const { outputVol } = useFrequentlyChangingCallingContext();
  const [debouncedVolume, setDebouncedVolume] = useState<number>(0);

  useEffect(() => {
    ref.current = outputVol;
  }, [outputVol]);

  useInterval(() => {
    setDebouncedVolume(ref.current);
  }, intervalMs);

  return debouncedVolume;
};

const DELTA_VALUES = {
  QUIET: 0.4,
  MEDIUM: 0.5,
  GOOD: 1,
  EXTRA: 1.2,
} as const;

export const LeadAvatarVolumeVisualizer: FC<
  LeadAvatarAudioVisualizerPropsI
> = ({ isCallInProgress = false, className, isInnerCircles = true }) => {
  const [delta, setDelta] = useState<number>(DELTA_VALUES.QUIET);
  const debouncedVolume = useDebouncedOutputVolume();

  const ripples = useMemo(() => {
    return isInnerCircles
      ? [
          ripple(ripple1(delta)),
          ripple(ripple2(delta)),
          ripple(ripple3(delta)),
          ripple(ripple4(delta)),
        ]
      : [];
  }, [delta, isInnerCircles]);

  useEffect(() => {
    if (isCallInProgress) {
      const { QUIET, MEDIUM, GOOD, EXTRA } = DELTA_VALUES;

      switch (true) {
        case inRange(debouncedVolume, 0.3, 0.6):
          setDelta(MEDIUM);
          break;
        case inRange(debouncedVolume, 0.61, 0.9):
          setDelta(GOOD);
          break;
        case debouncedVolume > 0.9:
          setDelta(EXTRA);
          break;
        default:
          setDelta(QUIET);
      }
    }
  }, [isCallInProgress, debouncedVolume]);

  return (
    <div className={clsx("z-[-1]", className)}>
      <div
        className={clsx(
          "flex items-center justify-center",
          "h-30 w-30",
          "rounded-full",
          "bg-[#282828]"
        )}
        css={
          isCallInProgress &&
          css`
            animation: ${pulse} 1.5s linear infinite;
          `
        }
      >
        {isCallInProgress && (
          <Fragment>
            {/* Outer circle 1 */}
            <div
              className={clsx(
                "absolute",
                "h-[100px] w-[100px] rounded-full bg-transparent"
              )}
              css={css`
                animation: ${outerCircle1} 1.5s linear infinite;
              `}
            />
            {/* Outer circle 2 */}
            <div
              className={clsx(
                "absolute",
                "h-[100px] w-[100px] rounded-full bg-transparent"
              )}
              css={css`
                animation: ${outerCircle2} 1.5s linear infinite;
              `}
            />

            {ripples.map((rCss, i) => (
              <div
                key={i}
                className={clsx(rippleClassName, "transition-all duration-300")}
                css={rCss}
              />
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};
