import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";
import { TextArea as TextAreaWrapper } from "shared/ui/ae-user-input";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

import throttle from "lodash/throttle";
import { glencocoClientAPI } from "@/api/glencoco";
import { LoadingComponent } from "@/components/modules/loading";
import toast from "react-hot-toast";
import { UpdateAccountNoteResponseI } from "@/api/routes/account/interfaces";
import { DayJs } from "shared/lib/helpers/date";
import {
  DATE_FORMAT_ONLY_TIME,
  DATE_FORMAT_ONLY_TIME_WITH_SECONDS,
  DATE_FORMAT_WITHOUT_TIME,
} from "shared/lib/constants/time";
import { SessionStorage } from "@/helpers/session-storage";

const TextArea = TextAreaWrapper();

const Header = ({
  isLoading,
  updatedAt: _updatedAt,
}: {
  isLoading?: boolean;
  updatedAt?: string;
}) => {
  const updatedAt = useMemo(() => {
    const isAfter1Minute = DayJs().isAfter(_updatedAt, "minute");
    const isAfter1Hour = DayJs().isAfter(_updatedAt, "hour");
    const isAfter1Day = DayJs().isAfter(_updatedAt, "day");

    const FORMAT = isAfter1Day
      ? DATE_FORMAT_WITHOUT_TIME
      : isAfter1Hour
      ? DATE_FORMAT_ONLY_TIME
      : isAfter1Minute
      ? DATE_FORMAT_ONLY_TIME
      : DATE_FORMAT_ONLY_TIME_WITH_SECONDS;

    return DayJs(_updatedAt).format(FORMAT);
  }, [_updatedAt]);
  return (
    <div className="flex w-full items-center justify-between">
      <h6>Notepad</h6>

      <div className="text-end">
        {isLoading && (
          <LoadingComponent
            delay={300}
            className="absolute right-4 top-[6px] animate-fadein"
          />
        )}{" "}
        {!isLoading && _updatedAt && (
          <span className="animate-fadein text-black/60 typography-body-4">
            Auto saved at {updatedAt}
          </span>
        )}
      </div>
    </div>
  );
};

export const NotepadSection: FC<
  PropsWithClassNameI & { inputClassName?: string }
> = ({ className, inputClassName }) => {
  const { accountNote, campaign, account, setAccountNote } =
    useAccountDetailsContext();
  const [note, setNote] = useState<string | undefined>(accountNote?.note);

  const [updatedAt, setUpdatedAt] = useState<string | undefined>(
    accountNote?.updated_at
  );

  const [isLoading, setIsLoading] = useState(false);

  const updateAccountNote = (e: any) => {
    (async () => {
      setIsLoading(true);
      const API = glencocoClientAPI();

      const _note = e.target.value;

      const UpdateAccountNoteReponse = await API.updateAccountNote(
        campaign?.id as string,
        account?.id as string,
        _note
      ).catch((e) => e);

      if (UpdateAccountNoteReponse?.status !== 200) {
        toast.error("Failed to update account note");
        setIsLoading(false);
        return;
      }

      const data = UpdateAccountNoteReponse?.data as UpdateAccountNoteResponseI;

      setUpdatedAt(data?.account_note?.updated_at);
      setAccountNote?.(data?.account_note);
      setIsLoading(false);
    })();
  };

  const throttledOnChange = useCallback(throttle(updateAccountNote, 3000), [
    campaign?.id,
    account?.id,
  ]);

  const handleOnChange = (e: any) => {
    const SS = new SessionStorage();
    SS.setAccountDetailsNote(account?.id as string, e.target.value);
    setNote?.(e.target.value);

    throttledOnChange(e);
  };

  /**
   * Load note from session storage if it exists.
   *
   * We need to do this because the note is not updated immediately, so we need to
   * force a re-render when the note is updated on initial load.
   *
   * It happens when user navigate between tabs immediately after
   * finishing typing.
   */
  useEffect(() => {
    if (account?.id) {
      const SS = new SessionStorage();

      const note = SS.getAccountDetailsNote(account?.id as string);

      if (typeof note === "string") {
        setNote?.(note);
      }
    }
  }, [account?.id]);

  return (
    <AccountDetailsSectionContainer
      title={<Header isLoading={isLoading} updatedAt={updatedAt} />}
      className={className}
      headerClassName={clsxMerge("bg-[#FFEE81]")}
      contentClassName="bg-[#FFFCEE] px-0 py-0"
    >
      <TextArea
        name="notes"
        className="mb-0"
        inputProps={{
          value: note,
          onChange: handleOnChange,
        }}
        inputClassName={clsxMerge(
          "[field-sizing:content]",
          "max-h-[30vh] min-h-[50px] w-full overflow-auto",
          "bg-transparent",
          "px-4 pt-4",
          "border-none outline-none rounded-none",
          "typography-body-4",
          inputClassName
        )}
        placeholder="Type your note here..."
      />
    </AccountDetailsSectionContainer>
  );
};
