import { StreamAPIConfig } from "@/api/stream";
import { AccountAIMetaTypeI } from "shared/lib/interfaces/account";

export interface TriggerAccountMetaWebAIRequestParamsI {
  update_type: AccountAIMetaTypeI;
}

function fetchWithTimeout(url: string, options: any, timeout = 30000) {
  const controller = new AbortController();
  const { signal } = controller;
  const fetchOptions = { ...options, signal };

  const timeoutId = setTimeout(() => controller.abort(), timeout);

  return fetch(url, fetchOptions).finally(() => clearTimeout(timeoutId));
}

export const AccountMetaWebAIAPIEndpoints = (API: StreamAPIConfig) => ({
  triggerAccountMetaUpdate: async (
    campaignId: string,
    accountId: string,
    params: TriggerAccountMetaWebAIRequestParamsI
  ) => {
    const response = await fetchWithTimeout(
      `${API.baseURL}/campaign/${campaignId}/account/${accountId}/account_meta_update`,
      {
        method: "POST",
        headers: API.headers,
        body: JSON.stringify(params),
      },
      API.timeout
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    return response;
  },
  // ... other endpoints
});
