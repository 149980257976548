import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import { WSCallCopilotMessageCompetitorI } from "@/interfaces/events";

/**
 * Add socket interface for call copilot
 */
export const callCopilotSocketEventHandler = {
  messageReceived: (data: WSCallCopilotMessageCompetitorI) => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.WEBSOCKETS.CALL_COPILOT.MESSAGE_RECEIVED,
      data,
    });
  },
};
