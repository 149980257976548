import { CallCopilotSectionHeader } from "./header";
import { useCallCopilot } from "@/modules/pipeline/account-details/hooks/use-call-copilot";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";
import { clsxMerge } from "shared/lib/helpers";
import { useEffect } from "react";
import { useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Badge } from "shared/ui/shadcn/badge";

export const CallCopilotSection = () => {
  const { content } = useCallCopilot();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [content]);

  //TODO testing only
  // useEffect(() => {
  //   setContent?.([
  //     {
  //       data: {
  //         competitor: "Salesforce",
  //         competitor_description:
  //           "Salesforce is a leading CRM platform that offers cloud-based solutions for sales, service, marketing and analytics. They are known for their robust enterprise features and extensive app marketplace.",
  //         competitor_handle:
  //           "Key differentiators:\n- More expensive but offers comprehensive functionality\n- Strong focus on large enterprise customers\n- Extensive customization capabilities\n- Steeper learning curve for users",
  //       },
  //       type: "competitor_copilot",
  //     },
  //     {
  //       data: {
  //         competitor: "HubSpot",
  //         competitor_description:
  //           "HubSpot is an inbound marketing and sales platform that provides a full suite of marketing automation, CRM, and sales tools. They are particularly known for their content marketing and lead generation capabilities.",
  //         competitor_handle:
  //           "Key differentiators:\n- Strong focus on inbound marketing methodology\n- More affordable than enterprise solutions\n- User-friendly interface with gentle learning curve\n- Excellent marketing automation features\n- Free tier available for small businesses",
  //       },
  //       type: "competitor_copilot",
  //     },
  //   ]);
  // }, []);

  return (
    <AccountDetailsSectionContainer
      title={<CallCopilotSectionHeader content={content} />}
      headerClassName="bg-gradient-to-r from-[#F88167] to-[#FB56B4] text-white"
      className={clsxMerge({
        "overflow-hidden rounded-md": !content?.length,
      })}
      contentClassName={clsxMerge({
        "p-0": !content?.length,
        "p-2 pb-0": content?.length,
      })}
    >
      <div
        className={clsxMerge("overflow-y-auto transition-all duration-300", {
          "max-h-[200px]": !!content?.length,
          "max-h-[0px]": !content?.length,
        })}
        ref={ref}
      >
        {content?.map((item, i) => (
          <div
            key={content?.length - i}
            className={clsxMerge(
              "mb-2 animate-fadein text-[#71717A]",
              "border border-[#ECECEC] bg-[#F8F8F8]",
              "rounded-md px-2 py-1"
            )}
          >
            <div className="mb-1 flex items-center gap-x-1">
              <Badge className="typography-body-4-medium">
                {item.data?.competitor}
              </Badge>
              <span className="text-black typography-body-4-semibold">
                was mentioned
              </span>
              <span className="italic text-[#71717A] typography-body-4">
                (Competitor detected)
              </span>
            </div>

            {item.data?.competitor_description && (
              <ReactMarkdown
                // components={{ p: CustomMarkdownParagraph }}
                className="mb-3 typography-body-5"
                remarkPlugins={[remarkGfm]}
              >
                {item.data?.competitor_description}
              </ReactMarkdown>
            )}

            {item.data?.competitor_handle && (
              <ReactMarkdown
                // components={{ p: CustomMarkdownParagraph }}
                className="text-black typography-body-4"
                remarkPlugins={[remarkGfm]}
              >
                {item.data?.competitor_handle}
              </ReactMarkdown>
            )}
          </div>
        ))}
      </div>
    </AccountDetailsSectionContainer>
  );
};
