import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { PerformanceFilterI } from "shared/lib/interfaces/performance";
import { preparePerformanceFiltersForAPICall } from "shared/lib/helpers/performance";

import { useApiClient } from "@/context/api-client";
import { PerformanceDashboardQueryKeys } from "@/api/routes/performance/queries/keys";

export const useFetchPerformanceDashboardMetrics = (
  filters?: PerformanceFilterI
) => {
  const api = useApiClient();

  const performanceDashboardApi = useQuery({
    enabled: !!filters && !!filters.selectedCampaignId && !!filters.timePeriod,
    queryKey: PerformanceDashboardQueryKeys.dashboardMetrics(filters),
    queryFn: () => {
      const { campaignId, startDate, endDate } =
        preparePerformanceFiltersForAPICall(
          // Because of the enabled prop, we can safely assert the filters as
          // PerformanceFilterI
          filters as PerformanceFilterI
        );

      return api.fetchPerformanceDashboard(
        campaignId as string,
        startDate,
        endDate
      );
    },
  });

  useEffect(() => {
    if (performanceDashboardApi.isError) {
      toast.error(
        "There was an error fetching your performance data. Please, reload the page."
      );
    }
  }, [performanceDashboardApi.isError]);

  return performanceDashboardApi;
};

export const useFetchPerformanceCampaignStats = (campaignId?: string) => {
  const api = useApiClient();

  const performanceCampaignStatsApi = useQuery({
    enabled: !!campaignId,
    queryKey: PerformanceDashboardQueryKeys.campaignStats(campaignId),
    queryFn: () => api.fetchPerformanceCampaignStats(campaignId as string),
  });

  useEffect(() => {
    if (performanceCampaignStatsApi.isError) {
      toast.error(
        "Failed to fetch performance stats by campaign. Please try to reload the page."
      );
    }
  }, [performanceCampaignStatsApi.isError]);

  return performanceCampaignStatsApi;
};

export const useFetchLifetimePerformance = () => {
  const api = useApiClient();

  const lifetimePerformanceApi = useQuery({
    queryKey: PerformanceDashboardQueryKeys.lifetimePerformance(),
    queryFn: api.fetchLifetimePerformance,
  });

  useEffect(() => {
    if (lifetimePerformanceApi.isError) {
      toast.error(
        "Failed to fetch lifetime performance data. Please try to reload the page."
      );
    }
  }, [lifetimePerformanceApi.isError]);

  return lifetimePerformanceApi;
};

export const useFetchCoachingMessagesByCampaignQuery = (
  campaignId?: string
) => {
  const apiClient = useApiClient();

  const query = useInfiniteQuery({
    enabled: !!campaignId,
    queryKey: PerformanceDashboardQueryKeys.callCoachingByCampaign(campaignId),
    queryFn: async ({ pageParam }) => {
      const response = await apiClient.coaching.getCoachingMessagesByCampaign(
        campaignId as string,
        pageParam
      );

      if (response instanceof AxiosError) {
        throw new Error("Failed to fetch coaching messages by campaign.");
      }

      return response.data;
    },
    getNextPageParam: (lastPage) => lastPage?.next_token,
    initialPageParam: undefined as string | undefined,
    select: (data) => data.pages.flatMap((page) => page.coaching_messages),
  });

  useEffect(() => {
    if (query.isError) {
      toast.error("Failed to fetch coaching messages by campaign.");
    }
  }, [query.isError]);

  return query;
};

export type UseFetchCoachingMessagesByCampaignApiI = ReturnType<
  typeof useFetchCoachingMessagesByCampaignQuery
>;

export const useFetchTodayPerformance = () => {
  const api = useApiClient();

  const todayPerformanceApi = useQuery({
    queryKey: PerformanceDashboardQueryKeys.todayPerformance(),
    queryFn: api.fetchTodayPerformance,
  });

  useEffect(() => {
    if (todayPerformanceApi.isError) {
      toast.error(
        "Failed to fetch today's performance data. Please try to re-open the widget or reload the page."
      );
    }
  }, [todayPerformanceApi.isError]);

  return todayPerformanceApi;
};

export const useFetchGoalTrackerMetrics = () => {
  const api = useApiClient();

  const goalTrackerProgressApi = useQuery({
    queryKey: PerformanceDashboardQueryKeys.goalTrackerProgress(),
    queryFn: api.fetchGoalTrackerMetrics,
  });

  useEffect(() => {
    if (goalTrackerProgressApi.isError) {
      toast.error(
        "Failed to fetch goal tracker metrics data. Please try to reload the page."
      );
    }
  }, [goalTrackerProgressApi.isError]);

  return goalTrackerProgressApi;
};

export const useFetchFirstCallTime = (enabled: boolean) => {
  const api = useApiClient();

  const firstCallTimeApi = useQuery({
    enabled,
    queryKey: PerformanceDashboardQueryKeys.firstTimeQuery(),
    queryFn: () => api.fetchFirstCallTime(),
  });

  useEffect(() => {
    if (firstCallTimeApi.isError) {
      toast.error(
        "Failed to fetch first call time data. Please try to reload the page."
      );
    }
  }, [firstCallTimeApi.isError]);

  return firstCallTimeApi;
};
