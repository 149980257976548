import { useMemo } from "react";

import { useFetchGoalTrackerMetrics } from "@/api/routes/performance/queries/queries";
import { useUpdateGoalTrackerMetrics } from "@/api/routes/performance/queries/mutations";

export const useGoalTracker = () => {
  const { isFetching, data } = useFetchGoalTrackerMetrics();

  const {
    isPending: isUpdatingGoalTrackerMetrics,
    mutateAsync: updateGoalTrackerMetrics,
  } = useUpdateGoalTrackerMetrics();

  const { weeklyQualifiedMeetingsTarget, weeklyQualifiedMeetings } = useMemo(
    () => ({
      weeklyQualifiedMeetingsTarget:
        data?.data?.performance?.qualified_meetings ?? 0,
      weeklyQualifiedMeetings: data?.data?.this_weeks_qualified_meetings ?? 0,
    }),
    [isFetching, data]
  );

  const progress = useMemo(() => {
    if (isFetching) {
      return 0;
    }

    const percent = Math.round(
      (weeklyQualifiedMeetings / weeklyQualifiedMeetingsTarget) * 100
    );

    return percent > 100 ? 100 : percent;
  }, [isFetching, weeklyQualifiedMeetings, weeklyQualifiedMeetingsTarget]);

  return {
    isFetching,
    progress,
    weeklyQualifiedMeetingsTarget,
    weeklyQualifiedMeetings,

    isUpdatingGoalTrackerMetrics,
    updateGoalTrackerMetrics,
  };
};
