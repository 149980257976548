import { FC } from "react";
import { LucideIcon, LucideProps } from "lucide-react";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

interface LucideIconWithGradientPropsI
  extends LucideProps,
    PropsWithClassNameI {
  icon: LucideIcon;
  startColor: string;
  stopColor: string;
}

type GenericLucideIconWithGradientPropsI = Omit<
  LucideIconWithGradientPropsI,
  "startColor" | "stopColor"
>;

export const LucideIconWithGradient: FC<LucideIconWithGradientPropsI> = ({
  className,
  icon: Icon,
  startColor,
  stopColor,
  size = 16,
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
  >
    <defs>
      <linearGradient id="lucideIconGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor={startColor} />
        <stop offset="100%" stopColor={stopColor} />
      </linearGradient>
    </defs>

    <Icon
      color="url(#lucideIconGradient)"
      size={size}
      className={className}
      {...rest}
    />
  </svg>
);

export const LucideIconWithAIGradient: FC<
  GenericLucideIconWithGradientPropsI
> = (props) => (
  <LucideIconWithGradient {...props} startColor="#ff00cc" stopColor="#3333ff" />
);
