import { PropsWithChildren } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

export interface AccountDetailsScriptProps
  extends PropsWithClassNameI,
    PropsWithChildren {
  onEdit?: () => void;
}

const AccountDetailsScriptActions = ({
  onEdit,
}: Pick<AccountDetailsScriptProps, "onEdit">) => {
  return (
    <div className="flex items-center gap-2">
      <button type="button" className="ae-link" onClick={onEdit}>
        Edit
      </button>
    </div>
  );
};

export const AccountDetailsScript = ({
  className,
  children,
  onEdit = () => {},
}: AccountDetailsScriptProps) => {
  return (
    <AccountDetailsSectionContainer
      title={
        <p>
          Script{" "}
          {/* <span className="gradient-text typography-body-4 italic">
            + Glen AI call copilot
          </span> */}
        </p>
      }
      actions={<AccountDetailsScriptActions onEdit={onEdit} />}
      className={clsxMerge("border-black", className)}
    >
      {children}
    </AccountDetailsSectionContainer>
  );
};
