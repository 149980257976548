import { AxiosInstance } from "axios";

import { StreamAPIConfig } from "@/api/stream";
import { EmailWebAIAPIEndpoints } from "@/api/routes/email-webai";
import { AccountMetaWebAIAPIEndpoints } from "@/api/routes/account/meta-webai";
import { getAuthToken } from "@/lib/auth/token";

export interface APII {
  Instance: AxiosInstance;
}

export const webAIClientStreamAPI = () => {
  const authToken = getAuthToken();

  const API: StreamAPIConfig = {
    baseURL: process.env.NEXT_PUBLIC_AI_API_DOMAIN ?? "",
    timeout: process.env.NEXT_PUBLIC_ENV === "production" ? 20000 : 60000,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  // Since Fetch doesn't have interceptors, we can create a wrapper function
  // to apply any request/response transformations or error handling
  // For example, you can create a custom fetch function

  return {
    ...EmailWebAIAPIEndpoints(API),
    ...AccountMetaWebAIAPIEndpoints(API),
  };
};
