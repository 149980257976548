import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { LOG_CATEGORIES } from "@/constants/logs";
import { dd } from "@/helpers/datadog";

import { ListMemberUpdateMessageI } from "@/interfaces/list-dialing";
import { EventHandler, useEffect, useState } from "react";
import { DIALER_LIST_DIALING_STATUS } from "shared/lib/constants/dialer";

export type CallsMapI = Map<string, ListMemberUpdateMessageI>;

interface UseWSListDialingI {
  onIncomingCallStatusUpdate?: (data: ListMemberUpdateMessageI) => void;
}

/**
 * Revalidates the call status based on Twilio events and connection guarantees.
 *
 * Since we cannot fully rely on the backend's "Connected" status for the call,
 * there is no 100% guarantee that a lead marked as "Connected" will trigger
 * a Twilio event for an incoming message. To handle this, we treat the "Connected"
 * status as "Dialing" until we receive a Twilio incoming message event.
 *
 * @function StatusRevalidation
 * @param {ListMemberUpdateMessageI|null|undefined} prevCall - The previous call state.
 * @param {ListMemberUpdateMessageI} newCall - The updated call state from the backend.
 * @returns {ListMemberUpdateMessageI} - The revalidated call status, setting "Connected"
 *                                       as "Dialing" if no incoming message event is detected.
 */
const StatusRevalidation = (
  prevCall: ListMemberUpdateMessageI | null | undefined,
  newCall: ListMemberUpdateMessageI
): ListMemberUpdateMessageI => {
  if (!prevCall) return newCall;

  if (
    prevCall.status === DIALER_LIST_DIALING_STATUS.DIALING &&
    newCall.status === DIALER_LIST_DIALING_STATUS.CONNECTED
  )
    return { ...newCall, status: DIALER_LIST_DIALING_STATUS.DIALING };

  return newCall;
};

export const useWSListDialing = ({
  onIncomingCallStatusUpdate,
}: UseWSListDialingI = {}) => {
  const [isListenWS, setIsListenWS] = useState(false);

  const [calls, setCalls] = useState<CallsMapI>(new Map());

  const handleIncomingCallStatusUpdate = (
    data: CustomEvent<ListMemberUpdateMessageI>
  ) => {
    const dialingStateData = data.detail;

    dd.log(`${LOG_CATEGORIES.DIALER_LIST}[WS][${dialingStateData.status}] `, {
      data: dialingStateData,
    });

    /**
     * Add or update the call status in the calls map
     * */
    setCalls((prevCalls) => {
      const newCalls = new Map(prevCalls);

      const callState = StatusRevalidation(
        prevCalls?.get(dialingStateData.membership_id),
        dialingStateData
      );

      dd.log(
        `${LOG_CATEGORIES.DIALER_LIST}[WS][${dialingStateData.status}] - revalidated `,
        {
          data: callState,
        }
      );

      newCalls.set(dialingStateData.membership_id, callState);

      return newCalls;
    });

    onIncomingCallStatusUpdate?.(dialingStateData);
  };

  const clear = () => {
    setCalls(new Map());
  };

  useEffect(() => {
    if (isListenWS)
      window.document.addEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.CALL_STATUS_UPDATE,
        handleIncomingCallStatusUpdate as EventHandler<any>
      );
    else
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.CALL_STATUS_UPDATE,
        handleIncomingCallStatusUpdate as EventHandler<any>
      );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.CALL_STATUS_UPDATE,
        handleIncomingCallStatusUpdate as EventHandler<any>
      );
    };
  }, [isListenWS]);

  return {
    calls,
    setCalls,
    clear,

    isListenWS,
    setIsListenWS,
  };
};
