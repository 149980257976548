import React, { FC, useRef } from "react";
import clsx from "clsx";
import { STAR_IMG_BG_CLASSNAME } from "shared/lib/constants/ui";
import { CloseIcon } from "shared/ui/icons";
import { Portal } from "react-portal";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { LocalStorage } from "@/helpers/local-storage";

interface NotificationsIntroModalPropsI {
  modalId?: string;
  onClose?: () => void;
}

export const NOTIFICATIONS_INTRO_MODAL_ID = "notifications-intro-modal";

export const NotificationsIntroModal: FC<NotificationsIntroModalPropsI> = ({
  modalId = NOTIFICATIONS_INTRO_MODAL_ID,
  onClose,
}) => {
  const checkboxRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClose = () => {
    checkboxRef?.current?.click();
    onClose?.();
  };

  useEffectOnce(() => {
    const LS = new LocalStorage();

    setTimeout(() => {
      LS.notificationIntroModalCompletionDate = new Date().toString();
    }, 100);
  });

  return (
    // @ts-ignore
    <Portal>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxRef}
      />

      <label htmlFor={modalId} className="modal">
        <label htmlFor="">
          <div className="modal-box relative min-w-[610px] rounded-[20px] p-0">
            <CloseIcon
              className="absolute right-4 top-4 h-6 w-6 cursor-pointer text-white"
              onClick={handleClose}
            />

            <div
              className={clsx(
                "h-[278px]",
                "bg-cover bg-center bg-no-repeat",
                "flex items-center justify-center",
                STAR_IMG_BG_CLASSNAME
              )}
            >
              <img
                width={378}
                className="relative top-5 m-auto"
                src="https://glencoco-assets.s3.amazonaws.com/platform/notifications/notifications-intro.png"
                alt=""
              />
            </div>

            <div className="flex flex-col p-8">
              <h3 className="typography-h1 mb-6">
                Your notifications just got an upgrade
              </h3>

              <p className="typography-body text-black/60">
                With global notifications, access, understand and take action.
                We’ve consolidated your campaign application statuses, missed
                calls, automated email responses and opens, account and AE
                messages and meeting feedback.
              </p>

              <div className="mt-4 flex justify-end">
                <button
                  className="btn-ae-default w-[190px] rounded-full"
                  onClick={handleClose}
                >
                  Got it!
                </button>
              </div>
            </div>
          </div>
        </label>
      </label>
    </Portal>
  );
};
