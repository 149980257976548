export const DISPOSITIONS = {
  TENTATIVE_INTEREST: "tentative_interest",
  NO_PICK_UP: "no_pick_up",
  NOT_INTERESTED: "not_interested",
  NOT_QUALIFIED: "not_qualified",
  BAD_DATA: "bad_data_dnc",
  OTHER: "other",
  BOOKED_MEETING: "booked_meeting",
  CALLBACK_BOOKED: "callback_booked",
  AE_NOTE: "ae_note",
  CANCELLED_MEETING: "cancelled_meeting",
  ACCOUNT_DNC: "account_dnc",
  MEETING_NO_SHOW: "meeting_no_show",
  MEETING_RESCHEDULED: "meeting_rescheduled",
  MEETING_NOT_QUALIFIED: "meeting_not_qualified",
  MEETING_QUALIFIED: "meeting_qualified",
} as const;

export const DISPOSITIONS_STRING_MAP = {
  [DISPOSITIONS.BOOKED_MEETING]: "Booked meeting",
  [DISPOSITIONS.TENTATIVE_INTEREST]: "Tentative interest",
  [DISPOSITIONS.NO_PICK_UP]: "No pick up",
  [DISPOSITIONS.CALLBACK_BOOKED]: "Callback booked",
  [DISPOSITIONS.NOT_INTERESTED]: "Not interested",
  [DISPOSITIONS.NOT_QUALIFIED]: "Not qualified",
  [DISPOSITIONS.BAD_DATA]: "Bad data / do not call",
  [DISPOSITIONS.OTHER]: "Other",
  [DISPOSITIONS.AE_NOTE]: "AE Note",
  [DISPOSITIONS.CANCELLED_MEETING]: "Cancelled meeting",
  [DISPOSITIONS.ACCOUNT_DNC]: "Bad data / do not call",
  [DISPOSITIONS.MEETING_NO_SHOW]: "No show meeting",
  [DISPOSITIONS.MEETING_RESCHEDULED]: "Rescheduled meeting",
  [DISPOSITIONS.MEETING_NOT_QUALIFIED]: "Not qualified meeting",
  [DISPOSITIONS.MEETING_QUALIFIED]: "Qualified meeting",
} as const;

const DISPOSITIONS_STRING_MAP_V2_BASE = {
  [DISPOSITIONS.BOOKED_MEETING]: "Booked meeting",
  [DISPOSITIONS.TENTATIVE_INTEREST]: "Tentative interest",
  [DISPOSITIONS.NO_PICK_UP]: "No pick up",
  [DISPOSITIONS.CALLBACK_BOOKED]: "Callback booked",
  [DISPOSITIONS.NOT_INTERESTED]: "Not interested",
  [DISPOSITIONS.NOT_QUALIFIED]: "Not qualified",
  [DISPOSITIONS.BAD_DATA]: "Bad data / do not call",
  [DISPOSITIONS.OTHER]: "Other",
  [DISPOSITIONS.AE_NOTE]: "AE Note",
  [DISPOSITIONS.CANCELLED_MEETING]: "Cancelled meeting",
  [DISPOSITIONS.ACCOUNT_DNC]: "Bad data / do not call",
  [DISPOSITIONS.MEETING_NO_SHOW]: "No show",
  [DISPOSITIONS.MEETING_RESCHEDULED]: "Rescheduled meeting",
};

export const DISPOSITIONS_STRING_MAP_V2 = {
  ...DISPOSITIONS_STRING_MAP_V2_BASE,
  [DISPOSITIONS.MEETING_NOT_QUALIFIED]: "Not qualified meeting",
  [DISPOSITIONS.MEETING_QUALIFIED]: "Qualified",
} as const;

export const DISPOSITIONS_STRING_MAP_GLENX_V2 = {
  ...DISPOSITIONS_STRING_MAP_V2_BASE,
  [DISPOSITIONS.MEETING_QUALIFIED]: "Opportunity Created",
  [DISPOSITIONS.MEETING_NOT_QUALIFIED]: "Opportunity Not Created",
} as const;

// V2 DISPOSITION_SPECIFICS MAPPINGS
export const DISPOSITION_SPECIFICS = {
  MORE_MATERIALS: "more_materials",
  VOICEMAIL_LEFT: "voicemail_left",
  VOICEMAIL_NOT_LEFT: "voicemail_not_left",
  PITCH_REJECTED_DM: "pitch_rejected_dm",
  INTRO_REJECTED_DM: "intro_rejected_dm",
  GATEKEEPER_REJECTED: "gatekeeper_rejected",
  NOT_QUALIFIED_BUDGET: "not_qualified_budget",
  NOT_QUALIFIED_AUTHORITY: "not_qualified_authority",
  NOT_QUALIFIED_NEED: "not_qualified_need",
  NOT_QUALIFIED_TIMING: "not_qualified_timing",
  BAD_PHONE_NUMBER: "bad_phone_number",
  DO_NOT_CALL: "do_not_call",
  DATA_WRONG: "data_wrong",

  INTRO_REJECTED: "intro_rejected",
  PITCH_REJECTED: "pitch_rejected",
  INTERESTED: "interested",
  QC_NOT_MET_FOR_ACCOUNT: "qc_not_met_for_account",
  OTHER: "other",
  OTHER_FOLLOW_UP: "other_follow_up",
  OTHER_DO_NOT_FOLLOW_UP: "other_do_not_follow_up",
} as const;

export const DISPOSITION_SPECIFICS_MAP = {
  [DISPOSITION_SPECIFICS.MORE_MATERIALS]: "Requested more materials",
  [DISPOSITION_SPECIFICS.VOICEMAIL_LEFT]: "Did not pick up, left Voicemail",
  [DISPOSITION_SPECIFICS.VOICEMAIL_NOT_LEFT]:
    "Did not pick up, did not leave Voicemail",
  [DISPOSITION_SPECIFICS.PITCH_REJECTED_DM]: "Pitch rejected by decision maker",
  [DISPOSITION_SPECIFICS.INTRO_REJECTED_DM]: "Intro rejected by decision maker",
  [DISPOSITION_SPECIFICS.GATEKEEPER_REJECTED]: "Rejected by Gatekeeper",
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_BUDGET]: "Not qualified - Budget",
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_AUTHORITY]: "Not qualified - Authority",
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_NEED]: "Not qualified - Need",
  [DISPOSITION_SPECIFICS.NOT_QUALIFIED_TIMING]: "Not qualified - Timing",
  [DISPOSITION_SPECIFICS.BAD_PHONE_NUMBER]: "Bad phone number",
  [DISPOSITION_SPECIFICS.DO_NOT_CALL]: "Request do not call list",
  [DISPOSITION_SPECIFICS.DATA_WRONG]: "Person data wrong",

  [DISPOSITION_SPECIFICS.INTRO_REJECTED]: "Intro rejected",
  [DISPOSITION_SPECIFICS.PITCH_REJECTED]: "Pitch rejected",
  [DISPOSITION_SPECIFICS.INTERESTED]: "Interested",
  [DISPOSITION_SPECIFICS.QC_NOT_MET_FOR_ACCOUNT]: "QC not met for account",
  [DISPOSITION_SPECIFICS.OTHER]: "Other",
  [DISPOSITION_SPECIFICS.OTHER_FOLLOW_UP]: "Other - Follow up",
  [DISPOSITION_SPECIFICS.OTHER_DO_NOT_FOLLOW_UP]: "Other - Do not folllow up",
} as const;

//V3 Disposition mappings
export const DISPOSITIONS_V3 = {
  INTERESTED: "interested",
  PITCH_REJECTED: "pitch_rejected",
  INTRO_REJECTED: "intro_rejected",
  NOT_QUALIFIED_NEED: "not_qualified_need",
  NOT_QUALIFIED_TIMING: "not_qualified_timing",
  DO_NOT_CALL: "do_not_call",
  QC_NOT_MET_FOR_ACCOUNT: "qc_not_met_for_account",
  OTHER_FOLLOW_UP: "other_follow_up",
  OTHER_DO_NOT_FOLLOW_UP: "other_do_not_follow_up",
};

export const DISPOSITIONS_V3_MAP = {
  [DISPOSITIONS_V3.DO_NOT_CALL]: "Do not call",
  [DISPOSITIONS_V3.QC_NOT_MET_FOR_ACCOUNT]: "QC Not Met for Account",
  [DISPOSITIONS_V3.NOT_QUALIFIED_NEED]: "Not qualified need",
  [DISPOSITIONS_V3.NOT_QUALIFIED_TIMING]: "Not qualified timing",
  [DISPOSITIONS_V3.PITCH_REJECTED]: "Pitch rejected",
  [DISPOSITIONS_V3.INTRO_REJECTED]: "Intro rejected",
  [DISPOSITIONS_V3.INTERESTED]: "Interested",
  [DISPOSITIONS_V3.OTHER_FOLLOW_UP]: "Other - Follow up",
  [DISPOSITIONS_V3.OTHER_DO_NOT_FOLLOW_UP]: "Other - Do not follow up",
};

//GlenX Dispositions
export const DISPOSITIONS_GLENX = {
  MEETING_BOOKED: "meeting_booked",
  ...DISPOSITIONS_V3,
};

export const DISPOSITIONS_GLENX_MAP = {
  [DISPOSITIONS_GLENX.MEETING_BOOKED]: "Meeting booked",
  ...DISPOSITIONS_V3_MAP,
};

export const AH_NOTE_OWNER = {
  CALLER: "caller",
  AE: "ae",
} as const;

/**
 * Note
 * - values are selectable in the dropdown
 * - values are NOT sent to BE
 * - values except NONE and CUSTOM are compatible with dayjs transformations
 */
export const NEXT_TOUCH_TIME_OPTIONS = {
  HOURS_1: "hours_1",
  DAYS_1: "days_1",
  DAYS_2: "days_2",
  DAYS_3: "days_3",
  DAYS_15: "days_15",
  DAYS_30: "days_30",
  DAYS_45: "days_45",
  CUSTOM: "custom",
} as const;

/**
 * Compatibility with Dayjs duration plugin
 * Example: result = dayjs().add(dayjs.duration({'days' : 1}))
 */
export const NEXT_TOUCH_TIME_OPTIONS_DAYJS_DURATION_MAP = {
  [NEXT_TOUCH_TIME_OPTIONS.HOURS_1 as string]: { hours: 1 },
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_1]: { days: 1 },
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_2]: { days: 2 },
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_3]: { days: 3 },
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_15]: { days: 15 },
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_30]: { days: 30 },
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_45]: { days: 45 },
} as const;

export const NEXT_TOUCH_TIME_OPTIONS_MAP = {
  [NEXT_TOUCH_TIME_OPTIONS.HOURS_1]: "1 hour",
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_1]: "1 day",
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_2]: "2 days",
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_3]: "3 days",
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_15]: "15 days",
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_30]: "30 days",
  [NEXT_TOUCH_TIME_OPTIONS.DAYS_45]: "45 days",
  [NEXT_TOUCH_TIME_OPTIONS.CUSTOM]: "Custom",
} as const;

export const PROSPECT_DISPOSITION_TYPES = {
  DECISION_MAKER: "decision_maker",
  INFLUENCER: "influencer",
  GATEKEEPER: "gatekeeper",
  NOT_RELEVANT: "not_relevant",
  BAD_DATA: "bad_data",
  DO_NOT_CALL: "do_not_call",
};

export const PROSPECT_DISPOSITION_TYPES_MAP = {
  [PROSPECT_DISPOSITION_TYPES.DECISION_MAKER]: "Decision maker",
  [PROSPECT_DISPOSITION_TYPES.INFLUENCER]: "Influencer",
  [PROSPECT_DISPOSITION_TYPES.GATEKEEPER]: "Gatekeeper",
  [PROSPECT_DISPOSITION_TYPES.NOT_RELEVANT]: "Not Relevant Persona",
  [PROSPECT_DISPOSITION_TYPES.BAD_DATA]: "Bad data",
  [PROSPECT_DISPOSITION_TYPES.DO_NOT_CALL]: "Do not call",
};

export const ACCOUNT_DISQUALIFY_DISPOSITIONS = {
  ACCOUNT_NO_VIABLE_CONTACTS: "account_no_viable_contacts",
  ACCOUNT_NOT_IN_ICP: "account_not_in_icp",
  ACCOUNT_BAD_DATA: "account_bad_data",
  ACCOUNT_DO_NOT_CALL: "account_do_not_call",
};

export const CONTACT_DISQUALIFY_DISPOSITIONS = {
  CONTACT_WRONG_TITLE: "contact_wrong_title",
  CONTACT_DO_NOT_CALL: "contact_do_not_call",
  CONTACT_BAD_DATA: "contact_bad_data",
};

export const DISQUALIFY_DISPOSITIONS = {
  ...ACCOUNT_DISQUALIFY_DISPOSITIONS,
  ...CONTACT_DISQUALIFY_DISPOSITIONS,
} as const;

export const DISQUALIFY_DISPOSITION_LABELS = {
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_NO_VIABLE_CONTACTS]: "No Viable Contacts",
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_NOT_IN_ICP]:
    "Does not meet Qualification Criteria",
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_BAD_DATA]: "Bad Data",
  [DISQUALIFY_DISPOSITIONS.ACCOUNT_DO_NOT_CALL]: "Do Not Call",

  [DISQUALIFY_DISPOSITIONS.CONTACT_WRONG_TITLE]:
    "Prospect Title does not match customer ICP",
  [DISQUALIFY_DISPOSITIONS.CONTACT_DO_NOT_CALL]: "Do Not Call",
  [DISQUALIFY_DISPOSITIONS.CONTACT_BAD_DATA]: "Bad Data",
};

// Used in Account Executive Survey and in List status pills
export const MEETING_DISPOSITIONS = {
  NO_SHOW: "no_show",
  RESCHEDULED: "reschedule",
  SHOW_UP: "show",
  CANCELED: "canceled",
} as const;

export const LIST_STATUS_MEETING_FEEDBACK = {
  ...MEETING_DISPOSITIONS,
  PENDING: "pending",
  QUALIFIED: "qualified",
  UNQUALIFIED: "unqualified",
};

export const LIST_STATUS_MEETING_FEEDBACK_LABELS = {
  [LIST_STATUS_MEETING_FEEDBACK.PENDING]: "Pending",
  [LIST_STATUS_MEETING_FEEDBACK.SHOW_UP]: "Showed up",
  [LIST_STATUS_MEETING_FEEDBACK.NO_SHOW]: "No show",
  [LIST_STATUS_MEETING_FEEDBACK.RESCHEDULED]: "Rescheduled",
  [LIST_STATUS_MEETING_FEEDBACK.CANCELED]: "Canceled",
  [LIST_STATUS_MEETING_FEEDBACK.QUALIFIED]: "Qualified",
  [LIST_STATUS_MEETING_FEEDBACK.UNQUALIFIED]: "Unqualified",
};

export const LIST_STATUS_MEETING_FEEDBACK_LABELS_GLENX = {
  ...LIST_STATUS_MEETING_FEEDBACK_LABELS,
  [LIST_STATUS_MEETING_FEEDBACK.QUALIFIED]: "Opportunity",
  [LIST_STATUS_MEETING_FEEDBACK.UNQUALIFIED]: "No opportunity",
};
