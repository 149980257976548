/**
 * Used in pipeline lists dialer mode for pills
 */
export const DIALER_LIST_DIALING_STATUS = {
  // Call in progress
  DIALING: "dialing",
  CONNECTED: "connected",

  // Success state after the call
  HAD_CONVERSATION: "had_conversation",
  CALLBACK_BOOKED: "callback_booked",
  MEETING_BOOKED: "booked_meeting",

  // Didn't connect state
  VM_DETECTED: "voicemail_detected",
  BAD_NUMBER: "bad_number",
  NO_ANSWER: "no_answer",
  BUSY: "busy",
  CANCELLED: "cancelled",
  IGNORED: "ignored",

  // No UI statuses
  QUEUED: "queued",
  NO_STATUS: "no_status",

  // UI only
  TIMEOUT: "timeout",
} as const;

/**
 * Used in pipeline lists dialer mode for pills
 */
export const DIALER_LIST_DIALING_STATUS_MAP = {
  [DIALER_LIST_DIALING_STATUS.DIALING]: "Dialing",
  [DIALER_LIST_DIALING_STATUS.CONNECTED]: "Connected",

  [DIALER_LIST_DIALING_STATUS.HAD_CONVERSATION]: "Was Connected",
  [DIALER_LIST_DIALING_STATUS.CALLBACK_BOOKED]: "Callback set",
  [DIALER_LIST_DIALING_STATUS.MEETING_BOOKED]: "Meeting booked",

  [DIALER_LIST_DIALING_STATUS.VM_DETECTED]: "VM detected",
  [DIALER_LIST_DIALING_STATUS.BAD_NUMBER]: "Bad number",
  [DIALER_LIST_DIALING_STATUS.BUSY]: "Busy",
  [DIALER_LIST_DIALING_STATUS.NO_ANSWER]: "No answer",
  [DIALER_LIST_DIALING_STATUS.CANCELLED]: "Canceled",
  [DIALER_LIST_DIALING_STATUS.IGNORED]: "Canceled",
  [DIALER_LIST_DIALING_STATUS.TIMEOUT]: "Timed out",
} as const;
