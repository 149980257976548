import { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import clsx from "clsx";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { useSetContactType } from "@/api/routes/account/mutations";
import { RadioButton as RadioButtonFactory } from "shared/ui/ae-user-input";
import {
  PROSPECT_DISPOSITION_TYPES,
  PROSPECT_DISPOSITION_TYPES_MAP,
} from "shared/lib/constants/dispositions";
import { ProspectDispositionTypeI } from "shared/lib/interfaces/dispositions";

interface SetContactTypeModalPropsI {
  campaignId?: string;
  accountId?: string;
  contactId?: string;
  onSuccess?: () => void;
  contactType?: ProspectDispositionTypeI;
}

const RadioButton = RadioButtonFactory();

export const SET_CONTACT_TYPE_MODAL_ID = "set-contact-type-modal";

const SetContactTypeModalContent: FC<
  ModalChildrenFnPropsI & SetContactTypeModalPropsI
> = ({
  campaignId,
  accountId,
  contactId,
  handleClose,
  contactType,
  onSuccess,
}) => {
  const [selectedContactType, setSelectedContactType] =
    useState<ProspectDispositionTypeI>();
  const { mutateAsync: setContactType, isPending } = useSetContactType();

  useEffect(() => {
    if (contactType) {
      // Set latest pre-defined contact type from modal props if present.
      setSelectedContactType(contactType);
    }
  }, [contactType]);

  const handleSubmit = async () => {
    if (!campaignId || !accountId || !contactId || !selectedContactType) {
      return toast.error(
        "Campaign, account or contact IDs are missing, or contact type is not provided."
      );
    }

    try {
      await setContactType({
        campaignId,
        accountId,
        contactId,
        contactType: selectedContactType,
      });

      onSuccess?.();
      handleClose();
    } catch (e) {
      toast.error(
        "Failed to set contact type. Please message us in the chat window on the bottom right so we can assist."
      );
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        {Object.values(PROSPECT_DISPOSITION_TYPES).map((contactType) => (
          <RadioButton
            name="contactType"
            className="w-full p-2"
            key={contactType}
            label={PROSPECT_DISPOSITION_TYPES_MAP[contactType]}
            inputProps={{
              disabled: isPending,
              checked: selectedContactType === contactType,
              onChange: () => setSelectedContactType(contactType),
            }}
          />
        ))}
      </div>

      <section className="mt-4 flex w-full justify-end gap-2">
        <button type="button" className="btn-ae-text" onClick={handleClose}>
          Cancel
        </button>

        <button
          type="submit"
          className={clsx("btn-ae-default w-[140px]", {
            disabled: isPending || !selectedContactType,
            loading: isPending,
          })}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </section>
    </div>
  );
};

export const SetContactTypeModal: FC<SetContactTypeModalPropsI> = (props) => (
  <Modal
    id={SET_CONTACT_TYPE_MODAL_ID}
    title="Set contact type"
    modalContainerClassName="w-[500px]"
  >
    {(modalProps) => <SetContactTypeModalContent {...props} {...modalProps} />}
  </Modal>
);
