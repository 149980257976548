import { useMutation, useQueryClient } from "@tanstack/react-query";

import { glencocoClientAPI } from "@/api/glencoco";
import { UpdateGoalTrackerRequestI } from "@/api/routes/performance/interfaces";
import { PerformanceDashboardQueryKeys } from "@/api/routes/performance/queries/keys";

export const useUpdateGoalTrackerMetrics = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateGoalTrackerRequestI) =>
      glencocoClientAPI().updateGoalTrackerMetrics(params),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: PerformanceDashboardQueryKeys.goalTrackerProgress(),
      });
    },
  });
};
