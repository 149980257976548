import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { PreQualifiedVerificationBadge } from "shared/ui/qualification/pre-qualified-verification-badge";

interface AccountDispositionStatusPillPropsI extends PropsWithClassNameI {
  style?: string;
  label: string;
  hasVerificationBadge?: boolean;
}

export const AccountDispositionStatusSmallPill: FC<
  AccountDispositionStatusPillPropsI
> = ({ className, style, label, hasVerificationBadge }) => {
  if (!label) {
    return null;
  }

  return (
    <section className="flex items-center gap-1">
      <div
        className={clsxMerge(
          "w-max min-w-[84px] px-2 py-0",
          "rounded text-center text-white typography-header-8-semibold",
          style,
          className
        )}
      >
        {label}
      </div>

      {hasVerificationBadge && <PreQualifiedVerificationBadge size={24} />}
    </section>
  );
};
