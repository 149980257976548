import { AxiosInstance } from "axios";

import { ReferralI } from "@/interfaces/referrals";

export interface GetReferralsDashboardI {
  referral_earnings: number;
  potential_earnings: number;
  referrals: ReferralI[];
}

export const ReferralsAPIEndpoints = (client: AxiosInstance) => ({
  create: (slug: string) => client.post("/v1/referral", { slug }),
  getDashboard: (isGlenXReferrals = false) =>
    client.get<GetReferralsDashboardI>(
      `/v1/referral_dashboard${isGlenXReferrals ? "?is_x_referrals=true" : ""}`
    ),
});
