import axios, { AxiosInstance } from "axios";
import {
  GetTrainingModuleResponseI,
  GetTrainingModulesResponseI,
  GetTrainingModuleUploadURLResponseI,
} from "shared/lib/interfaces/training";

export const TrainingAPIEndpoints = (api: AxiosInstance) => ({
  getTrainingModules: (campaignId: string) =>
    api.get<GetTrainingModulesResponseI>(
      `/v1/campaign/${campaignId}/training_modules`
    ),

  getTrainingModule: (campaignId: string, trainingModuleId: string) =>
    api.get<GetTrainingModuleResponseI>(
      `/v1/campaign/${campaignId}/training_module/${trainingModuleId}`
    ),

  submitTrainingModuleCompleted: (
    campaignId: string,
    trainingModuleId: string
  ) =>
    api.post(
      `v1/campaign/${campaignId}/training_module/${trainingModuleId}/complete`
    ),

  enrollIntoCampaignTraining: (campaignId: string) =>
    api.post(`/v1/campaign/${campaignId}/enroll`, {}),

  leaveCampaign: (campaignId: string) =>
    api.post(`/v1/campaign/${campaignId}/leave`, {}),

  submitTrainingCompleted: (campaignId: string) =>
    api.post(`/v1/campaign/${campaignId}/training/complete`, {}),

  getAITrainingModuleUploadURL: (
    campaignId: string,
    trainingModuleId: string
  ) =>
    api.post<GetTrainingModuleUploadURLResponseI>(
      `/v1/campaign/${campaignId}/training_modules/${trainingModuleId}/submit_new_block_notes_url`
    ),

  uploadTrainingModuleContent: (preSignedUploadURL: string, document: Blob) =>
    axios.put(preSignedUploadURL, document, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
});
