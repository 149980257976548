import { FC } from "react";
import _noop from "lodash/noop";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { QualificationCriteriaTableWithToggles } from "shared/ui/qualification/table/with-toggles";
import {
  getQCTextsFromResponse,
  mapActivityLogQCToSelectedQCs,
} from "shared/lib/helpers/qualification-criteria";
import { QCPreCheckAuditResultI } from "shared/lib/interfaces/qualification-criteria";
import { QC_TABLE_TOGGLE_STYLES } from "shared/ui/qualification/table/shared/qc-checkbox";

interface QualificationCriteriaPreCheckAuditResultPropsI
  extends PropsWithClassNameI {
  auditResult?: QCPreCheckAuditResultI | null;
}

export const AccountHistoryCardQualificationCriteriaPreCheckAuditResult: FC<
  QualificationCriteriaPreCheckAuditResultPropsI
> = ({ auditResult }) => {
  if (!auditResult) {
    return null;
  }

  if (auditResult) {
    const qcTexts = getQCTextsFromResponse(auditResult);
    const qcValues = mapActivityLogQCToSelectedQCs(auditResult);

    return (
      <QualificationCriteriaTableWithToggles
        toggleStyle={QC_TABLE_TOGGLE_STYLES.AI}
        isEditable={false}
        value={qcValues}
        texts={qcTexts}
        hasBonus={!!qcTexts.timingBudgetCriteria}
        onChange={_noop}
      />
    );
  }

  return null;
};
