import {
  NOTIFICATION_ACTION_TYPES,
  createNotificationAction,
} from "shared/ui/notifications/notification-generic/base-parts/actions";
import { NotificationBase } from "@/modules/notifications/notification/base";
import Router from "@/helpers/router";
import { NotificationPropsI } from "@/modules/notifications/notification";

export const NotificationColdOutreachEmailResponded = (
  props: NotificationPropsI
) => {
  const {
    className,
    id,
    list_id,
    campaign_id,
    campaign_name,
    account_id,
    account_name,
    contact_name,
    is_seen,
    created_at,
  } = props;

  return (
    <NotificationBase
      id={id}
      isUnread={!is_seen}
      className={className}
      avatar={props}
      description={{
        text: (
          <div>
            <b>{contact_name}</b> from <b>{account_name}</b> responded{" "}
            <b>with interest</b> to an outbound email we sent out for{" "}
            <b>{campaign_name}</b>.
          </div>
        ),
        date: created_at,
        campaignName: campaign_name,
      }}
      actions={[
        createNotificationAction(
          NOTIFICATION_ACTION_TYPES.PRIMARY,
          "View account",
          () => {
            if (!campaign_id || !account_id) {
              return;
            }

            window.location.href = `${Router.lists(
              list_id
            )}&${Router.searchQueryParams.accountDetails(
              campaign_id,
              account_id
            )}`;
          }
        ),
      ]}
    />
  );
};
