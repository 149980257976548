import { GetEmailGenerationStatusResponseI } from "@/api/routes/email";
import { ListTableSortingStateI } from "@/components/modules/pipeline/lists/list/workspace/table/interfaces";
import { CampaignV3I } from "@/interfaces/campaign";
import { UserI } from "@/interfaces/user";

const USER_KEY = "user";
const CAMPAIGNS_KEY = `campaigns`;

const DIALER_GLOBAL_TWILIO_TOKEN_UPDATED_AT =
  "dialer_global_twilio_token_updated_at";

const ACCOUNT_DETAILS_NOTE = "account_details_note";

/**
 * Should followup by list id
 */
const LIST_SORTING = "list_sorting";

const EMAIL_LOCAL_DRAFTS_BY_CALL_ID = "email_local_drafts_by_call_id";

// Works ONLY in the browser
export class SessionStorage {
  /**
   * Cache Get User API response
   */
  get user(): UserI | undefined {
    const userAsString = window.sessionStorage.getItem(USER_KEY) as string;

    try {
      return JSON.parse(userAsString);
    } catch (e) {
      // TODO datadog
      console.log("Failed to parse user");
      console.log(userAsString);
      return undefined;
    }
  }
  set user(data: UserI) {
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(data));
  }

  /**
   * Cache Get Campaigns available campaigns
   */
  get campaigns(): Array<CampaignV3I> | undefined {
    const campaignsAsString = window.sessionStorage.getItem(
      CAMPAIGNS_KEY
    ) as string;

    try {
      return JSON.parse(campaignsAsString);
    } catch (e) {
      // TODO datadog
      console.log("Failed to parse user");
      console.log(campaignsAsString);
      return undefined;
    }
  }
  set campaigns(data: Array<CampaignV3I>) {
    window.sessionStorage.setItem(CAMPAIGNS_KEY, JSON.stringify(data));
  }

  get dialerGlobalTwilioTokenUpdatedAt(): string | undefined | null {
    return window.sessionStorage.getItem(DIALER_GLOBAL_TWILIO_TOKEN_UPDATED_AT);
  }
  set dialerGlobalTwilioTokenUpdatedAt(date: string) {
    window.sessionStorage.setItem(DIALER_GLOBAL_TWILIO_TOKEN_UPDATED_AT, date);
  }

  get emailLocalDraftsByCallId(): Record<
    string,
    GetEmailGenerationStatusResponseI
  > {
    return JSON.parse(
      window.sessionStorage.getItem(EMAIL_LOCAL_DRAFTS_BY_CALL_ID) as string
    );
  }
  set emailLocalDraftsByCallId(
    data: Record<string, GetEmailGenerationStatusResponseI>
  ) {
    window.sessionStorage.setItem(
      EMAIL_LOCAL_DRAFTS_BY_CALL_ID,
      JSON.stringify(data)
    );
  }

  getAccountDetailsNote(accountId: string): string | undefined | null {
    return window.sessionStorage.getItem(
      `${ACCOUNT_DETAILS_NOTE}-${accountId}`
    );
  }
  setAccountDetailsNote(accountId: string, note: string) {
    window.sessionStorage.setItem(`${ACCOUNT_DETAILS_NOTE}-${accountId}`, note);
  }

  getListSorting(listId: string): ListTableSortingStateI {
    try {
      return JSON.parse(
        window.sessionStorage.getItem(`${LIST_SORTING}-${listId}`) as string
      );
    } catch {
      return {};
    }
  }

  setListSorting(listId: string, sortParams?: ListTableSortingStateI): void {
    window.sessionStorage.setItem(
      `${LIST_SORTING}-${listId}`,
      JSON.stringify(sortParams || {})
    );
  }

  clear() {
    window.sessionStorage.clear();
  }
}
