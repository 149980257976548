import { FC } from "react";

import Modal, { ModalPropsI } from "./index";
import { clsxMerge } from "shared/lib/helpers";

type ConfirmActionModalPropsI = Pick<
  ModalPropsI,
  | "id"
  | "title"
  | "titleImage"
  | "description"
  | "onClose"
  | "unmountOnClose"
  | "showCloseButton"
  | "modalBoxClassName"
> & {
  confirmButtonDisabled?: boolean;
  confirmButtonText?: string;
  onConfirm?: () => void;
  cancelButtonText?: string;
  onCancel?: () => void;
};

export const ConfirmActionModal: FC<ConfirmActionModalPropsI> = ({
  confirmButtonDisabled,
  confirmButtonText,
  onConfirm,
  cancelButtonText,
  onCancel,

  ...modalProps
}) => (
  <Modal {...modalProps}>
    {({ handleClose }) => {
      const handleCancel = () => {
        onCancel?.();
        handleClose();
      };

      const handleConfirm = () => {
        onConfirm?.();
        handleClose();
      };

      return (
        <div className="flex justify-end gap-3">
          <button className={clsxMerge("btn-ae-text")} onClick={handleCancel}>
            {cancelButtonText || "Cancel"}
          </button>

          <button
            className={clsxMerge("btn-ae-default", {
              disabled: confirmButtonDisabled,
            })}
            onClick={handleConfirm}
            disabled={confirmButtonDisabled}
          >
            {confirmButtonText || "Confirm"}
          </button>
        </div>
      );
    }}
  </Modal>
);
