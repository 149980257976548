import { FC, useState } from "react";
import { Portal } from "react-portal";

import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { QC_TABLE_TOGGLES_INITIAL_STATE } from "shared/lib/constants/qualification-criteria";

import CalendlyModal, { CALENDLY_MODAL_ID } from "./calendly-modal";
import SubmitBookedMeetingModal, {
  SUBMIT_BOOKED_MEETING_MODAL_ID,
} from "./submit-booked-meeting-modal";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { CampaignI } from "@/interfaces/campaign";
import { CallAccountI, CallContactI } from "@/interfaces/dialer";
import { useFetchQualificationCriteriaAndPricing } from "@/api/routes/qualification-criteria/queries";
import QualificationModal from "@/modals/account-book-meeting-modal/qualification-modal";
import { QualificationCriteriaTableTogglesValueI } from "shared/lib/interfaces/qualification-criteria";

interface BookMeetingModalPropsI {
  modalId?: string;
  campaign: CampaignI;
  account: CallAccountI;
  contact: CallContactI;
  calendlyUri: string;
  callId: string;
  notes?: string;
  isAutoTriggerSubmitBookedMeetingModal?: boolean;
  onQualificationCriteriaSubmitSuccess?: (
    qc: QualificationCriteriaTableTogglesValueI
  ) => void;
  onCalendlySubmitSuccess?: (calendlyEvent: CalendlyEventI) => void;
  onFinalSubmitSuccess?: () => void;
}

export const BOOK_MEETING_MODAL_ID = "book_meeting_modal";

export const BookMeetingModal: FC<BookMeetingModalPropsI> = ({
  campaign,
  account,
  contact,
  calendlyUri,
  callId,
  modalId,
  notes,
  isAutoTriggerSubmitBookedMeetingModal = true,
  onQualificationCriteriaSubmitSuccess = () => {},
  onCalendlySubmitSuccess = () => {},
  onFinalSubmitSuccess = () => {},
}) => {
  const [calendlyEvent, setCalendlyEvent] = useState<
    CalendlyEventI | undefined
  >();

  const qcApi = useFetchQualificationCriteriaAndPricing(campaign?.id);

  const [selectedQCs, setSelectedQCs] =
    useState<QualificationCriteriaTableTogglesValueI>(
      QC_TABLE_TOGGLES_INITIAL_STATE
    );

  return (
    <>
      <QualificationModal
        modalId={modalId || BOOK_MEETING_MODAL_ID}
        qcApi={qcApi}
        accountName={account?.name as string}
        onSuccess={(qc) => {
          setSelectedQCs(qc);
          onQualificationCriteriaSubmitSuccess(qc);
          modalHelpers.lazyOpen(CALENDLY_MODAL_ID);
        }}
      />

      {/*@ts-ignore*/}
      <Portal>
        <CalendlyModal
          calendlyUri={calendlyUri}
          campaign={campaign}
          account={account}
          contact={contact}
          callId={callId}
          onSuccess={(calendlyEvent) => {
            setCalendlyEvent(calendlyEvent);

            if (isAutoTriggerSubmitBookedMeetingModal) {
              modalHelpers.lazyOpen(SUBMIT_BOOKED_MEETING_MODAL_ID);
            }

            onCalendlySubmitSuccess(calendlyEvent);
          }}
        />

        <SubmitBookedMeetingModal
          modalId={SUBMIT_BOOKED_MEETING_MODAL_ID}
          calendlyUri={calendlyUri}
          calendlyEvent={calendlyEvent as CalendlyEventI}
          campaign={campaign}
          callId={callId}
          account={account}
          contact={contact}
          notes={notes}
          qcApi={qcApi}
          selectedQCs={selectedQCs}
          onSuccess={onFinalSubmitSuccess}
        />
      </Portal>
    </>
  );
};
