import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { clsxMerge } from "shared/lib/helpers";

import LeadDescription from "@/components/modules/calling/cards/lead-card/v1/lead-description";
import { ModalAccountDataI } from "@/context/accounts";
import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import {
  GetAccountCalendlyStartTimeResponseI,
  glencocoClientAPI,
} from "@/api/glencoco";
import { useAccountsContext } from "@/hooks/use-accounts-context";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const ACCOUNT_CALENDLY_MODAL_ID = "account_calendly_modal";

interface CalendlyModalPropsI {
  calendlyUri: string;
  accountName: string;
  contact?: ModalAccountDataI["contact"];
  modalId?: string;
  onSuccess: (event: CalendlyEventI) => void;
  onClose?: () => void;
}

const AccountCalendlyModalContent = ({
  calendlyUri,
  accountName,
  contact,
  modalId = ACCOUNT_CALENDLY_MODAL_ID,
  onSuccess = () => {},
}: CalendlyModalPropsI & ModalChildrenFnPropsI) => {
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      (async () => {
        const API = glencocoClientAPI();

        const getStartTimeResponse = await API.getAccountCalendlyStartTime(
          e.data.payload.event.uri
        ).catch((e) => e);

        if (getStartTimeResponse.status === 200) {
          const data =
            getStartTimeResponse.data as GetAccountCalendlyStartTimeResponseI;

          onSuccess({
            id: e.data.payload.event.uri,
            start_time: data.start_time,
          });
        }
      })();
    },
  });

  const onFailedBooking = () => {
    toast.error("Failed to book a meeting due to internal error.");
  };

  if (calendlyUri) {
    return (
      <div className="relative z-[1] h-full w-full max-w-[1000px] rounded-md bg-transparent shadow-none scrollbar-none">
        <InlineWidget
          url={calendlyUri}
          prefill={{
            name: contact?.name,
            email: contact?.email,
          }}
          styles={{
            width: "100%",
            height: "750px",
            zIndex: "2",
          }}
        />
      </div>
    );
  }

  return (
    <div className="modal-box relative min-w-[520px] rounded-md p-6">
      <h3 className="ae-typography-h3 mb-2">
        Unfortunately we failed to provide you with Calendar link
      </h3>

      <p className="ae-typography-detail1 mb-6 text-base-content/60">
        Please{" "}
        <a
          href="mailto:help@glencoco.io"
          target="_blank"
          rel="noreferrer"
          className="ae-link"
        >
          contact Glencoco
        </a>{" "}
        with the following data
      </p>

      <div className="rounded-lg bg-black/5 px-3 py-6">
        <div className="mb-8">
          <label className="ae-typography-h2 mb-1 flex items-center">
            <span className="mr-2 flex items-center">{contact?.name}</span>
          </label>

          <p className="ae-typography-body3 text-black/60">
            <span>
              {contact?.title} @ {accountName}
            </span>
          </p>
        </div>

        <LeadDescription />
      </div>

      <div className="mt-8 flex justify-end">
        <label
          htmlFor={modalId}
          className="btn-ae-default"
          onClick={onFailedBooking}
        >
          Understood
        </label>
      </div>
    </div>
  );
};

const AccountCalendlyModal = (props: CalendlyModalPropsI) => {
  const { onChangeStatusCancelRef } = useAccountsContext();

  const onClose = (isInitiatedByUser?: boolean) => {
    if (onChangeStatusCancelRef.current) {
      onChangeStatusCancelRef.current();
      onChangeStatusCancelRef.current = null;
    }

    if (isInitiatedByUser) {
      props.onClose?.();
    }
  };

  return (
    <Modal
      id={ACCOUNT_CALENDLY_MODAL_ID}
      modalBoxClassName={clsxMerge(
        "w-[1000px] h-full max-h-full",
        "rounded-lg bg-transparent shadow-none p-0"
      )}
      shouldCloseOnOverlayClick={false}
      closeButtonClassName="text-white right-4"
      onClose={onClose}
    >
      {(modalProps) => (
        <AccountCalendlyModalContent {...props} {...modalProps} />
      )}
    </Modal>
  );
};

export default AccountCalendlyModal;
