import { clsxMerge } from "shared/lib/helpers";
import { AccountDetailsButton } from "shared/ui/account-details/base/button";

import { Columns4Icon } from "lucide-react";

export const MenuTrigger = () => {
  return (
    <AccountDetailsButton className={clsxMerge("outline-none", "border-none")}>
      <Columns4Icon className="w-4" />
      View
    </AccountDetailsButton>
  );
};
