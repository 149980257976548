import { LoadingComponent } from "@/components/modules/loading";

export const StartCallingModalGeneralDialerSection = ({
  isLoading,
}: {
  isLoading?: boolean;
}) => {
  return (
    <div className="flex flex-grow animate-fadein flex-col">
      {isLoading && (
        <div className="flex-grow">
          <LoadingComponent delay={1000} />
        </div>
      )}

      {!isLoading && (
        <div className="flex flex-col items-center gap-4 px-10 text-center">
          <h6 className="mb-1 typography-header-8-semibold">
            Getting leads to call has changed
          </h6>
          <p className="text-[#999999] typography-body-5">
            Every campaign now has a pool of cold leads to call. When you want
            to call, you'll have the option to request 50 accounts.
          </p>

          <p className="text-[#999999] typography-body-5">
            When you have 10 leads left, you can request more.{" "}
            <span className="italic">
              At the end of the day, all cold leads return to the pool.
            </span>{" "}
            Your nurture leads stay with you.
          </p>
        </div>
      )}
    </div>
  );
};
