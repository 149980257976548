import { FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

interface AccountSummarySectionPropsI {
  className?: string;
}

export const AccountSummarySection: FC<AccountSummarySectionPropsI> = ({
  className,
}) => {
  const { accountDetailsApi, accountSummary: accountDetailsSummaryData } =
    useAccountDetailsContext();

  const aiSummary = accountDetailsSummaryData?.ai_summary;

  return (
    <div className={clsxMerge("rounded-lg bg-white", className)}>
      <AccountDetailsSectionContainer title="Account summary">
        <DataStatesWrapper
          viewName="account summary"
          api={accountDetailsApi}
          loading={<CenteredSpinner className="h-[140px]" />}
        >
          {aiSummary ? (
            <div className="typography-body-4">{aiSummary}</div>
          ) : (
            <div className="italic text-black/30 typography-body-4">
              No data available
            </div>
          )}
        </DataStatesWrapper>
      </AccountDetailsSectionContainer>
    </div>
  );
};
