import { Fragment } from "react";

import { ControlledDropdown } from "shared/ui";
import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/navigation/primary/interfaces";
import { clsxMerge } from "shared/lib/helpers";
import { NavButtonPrimary } from "@/components/navigation/primary/nav-bar/nav-items-row/components/nav-button-primary";
import { NavDropdownMenuItem } from "@/components/navigation/primary/nav-bar/nav-items-row/components/nav-dropdown-menu-item";
import {
  MY_CAMPAIGNS_ITEM_ID,
  REFERRALS_ITEM_ID,
} from "@/components/navigation/primary/constants";

// dropdown menu items that should have the grey separator line below them
const DROPDOWN_ITEM_IDS_WITH_DIVIDER = [
  MY_CAMPAIGNS_ITEM_ID,
  REFERRALS_ITEM_ID,
];

/**
 * Dropdown component for items in the nav bar that have a sub menu
 * @param navItem data for the nav item
 * @param dropdownClassName classNames for the dropdown sub menu component
 * @returns
 */
export const NavDropdown = ({
  navItem,
  dropdownClassName,
  disabled,
  isBtnIconOnly,
}: {
  navItem: PrimaryNavigationItemCollapsableWithIconI;
  dropdownClassName?: string;
  disabled?: boolean;
  isBtnIconOnly?: boolean;
}) => {
  return (
    <ControlledDropdown
      className={clsxMerge(
        "dropdown-bottom flex h-full items-center",
        { "cursor-not-allowed opacity-60": disabled },
        dropdownClassName
      )}
      closeOnContentClick={true}
    >
      {() => (
        <>
          {/* DROPDOWN TRIGGER */}
          <div
            tabIndex={0}
            className={clsxMerge("flex h-full items-center", {
              "pointer-events-none": disabled,
            })}
          >
            <NavButtonPrimary navItem={navItem} isBtnIconOnly={isBtnIconOnly} />
          </div>

          {/* DROPDOWN CONTENT */}
          <div tabIndex={0} className="dropdown-content min-w-[220px]">
            {navItem?.children?.length && (
              <ul className="rounded-box menu-compact w-full bg-white p-2 shadow-lg">
                {navItem.children.map((subItem, idx) => (
                  <Fragment key={idx}>
                    <li>
                      <NavDropdownMenuItem
                        subItem={subItem}
                        disabled={disabled}
                      />
                    </li>

                    {DROPDOWN_ITEM_IDS_WITH_DIVIDER.includes(
                      subItem.id as string
                    ) && <div className="divider my-0" />}
                  </Fragment>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
    </ControlledDropdown>
  );
};
