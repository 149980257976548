import { FC, ReactNode } from "react";

import Avatar from "shared/ui/avatar";
import { clsxMerge } from "shared/lib/helpers";

import { CampaignCardDefaultI } from "./campaign-card-default";
import { CampaignCardBonusPrice } from "./shared/campaign-card-bonus-price";

export interface CampaignCardExtendedI extends CampaignCardDefaultI {
  upcomingDates?: ReactNode;
  performance?: ReactNode;
  actions?: ReactNode;
}

export const CampaignCardExtended: FC<CampaignCardExtendedI> = ({
  imgSrc,
  title,
  price,
  bonusPrice,
  priceDescription,
  status,
  labels,
  description,
  stats,
  features,
  performance,
  actions,
  upcomingDates,
  onClick,
  className,
}) => {
  return (
    <div
      className={clsxMerge(
        "card cursor-pointer rounded-lg bg-[white] p-8",
        className
      )}
      onClick={onClick}
    >
      <div className="flex flex-row gap-4">
        <div>
          <Avatar
            src={imgSrc}
            placeholderText={title}
            className="h-[88px] w-[88px] rounded-lg border-[1px]"
          />
        </div>

        <article className="w-full pr-8">
          <section className="mb-5">
            <div className="flex items-center justify-between">
              <div className="b-typography-h2 mb-2 font-medium">{title}</div>
              <div className="flex items-center gap-5">
                {upcomingDates}
                {status}
              </div>
            </div>
            <div className="flex items-center">
              <div className="ae-typography-h1">{price}</div>

              {priceDescription && (
                <>
                  <div className="divider divider-horizontal" />
                  {priceDescription}
                </>
              )}

              <CampaignCardBonusPrice className="ml-3" price={bonusPrice} />
            </div>
          </section>

          <section className="mb-2">{labels}</section>

          <section className="mb-4">{description}</section>

          <section className="mb-5">{stats}</section>

          <section className="bg-black/3 mb-7 w-fit rounded p-3">
            {features}
          </section>

          <section className="mb-10">{performance}</section>

          <section className="py-3">{actions}</section>
        </article>
      </div>
    </div>
  );
};
