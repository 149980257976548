// Core event handling
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import { handleError } from "./error";

// Feature handlers
import { dialerListSocketEventHandler } from "./dialer/event-handlers";
import { messagingSocketEventHandler } from "./messaging/event-handlers";
import { callCopilotSocketEventHandler } from "./call-copilot/event-handlers";

// Toast/notification related
import { meetingsToastEmitter } from "@/components/shared/custom-meetings-toaster";
import { LocalStorage } from "@/helpers/local-storage";
import { CustomMeetingsToastI } from "@/interfaces/custom-toasts/meetings";

import { WSAuthSuccessDataI } from "@/interfaces/events";
import { WEBSOCKET_CONNECTION_TYPES } from "./constants";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
const MAX_MEETING_TOASTS = 15;

export const socketEventHandlers = {
  websocket: {
    authSuccess: (
      connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>
    ) => {
      dispatchCustomEvent<WSAuthSuccessDataI>({
        eventType: CUSTOM_EVENTS.WEBSOCKETS.AUTH_SUCCESS,
        data: {
          connectionType,
        },
      });
    },

    handleError,
  },
  globalToasts: {
    meetings: (data: CustomMeetingsToastI) => {
      const LS = new LocalStorage();
      const toasts = LS.customMeetingsToasts || [];

      if (toasts.length > MAX_MEETING_TOASTS) {
        toasts.shift();
      }

      const updatedToasts = [...toasts, data];
      LS.customMeetingsToasts = updatedToasts;

      meetingsToastEmitter.show();
    },
  },
  notifications: {
    /**
     * NOTE: This might be updated in the future
     *       for now it only sets triggers window
     *       event
     */
    update: () => {
      dispatchCustomEvent({
        eventType: CUSTOM_EVENTS.WEBSOCKETS.NOTIFICATIONS.NEW,
      });
    },
  },
  messaging: {
    ...messagingSocketEventHandler,
  },
  dialer: {
    list: {
      ...dialerListSocketEventHandler,
    },
  },
  callCopilot: {
    ...callCopilotSocketEventHandler,
  },
};
