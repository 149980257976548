import dayjs from "dayjs";

import { PERFORMANCE_DASHBOARD_TIME_PERIODS } from "shared/lib/constants/performance";
import {
  PerformanceFilterI,
  PerformanceMetricI,
  PerformanceMetricsI,
} from "shared/lib/interfaces/performance";
import { calculatePercentage } from "shared/lib/helpers/numbers";

export const preparePerformanceFiltersForAPICall = ({
  selectedCampaignId,
  timePeriod,
}: PerformanceFilterI) => {
  const startDateConfigMap = {
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.TODAY]: () => dayjs().startOf("day"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.YESTERDAY]: () =>
      dayjs().subtract(1, "day").startOf("day"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.THIS_WEEK]: () =>
      dayjs().startOf("week"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_WEEK]: () =>
      dayjs().subtract(1, "week").startOf("week"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.THIS_MONTH]: () =>
      dayjs().startOf("month"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_MONTH]: () =>
      dayjs().subtract(1, "month").startOf("month"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_30_DAYS]: () =>
      dayjs().subtract(30, "day").startOf("day"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_3_MONTHS]: () =>
      dayjs().subtract(3, "month").startOf("month"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.YEAR_TO_DATE]: () =>
      dayjs().startOf("year"),
    [PERFORMANCE_DASHBOARD_TIME_PERIODS.LIFETIME]: () => dayjs("2022-01-01"),
  };

  return {
    campaignId: selectedCampaignId,
    startDate: Object.hasOwn(startDateConfigMap, timePeriod)
      ? startDateConfigMap[timePeriod]().toISOString()
      : dayjs().startOf("day").toISOString(),
    endDate:
      timePeriod === PERFORMANCE_DASHBOARD_TIME_PERIODS.LAST_MONTH
        ? dayjs().subtract(1, "month").endOf("month").toISOString()
        : undefined,
  };
};

export const createPerformanceDashboardMetrics = ({
  calls = 0,
  conversations = 0,
  booked_meetings = 0,
  run_meetings = 0,
  showed_meetings = 0,
  qualified_meetings = 0,
}: PerformanceMetricsI): PerformanceMetricI[] => [
  {
    percentage: calculatePercentage(conversations, calls) || "N/A",
    fraction: `${conversations}/${calls}`,
    label: "Conversations",
  },
  {
    percentage: calculatePercentage(booked_meetings, conversations) || "N/A",
    fraction: `${booked_meetings}/${conversations}`,
    label: "Bookings",
  },
  {
    percentage: calculatePercentage(showed_meetings, run_meetings) || "N/A",
    fraction: `${showed_meetings}/${run_meetings}`,
    label: "Show Up Rate",
  },
  {
    percentage:
      calculatePercentage(qualified_meetings, showed_meetings) || "N/A",
    fraction: `${qualified_meetings}/${showed_meetings}`,
    label: "Qualified Meetings",
  },
];
