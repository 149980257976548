import { useCallingContext } from "@/hooks/dialer/use-dialer-context";

import { CalendlyEventI } from "@/interfaces/dialer/calendly-event";
import { glencocoClientAPI } from "@/api/glencoco";

import { handleErrorMessage } from "@/helpers/error";

/**
 * This hook is used to book a meeting by call id and only when call is available
 * @returns {Promise<boolean>}
 */
export const useBookMeetingByCallId = () => {
  const { call, campaign } = useCallingContext();

  /**
   * This function is used to book a meeting by call id and only when call is available
   * @param {CalendlyEventI} calendlyEvent - The calendly event
   * @param {string} notes - The notes for the meeting
   * @returns {Promise<boolean>}
   */
  const handleBookMeetingByCallId = async (
    calendlyEvent: CalendlyEventI,
    notes: string
  ) => {
    const API = glencocoClientAPI();

    let response = await API.bookMeeting(
      campaign?.id as string,
      call?.customParameters.get("call_id") as string,
      calendlyEvent.id
    );

    if (response.status !== 200) {
      handleErrorMessage(
        (response as any)?.response?.data?.error_code as number,
        "Failed to book meeting. Please try again."
      );
      return;
    }

    const bookedMeetingId = response.data?.event?.id;

    response = await API.updateBookedMeeting(
      campaign?.id as string,
      call?.customParameters.get("call_id") as string,
      bookedMeetingId,
      {
        notes: notes || "",
      }
    );

    if (response.status !== 200) {
      handleErrorMessage(
        (response as any)?.response?.data?.error_code as number,
        "Failed to update meeting. Please try again."
      );
      return;
    }

    return true;
  };

  return { handleBookMeetingByCallId };
};
