import { useEffect, useState } from "react";
import _throttle from "lodash/throttle";

import { FrequentlyChangingCallingContextI } from "@/interfaces/contexts/calling";

import { FrequentlyChangingCallingContext } from "./default-context";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import { useEffectOnce } from "shared/lib/hooks";
import { checkIfClient } from "shared/lib/helpers";

export const FrequentlyChangingCallingContextEventDispatcher = {
  dispatch: {
    inputVolUpdated: (inputVol: number) => {
      dispatchCustomEvent<number>({
        eventType:
          CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
            .INPUT_VOLUME_UPDATED,
        data: inputVol,
      });
    },

    outputVolUpdated: (outputVol: number) => {
      dispatchCustomEvent<number>({
        eventType:
          CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
            .OUTPUT_VOLUME_UPDATED,
        data: outputVol,
      });
    },

    callQualityUpdated: (callQuality: number) => {
      dispatchCustomEvent<number>({
        eventType:
          CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
            .CALL_QUALITY_UPDATED,
        data: callQuality,
      });
    },
  },
};

export const CallingFrequentlyChangingContentProvider = ({
  children,
  context,
}: {
  children: any;
  context: FrequentlyChangingCallingContextI;
}) => {
  // Define set functions
  const [inputVol, setInputVol] = useState(context.inputVol);
  const [outputVol, setOutputVol] = useState(context.outputVol);
  const [callQuality, setCallQuality] = useState(context.callQuality);

  const [ctx, setCallingContext] = useState({
    inputVol,
    setInputVol: _throttle(setInputVol, 300),
    outputVol,
    setOutputVol: _throttle(setOutputVol, 300),
    callQuality,
    setCallQuality,
  } as FrequentlyChangingCallingContextI);

  useEffectOnce(() => {
    if (checkIfClient()) {
      const handleInputVol = (inputVol: any) => setInputVol(inputVol.detail);
      const handleOutputVol = (outputVol: any) =>
        setOutputVol(outputVol.detail);
      const handleCallQuality = (callQuality: any) =>
        setCallQuality(callQuality);

      window.document.addEventListener(
        CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
          .INPUT_VOLUME_UPDATED,
        handleInputVol
      );

      window.document.addEventListener(
        CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
          .INPUT_VOLUME_UPDATED,
        handleOutputVol
      );

      window.document.addEventListener(
        CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
          .CALL_QUALITY_UPDATED,
        handleCallQuality
      );

      return () => {
        window.document.removeEventListener(
          CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
            .OUTPUT_VOLUME_UPDATED,
          handleInputVol
        );

        window.document.removeEventListener(
          CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
            .OUTPUT_VOLUME_UPDATED,
          handleOutputVol
        );

        window.document.removeEventListener(
          CUSTOM_EVENTS.CALLING_MODULE.FREQUENTLY_CHANGING_CONTEXT
            .CALL_QUALITY_UPDATED,
          handleCallQuality
        );
      };
    }
  });

  useEffect(() => {
    setCallingContext({
      ...ctx,
      inputVol,
      outputVol,
      callQuality,
    });
  }, [inputVol, outputVol, callQuality]);

  return (
    <FrequentlyChangingCallingContext.Provider
      value={ctx as FrequentlyChangingCallingContextI}
    >
      {children}
    </FrequentlyChangingCallingContext.Provider>
  );
};

export default CallingFrequentlyChangingContentProvider;
