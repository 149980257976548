import { GetListsRequestFilterParamsI } from "@/api/routes/list";

import { PipelineListContactI } from "shared/lib/interfaces/lists";

export const selectorNormalizedFilters = (
  filters?: GetListsRequestFilterParamsI,
  isNurtureList?: boolean,
  isSystemList?: boolean
) => {
  let normalizedFilters: GetListsRequestFilterParamsI = {};

  if (filters?.campaign_filters?.length) {
    normalizedFilters = {
      ...normalizedFilters,
      campaign_filters: filters?.campaign_filters,
    };
  }

  if (filters?.timezone_filters?.length) {
    normalizedFilters = {
      ...normalizedFilters,
      timezone_filters: filters?.timezone_filters,
    };
  }

  if (filters?.filter_available_to_call) {
    normalizedFilters = {
      ...normalizedFilters,
      filter_available_to_call: filters?.filter_available_to_call,
    };
  }

  /**
   * NOTE
   * In the system lists we want to remove passing list_subsection
   */
  if (isNurtureList && !isSystemList && filters?.list_subsection) {
    normalizedFilters = {
      ...normalizedFilters,
      list_subsection: filters?.list_subsection,
    };
  }

  return normalizedFilters;
};

/**
 * Filters and synchronizes a list of contacts by the given account ID.
 *
 * This function takes a list of contacts and a list of contacts specifically associated with an account,
 * and returns a tuple where:
 * 1. The first element contains contacts from the `contacts` list. If the contact belongs to the specified account,
 *    it is replaced by the corresponding contact from the `accountContacts` list if available.
 *    Contacts belonging to the account that are not present in `accountContacts` are filtered out.
 * 2. The second element contains any remaining contacts from the `accountContacts` list that were not matched
 *    in the first pass.
 *
 * @param {PipelineListContactI[]} contacts - The main list of contacts to filter and synchronize.
 * @param {PipelineListContactI[]} accountContacts - A list of contacts specifically associated with the given account.
 * @param {string} accountId - The ID of the account to filter the contacts by.
 * @returns {[PipelineListContactI[], PipelineListContactI[]]} - A tuple containing:
 *  - The synchronized contacts list, excluding any contacts in the same account but not present in `accountContacts`.
 *  - A list of remaining contacts from `accountContacts` that were not found in the main contacts list.
 */
export const selectorSyncContactsByAccountId = (
  contacts: Array<PipelineListContactI>,
  accountContacts: PipelineListContactI[],
  accountId: string
) => {
  const accountContactsMap = Object.fromEntries(
    accountContacts.map((c) => [c.contact_id, c])
  );

  const syncedContacts = contacts.reduce<PipelineListContactI[]>(
    (contacts, c) => {
      const isSameAccount = c.account_id === accountId;

      if (!isSameAccount) {
        contacts.push(c);
        return contacts;
      }

      const isInAccountContacts = !!accountContactsMap[c.contact_id];

      if (isInAccountContacts) {
        contacts.push(accountContactsMap[c.contact_id]);
        delete accountContactsMap[c.contact_id];
      }

      return contacts;
    },
    []
  );

  const remainingContactsToSync = Object.values(accountContactsMap);

  return [syncedContacts, remainingContactsToSync];
};
