import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { clsxMerge } from "shared/lib/helpers";
import { Button } from "shared/ui/shadcn/button";

import { useAddNoteToAccount } from "@/lib/api/routes/account/mutations";
import { NotesField } from "@/modules/pipeline/account-details/shared/notes-field";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

const FormSchema = Yup.object().shape({
  note: Yup.string().min(2, "").required(""),
});

interface AddAccountNoteFormI {
  note: string;
}

export const AccountDetailsTabHistoryAddAccountNote = ({
  className,
}: {
  className?: string;
}) => {
  const {
    account,
    campaign,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const { mutateAsync: addNoteToAccount } = useAddNoteToAccount(
    campaign?.id as string,
    account?.id as string
  );

  const handleSubmit = async (
    data: AddAccountNoteFormI,
    { resetForm }: FormikHelpers<AddAccountNoteFormI>
  ) => {
    const resp = await addNoteToAccount(data.note);

    if (resp.status === 200) {
      reloadAccountHistory();
      resetForm();
    }
  };

  return (
    <div className={clsxMerge("rounded-xl border bg-white p-4", className)}>
      <Formik
        enableReinitialize={true}
        validateOnMount
        initialValues={{ note: "" }}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form className="flex gap-2">
            <NotesField
              name="note"
              textAreaClassName="mb-0"
              errors={errors.note}
              touched={touched.note}
            />

            <div className="flex items-end">
              <Button type="submit" disabled={isSubmitting || !isValid}>
                Add note
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
