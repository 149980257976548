import { useEffect, useRef, MutableRefObject } from "react";

import { TIME_INTERVALS_MS } from "shared/lib/constants/time";

type UseIntervalI = (
  asyncCallback: (intervalId?: number, ...rest: unknown[]) => unknown,
  intervalMs: number,
  dependenciesArray?: unknown[]
) => {
  intervalRef: MutableRefObject<number | undefined>;
  cancelInterval: () => void;
  runInterval: () => void;
};

export const useInterval: UseIntervalI = (
  asyncCallback,
  intervalMs = TIME_INTERVALS_MS.ONE_SECOND,
  dependenciesArray = []
) => {
  const intervalRef = useRef<number>();

  const isIntervalActiveRef = useRef(true);

  const cancelInterval = () => {
    isIntervalActiveRef.current = false;

    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
    }
  };

  const runInterval = () => {
    isIntervalActiveRef.current = true;
  };

  useEffect(() => {
    if (!isIntervalActiveRef.current) {
      return;
    }

    intervalRef.current = window.setInterval(() => {
      void asyncCallback(intervalRef.current);
    }, intervalMs);

    return () => {
      window.clearInterval(intervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesArray);

  return {
    intervalRef,
    cancelInterval,
    runInterval,
  };
};
