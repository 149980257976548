import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import _isEmpty from "lodash/isEmpty";

import { Tooltip, TooltipContent, TooltipPlacementI } from "shared/ui/tooltip";
import { clsxMerge } from "shared/lib/helpers";
import { useTimeout } from "shared/lib/hooks/use-timeout";

export type FeatureIntroTooltipButtonI = {
  text: string;
  className?: string;
  actionHandler?: (onClose: () => void) => void;
} | null;

export interface FeatureIntroTooltipPropsI {
  referenceId?: string;
  placement?: TooltipPlacementI;
  title: ReactNode;
  description: ReactNode;
  buttons?: {
    primary: FeatureIntroTooltipButtonI;
    secondary?: FeatureIntroTooltipButtonI;
  };

  shouldOutlineReference?: boolean;
  tooltipContentClassName?: string;
  secondaryFooterContent?: ReactNode;
}

export type FeatureIntroTooltipStepI = Omit<
  FeatureIntroTooltipPropsI,
  "referenceId"
> & {
  delayMs?: number;
};

export type FeatureIntroTooltipGuideStepsI = Record<
  string,
  FeatureIntroTooltipStepI
>;

interface FeatureIntroTooltipGuidePropsI {
  isActive?: boolean;
  // The key is the step reference element (tooltip trigger) id, the value
  // the tooltip content definition.
  steps: FeatureIntroTooltipGuideStepsI;
}

type FeatureIntroTooltipGuideContentPropsI = Pick<
  FeatureIntroTooltipGuidePropsI,
  "steps"
>;

export const FeatureIntroTooltip: FC<FeatureIntroTooltipPropsI> = ({
  referenceId,
  placement,
  title,
  description,
  buttons,
  tooltipContentClassName,
  secondaryFooterContent,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const hasFooter = secondaryFooterContent || buttons;

  return (
    <Tooltip
      isInitiallyOpen
      isOpen={isOpen}
      placement={placement}
      referenceId={referenceId}
      arrowFillColor="#242424"
      offsetPx={20}
    >
      <TooltipContent
        className={clsxMerge(
          "z-10 flex w-[276px] flex-col",
          "rounded-lg bg-[#5C24D3] p-6 text-white",
          "shadow-[0px_4px_16px_0px_rgba(0,0,0,0.10)]",
          "animate-fadein",
          tooltipContentClassName
        )}
      >
        <h3 className="b-typography-h3">{title}</h3>
        <div className="typography-body-4 mt-2">{description}</div>

        {hasFooter && (
          <div className={"mt-4 flex items-center justify-between gap-2"}>
            <div className="typography-body-5-medium">
              {secondaryFooterContent}
            </div>

            <div className="flex flex-row-reverse gap-2">
              {[buttons?.primary, buttons?.secondary]?.map(
                (button, idx) =>
                  button && (
                    <button
                      key={idx}
                      onClick={() => button.actionHandler?.(handleClose)}
                      className={clsxMerge(
                        {
                          "btn-ae-default": idx === 0,
                          "btn-nofill text-white": idx === 1,
                        },
                        button?.className
                      )}
                    >
                      {button.text}
                    </button>
                  )
              )}
            </div>
          </div>
        )}
      </TooltipContent>
    </Tooltip>
  );
};

const setReferenceElementBorder = (referenceId: string, isActive: boolean) => {
  const referenceElement = document.getElementById(referenceId);

  if (referenceElement) {
    referenceElement.style.borderRadius = isActive ? "4px" : "";
    referenceElement.style.outline = isActive ? "3px solid #5C24D3" : "";
    referenceElement.style.outlineOffset = isActive ? "4px" : "";
  }
};

const FeatureIntroTooltipGuide: FC<FeatureIntroTooltipGuidePropsI> = ({
  isActive = false,
  steps,
}) =>
  isActive && Object.keys(steps).length > 0 ? (
    <FeatureIntroTooltipGuideContent steps={steps} />
  ) : null;

const FeatureIntroTooltipGuideContent: FC<
  FeatureIntroTooltipGuideContentPropsI
> = ({ steps }) => {
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const referenceIds = useMemo(() => Object.keys(steps), [steps]);
  const [activeStepId, setActiveStepId] = useState<string | null>(
    referenceIds[0]
  );

  useTimeout(() => setIsReadyToRender(true), 400);

  useEffect(() => {
    if (referenceIds.length > 0) {
      referenceIds.forEach((referenceId) => {
        const shouldOutlineReference =
          steps[referenceId].shouldOutlineReference ?? true;

        if (shouldOutlineReference) {
          setReferenceElementBorder(referenceId, activeStepId === referenceId);
        }
      });
    }

    return () => {
      if (referenceIds.length > 0) {
        referenceIds.forEach((referenceId) => {
          const shouldOutlineReference =
            steps[referenceId].shouldOutlineReference ?? true;

          if (shouldOutlineReference) {
            setReferenceElementBorder(referenceId, false);
          }
        });
      }
    };
  }, [activeStepId, referenceIds]);

  const nextStep = useCallback(() => {
    if (activeStepId === null) {
      return;
    }

    const idx = referenceIds.indexOf(activeStepId);

    if (idx < referenceIds.length - 1) {
      const nextStepId = referenceIds[idx + 1];
      const { delayMs } = steps[nextStepId];

      if (delayMs && delayMs > 0) {
        setTimeout(() => {
          setActiveStepId(referenceIds[idx + 1]);
        }, delayMs);
      } else {
        setActiveStepId(referenceIds[idx + 1]);
      }
    } else {
      setActiveStepId(null);
    }
  }, [referenceIds, activeStepId]);

  const secondaryFooterContent = useMemo(() => {
    if (!activeStepId) {
      return null;
    }

    const idx = referenceIds.indexOf(activeStepId);
    return `${idx + 1} of ${referenceIds.length}`;
  }, [activeStepId, referenceIds]);

  if (!isReadyToRender) {
    return null;
  }

  return referenceIds.map((referenceId) => {
    const {
      placement,
      title,
      description,
      buttons,
      tooltipContentClassName,
      shouldOutlineReference,
    } = steps[referenceId];

    const { primary, secondary } = buttons || {};
    const isLastStep = referenceId === referenceIds[referenceIds.length - 1];

    return (
      activeStepId === referenceId && (
        <FeatureIntroTooltip
          key={referenceId}
          referenceId={referenceId}
          placement={placement}
          title={title}
          description={description}
          tooltipContentClassName={tooltipContentClassName}
          shouldOutlineReference={shouldOutlineReference}
          secondaryFooterContent={secondaryFooterContent}
          buttons={
            _isEmpty(buttons)
              ? undefined
              : {
                  primary: {
                    text: primary?.text || (isLastStep ? "Finish" : "Next"),
                    actionHandler: (onClose) => {
                      nextStep();

                      primary?.actionHandler?.(onClose);
                    },
                  },
                  secondary,
                }
          }
        />
      )
    );
  });
};

export default FeatureIntroTooltipGuide;
