import { FC } from "react";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

import { clsxMerge, fixUrl, formatNumbers } from "shared/lib/helpers";

import {
  LabelValueV2,
  createRecord,
} from "@/components/modules/pipeline/account-details/shared/label-value";

import { formatMoneyDollars } from "shared/lib/helpers/numbers";
import { ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME } from "@/modules/pipeline/account-details/constants";
import { modal } from "@/modals/index";
import { EDIT_ACCOUNT_MODAL_ID } from "@/modals/edit-account-modal";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

const Header: FC<PropsWithClassNameI> = ({ className }) => {
  return (
    <div
      className={clsxMerge(
        "flex w-full items-center justify-between",
        className
      )}
    >
      <h6>Details</h6>
      <div>
        <button
          className={ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME}
          onClick={() => modal.trigger(EDIT_ACCOUNT_MODAL_ID)}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export const AccountSection = ({ className }: { className?: string }) => {
  const { campaign, account, accountDetailsApi } = useAccountDetailsContext();

  const accountInfoItems = [
    createRecord("Campaign Name", campaign?.name || null),
    createRecord(
      "Website",
      account?.website ? (
        <a
          className="ae-link w-full cursor-pointer"
          target="_blank"
          href={fixUrl(account?.website)}
          rel="noreferrer"
        >
          {fixUrl(account?.website)}
        </a>
      ) : null,
      "truncate h-5"
    ),
    createRecord(
      "Revenue",
      account?.revenue
        ? `${formatMoneyDollars(account.revenue, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`
        : null
    ),
    createRecord(
      "Employees",
      account?.employee_count
        ? `${formatNumbers(account?.employee_count)}`
        : null
    ),
    createRecord("Industry", account?.industry || null),
    createRecord("HQ State", account?.hq_state || null),
  ];

  return (
    <AccountDetailsSectionContainer title={<Header />} className={className}>
      <DataStatesWrapper
        viewName="Details"
        api={accountDetailsApi}
        loading={<CenteredSpinner className="h-[120px]" />}
      >
        <div className="mt-2 flex w-full flex-col gap-5">
          <h6 className="typography-header-7-bold">{account?.name}</h6>

          {accountInfoItems?.map((record, i) => (
            <LabelValueV2 key={i} {...record} />
          ))}
        </div>
      </DataStatesWrapper>
    </AccountDetailsSectionContainer>
  );
};
