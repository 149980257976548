import { FC, ReactNode, useRef } from "react";
import { ChartBarSquareIcon } from "@heroicons/react/24/outline";

import { useDisclosure } from "shared/lib/hooks/use-disclosure";
import { useOnClickOutside } from "shared/lib/hooks/use-on-click-outside";
import {
  clsxMerge,
  formatNumbers,
  getFullEntityName,
  pluralizeNoun,
} from "shared/lib/helpers";
import { useGlobalContext } from "@/hooks/use-global-context";
import Avatar from "shared/ui/avatar";
import { CenteredSpinner } from "shared/ui";
import { useGoalTracker } from "@/hooks/use-goal-tracker";
import { useFetchTodayPerformance } from "@/api/routes/performance/queries/queries";
import { formatDurationToHoursAndMinutesString } from "@/helpers/date";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";

interface PerformanceDetailsPropsI {
  className?: string;
  value?: ReactNode;
  label: string;
}

const SECTION_NAME = "Today’s performance";

const PerformanceDetail: FC<PerformanceDetailsPropsI> = ({
  className,
  value,
  label,
}) => (
  <div className={clsxMerge("flex flex-col text-black", className)}>
    <span className="typography-body-1-bold">{value ?? "N/A"}</span>
    <span className="typography-body-4-semibold">{label}</span>
  </div>
);

const TodayPerformanceContent = () => {
  const { glencocoUser } = useGlobalContext();
  const { progress, weeklyQualifiedMeetings, weeklyQualifiedMeetingsTarget } =
    useGoalTracker();
  const todayPerformanceApi = useFetchTodayPerformance();

  const todayPerformance = todayPerformanceApi.data?.data;

  return (
    <div
      className={clsxMerge(
        "absolute right-4 top-12",
        "w-[240px] animate-fadein rounded-xl px-7 py-5",
        "bg-white/60 backdrop-blur-[10px]",
        "shadow-[0px_0px_4px_4px_rgba(0,0,0,0.06)]"
      )}
    >
      <DataStatesWrapper
        viewName={SECTION_NAME}
        api={todayPerformanceApi}
        loading={<CenteredSpinner className="h-[120px]" />}
      >
        <div className="flex animate-fadein flex-col items-center justify-center gap-1.5">
          <span className="typography-body-4-bold">{SECTION_NAME}</span>

          <Avatar
            src={todayPerformance?.profile_pic_url}
            placeholderText={getFullEntityName(glencocoUser)}
            className="h-20 w-20"
          />

          <div className="flex w-full flex-col gap-1">
            <div className="flex items-center justify-between text-black">
              <span className="typography-body-5-bold">Goal tracker</span>

              <span className="typography-body-5">
                {weeklyQualifiedMeetings}/{weeklyQualifiedMeetingsTarget}{" "}
                {pluralizeNoun("QM", weeklyQualifiedMeetingsTarget as number)}
              </span>
            </div>

            <div className="h-[5px] w-full overflow-hidden rounded bg-[#D1FFE9]">
              <div
                style={{ width: `${progress}%` }}
                className="h-full bg-[#67F8B2]"
              />
            </div>
          </div>

          <hr className="mt-5 h-[1px] w-full rounded-full bg-[#A0A0A0]" />

          <div className="flex w-full flex-col gap-2">
            <PerformanceDetail
              value={formatDurationToHoursAndMinutesString({
                minutes:
                  todayPerformance?.performance.time_on_dialer_in_minutes,
              })}
              label="Time on dialer"
            />
            <PerformanceDetail
              value={formatNumbers(todayPerformance?.performance.calls)}
              label="Dials"
            />
            <PerformanceDetail
              value={formatNumbers(todayPerformance?.performance.conversations)}
              label="Conversations"
            />
            <PerformanceDetail
              value={formatNumbers(
                todayPerformance?.performance.booked_meetings
              )}
              label="Meetings booked"
            />
          </div>
        </div>
      </DataStatesWrapper>
    </div>
  );
};

export const TodayPerformanceButton = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  useOnClickOutside(containerRef, onClose);

  return (
    <div ref={containerRef} className="relative">
      <button
        className={clsxMerge(
          "group btn btn-ghost relative p-2 text-white",
          "hover:border-[#F88167]/50 hover:bg-[#F88167]/20"
        )}
        onClick={onOpen}
      >
        <ChartBarSquareIcon className="h-8 w-8 text-white group-hover:animate-wiggle" />
      </button>

      {isOpen && <TodayPerformanceContent />}
    </div>
  );
};
