import { AxiosInstance } from "axios";

import { QCsAndPricingResponseI } from "shared/lib/interfaces/qualification-criteria";

export const QualificationCriteriaAPIEndpoints = (api: AxiosInstance) => ({
  getQCsAndPricing: (campaignId: string) =>
    api.get<QCsAndPricingResponseI>(
      `/v1/campaign/${campaignId}/qualification_criteria`
    ),
});
