import { FC } from "react";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers";

interface CampaignCardBonusPricePropsI extends PropsWithClassNameI {
  price: string | undefined;
}

export const CampaignCardBonusPrice: FC<CampaignCardBonusPricePropsI> = ({
  className,
  price,
}) => {
  if (!price) {
    return null;
  }

  return (
    <div className={clsxMerge("flex-center relative h-9 w-[200px]", className)}>
      <span className="typography-header-6-bold relative z-10 -ml-4 text-white">
        + {price} Bonus
      </span>
      <svg
        className="absolute left-0 top-0"
        xmlns="http://www.w3.org/2000/svg"
        width="197"
        height="36"
        viewBox="0 0 197 36"
        fill="none"
      >
        <path
          d="M0 32V4C0 1.79086 1.79086 0 4 0H192.807C196.138 0 198.01 3.83356 195.961 6.46017L188.879 15.5398C187.751 16.986 187.751 19.014 188.879 20.4602L195.961 29.5398C198.01 32.1664 196.138 36 192.807 36H4C1.79086 36 0 34.2091 0 32Z"
          fill="#69B55D"
        />
      </svg>
    </div>
  );
};
