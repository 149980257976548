export const DEFAULT_MARKDOWN_TEXT = `# This is a heading h1

And here is plain text

## This is a heading h2

You can bold text like this

*This will be italic*

* This is a bulleted list
* Here is another item in the list`;
