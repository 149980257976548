import DomPurifier from "dompurify";

export const DOM_PURIFIER_CONFIG: DomPurifier.Config = {
  FORCE_BODY: false,
  ALLOWED_TAGS: [
    "figure",
    "oembed",
    "iframe",
    "p",
    "a",
    "ul",
    "li",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "strong",
    "em",
    "blockquote",
    "code",
    "pre",
    "br",
    "hr",
    "img",
    "div",
    "span",
    "s",
  ],
  ALLOWED_ATTR: ["url", "alt", "title", "href", "target", "rel", "src"],
};
