import { FC, ReactNode } from "react";

import Avatar from "shared/ui/avatar";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

import { CampaignCardBonusPrice } from "./shared/campaign-card-bonus-price";

export interface CampaignCardMiniI extends PropsWithClassNameI {
  imgSrc?: string;
  title?: string;
  price?: string;
  priceDescription?: ReactNode;
  bonusPrice?: string;
  labels?: ReactNode;
  actions?: ReactNode;
  onClick?: () => void;
}

export const CampaignCardMini: FC<CampaignCardMiniI> = ({
  imgSrc,
  title,
  price,
  priceDescription,
  bonusPrice,
  labels,
  actions,
  onClick,
  className,
}) => {
  return (
    <div
      className={clsxMerge("card rounded-lg bg-[white] p-8", className)}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <section className="flex gap-4">
          <div>
            <Avatar
              src={imgSrc}
              placeholderText={title}
              className="h-[88px] w-[88px] rounded-lg border-[1px]"
            />
          </div>

          <div>
            <div className="b-typography-h2 mb-2 font-medium">{title}</div>

            <div className="mb-5 flex items-center">
              <div className="ae-typography-h1">{price}</div>

              {priceDescription && (
                <>
                  <div className="divider divider-horizontal" />
                  {priceDescription}
                </>
              )}

              <CampaignCardBonusPrice className="ml-3" price={bonusPrice} />
            </div>

            <section>{labels}</section>
          </div>
        </section>

        <section>{actions}</section>
      </div>
    </div>
  );
};
