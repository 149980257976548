import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useApiClient } from "@/context/api-client";
import { UpdateCampaignCustomScriptRequestParamsI } from "@/api/glencoco";
import { AccountDetailsQueryKeys } from "@/components/modules/pipeline/account-details/queries/keys";
import {
  GetShouldSendEmailRequestParamsI,
  GetShouldSendEmailResponseI,
} from "@/api/routes/email";
import { usePollableMutation } from "shared/lib/hooks/use-react-query-extensions";
import { AxiosResponse } from "axios";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

interface UseUpdateCampaignScriptParamsI {
  campaignId: string;
  params: UpdateCampaignCustomScriptRequestParamsI;
}

export const useUpdateCampaignScript = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ campaignId, params }: UseUpdateCampaignScriptParamsI) =>
      api.updateCampaignCustomScript(campaignId, params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: AccountDetailsQueryKeys.script,
      });
    },
  });
};

export const useResetCampaignScript = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (campaignId: string) =>
      api.resetCampaignCustomScript(campaignId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: AccountDetailsQueryKeys.script,
      });
    },
  });
};

interface GetShouldSendEmailFnParamsI {
  campaignId: string;
  callId: string;
  parameters: GetShouldSendEmailRequestParamsI;
}

export const getShouldSendFollowUpEmailFn = (
  api: ReturnType<typeof useApiClient>,
  setEmailFollowUpRequestParams?: (
    params: GetShouldSendEmailRequestParamsI
  ) => void
) => {
  return async ({
    campaignId,
    callId,
    parameters,
  }: GetShouldSendEmailFnParamsI) => {
    /** We need to set the parameters to the context
     * So we could try polling last selected parameters
     * after disposition submission or booked meeting
     */
    setEmailFollowUpRequestParams?.(parameters);

    return api.email.getShouldSendFollowUpEmail(campaignId, callId, parameters);
  };
};

export const useShouldSendFollowUpEmail = () => {
  const api = useApiClient();
  const { setEmailFollowUpRequestParams } = useAccountDetailsContext();

  const mutationFn = getShouldSendFollowUpEmailFn(
    api,
    setEmailFollowUpRequestParams
  );

  return useMutation({
    mutationFn: mutationFn,
  });
};

export const getShouldSendFollowUpEmailAxiosFn =
  (api: ReturnType<typeof useApiClient>) =>
  async ({ campaignId, callId, parameters }: GetShouldSendEmailFnParamsI) => {
    const response: AxiosResponse<GetShouldSendEmailResponseI> =
      await api.email.getShouldSendFollowUpEmail(
        campaignId,
        callId,
        parameters
      );
    return response.data; // Extract the data from AxiosResponse
  };

export const useShouldSendFollowUpEmailPolling = () => {
  const api = useApiClient();
  const mutationFn = getShouldSendFollowUpEmailAxiosFn(api);

  return usePollableMutation<
    GetShouldSendEmailResponseI,
    GetShouldSendEmailFnParamsI
  >(mutationFn, {
    queryKey: AccountDetailsQueryKeys.shouldSendEmailPolling,
    pollingInterval: 1000,
    maxAttempts: 20,
    shouldContinuePolling: (data) => !data.email_subject || !data.email_body,
  });
};
