import { FC } from "react";

import {
  NOTIFICATION_ACTION_TYPES,
  createNotificationAction,
} from "shared/ui/notifications/notification-generic/base-parts/actions";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

import { NotificationBase } from "@/modules/notifications/notification/base";
import { NotificationI } from "@/interfaces/notification";
import { openAccountDetails } from "@/helpers/account-details";

type NotificationQCPreCheckSucceededPropsI = NotificationI &
  PropsWithClassNameI;

export const NotificationQCPreCheckSucceeded: FC<
  NotificationQCPreCheckSucceededPropsI
> = (props) => {
  const {
    id,
    campaign_id,
    account_id,

    campaign_name,
    contact_name,

    is_seen,
    created_at,
    className,
  } = props;

  return (
    <NotificationBase
      id={id}
      isUnread={!is_seen}
      className={className}
      avatar={props}
      description={{
        text: (
          <>
            Well done! Your meeting with{" "}
            <b>{contact_name || "N/A"} has been pre-qualified</b>. As long as
            the prospect attends the meeting we will mark this qualified.
          </>
        ),
        date: created_at,
        campaignName: campaign_name,
      }}
      actions={[
        createNotificationAction(NOTIFICATION_ACTION_TYPES.PRIMARY, "-", () => {
          openAccountDetails({
            campaignId: campaign_id,
            accountId: account_id,
          });
        }),
      ]}
    />
  );
};
