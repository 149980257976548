import { WEBSOCKET_CONNECTION_TYPES, WEBSOCKET_EVENT_TYPES } from "./constants";
import { socketEventHandlers } from "./event-handlers";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

export interface WebsocketEventsI {
  connectionType: ValueOfObjectFields<typeof WEBSOCKET_CONNECTION_TYPES>;
  eventType: ValueOfObjectFields<typeof WEBSOCKET_EVENT_TYPES>;
  data: any;
}

export const websocketEvents = ({
  connectionType,
  eventType,
  data,
}: WebsocketEventsI) => {
  switch (connectionType) {
    case WEBSOCKET_CONNECTION_TYPES.GENERAL:
      switch (eventType) {
        case WEBSOCKET_EVENT_TYPES.AUTH_SUCCESS:
          socketEventHandlers.websocket.authSuccess(connectionType);
          break;
        case WEBSOCKET_EVENT_TYPES.GLOBAL_MEETINGS_BOOKED:
          socketEventHandlers.globalToasts.meetings(data);
          break;
        case WEBSOCKET_EVENT_TYPES.GLOBAL_MEETINGS_QUALIFIED:
          socketEventHandlers.globalToasts.meetings(data);
          break;
        case WEBSOCKET_EVENT_TYPES.NOTIFICATIONS_NEW:
          socketEventHandlers.notifications.update();
          break;
        case WEBSOCKET_EVENT_TYPES.CHAT:
          socketEventHandlers.messaging.receiveMessage(data);
          break;
        case WEBSOCKET_EVENT_TYPES.DIALER_LIST_RECEIVE_CALL_STATUS_UPDATE:
          socketEventHandlers.dialer.list.updateCallStatus(data);
          break;
        case WEBSOCKET_EVENT_TYPES.ERROR:
          socketEventHandlers.websocket.handleError(data);
          break;
      }
      break;
    case WEBSOCKET_CONNECTION_TYPES.CALL_COPILOT:
      switch (eventType) {
        case WEBSOCKET_EVENT_TYPES.AUTH_SUCCESS:
          socketEventHandlers.websocket.authSuccess(connectionType);
          break;
        case WEBSOCKET_EVENT_TYPES.CALL_COPILOT_MESSAGE_COMPETITOR:
          socketEventHandlers.callCopilot.messageReceived(data);
          break;
        case WEBSOCKET_EVENT_TYPES.ERROR:
          socketEventHandlers.websocket.handleError(data);
          break;
      }
      break;
  }
};
