import { LoadingComponent } from "@/components/modules/loading";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { withDoubleClickDisabled } from "@/helpers/utils";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useDialerDirectCall } from "@/hooks/dialer/use-dialer-direct-call";
import { useMemo, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";
import wait from "wait";
import { dd } from "@/helpers/datadog";
import { LOG_CATEGORIES } from "@/constants/logs";

export const PhoneLink = ({
  contactId,
  phoneNumber,
  className,
  isDNC,
  isLoadingComponent,
}: {
  contactId?: string;
  phoneNumber?: string;
  className?: string;
  isDNC?: boolean;
  isLoadingComponent?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const status = useDialerCallStatus();
  const { startDirectCall } = useDialerDirectCall();

  const startCallIfAvailable = useMemo(
    () =>
      withDoubleClickDisabled(async () => {
        /**
         * User is able to call only when current call is not active
         */

        dd.log(
          `${LOG_CATEGORIES.MAXED_DAILER} - attempt to start direct call`,
          {
            data: {
              status,
              isDNC,
              callParams: {
                contactId,
                phoneNumber,
              },
            },
          }
        );

        if (DIALER_CALL_STATUS.DURING !== status) {
          setIsLoading(true);
          startDirectCall({
            contact: {
              id: contactId,
              phone: phoneNumber,
            },
          });
        }

        await wait(3000);

        setIsLoading(false);
      }),
    [phoneNumber, status]
  );

  return (
    <button
      className={clsxMerge(
        "btn-ae-text relative h-[21px] min-h-[21px] !overflow-visible p-0",
        {
          "disabled cursor-not-allowed text-[#CCCCCC] line-through": isDNC,
          "ae-link cursor-pointer": !isDNC,
        },
        className
      )}
      onClick={isDNC ? undefined : startCallIfAvailable}
    >
      {phoneNumber}

      {isLoadingComponent && isLoading && (
        <LoadingComponent className="absolute right-[-60px] top-[-16px]" />
      )}
    </button>
  );
};
