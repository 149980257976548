/**
 * 3rd Party Libs
 */
import React, {
  createContext,
  Dispatch,
  EventHandler,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";
import toast from "react-hot-toast";
import throttle from "lodash/throttle";
import wait from "wait";

/**
 * Websockets & API
 */
import { dialerListSocketActions } from "lib/websockets/dialer/socket-actions";
import { WSErrorListDialingI } from "lib/websockets/error";
import { WEBSOCKET_CONNECTION_TYPES } from "lib/websockets/constants";

/**
 * Constants
 */
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { DIALER_MODES } from "@/constants/dialer";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { GLOBAL_DIALER_STATE } from "@/constants/window-globals";
import { LOG_CATEGORIES } from "@/constants/logs";
import { WIDGETS } from "@/constants/widgets";

/**
 * Interfaces
 */
import { CallsMapI } from "./use-ws-list-dialing";
import {
  HookLeadsQueueManagerI,
  useLeadsQueueManager,
} from "./use-leads-queue-manager";

/**
 * Context & Providers
 */
import { defaultContextObject } from "@/context/dialer-v1/default-context";
import { DialerV2Provider } from "@/context/dialer-v2";

/**
 * Helpers
 */
import { askPermissionMic } from "@/helpers/calling";
import { asyncGet } from "@/helpers/context";
import { checkIfClient } from "shared/lib/helpers";
import { dd } from "@/helpers/datadog";
import { isDialerWidgetOpen } from "@/helpers/widgets";
import { widgets } from "@/components/shared/widgets";

/**
 * Components
 */
import { DialerWidgetController } from "./dialer-widget-controller";
import { TwilioDeviceRegistrator } from "./twilio-device-registrator";
import { CustomEventData_ListDialing_ConnectedLeadI } from "@/interfaces/events/list-dialing";
import { DIALER_LIST_DIALING_STATUS } from "shared/lib/constants/dialer";
import { PipelineListContactI } from "shared/lib/interfaces/lists";
import { WSClosedDataI } from "@/interfaces/events";

interface DialerGlobalProviderPropsI {
  children?: ReactElement;
}

export interface DialerGlobalContextI extends Partial<HookLeadsQueueManagerI> {
  isEnabled?: boolean;
  setIsEnabled: Dispatch<SetStateAction<boolean>>;

  isSessionClosing?: boolean;
  setIsSessionClosing: Dispatch<SetStateAction<boolean>>;

  twilioIdentity?: string;
  setTwilioIdentity: Dispatch<SetStateAction<string | undefined>>;

  listId?: string;
  setListId: Dispatch<SetStateAction<string | undefined>>;

  isTwilioTokenUpdating?: boolean;
  setIsTwilioTokenUpdating: Dispatch<SetStateAction<boolean>>;

  startSession: (listId: string) => void;
  endSession: () => void;
}

export const DialerGlobalContext = createContext<DialerGlobalContextI>({
  setIsEnabled: () => {},
  setListId: () => {},
  setTwilioIdentity: () => {},
  setIsSessionClosing: () => {},
  startSession: () => {},
  endSession: () => {},
  setIsTwilioTokenUpdating: () => {},
});

export const DialerGlobalProvider: FC<DialerGlobalProviderPropsI> = ({
  children,
}) => {
  /**
   * sets list id that is required to start dailing session
   */
  const [listId, setListId] = useState<string>();
  const [twilioIdentity, setTwilioIdentity] = useState<string>();

  const LeadsQueueManager = useLeadsQueueManager({ listId });

  const {
    setIsListenWS,
    clear: resetLeadsQueueManager,

    calls,
    callsMetadataMap,
    leadsMap,
    accountDetailsMap,
    leadsQueue,
    leadsDialing,
    leadsProcessed,
    connectedLead,

    pauseQueue,
    resumeQueue,

    setLeadsQueue,
    setLeadsDialing,
    setLeadsProcessed,
    setConnectedLead,
    setLeadsMap,
    setIsIdle,

    updateConnectedCall,
    isFreeSpotForDialing,
    moveToDialingList,
    saveCallDate,
    processTimedoutCalls,
  } = LeadsQueueManager;

  /**
   * Shouldn't be executed more than once in 3 seconds
   */
  const throttledProcessTimedoutCalls = useMemo(() => {
    return throttle(processTimedoutCalls, 3000);
  }, [processTimedoutCalls]);

  /**
   * Indicates if Global dialng mode is enabled
   */
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSessionClosing, setIsSessionClosing] = useState(false);
  const [isError, setIsError] = useState(false);
  const endSessionAttemptsRef = useRef(0);

  /**
   * Campaign Id of last connected lead
   *
   * NOTE even when lead was disconnected we need campaign id
   * to be able to execute all kinds of actions inside
   * of dialer widget
   */
  const [campaignId, setCampaignId] = useState<string>();

  /**
   * When Twilio token is updaing all leads should stop dialing
   * and there should be no connected lead or opened widget
   */
  const [isTwilioTokenUpdating, setIsTwilioTokenUpdating] =
    useState<boolean>(false);

  const startSession = async (listId: string) => {
    setIsError(false);
    setIsTwilioTokenUpdating(false);
    setIsSessionClosing(false);
    endSessionAttemptsRef.current = 0;

    /**
     * Ask for permission to use mic and speaker
     */
    const isMicAllowed = await askPermissionMic().catch((e) => e);
    if (!isMicAllowed) {
      toast.error("Permission to use audio devices is required to make calls.");
      endSession();
      return;
    }

    setIsListenWS?.(true);
    setListId(listId);
    setIsEnabled(true);

    dialerListSocketActions.startSession(listId);

    /**
     * More logs to figure out why sometimes leads fail to start dialing
     */
    await wait(3000);

    const isEnabled = await asyncGet(setIsEnabled);
    const isSessionClosing = await asyncGet(setIsSessionClosing);
    const leadsQueue = await asyncGet(setLeadsQueue);

    if (!isEnabled || isSessionClosing || !leadsQueue?.length) return;

    const leadsDialing = await asyncGet(setLeadsDialing);
    const processedLeads = await asyncGet(setLeadsProcessed);

    /**
     * No movement of leads in leads queue
     */
    if (!leadsDialing?.length && !processedLeads?.length) {
      dd.rum.error(
        `${ERROR_CATEGORIES.DIALER_LIST}[START SESSION] calling experience hasn't started`,
        {
          data: (window as any)[GLOBAL_DIALER_STATE],
        }
      );
    }
  };

  const endSession = async () => {
    const listId = await asyncGet(setListId);

    setIsSessionClosing(true);
    dialerListSocketActions.killAllCallsByListId(listId as string);
    setTwilioIdentity(undefined);
    setIsError(false);
    setIsListenWS?.(false);
    setIsTwilioTokenUpdating(false);
    resetLeadsQueueManager();
    setListId(undefined);
    widgets.close({ id: WIDGETS.MAXED_DIALER });

    /**
     * To make sure there are no leftovers after useEffects
     */
    setTimeout(() => {
      resetLeadsQueueManager();
      setIsEnabled(false);
      setIsSessionClosing(false);
      endSessionAttemptsRef.current = 0;
    }, 1000);
  };

  /**
   * Initiates a call for a given membership ID.
   *
   * @param {string} membershipId - The membership ID of the lead to start a call for.
   *
   * The function retrieves the list ID and leads that are currently dialing.
   * If the lead is found in the dialing list, it starts a call via `dialerListSocketActions`.
   * This function is used in the `sessionQueueProcessor` to process the queue and start calls for eligible leads.
   */
  const startCall = async (membershipId: string) => {
    /**
     * Initiates an asynchronous operation after a delay to start a call if the specified membership ID is found in the leads dialing list.
     *
     * The function waits for 1 second before executing an asynchronous function that retrieves the current leads dialing list.
     * If the retrieved list contains a lead with the specified membership ID, it triggers the start of a call for that lead.
     */
    setTimeout(() => {
      (async () => {
        const listId = await asyncGet(setListId);
        const twilioIdentity = await asyncGet(setTwilioIdentity);
        const leadsDialing = await asyncGet(setLeadsDialing);

        if (
          leadsDialing.some((lead) => lead.list_membership_id === membershipId)
        ) {
          /**
           * Saving date when call request have been initiated
           * so we could timeout in time and user won't get stuck
           */
          saveCallDate(membershipId);
          dialerListSocketActions.startCall({
            listId: listId as string,
            membershipIds: [membershipId],
            identity_id: twilioIdentity as string,
            //TODO remove random error
            // membershipIds:
            //   Math.random() >= 0.5
            //     ? [membershipId]
            //     : ["20c48f4e-2cd8-4050-86de-73593508dc05"],
          });
        } else {
          dd.rum.error(
            `${ERROR_CATEGORIES.DIALER_LIST}[START CALL] - lead is not found in dialing list so it will not be initiated`,
            { data: { listId, membershipId, leadsDialing } }
          );
        }
      })();
    }, 1000);
  };

  /**
   * Processes the session queue and manages the dialing of leads.
   *
   * This function checks the current state of the leads queue, leads dialing, connected lead,
   * and Twilio token updating status to determine the necessary actions. It can end the session,
   * unpause the queue, or start dialing based on these conditions.
   *
   * The function is designed to handle the following scenarios:
   * - End the session if there are no leads in the queue, no leads dialing, no connected lead, and the dialer widget is closed.
   * - Unpause the queue if the dialer widget is closed, there is no connected lead, and the Twilio token is not updating.
   * - Start dialing if there are leads in the queue, a free spot for dialing, and no connected lead.
   * - Handle error scenarios appropriately, either by doing nothing if the widget is open or ending the session if an error has occurred.
   * - Process any timed-out calls by removing them from the dialing leads and moving them to processed leads.
   *
   * @async
   * @function sessionQueueProcessor
   */
  const sessionQueueProcessor = async () => {
    const isSessionClosing = await asyncGet(setIsSessionClosing);
    const isTwilioTokenUpdating = await asyncGet(setIsTwilioTokenUpdating);
    const isError = await asyncGet(setIsError);

    const leadsQueue = await asyncGet(setLeadsQueue);
    const leadsDialing = await asyncGet(setLeadsDialing);
    const connectedLead = await asyncGet(setConnectedLead);

    /**
     * If session is closing
     * OR
     * Error Occured BUT  widget is open
     *
     * do nothing
     */
    if (isSessionClosing || (isError && isDialerWidgetOpen())) return;

    /**
     * If error happened end session
     */
    if (isError) {
      endSession();
      return;
    }

    //TODO update description
    /**
     * Set idle status when there is no leads to call
     *
     * - There are no more leads left to process.
     * - There are no leads currently being dialed.
     * - No lead is currently connected.
     * - The dialer widget is not open
     * - for more than 3 seconds there is no change in leadsQueue state
     *
     * If all conditions are true, the session is considered complete, and `endSession()`
     * is invoked to finalize the session.
     */
    if (
      !leadsQueue.length &&
      !leadsDialing.length &&
      !connectedLead &&
      !isDialerWidgetOpen()
    ) {
      setIsIdle(true);

      return;
    }
    setIsIdle(false);

    /**
     * Should unpause queue
     */
    if (!isDialerWidgetOpen() && !connectedLead && !isTwilioTokenUpdating) {
      resumeQueue();
    } else {
      return;
    }

    /**
     * Should start dialing?
     *
     * is not on pause
     * leadsQueue is more than 1
     * leadsDialing has a free spot
     * widget is not open
     * there is no other connected lead
     */
    if (
      leadsQueue.length &&
      isFreeSpotForDialing(leadsDialing) &&
      !connectedLead
    ) {
      const handlerOnSuccessMoveToDialingList = (
        leadsToCall: PipelineListContactI[]
      ) => {
        leadsToCall?.map((lead) => {
          dd.rum.log(
            `${LOG_CATEGORIES.DIALER_LIST}[START CALL] - selected lead to call`,
            { data: { lead } }
          );

          startCall(lead.list_membership_id);
        });
      };

      /**
       * This will move leads to dialing list before
       * call gets executed
       */
      moveToDialingList(handlerOnSuccessMoveToDialingList);
    }

    /**
     * This will check if any dialing leads have timed out.
     * If there are any timed out leads this function
     * will remove them from the dialing leads
     * and place them into processed leads.
     *
     * Should be called  only once in 3 seconds
     */
    throttledProcessTimedoutCalls();
  };

  const intervalRef = useRef<number>();
  useEffect(() => {
    if (isEnabled && !isSessionClosing) {
      const sessionQueueInterval = window.setInterval(() => {
        sessionQueueProcessor();
      }, 1000);

      intervalRef.current = sessionQueueInterval;
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isEnabled, isSessionClosing]);

  /**
   * Executes necessary transformations when a lead becomes connected.
   *
   * When a connected lead is identified, this effect iterates through all leads currently dialing,
   * terminates the calls for leads that are not the connected lead, and updates the status
   * of the connected call accordingly.
   *
   * @function useEffect
   * @param {Object} connectedLead - The currently connected lead.
   */
  useEffect(() => {
    if (connectedLead?.list_membership_id) {
      leadsDialing.forEach((lead) => {
        if (lead.list_membership_id !== connectedLead?.list_membership_id)
          dialerListSocketActions.killCall({
            listId: listId as string,
            membershipId: lead.list_membership_id,
          });
      });

      updateConnectedCall(connectedLead?.list_membership_id);
    }
  }, [connectedLead]);

  /**
   * Handles the event of a lead being connected.
   *
   * This effect sets up an event listener for when a lead is connected. It pauses the queue,
   * finds the connected lead in the leads map, and sets necessary state variables such as the campaign ID
   * and connected lead details. Additionally, it prepares account details and triggers the widget with updated information.
   *
   * Here is schema for the connected lead
   *
   * [Twilio Event "incoming"] -> [Custom Event "LEAD_CONNECTED"] -> [UseEffect kill calls] -> [Function update calls map]
   *                                            ↓
   *                                       [Open Widget]
   * @function useEffect
   */
  useEffect(() => {
    const eventHandlerConnectedLead = async (
      event: CustomEvent<CustomEventData_ListDialing_ConnectedLeadI>
    ) => {
      const data = event.detail;

      pauseQueue();

      const allLeadsMap = await asyncGet(setLeadsMap);
      const allLeads = Array.from(allLeadsMap).map((pair) => pair[1]);

      /**
       * Find connected lead
       */
      const connectedLead = allLeads.find(
        (lead) => lead.list_membership_id === data.membershipId
      );

      if (!connectedLead) {
        dd.rum.error(
          `${ERROR_CATEGORIES.DIALER_LIST} - connected lead is not found`,
          {
            data: {
              eventData: data,
            },
          }
        );
        return;
      }

      setCampaignId(connectedLead.campaign_id);
      setConnectedLead(connectedLead);

      /**
       * Prepare account details
       */
      const accountDetails = accountDetailsMap?.get(
        connectedLead.list_membership_id
      );

      dd.rum.log(`${LOG_CATEGORIES.DIALER_LIST}[OPEN WIDGET]`, {
        data: {
          connectedLead: connectedLead,
          dialer_state: (window as any)[GLOBAL_DIALER_STATE],
        },
      });

      widgets.trigger({
        id: WIDGETS.MAXED_DIALER,
        config: {
          accountId: connectedLead.account_id,
          campaignId: connectedLead.campaign_id,
          accountDetails,
          connectedLead,
        },
      });
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.LIST_DIALING.LEAD_CONNECTED,
      eventHandlerConnectedLead as EventHandler<any>
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.LIST_DIALING.LEAD_CONNECTED,
        eventHandlerConnectedLead as EventHandler<any>
      );
    };
  }, []);

  /**
   * Effect hook to ignore connected calls that do not match the current connected lead's membership ID.
   *
   * This effect listens for changes to the `connectedLead` and `calls` dependencies.
   * When a connected lead is present, it filters out calls that are connected but have a different
   * membership ID than the connected lead. It then updates the status of these calls to "ignored".
   *
   */
  useEffect(() => {
    const callsArray = Array.from(calls as CallsMapI, ([, data]) => data);
    const connectedCalls = callsArray.filter(
      (call) => call.status === DIALER_LIST_DIALING_STATUS.CONNECTED
    );

    /**
     * Check if there is no connected Leads or there is only single Connected call
     * basically check if there is no race updates
     */
    if (!connectedLead || connectedCalls.length <= 1) {
      return;
    }

    const connectedCallsToIgnore = connectedCalls.filter(
      (call) => connectedLead.list_membership_id !== call.membership_id
    );

    connectedCallsToIgnore.forEach((call) => {
      dialerListSocketActions.ignoreCall({
        listId: listId as string,
        membershipId: call.membership_id,
      });
    });
  }, [connectedLead, calls]);

  /**
   * useEffect hook that sets up event listeners for WebSocket errors and closures
   * during a dialing session. It handles errors and logs relevant information
   * using Datadog's RUM, as well as displaying error notifications to the user.
   *
   * The hook performs the following:
   * - Listens for a custom WebSocket error event (`CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.ERROR`)
   *   and triggers error handling logic when this event occurs.
   * - Listens for a WebSocket closed event (`CUSTOM_EVENTS.WEBSOCKETS.CLOSED`) and checks
   *   if the session was closed due to an error, triggering error handling logic if necessary.
   * - Logs errors using Datadog's RUM service for monitoring and alerting.
   * - Displays a toast notification to inform the user of the error.
   */
  useEffect(() => {
    const handleSessionError = (data?: WSErrorListDialingI) => {
      (async () => {
        setIsError(true);
        toast.error(
          "An error occurred with one of the dialing leads. Please try restart the session"
        );

        const listId = await asyncGet(setListId);
        const leadsDialing = await asyncGet(setLeadsDialing);
        const leadsProcessed = await asyncGet(setLeadsProcessed);

        const errorCode = data?.errorCode;

        dd.rum.error(
          `${ERROR_CATEGORIES.DIALER_LIST}${
            errorCode ? `[${errorCode}]` : ""
          } An error occurred with one of the dialing leads`,
          { data: { listId, leadsDialing, leadsProcessed } }
        );
      })();
    };

    const handleWSClosed = ({
      event,
    }: {
      event: CustomEvent<WSClosedDataI>;
    }) => {
      if (event?.detail?.connectionType !== WEBSOCKET_CONNECTION_TYPES.GENERAL)
        return;

      (async () => {
        const isError = await asyncGet(setIsError);
        const isSessionClosing = await asyncGet(setIsSessionClosing);
        const isEnabled = await asyncGet(setIsEnabled);

        /**
         * If socket closed without an error
         */
        if (isEnabled && !isError && !isSessionClosing) {
          setIsError(true);
          toast.error(
            "Dialing Session ended due to an error. Please reach out to glencoco team"
          );

          dd.rum.error(
            `${ERROR_CATEGORIES.DIALER_LIST} Dialing session ended due to websocket connection closed`,
            { data: (window as any)[GLOBAL_DIALER_STATE] }
          );
        }
      })();
    };

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.ERROR,
      handleSessionError as EventHandler<any>
    );

    window.document.addEventListener(
      CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
      handleWSClosed as EventHandler<any>
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.LIST_DIALING.ERROR,
        handleSessionError as EventHandler<any>
      );

      window.document.removeEventListener(
        CUSTOM_EVENTS.WEBSOCKETS.CLOSED,
        handleWSClosed as EventHandler<any>
      );
    };
  }, []);

  /**
   * For Debugging purposes
   */
  if (checkIfClient())
    (window as any)[GLOBAL_DIALER_STATE] = {
      campaignId,
      listId,

      calls,
      callsMetadataMap,
      leadsMap,
      leadsQueue,
      leadsDialing,
      leadsProcessed,
      connectedLead,

      isEnabled,
      isSessionClosing,
      isError,
      isTwilioTokenUpdating,
    };

  return (
    <DialerGlobalContext.Provider
      value={{
        twilioIdentity,
        setTwilioIdentity,

        startSession,
        endSession,
        isSessionClosing,
        setIsSessionClosing,

        isEnabled,
        setIsEnabled,
        listId,
        setListId,

        calls,
        callsMetadataMap,
        connectedLead,

        isTwilioTokenUpdating,
        setIsTwilioTokenUpdating,

        ...LeadsQueueManager,
      }}
    >
      <DialerV2Provider
        context={{
          ...defaultContextObject,
          campaignId: campaignId as string,
        }}
        config={{
          mode: DIALER_MODES.WIDGET_MINI_DIALER,
          disableTwilioProvider: !isEnabled,
        }}
      >
        {isEnabled && !isSessionClosing && <DialerWidgetController />}
        {isEnabled && !isSessionClosing && !!leadsQueue.length && (
          <TwilioDeviceRegistrator />
        )}

        {children}
      </DialerV2Provider>
    </DialerGlobalContext.Provider>
  );
};
