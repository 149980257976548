import {
  WEBSOCKET_CONNECTION_TYPES,
  WEBSOCKET_EVENT_TYPES,
} from "lib/websockets/constants";
import { getWebsocketInstance } from "@/helpers/websockets";

/**
 * SOCKET MESSAGES SEND TYPE ONLY
 */
export const callCopilotSocketActions = {
  /**
   * This function starts listening to a call via a WebSocket connection.
   * It establishes a WebSocket connection using a call copilot connection type and sends
   * a message formatted as a JSON string. The JSON object includes the event type and call_id
   *
   * @param call_id - string
   */
  startListening: (call_id: string) => {
    const ws = getWebsocketInstance(WEBSOCKET_CONNECTION_TYPES.CALL_COPILOT);

    ws?.send(
      JSON.stringify({
        msg_type: WEBSOCKET_EVENT_TYPES.CALL_COPILOT_START_LISTENING,
        msg_dialer_copilot: { call_id },
      })
    );
  },
};
