// React and types
import { CSSProperties, FC, useEffect, useState } from "react";

// Shared libraries and interfaces
import { clsxMerge } from "shared/lib/helpers/styles";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { AccountDetailsSectionContainer } from "shared/ui/account-details/component-wrapper";

// Hooks
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

// Components
import { EmailSectionContent } from "./content";
import { EmailSectionHeader } from "./header";
import dayjs from "dayjs";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { EmailSectionSkeleton } from "./skeleton";
import { useShouldSendFollowUpEmailPolling } from "@/modules/pipeline/account-details/queries/mutations";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";

export const EmailSection: FC<PropsWithClassNameI> = ({ className }) => {
  const [isEmailDataInitialized, setIsEmailDataInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { mutateAsync: shouldSendFollowUpEmailPollingAsync } =
    useShouldSendFollowUpEmailPolling();

  const {
    setFocusedContact,
    contacts,
    emailFollowUpData,
    emailFollowUpRequestParams,
    campaign,
    setEmailFollowUpData,
  } = useAccountDetailsContext();

  const { account, contact: callContact, startAt, endAt } = useInCallContext();
  const { call } = useCallingContext();

  useEffect(() => {
    const contact = contacts?.find((c) => c.id === callContact?.id);

    if (contact) {
      setFocusedContact(contact);
    }
  }, [callContact, contacts]);

  /**
   * This useEffect checks if email polling is required based on missing email content
   * and triggers an async call to shouldSendFollowUpEmailAsync when conditions are met.
   * It manages loading state and ensures polling only happens once by tracking
   * isEmailDataInitialized. Runs when emailFollowUpData changes.
   */
  useEffect(() => {
    const callId = call?.customParameters.get("call_id");
    const isPollRequired =
      !emailFollowUpData?.email_subject || !emailFollowUpData?.email_body;

    if (!isPollRequired || !callId) {
      setIsEmailDataInitialized(true);
      setIsLoading(false);
      return;
    }

    if (!isEmailDataInitialized && callId && isPollRequired) {
      setIsEmailDataInitialized(true);
      (async () => {
        try {
          const emailData = await shouldSendFollowUpEmailPollingAsync({
            campaignId: campaign?.id as string,
            callId: callId,
            parameters: emailFollowUpRequestParams || {}, // Add your parameters here
          });

          setEmailFollowUpData?.(emailData);

          setIsLoading(false);
        } catch (error) {
          dd.error(
            `${ERROR_CATEGORIES.FOLLOW_UP_EMAIL}[EMAIL_SECTION] - Failed to poll email data`,
            {
              data: {
                error,
                campaignId: campaign?.id as string,
                callId: callId,
                parameters: emailFollowUpRequestParams,
              },
            }
          );
          setIsLoading(false);
        }
      })();
    }
  }, [emailFollowUpData]);

  /**
   * Logs an error if a call longer than 30 seconds has no follow-up email content.
   * This helps identify cases where we expect an email template but none was generated.
   * Only runs once on mount and requires both startAt and endAt timestamps.
   */
  useEffect(() => {
    if (!(startAt && endAt)) return;

    const callDuration =
      startAt && endAt
        ? Math.abs(dayjs(endAt).diff(startAt, "second"))
        : undefined;

    const { email_subject: subject, email_body: body } =
      emailFollowUpData || {};

    if (callDuration && callDuration > 30 && !subject && !body) {
      dd.error(
        `${ERROR_CATEGORIES.FOLLOW_UP_EMAIL}[EMAIL_SECTION] - Empty follow-up email provided for a call that is longer than 30 seconds`,
        {
          data: {
            contact: callContact,
            account,
            campaign,
          },
        }
      );
    }
  }, []);

  return (
    <AccountDetailsSectionContainer
      title={<EmailSectionHeader />}
      className={clsxMerge(
        "border border-[#4474E3]",
        "animate-pulse-alt",
        className
      )}
      style={
        {
          "--pulse-alt-start-color": "rgba(68,116,227,.7)",
          "--pulse-alt-end-color": "rgba(0,0,0,0)",
        } as CSSProperties
      }
    >
      {!isLoading ? (
        <EmailSectionContent
          subject={emailFollowUpData?.email_subject}
          body={emailFollowUpData?.email_body}
          emailId={emailFollowUpData?.email_id}
          accountExecutiveEmail={emailFollowUpData?.account_executive_email}
        />
      ) : (
        <EmailSectionSkeleton />
      )}
    </AccountDetailsSectionContainer>
  );
};
