import { FC } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export interface EmailFormActionsSectionI {
  isSendingEmail?: boolean;
  isSendEmailSuccess?: boolean;
  isEmailsValid?: boolean;
  isAIGenerating?: boolean;
  onSubmit?: () => void;
}

export const EmailFormActionsSection: FC<EmailFormActionsSectionI> = ({
  isSendingEmail,
  isSendEmailSuccess,
  // isEmailsValid,
  isAIGenerating,
  onSubmit,
}) => {
  return (
    <section
      className={clsxMerge(
        "flex items-center justify-end",
        "rounded-md bg-black/10",
        "gap-4 p-4"
      )}
    >
      <button
        type="button"
        className={clsxMerge("btn-ae-default min-w-[120px]", {
          loading: isSendingEmail,
          "btn-nofill pointer-events-none": isSendEmailSuccess,
        })}
        disabled={isAIGenerating}
        onClick={onSubmit}
      >
        {isSendEmailSuccess ? (
          <span className="flex items-center gap-1">
            SENT <CheckCircleIcon className="w-5" />
          </span>
        ) : (
          "SEND"
        )}
      </button>
    </section>
  );
};
