import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useMemo } from "react";
import { SECONDS_IN_HOUR } from "shared/lib/constants/time";
import { clsxMerge } from "shared/lib/helpers";
import { DayJs } from "shared/lib/helpers/date";

export const EndedLabelOnly = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsxMerge(
        "w-max rounded-xl bg-error-content px-3 py-1",
        "brand-typography-h7 animate-fadein text-white",
        className
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <span>Ended</span>
      </div>
    </div>
  );
};

export const EndedLabel = ({ className }: { className?: string }) => {
  const { startAt, endAt } = useInCallContext();

  const callDuration = useMemo(() => {
    if (!startAt || !endAt) {
      return "Ended";
    }

    const rawDuration = DayJs.duration(DayJs(endAt).diff(startAt));

    const isHours = rawDuration.asSeconds() >= SECONDS_IN_HOUR;

    return rawDuration.format(isHours ? "HH:mm:ss" : "mm:ss");
  }, [startAt, endAt]);

  return (
    <div
      className={clsxMerge(
        "w-max rounded-xl bg-error-content px-3 py-1",
        "brand-typography-h7 animate-fadein text-white",
        className
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <span>{callDuration}</span>
      </div>
    </div>
  );
};
