import { CAMPAIGN_USER_STATUS } from "shared/lib/constants/campaign";
import { CampaignUserStatusI } from "shared/lib/interfaces/campaign";

export const CampaignCardActionsVertical = ({
  campaignUserStatus,
  startCallingUrl,
  assessmentUrl,
  onClickTraining,
  salesFloorUrl,
  onClickLeaveCampaign,
}: {
  campaignUserStatus?: CampaignUserStatusI;
  startCallingUrl?: string;
  assessmentUrl?: string;
  salesFloorUrl?: string;
  onClickTraining?: () => Promise<void> | undefined;
  onClickLeaveCampaign?: () => Promise<void> | undefined;
}) => {
  const handleTrainingClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickTraining?.();
  };

  const handleLeaveCampaign = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickLeaveCampaign?.();
  };

  return (
    <>
      {CAMPAIGN_USER_STATUS.NOT_STARTED === campaignUserStatus && (
        <div className="flex flex-col items-center gap-3">
          <button
            className="btn-ae-default min-w-[190px]"
            onClick={handleTrainingClick}
          >
            Start Training
          </button>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.TRAINING_IN_PROGRESS === campaignUserStatus && (
        <div className="flex flex-col items-center gap-3">
          <button
            className="btn-ae-default min-w-[190px]"
            onClick={handleTrainingClick}
          >
            Continue Training
          </button>

          <button
            className="btn-ae-text relative -top-2"
            onClick={handleLeaveCampaign}
          >
            Leave Campaign
          </button>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.TRAINING_SUBMITTED === campaignUserStatus && (
        <div className="flex flex-col items-center gap-3">
          <a
            href={assessmentUrl}
            target="_blank"
            rel="noreferrer"
            className="btn-ae-default min-w-[190px]"
          >
            Start Audition
          </a>

          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTrainingClick}
            >
              Review Training
            </button>
          </div>

          <button
            className="btn-ae-text relative -top-2"
            onClick={handleLeaveCampaign}
          >
            Leave Campaign
          </button>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.AUDITION_IN_PROGRESS === campaignUserStatus && (
        <div className="flex flex-col items-center gap-3">
          <a
            href={assessmentUrl}
            target="_blank"
            rel="noreferrer"
            className="btn-ae-default min-w-[190px]"
          >
            Continue Audition
          </a>

          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTrainingClick}
            >
              Review Training
            </button>
          </div>

          <button
            className="btn-ae-text relative -top-2"
            onClick={handleLeaveCampaign}
          >
            Leave Campaign
          </button>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.AUDITION_IN_REVIEW === campaignUserStatus && <></>}

      {CAMPAIGN_USER_STATUS.AUDITION_RETRY === campaignUserStatus && (
        <div className="flex flex-col items-center gap-3">
          <a
            href={assessmentUrl}
            target="_blank"
            rel="noreferrer"
            className="btn-ae-default min-w-[190px]"
          >
            Retry Audition
          </a>

          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTrainingClick}
            >
              Review Training
            </button>
          </div>

          <button
            className="btn-ae-text relative -top-2"
            onClick={handleLeaveCampaign}
          >
            Leave Campaign
          </button>
        </div>
      )}

      {CAMPAIGN_USER_STATUS.AUDITION_REJECTED === campaignUserStatus && <></>}

      {CAMPAIGN_USER_STATUS.AUDITION_APPROVED === campaignUserStatus && (
        <div className="flex flex-col items-center gap-3">
          <a href={startCallingUrl} className="btn-ae-default min-w-[190px]">
            Start Calling
          </a>

          <a href={salesFloorUrl} className="btn-nofill min-w-[190px]">
            Go To Sales Floor
          </a>

          <div>
            <button
              className="ae-link btn-sm b-typography-body1 font-bold"
              onClick={handleTrainingClick}
            >
              Review Training
            </button>
          </div>

          <button
            className="btn-ae-text relative -top-2"
            onClick={handleLeaveCampaign}
          >
            Leave Campaign
          </button>
        </div>
      )}
    </>
  );
};
