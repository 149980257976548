import { FC } from "react";

import { AttachmentI } from "@/api/routes/email";
import { clsxMerge } from "shared/lib/helpers";
import { AttachmentPill } from "./attachment-pill";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "shared/ui/shadcn/dropdown-menu";
import { Button } from "shared/ui/shadcn/button";
import { FileTypeIcon } from "lucide-react";

interface EmailAttachmentsSectionPropsI {
  className?: string;
  attachmentOptions?: AttachmentI[];
  selectedAttachments?: AttachmentI[];
  onChange?: (attachment: AttachmentI) => void;
}

export const EmailAttachmentsSection: FC<EmailAttachmentsSectionPropsI> = ({
  className,
  attachmentOptions = [],
  selectedAttachments = [],
  onChange = () => {},
}) => {
  if (!attachmentOptions || attachmentOptions.length === 0) {
    return null;
  }

  return (
    <section>
      <div
        className={clsxMerge("flex items-center justify-between", className)}
      >
        <div className="typography-body-4-bold">Attachments</div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="link" className="text-[#0F35FF] typography-body-4">
              + Add attachment
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            {attachmentOptions.map((a) => (
              <DropdownMenuItem key={a.id} onClick={() => onChange(a)}>
                <FileTypeIcon className="w-3" />
                {a.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div
        className={clsxMerge("mt-2 flex flex-wrap items-center gap-2 py-3.5")}
      >
        {!selectedAttachments?.length && (
          <span className="text-[#ccc] typography-body-4-medium">
            No attachments
          </span>
        )}

        {selectedAttachments.map((attachment, idx) => (
          <AttachmentPill
            key={idx}
            title={
              attachmentOptions.find((opt) => opt.id === attachment.id)
                ?.name as string
            }
            onClose={() => onChange(attachment)}
          />
        ))}
      </div>
    </section>
  );
};
