import { Node } from "@tiptap/core";

// Custom DivBlock to replace <p> with <div>
const DivBlock = Node.create({
  name: "divBlock",
  group: "block",
  content: "inline*",
  parseHTML() {
    return [{ tag: "div" }];
  },
  renderHTML({ HTMLAttributes }) {
    return ["div", HTMLAttributes, 0];
  },
  addKeyboardShortcuts: () => ({
    Enter: ({ editor }) => {
      return editor.commands.setHardBreak();
    },
    "Shift-Enter": ({ editor }) => {
      return editor.commands.setHardBreak();
    },
  }),
});

export const TipTapExtensions = {
  DivBlock,
};
