import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/base/shadcn/dropdown-menu";

import { MenuTrigger } from "./menu-trigger";

import { AccountDetailsViewOptionI } from "./interface";
import { OptionsColumn } from "./options-column";

import { syncOptions } from "./utils";
import { LEFT_SIDE_OPTIONS, RIGHT_SIDE_OPTIONS } from "./constants";
import { useEffect, useState } from "react";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

const updateOptionsIfChanged = (
  /**
   * Current options are either left or right side options
   */
  currentOptions: AccountDetailsViewOptionI[],
  /**
   * New options are options has been changed might be left or right side
   */
  newOptions: AccountDetailsViewOptionI[],
  setOptions: (options: AccountDetailsViewOptionI[]) => void
) => {
  if (
    newOptions.some((o) => currentOptions.find((co) => co.label === o.label))
  ) {
    setOptions(
      currentOptions.map((option) => {
        const updatedOption = newOptions.find((o) => o.label === option.label);
        return updatedOption || option;
      })
    );
  }
};

export const AccountDetailsViewOptions = () => {
  const { setDialerToolkitViewOptions } = useAccountDetailsContext();

  const [leftSideOptions, setLeftSideOptions] = useState(
    syncOptions(LEFT_SIDE_OPTIONS)
  );
  const [rightSideOptions, setRightSideOptions] = useState(
    syncOptions(RIGHT_SIDE_OPTIONS)
  );

  const handleOnChange = (options: AccountDetailsViewOptionI[]) => {
    updateOptionsIfChanged(leftSideOptions, options, setLeftSideOptions);
    updateOptionsIfChanged(rightSideOptions, options, setRightSideOptions);
  };

  useEffect(() => {
    setDialerToolkitViewOptions?.([...leftSideOptions, ...rightSideOptions]);
  }, [leftSideOptions, rightSideOptions]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="animate-fadein">
        <MenuTrigger />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[350px]">
        <DropdownMenuLabel>Dialer toolkit options</DropdownMenuLabel>
        <div className="grid grid-cols-2">
          <div className="pr-2">
            <span className="pl-8 text-[#ccc] typography-body-5">
              Left Side
            </span>
            <OptionsColumn
              options={leftSideOptions}
              onChange={handleOnChange}
            />
          </div>
          <div className="border-l border-[#E5E7FF] pl-2">
            <span className="pl-8 text-[#ccc] typography-body-5">
              Right Side
            </span>
            <OptionsColumn
              options={rightSideOptions}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
