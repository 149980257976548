import { FC, useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import DomPurifier from "dompurify";
import ReactPlayer from "react-player";

import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { clsxMerge } from "shared/lib/helpers/styles";
import { DOM_PURIFIER_CONFIG } from "./constants";
import * as htmlProcessors from "./utils";

interface RenderHTMLPropsI extends PropsWithClassNameI {
  html: string;
  domPurifierConfig?: DomPurifier.Config;
}

const videoInitializer = () => {
  Array.from(document.querySelectorAll(".video-container"))?.forEach(
    (videoContainer) => {
      const contentURL = videoContainer.getAttribute("data-video-url");

      if (contentURL) {
        createRoot(videoContainer).render(
          <div className="flex w-full justify-center">
            <ReactPlayer
              url={contentURL}
              controls
              className="mt-8 h-[500px] w-full rounded-xl"
            />
          </div>
        );
      }
    }
  );
};
const RenderHTML: FC<RenderHTMLPropsI> = ({
  className,
  html,
  domPurifierConfig: customDomPurifierConfig,
}) => {
  const [isRendererReady, setIsRendererReady] = useState(false);

  useEffect(() => {
    (DomPurifier.addHook as any)?.(
      "afterSanitizeAttributes",
      (element: Element) => {
        htmlProcessors.processLink(element);
        htmlProcessors.processVideoEmbed(element);
      }
    );

    setIsRendererReady(true);

    return () => {
      DomPurifier.removeHook("afterSanitizeAttributes");

      setIsRendererReady(false);
    };
  }, [html]);

  // merge applicable values from props config into default config
  const effectiveDomPurifierConfig = useMemo(
    () => ({
      ...DOM_PURIFIER_CONFIG,
      FORCE_BODY: customDomPurifierConfig?.FORCE_BODY,
      ALLOWED_TAGS: [
        ...(DOM_PURIFIER_CONFIG.ALLOWED_TAGS || []),
        ...(customDomPurifierConfig?.ALLOWED_TAGS || []),
      ],
      ALLOWED_ATTR: [
        ...(DOM_PURIFIER_CONFIG.ALLOWED_ATTR || []),
        ...(customDomPurifierConfig?.ALLOWED_ATTR || []),
      ],
    }),
    [customDomPurifierConfig]
  );

  const processedHTML = useMemo(() => {
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(html);
    const content = isHTML
      ? html
      : htmlProcessors.convertPlainTextToHTMLWithLinks(html);

    return isRendererReady
      ? DomPurifier.sanitize(content, effectiveDomPurifierConfig)
      : null;
  }, [isRendererReady, html, effectiveDomPurifierConfig]);

  useEffect(() => {
    if (processedHTML) {
      videoInitializer();
    }
  }, [processedHTML]);

  return (
    <div
      className={clsxMerge("rendered-html", className)}
      dangerouslySetInnerHTML={{
        __html: processedHTML || "Loading content...",
      }}
    />
  );
};

export default RenderHTML;
