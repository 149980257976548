import { useEffect, useState } from "react";

import { modalHelpers } from "shared/lib/helpers/modalHelpers";

import { BookMeetingModal } from "@/components/modals/book-meeting-modal";
import { SUBMIT_BOOKED_MEETING_MODAL_ID } from "@/components/modals/book-meeting-modal/submit-booked-meeting-modal";
import { combineActivityNotes } from "@/helpers/activity-log";
import Calling from "@/helpers/calling";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { CampaignI } from "@/interfaces/campaign";
import { useCalendly } from "@/modules/calling/hooks/use-calendly";
import { useLiveTransfer } from "../../hooks/use-live-transfer";
import { QualificationCriteriaTableTogglesValueI } from "shared/lib/interfaces/qualification-criteria";

export const CALLING_MODULE_BOOK_MEETING_MODAL_ID =
  "calling_module_book_meeting_modal";

/**
 * Dialer Widget primary benefits from these params
 * @param disableCleanup
 * @param disableTriggerEndCall
 * @param ignoreActivityLog
 * @returns
 */
export const CallingModuleBookMeetingModal = ({
  disableCleanup,
  disableTriggerEndCall,
  ignoreActivityLog,
  notes: _notes,
  onSuccess,
}: {
  disableCleanup?: boolean;
  disableTriggerEndCall?: boolean;
  ignoreActivityLog?: boolean;
  notes?: string;
  onSuccess?: () => void;
}) => {
  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();
  const { campaign, call } = callingContext;
  const {
    account,
    contact,
    calendly_uri,
    calendlyEvent,
    activity_log,
    endAt,
    setQualificationCriteria,
  } = inCallContext;
  const { handleCalendlyBookingSuccess } = useCalendly();
  const { isLiveTransfer } = useLiveTransfer();

  const [notes, setNotes] = useState(_notes);
  useEffect(() => {
    setNotes(_notes);
  }, [_notes]);

  const handleQualificationCriteriaSubmitSuccess = (
    selectedQCs: QualificationCriteriaTableTogglesValueI
  ) => {
    setQualificationCriteria(selectedQCs);
  };

  const handleFinalSubmitSuccess = () => {
    if (!disableCleanup && endAt) {
      Calling.exit({
        context: callingContext,
        inCallContext,
      });
    }

    onSuccess?.();
  };

  useEffect(() => {
    if (
      !disableTriggerEndCall &&
      endAt &&
      calendlyEvent?.id &&
      !isLiveTransfer
    ) {
      modalHelpers.open(SUBMIT_BOOKED_MEETING_MODAL_ID);
    }
  }, [endAt, calendlyEvent?.id]);

  useEffect(() => {
    if (!ignoreActivityLog)
      setNotes(combineActivityNotes(inCallContext).join("\r\n"));
  }, [activity_log?.length]);

  return (
    <BookMeetingModal
      modalId={CALLING_MODULE_BOOK_MEETING_MODAL_ID}
      campaign={campaign as CampaignI}
      account={account}
      contact={contact}
      calendlyUri={calendly_uri}
      callId={call?.customParameters.get("call_id") as string}
      notes={notes}
      isAutoTriggerSubmitBookedMeetingModal={false}
      onQualificationCriteriaSubmitSuccess={
        handleQualificationCriteriaSubmitSuccess
      }
      onCalendlySubmitSuccess={handleCalendlyBookingSuccess}
      onFinalSubmitSuccess={handleFinalSubmitSuccess}
    />
  );
};
