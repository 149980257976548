import { useState, useEffect, useRef } from "react";

export const useTruncateMiddle = (text: string) => {
  const [truncatedText, setTruncatedText] = useState(text);
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    function updateTruncate() {
      if (elementRef.current) {
        const elementWidth = elementRef.current.clientWidth; // Get actual element width
        const avgCharWidth = 8; // Approximate width per character (adjust for font)
        const maxChars = Math.floor(elementWidth / avgCharWidth); // Max chars that fit

        if (text.length > maxChars) {
          const half = Math.floor(maxChars / 2);
          setTruncatedText(text.slice(0, half) + "..." + text.slice(-half));
        } else {
          setTruncatedText(text);
        }
      }
    }

    updateTruncate();
    window.addEventListener("resize", updateTruncate);
    return () => window.removeEventListener("resize", updateTruncate);
  }, [text]);

  return { truncatedText, elementRef };
};
