import { AccountSendEmailModal } from "@/components/modals/account-send-email-modal";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { Portal } from "react-portal";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ContactDetailI } from "shared/lib/interfaces/account";

const DIALER_WIDGET_SEND_EMAIL_MODAL_ID = "dialer-widget-send-email-modal";

export const SendEmailButton = () => {
  const { campaign } = useCallingContext();
  const { contact } = useInCallContext();

  const partialAccountDetails: ContactDetailI = useMemo(
    () => ({
      contact_id: contact?.id as string,
      email: contact?.email,
    }),
    [contact]
  );

  const handleOpenSendEmailModal = () =>
    modalHelpers.open(DIALER_WIDGET_SEND_EMAIL_MODAL_ID);

  const handleOnEmailSendSuccess = () => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
    });
  };

  return (
    <>
      <button
        className="btn-b-white-outline flex items-center border-[0.5px] px-2"
        onClick={handleOpenSendEmailModal}
      >
        <EnvelopeIcon className="mr-2 w-4" />
        Send Email
      </button>

      {/*@ts-ignore*/}
      <Portal>
        <AccountSendEmailModal
          modalId={DIALER_WIDGET_SEND_EMAIL_MODAL_ID}
          campaignId={campaign?.id as string}
          contact={partialAccountDetails}
          onSuccess={handleOnEmailSendSuccess}
        />
      </Portal>
    </>
  );
};
