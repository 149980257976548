import { CustomEventI } from "@/interfaces/events";

export const customEventData = (data: any) => ({ detail: data });

export function dispatchCustomEvent<T = unknown>({
  eventType,
  data,
}: CustomEventI<T>) {
  window.document.dispatchEvent(
    new CustomEvent(eventType, customEventData(data))
  );
}
