import { FC, useEffect, useMemo, useRef } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import History from "@tiptap/extension-history";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import _reject from "lodash/reject";

import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

import { EditorMenu } from "./editor-menu";
import { processEmailEditorContent } from "./utils";
import { COMMON_TIPTAP_EXTENSIONS } from "./constants";
import { TipTapExtensions } from "./editor-extensions";

export interface EmailEditorContentI {
  plainText?: string;
  htmlText?: string;
}

export interface EmailEditorPropsI extends PropsWithClassNameI {
  shouldImmediatelyRender?: boolean;
  menuClassName?: string;
  content?: string;
  initialContent?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  onChange?: (content: EmailEditorContentI) => void;
}

/**
 * Filter out Document and Paragraph to then replace them with custom blocks
 * to make Gmail-like formatting possible. That's needed to ensure that emails
 * are sent in the format that Gmail and other email clients expect.
 */
const TIPTAP_EXTENSIONS_FOR_EMAIL_EDITOR = [
  ..._reject(COMMON_TIPTAP_EXTENSIONS, ({ name }) =>
    ["paragraph"].includes(name)
  ),
  TipTapExtensions.DivBlock,
];

// Note: this component only has a `initialContent` prop, and no `value` prop because the live value of it is controlled interally by the Editor
export const EmailEditor: FC<EmailEditorPropsI> = ({
  shouldImmediatelyRender = true,
  className,
  menuClassName,
  content,
  initialContent,
  placeholder,
  error,
  disabled,
  onChange = () => {},
}) => {
  const isInitialMount = useRef(true); // Track initial render

  const editorExtensions = useMemo(
    () => [
      ...TIPTAP_EXTENSIONS_FOR_EMAIL_EDITOR,
      Link.extend({
        inclusive: false,

        // Override the default setLink command to enforce https
        addCommands() {
          return {
            setLink:
              (attributes) =>
              ({ commands }) => {
                let href = attributes.href;

                if (
                  href &&
                  !href.match(/^https?:\/\//) &&
                  !href.startsWith("mailto:")
                ) {
                  // Force https:// if no protocol is provided,
                  // but only for non-mailto links
                  href = `https://${href}`;
                }

                return commands.setMark("link", { href });
              },
            unsetLink:
              () =>
              ({ commands }) => {
                return commands.unsetMark("link");
              },
          };
        },
      }).configure({
        protocols: ["https", "mailto"],
        autolink: true,
        defaultProtocol: "https",
        shouldAutoLink: (url) => {
          // Autolink if it’s a domain-like string (with or without protocol)
          const looseUrlPattern =
            /^(https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/.*)?$/;

          return looseUrlPattern.test(url);
        },
        HTMLAttributes: {
          rel: null,
          target: null,
        },
      }),
      History.configure({
        depth: 10,
      }),
      Placeholder.configure({
        placeholder,
      }),
      CharacterCount.configure({
        mode: "textSize",
        limit: 2000,
      }),
    ],
    [placeholder]
  );

  const editor = useEditor({
    content: content || initialContent,
    extensions: editorExtensions,
    immediatelyRender: shouldImmediatelyRender,
    parseOptions: {
      preserveWhitespace: "full",
    },
    editorProps: {
      attributes: {
        class: clsxMerge(
          "focus:outline-none typography-body-4 [&>p]:mb-4",
          "[&_a]:text-[#0F35FF] [&_a]:underline [&_a]:cursor-pointer"
        ),
      },
      handleKeyDown: (view, event) => {
        if (event.key === " ") {
          console.log("Spacebar detected in editor");
        }

        return false; // Let TipTap handle the event
      },
    },
    onUpdate: ({ editor }) => {
      onChange(
        processEmailEditorContent({
          plainText: editor.getText(),
          htmlText: editor.getHTML(),
        })
      );
    },
  });

  useEffect(() => {
    if (isInitialMount.current) {
      // Only set content on initial mount
      editor?.commands.setContent(initialContent || "", true, {
        preserveWhitespace: "full",
      });
      isInitialMount.current = false;
    }
    // Do not reset content on subsequent initialContent changes
  }, [initialContent, editor]);

  useEffect(() => {
    if (content) {
      if (!editor) {
        return;
      }

      const { from, to } = editor.state.selection;

      editor.commands.setContent(content || "", true, {
        preserveWhitespace: "full",
      });

      /**
       * Set the cursor back to the same position
       * because the content is set to an empty string
       * and the cursor is lost or the end of the content
       */
      editor.commands.setTextSelection({ from, to });
    }
  }, [content, editor]);

  useEffect(() => {
    editor?.setEditable(!disabled);
  }, [disabled, editor]);

  return (
    <div>
      <div className={clsxMerge("rounded-lg bg-white", className)}>
        <EditorMenu editor={editor} className={menuClassName} />

        <div className="p-4" onMouseDown={() => editor?.commands.focus()}>
          <EditorContent editor={editor} className="min-h-[60px] cursor-text" />
        </div>
      </div>

      {error && (
        <div className="text-error-content mt-1 flex items-center gap-1 text-xs">
          <ExclamationCircleIcon className="w-5" />
          {error}
        </div>
      )}
    </div>
  );
};
