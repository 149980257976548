import { FC, ReactElement, ReactNode, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import _noop from "lodash/noop";

import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { clsxMerge } from "shared/lib/helpers";
import { QueryResultI } from "shared/lib/interfaces/react-query";

interface DataStatesWrapperPropsI {
  viewName?: string; // The name of the view, like "Today's Performance", "Productivity", etc.

  api: QueryResultI<unknown> | undefined;

  loading?: ReactNode;
  loadingClassName?: string;

  error?: ReactNode;
  errorClassName?: string;

  empty?: ReactNode;
  emptyClassName?: string;

  children?: ReactNode;
}

/**
 * This component is a wrapper for pending, success, error and empty states
 * for data from API calls / react query. See the example of usage on the user
 * performance dashboard.
 *
 * @component
 * @param viewName - Name of a view, which should be used for error and empty states.
 * @param api - collection of API states and, optionally, refetch function.
 * @param api.isFetching - State: API is fetching data.
 * @param api.isSuccess - State: API has successfully fetched data.
 * @param api.isError - State: API has failed to fetch data.
 * @param api.isEmpty - State: API has successfully fetched data, but it's empty. Usually it's a user defined state.
 * @param refetch - Function to re-fetch data from API in case an error occurred.
 * @param loading - Custom loading state.
 * @param loadingClassName - Custom loading state class name.
 * @param error - Custom error state.
 * @param errorClassName - Custom error state class name.
 * @param empty - Custom empty state.
 * @param emptyClassName - Custom empty state class name.
 * @param children - Children to render when data is successfully fetched.
 */
export const DataStatesWrapper: FC<DataStatesWrapperPropsI> = ({
  viewName = "data",
  api,
  loading,
  loadingClassName,
  error,
  errorClassName,
  empty,
  emptyClassName,
  children,
}) => {
  const {
    isFetching = false,
    isSuccess = false,
    isError = false,
    isEmpty = false,
    refetch = _noop,
  } = api || {};

  // Error content is reused across both API error and error boundary fallback.
  const errorContent = useMemo(
    () =>
      (error as ReactElement) || (
        <div
          className={clsxMerge(
            "flex h-full w-full flex-col items-center justify-center gap-3",
            errorClassName
          )}
        >
          <h4 className="typography-header-8-semibold text-center">
            We’ve detected an error
          </h4>

          <span className="typography-body-4 max-w-[220px] text-center">
            An error occurred while loading {viewName}.
          </span>

          {refetch && (
            <button className="btn-ae-default" onClick={() => refetch()}>
              Reload
            </button>
          )}
        </div>
      ),
    [viewName, refetch]
  );

  if (isError) {
    return errorContent;
  }

  if (isFetching) {
    if (loading === null) {
      return null;
    }

    return (
      loading || (
        <CenteredSpinner
          className={clsxMerge("h-full w-full", loadingClassName)}
        />
      )
    );
  }

  if (isSuccess) {
    if (isEmpty) {
      return (
        empty || (
          <div
            className={clsxMerge(
              "flex h-full min-h-[120px] w-full flex-col items-center justify-center",
              "rounded-xl bg-[#F4F4F4] font-normal",
              emptyClassName
            )}
          >
            <span className="text-center text-[#666]">
              No {viewName} data available.
            </span>
          </div>
        )
      );
    }

    return <ErrorBoundary fallback={errorContent}>{children}</ErrorBoundary>;
  }

  return null;
};
