import clsx from "clsx";

import { Field as FormikField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Createable from "react-select/creatable";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { clsxMerge } from "shared/lib/helpers";

export interface BadgeI {
  label: string;
  value: string;
}

export interface BadgeValidationI {
  validValues: string[];
  invalidValues: string[];
}

interface BadgeInputPropsI {
  name: string;
  value?: Array<BadgeI>;
  label?: string;
  placeholder?: string;
  touched?: boolean;
  errors?: string | string[];
  className?: string;
  labelClassName?: string;
  labelContentClassName?: string;
  disabled?: boolean;
  presetOptions?: BadgeI[];

  // badge-validation is separate from the formik validation because this one occurs on the individual values
  // and may require custom (backend) logic
  onValidateBadge?: (value: string[]) => Promise<BadgeValidationI>;
  badgeValidationErrorMessage?: string;
  badgeValidationSuccessMessage?: string;
  badgeValidationErrorClassName?: string;
  badgeValidationSuccessClassName?: string;
}

export const BadgeInput = (Field: typeof FormikField) => {
  // eslint-disable-next-line react/display-name
  return ({
    name,
    label,
    value: _value,
    placeholder,
    touched,
    errors,
    className,
    labelClassName,
    labelContentClassName,
    disabled = false,
    presetOptions = [],

    onValidateBadge = async () => ({ validValues: [], invalidValues: [] }),
    badgeValidationErrorMessage,
    badgeValidationSuccessMessage,
    badgeValidationErrorClassName,
    badgeValidationSuccessClassName,
  }: BadgeInputPropsI) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState<Array<BadgeI> | undefined>(_value);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inputValue, setInputValue] = useState<string>();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formikContext = useFormikContext();

    // locally store list of invalid values so we can refer to them when styling those badges uniquely
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [invalidValues, setInvalidValues] = useState<Array<string>>([]);

    const safeValues = (values: Array<any>) => {
      return (
        values?.map((v) =>
          typeof v === "string" ? { label: v, value: v } : v
        ) || []
      );
    };

    const addLabel = (value: string) => {
      if (value) {
        const newValue = { label: value, value };

        const { values: allValues } = formikContext;
        // @ts-ignore
        const values = safeValues((allValues as Array<BadgeI>)?.[name]);

        if (!values?.some((v: BadgeI) => v?.value === value)) {
          const allLabels = [
            // @ts-ignore
            ...(values || []),
            newValue,
          ];

          formikContext.setFieldValue(name, allLabels);
          setValue(allLabels);
        }

        setInputValue("");
      }
    };

    const onInputChange = (
      textInput: string,
      { action }: { action?: string }
    ) => {
      if (action === "input-change") {
        if (textInput && textInput.endsWith(",")) {
          const label = textInput.slice(0, -1); // trim off comma
          addLabel(label);
        } else {
          setInputValue(textInput);
        }
      }
    };

    const onChange = (option: any) => {
      setValue(option);
      formikContext.setFieldValue(name, option);
      setInputValue("");
    };

    const onBlur = (e: any) => {
      addLabel(e?.target?.value);
    };

    const onKeyEnter = (e: any) => {
      if (e.key === "Enter") {
        addLabel(e?.target?.value);
        event?.preventDefault();
      }
    };

    const handleBadgeValidations = async (badges: BadgeI[] | undefined) => {
      const badgeValues = badges?.map((badge) => badge.value);

      if (badgeValues) {
        const { invalidValues } = await onValidateBadge(badgeValues);
        setInvalidValues([...invalidValues]);
      }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      handleBadgeValidations(value);
    }, [value]);

    return (
      <div className={twMerge(clsx("form-control mb-4 w-full", className))}>
        {!!label && (
          <label className={twMerge(clsx("label", labelClassName))}>
            <span
              className={twMerge(
                clsx("label-text font-bold", labelContentClassName)
              )}
            >
              {label}
            </span>
          </label>
        )}

        <Field name={name}>
          {/* {({ field }: FieldProps<string[]>) => { */}
          {() => {
            return (
              <Createable
                className={twMerge(
                  clsx("input b-typography-body1 h-auto min-h-[48px] p-0", {
                    "pointer-events-none cursor-not-allowed opacity-80":
                      disabled,
                    "border-error-content": errors && touched,
                  })
                )}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: "rgba(0,0,0,0.2)",
                    borderRadius: "8px",
                    width: "100%",
                    height: "auto",
                    minHeight: "48px",
                    boxShadow: "none",
                    paddingLeft: "8px",
                    ":focus": {
                      borderColor: "rgba(0,0,0,0.2)",
                      boxShadow: "none",
                    },
                    ":hover": {
                      borderColor: "rgba(0,0,0,0.2)",
                      boxShadow: "none",
                    },
                  }),
                  dropdownIndicator: (baseStyles) => ({
                    ...baseStyles,
                    cursor: "pointer",
                  }),
                  clearIndicator: (baseStyles) => ({
                    ...baseStyles,
                    cursor: "pointer",
                  }),
                  multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    fontSize: "12px",
                  }),
                  multiValue: (baseStyles, { data }) => ({
                    ...baseStyles,
                    overflow: "hidden",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    border: invalidValues?.includes(data.value)
                      ? "solid 1px red"
                      : "solid 1px #E4E4E7",
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                options={presetOptions}
                noOptionsMessage={() => null}
                placeholder={placeholder}
                onInputChange={onInputChange}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyEnter}
                inputValue={inputValue}
                value={value}
                isMulti
              />
            );
          }}
        </Field>

        {errors && touched && (
          <div className="mt-1 flex items-center text-xs">
            <ExclamationCircleIcon className="text-error-content mr-1 h-5 w-5 bg-none" />
            <div className="text-error-content">{errors}</div>
          </div>
        )}

        {/* Badge validation error message */}
        {badgeValidationErrorMessage && invalidValues.length > 0 && (
          <div
            className={clsxMerge(
              "text-error-content mt-1 flex w-fit items-center text-xs",
              badgeValidationErrorClassName
            )}
          >
            <XCircleIcon className="mr-1 h-5 w-4 bg-none" />
            {`(${invalidValues.length}) ${badgeValidationErrorMessage}`}
          </div>
        )}

        {/* Badge validation success message */}
        {badgeValidationSuccessMessage &&
          invalidValues.length == 0 &&
          value &&
          value.length > 0 && (
            <div
              className={clsxMerge(
                "flex w-fit items-center rounded-lg bg-[#0EAB0080]",
                "mt-1 px-3 py-1",
                "text-xs text-white",
                badgeValidationSuccessClassName
              )}
            >
              <CheckCircleIcon className="mr-1 h-5 w-4 bg-none" />
              {badgeValidationSuccessMessage}
            </div>
          )}
      </div>
    );
  };
};

export default BadgeInput;
