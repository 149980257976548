import { FC, useEffect, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { DEFAULT_MARKDOWN_TEXT } from "shared/lib/constants/markdown";

const MAX_CHARS = 20_000;

export interface ScriptEditorSectionProps extends PropsWithClassNameI {
  text?: string;
  onChange: (value: string) => void;
}

export const ScriptEditorSection: FC<ScriptEditorSectionProps> = ({
  className,
  text,
  onChange,
}) => {
  const [value, setValue] = useState(text || DEFAULT_MARKDOWN_TEXT);

  const handleOnChange = (event: any) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    if (text) {
      setValue(text);
    }
  }, [text]);

  return (
    <div className={clsxMerge("h-full", className)}>
      <textarea
        className={clsxMerge(
          "textarea h-full w-full rounded-lg border border-[#E4E4E7] p-3",
          { "border-error-content": value.length > MAX_CHARS }
        )}
        value={value}
        onChange={handleOnChange}
      />

      <span
        className={clsxMerge("typography-tiny", {
          "text-error-content": value.length > MAX_CHARS,
        })}
      >
        {MAX_CHARS - value.length}/{MAX_CHARS}{" "}
        {value.length > MAX_CHARS ? "" : "characters left"}
      </span>
    </div>
  );
};
