import { EnvironmentI } from "shared/lib/interfaces/environment";
import {
  ENVIRONMENT,
  REMOTE_ENVIRONMENTS,
} from "shared/lib/constants/environment";

/** Checks if the environment is remote, basically non-local, i.e. staging or
 * production
 *
 * @param environment
 */
export const checkIfRemoteEnvironment = (
  environment: string | EnvironmentI | undefined
) => {
  if (!environment) {
    return false;
  }

  return REMOTE_ENVIRONMENTS.includes(environment as EnvironmentI);
};

/** Checks if the environment is a local environment
 *
 * @param environment
 */
export const checkIfLocalEnvironment = (
  environment: string | EnvironmentI | undefined
) => {
  if (!environment) {
    return true;
  }

  return environment === ENVIRONMENT.LOCAL;
};

/**
 * Returns the current environment, defaulting to local if not set
 */
export const getCurrentEnvironment = () => {
  return (process.env.NEXT_PUBLIC_ENV as EnvironmentI) || ENVIRONMENT.LOCAL;
};
