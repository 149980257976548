import CallingFollowUpModal from "@/components/modules/calling/modals/follow-up-modal";
import { widgets } from "@/components/shared/widgets";

import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { WIDGETS } from "@/constants/widgets";
import { useDialerWidgetContext } from "@/context/dialer-v2/widget";
import { dispatchCustomEvent } from "@/helpers/events";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

import { HeartIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Portal } from "react-portal";
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

const DIALER_WIDGET_DURING_CALL_CALLBACK_MODAL =
  "dialer-widget-during-call-callback-modal";
const DIALER_WIDGET_AFTER_CALL_CALLBACK_MODAL =
  "dialer-widget-after-call-callback-modal";

export const SetCallbackButton = () => {
  const { callbackData } = useInCallContext();
  const { notes } = useDialerWidgetContext();
  const callStatus = useDialerCallStatus();

  const handleOpenModal = () => {
    if (DIALER_CALL_STATUS.DURING === callStatus)
      modalHelpers.open(DIALER_WIDGET_DURING_CALL_CALLBACK_MODAL);

    if (DIALER_CALL_STATUS.AFTER === callStatus) {
      modalHelpers.close(DIALER_WIDGET_DURING_CALL_CALLBACK_MODAL);
      modalHelpers.open(DIALER_WIDGET_AFTER_CALL_CALLBACK_MODAL);
    }
  };

  const handleOnSuccessSetCallback = () => {
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD,
    });
    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
    });

    widgets.close?.({ id: WIDGETS.DIALER });
  };

  useEffect(() => {
    if (
      DIALER_CALL_STATUS.AFTER === callStatus &&
      callbackData?.isCallbackSet
    ) {
      modalHelpers.close(DIALER_WIDGET_DURING_CALL_CALLBACK_MODAL);
      modalHelpers.open(DIALER_WIDGET_AFTER_CALL_CALLBACK_MODAL);
    }
  }, [callStatus]);

  return (
    <>
      <button
        className={clsxMerge(
          "btn-b-white flex items-center border-[0.5px] px-3",
          {
            "disabled:bg-white/70":
              DIALER_CALL_STATUS.DURING === callStatus &&
              callbackData?.isCallbackSet,
          }
        )}
        onClick={handleOpenModal}
        disabled={
          DIALER_CALL_STATUS.DURING === callStatus &&
          callbackData?.isCallbackSet
        }
      >
        <HeartIcon className="mr-2 w-4" />
        Set Callback
      </button>

      {/*@ts-ignore*/}
      <Portal>
        <CallingFollowUpModal
          isNotes={false}
          modalId={DIALER_WIDGET_DURING_CALL_CALLBACK_MODAL}
        />

        <CallingFollowUpModal
          notes={notes}
          isFinal
          modalId={DIALER_WIDGET_AFTER_CALL_CALLBACK_MODAL}
          onSuccess={handleOnSuccessSetCallback}
        />
      </Portal>
    </>
  );
};
