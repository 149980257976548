import { FC } from "react";
import { Check, CircleMinus, Sparkles } from "lucide-react";

import {
  QualificationCriteriaTableTogglesValueI,
  QualificationCriteriaTypeWithBonusI,
} from "shared/lib/interfaces/qualification-criteria";
import { Checkbox } from "shared/ui/shadcn/checkbox";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { LucideIconWithAIGradient } from "shared/ui/icons/lucide";

export const QC_TABLE_TOGGLE_STYLES = {
  REGULAR: "regular",
  AI: "ai",
};

export type QCTableToggleStyleI = ValueOfObjectFields<
  typeof QC_TABLE_TOGGLE_STYLES
>;

export const QCCheckbox: FC<{
  isEditable?: boolean;
  value?: QualificationCriteriaTableTogglesValueI;
  type: QualificationCriteriaTypeWithBonusI;
  onChange: (value: QualificationCriteriaTableTogglesValueI) => void;
  toggleStyle?: QCTableToggleStyleI;
}> = ({
  toggleStyle = QC_TABLE_TOGGLE_STYLES.REGULAR,
  isEditable,
  value: _value,
  type,
  onChange,
}) => {
  const value = _value as QualificationCriteriaTableTogglesValueI;

  if (!value) {
    return null;
  }

  if (!isEditable && toggleStyle === QC_TABLE_TOGGLE_STYLES.AI) {
    return (
      <div className="flex w-full items-center justify-center">
        {value[type] ? (
          <>
            <LucideIconWithAIGradient size={24} icon={Check} />
            <LucideIconWithAIGradient size={12} icon={Sparkles} />
          </>
        ) : (
          <div className="relative h-5 w-5">
            <LucideIconWithAIGradient size={20} icon={CircleMinus} />
            <div className="app-gradient absolute left-[5px] top-[9px] h-[2px] w-2.5 rounded-full" />
          </div>
        )}
      </div>
    );
  }

  return (
    <label className="flex-center flex h-9 w-full justify-center">
      <Checkbox
        disabled={!isEditable}
        checked={value[type] ?? false}
        onCheckedChange={() => onChange({ ...value, [type]: !value[type] })}
      />
    </label>
  );
};
